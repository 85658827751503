import React, { useState } from "react";
import { heroes } from "../Heroes.tsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Hero } from "../../types.tsx";
import axios from "axios";
// Example list with hero_id, picked, and banned stats

interface HeroStatsProps {
  isRefreshingLoading:boolean;
  heroes: Hero[];
  updateHero: (heroId: number, updates: Partial<Hero>) => Promise<void>;
  refreshHeroStatistics : () =>Promise<void>

}

const HeroStatsDisplay: React.FC<HeroStatsProps> = ({ isRefreshingLoading, heroes, updateHero , refreshHeroStatistics }:any) => {

  const handleHeroUpdate = async (heroId: number, updates: Partial<Hero>) => {
    try {
      await updateHero(heroId, updates); // Call the update function provided by the parent
      console.log(`Hero ${heroId} updated with`, updates);
    } catch (error) {
      console.error('Error updating hero:', error);
    }
  };
  // 
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [selectedHero, setSelectedHero] = useState<any | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);


  const tiers = ["S", "A", "B", "E", "F"];

  const tierRanges: Record<string, [number, number]> = {
    S: [800, 1000], // Gold cost range for S tier
    A: [600, 800],  // Gold cost range for A tier
    B: [400, 600],  // Gold cost range for B tier
    E: [200, 400],  // Gold cost range for E tier
    F: [0, 200],  // Gold cost range for F tier
  };
  
  const handleImageClick = (hero: any) => {
    
    setSelectedHero(hero);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedHero(null);
  };
  return (
    <> 
    {isRefreshingLoading ? <>
    <h2>Loading...</h2>
    </>:<> <div style={{padding:'1rem 0rem 0rem 1rem',
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "start",
        // marginTop: "1rem",
        background:'#dd183b59'
      }}
      >
         <Button
            onClick={async () => {
              refreshHeroStatistics()
              handleDialogClose()
            }}
            style={{
              backgroundColor: 'green',
              color: 'white',
            }}
          >
            Refresh hero statistics
          </Button>
      </div>
    
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "center",
        // marginTop: "1rem",
        background:'#dd183b59'
      }}
    >
         
<div>
{tiers.map((tier) => {
  // Filter heroes for the current tier based on gold_cost
  const tierHeroes = heroes
    .filter((hero: any) => {
      const [min, max] = tierRanges[tier];
      return hero.gold_cost >= min && hero.gold_cost <= max;
    })
    .sort((a: any, b: any) => b.gold_cost - a.gold_cost); // Sort by gold_cost descending

  return (
    <div key={tier} style={{ padding: "1rem" }}>
      {/* Tier Title */}
      <h2
        style={{
          textAlign: "left",
          color: "gold",
          marginBottom: "1rem",
          marginTop: "0rem",
        }}
      >
        {tier}-Tier Heroes
      </h2>

      {/* Tier Heroes */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.2rem",
          justifyContent: "left",
        }}
      >
        {tierHeroes.map((hero: any) => {
          const isUsed = false;

          return (
            <div
              key={hero.name.replace("npc_dota_hero_", "")}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                background: "#f57a90",
                padding: "0.2rem",
                width: "4.2rem",
                opacity: isUsed ? "0.3" : "1",
              }}
              className={isUsed ? "0.3" : "hero_img"}
            >
              {/* Hero Image */}
              <img
                src={`/assets/img/heroes/${hero.name.replace(
                  "npc_dota_hero_",
                  ""
                )}.png`}
                alt={hero.name}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onMouseEnter={() => {
                  if (!isUsed) {
                    setCurrentId(String(hero.id)); // Update the current hero ID
                  }
                }}
                onClick={() =>
                  handleImageClick({
                    id: hero.id,
                    cost: hero.gold_cost,
                    pick_points: hero.pick_points,
                    win_points: hero.win_points,
                    hero_picked: hero.picked,
                    hero_banned: hero.banned,
                    ban_points: hero.ban_points,
                    localized_name: hero.localized_name,
                    name: hero.name,
                    picked: hero.picked,
                    banned: hero.banned,
                  })
                }
              />
              {/* Gold Cost */}
              <div
                style={{
                  position: "absolute",
                  top: "0.2rem",
                  left: "0",
                  width: "100%",
                  color: "white",
                  fontSize: "0.7rem",
                  zIndex: 2,
                  display: "flex",
                  flexDirection: "column",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "0.2rem 0.5rem",
                  }}
                >
                  <span
                    style={{
                      background: "#fcba03",
                      padding: "0.2rem 0.5rem",
                      color: "black",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      fontSize: "0.5rem",
                    }}
                  >
                    <strong>Gold:</strong> {hero.gold_cost}
                  </span>
                </div>
              </div>

              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "0.2rem",
                    color: "black",
                    gap:'0.5rem',
                    fontSize:'0.5rem'
                  }}
                >
                  <span   style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                    fontSize:'0.5rem'
                  }}>
                    <strong>Pick:</strong> {hero.picked}
                  </span>
                  <span   style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                    fontSize:'0.5rem'
                  }}> 
                    <strong>Ban:</strong> {hero.banned}
                  </span>
                </div>
              {/* Hero Image */}
             
            </div>
          );
        })}
      </div>
    </div>
  );
})}

  </div>
      
      {/* Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{ "& .MuiDialog-paper": { minWidth: "50%", background:'#f57a90' } }}
      >
        <DialogTitle>
          {selectedHero?.localized_name || "Hero Details"}
        </DialogTitle>
        <DialogContent>
          {selectedHero && (
            <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
              <img
                src={`/assets/img/heroes/${selectedHero.name.replace(
                  "npc_dota_hero_",
                  ""
                )}.png`}
                alt={selectedHero.localized_name}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "1rem",
                }}
              />
              {/* <div>
                <strong>Primary Attribute:</strong> {selectedHero.primary_attr}
              </div> */}
              {/* <div>
                <strong>Attack Type:</strong> {selectedHero.attack_type}
              </div>
              <div>
                <strong>Roles:</strong> {selectedHero.roles.join(", ")}
              </div>
              <div>
                <strong>Legs:</strong> {selectedHero.legs}
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem"}}
                >
                  <strong style={{minWidth:'fit-content' }}>Gold Cost:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.cost}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        cost: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                <strong style={{minWidth:'fit-content' }}>Win Points:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.win_points}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        win_points: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                 <strong style={{minWidth:'fit-content' }}>Pick Points:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.pick_points}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        pick_points: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <strong style={{minWidth:'fit-content' }}>Ban Points:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.ban_points}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        ban_points: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <strong style={{minWidth:'fit-content' }}>Picked:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.picked}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        picked: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <strong style={{minWidth:'fit-content' }}>Banned:</strong>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={selectedHero.banned}
                    onChange={(e) =>
                      setSelectedHero({
                        ...selectedHero,
                        banned: e.target.value,
                      })
                    }
                    type="number"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
                
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Close
          </Button>
          <Button
            onClick={async () => {
              handleHeroUpdate(selectedHero.id, {
                gold_cost: selectedHero.cost,
                win_points: selectedHero.win_points,
                pick_points: selectedHero.pick_points,
                ban_points: selectedHero.ban_points,
                picked: selectedHero.picked,
                banned: selectedHero.banned,
              })
              handleDialogClose()
            }}
            style={{
              backgroundColor: 'green',
              color: 'white',
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div></>}
   
    </>
  );
};

export default HeroStatsDisplay;
