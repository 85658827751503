// TeamList.tsx

export interface Player {
  role: string;
  name: string;
  rank: string;
}

export interface Team {
  name: string;
  rank: string;
  winrate: string;
  avgKills: string;
  logo: string;
  players: Player[];
}

export const teams: Team[] = [
  {
    name: "TEAM FALCONS",
    rank: "#1",
    winrate: "61%",
    avgKills: "28.1",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM_FALCONS.png",
    players: [
      { role: "Core", name: "SKITTER", rank: "96" },
      { role: "Mid", name: "MARLINE", rank: "1" },
      { role: "Offlane", name: "ATF", rank: "13" },
      { role: "Support", name: "CRIT-", rank: "54" },
      { role: "Full Support", name: "SNEYKING", rank: "56" },
    ],
  },
  {
    name: "GAIMIN GLADIATORS",
    rank: "#2",
    winrate: "63%",
    avgKills: "22.8",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/GAIMIN-GLADIATORS.png",
    players: [
      { role: "Core", name: "WATSON", rank: "3" },
      { role: "Mid", name: "QUINN", rank: "16" },
      { role: "Offlane", name: "ACE", rank: "430" },
      { role: "Support", name: "TOFU", rank: "36" },
      { role: "Full Support", name: "SELERI", rank: "331" },
    ],
  },
  {
    name: "XTREME GAMING",
    rank: "#3",
    winrate: "67%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/XTREME-GAMING.png",
    players: [
      { role: "Core", name: "AME", rank: "26" },
      { role: "Mid", name: "XM", rank: "10" },
      { role: "Offlane", name: "XXS", rank: "22" },
      { role: "Support", name: "XINQ", rank: "215" },
      { role: "Full Support", name: "DY", rank: "442" },
    ],
  },
  {
    name: "TEAM LIQUID",
    rank: "#4",
    winrate: "58%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/team_liquid.png",
    players: [
      { role: "Core", name: "MICKE", rank: "-" },
      { role: "Mid", name: "NISHA", rank: "-" },
      { role: "Offlane", name: "SaberLight", rank: "128" },
      { role: "Support", name: "BOXI", rank: "289" },
      { role: "Full Support", name: "INSANIA", rank: "239" },
    ],
  },
  {
    name: "TEAM SPIRIT  ",
    rank: "#5",
    winrate: "58%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM-SPIRIT.png",
    players: [
      { role: "Core", name: "RADDAN", rank: "21" },
      { role: "Mid", name: "LARL", rank: "108" },
      { role: "Offlane", name: "COLLAPSE", rank: "27" },
      { role: "Support", name: "MIRA", rank: "347" },
      { role: "Full Support", name: "MIPOSHKA", rank: "127" },
    ],
  },
  {
    name: "BETBOOM TEAM",
    rank: "#6",
    winrate: "44%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM_BB.png",
    players: [
      { role: "Core", name: "SAIKA", rank: "2" },
      { role: "Mid", name: "GPJ", rank: "5" },
      { role: "Offlane", name: "MEIRA", rank: "-" },
      { role: "Support", name: "SAVE", rank: "52" },
      { role: "Full Support", name: "TORONTOTOKYO", rank: "37" },
    ],
  },
  {
    name: "TUNDRA ESPORTS",
    rank: "#7",
    winrate: "65%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/THUNDRA-TEAM.png",
    players: [
      { role: "Core", name: "SHAD", rank: "44" },
      { role: "Mid", name: "MIDONE", rank: "53" },
      { role: "Offlane", name: "33", rank: "175" },
      { role: "Support", name: "SAKSA", rank: "11" },
      { role: "Full Support", name: "WHITEMON", rank: "134" },
    ],
  },
  {
    name: "G2.IG",
    rank: "#8",
    winrate: "56%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/G2IG.png",
    players: [
      { role: "Core", name: "MONET", rank: "161" },
      { role: "Mid", name: "NOTHINGTOSAY", rank: "10" },
      { role: "Offlane", name: "JT-", rank: "94" },
      { role: "Support", name: "BOBOKA", rank: "208" },
      { role: "Full Support", name: "XNOVA", rank: "378" },
    ],
  },
  {
    name: "1WIN",
    rank: "#9",
    winrate: "63%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/1WIN-IMAGE.png",
    players: [
      { role: "Core", name: "MUNKUSHI", rank: "83" },
      { role: "Mid", name: "CHIRA_JUNIOR", rank: "57" },
      { role: "Offlane", name: "CLOUD", rank: "102" },
      { role: "Support", name: "SWEDENSTRONG", rank: "48" },
      { role: "Full Support", name: "RESPECT", rank: "228" },
    ],
  },
  {
    name: "NOUNS",
    rank: "#12",
    winrate: "55%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/nouns.png",
    players: [
      { role: "Core", name: "YUMA", rank: "43" },
      { role: "Mid", name: "COPY", rank: "107" },
      { role: "Offlane", name: "GUNNAR", rank: "321" },
      { role: "Support", name: "LELIS", rank: "146" },
      { role: "Full Support", name: "FLY", rank: "190" },
    ],
  },
  {
    name: "HEROIC",
    rank: "#13",
    winrate: "55%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/HEROIC.png",
    players: [
      { role: "Core", name: "K1", rank: "-" },
      { role: "Mid", name: "4NALOG", rank: "-" },
      { role: "Offlane", name: "DAVAI LAMA", rank: "18" },
      { role: "Support", name: "SCOFIELD", rank: "11" },
      { role: "Full Support", name: "KJ", rank: "271" },
    ],
  },
  {
    name: "AURORA",
    rank: "#14",
    winrate: "56%",
    avgKills: "33",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/AURORA.png",
    players: [
      { role: "Core", name: "23SAVAGE", rank: "-" },
      { role: "Mid", name: "LORENOF", rank: "-" },
      { role: "Offlane", name: "JABZ", rank: "-" },
      { role: "Support", name: "Q", rank: "-" },
      { role: "Full Support", name: "OLI", rank: "-" },
    ],
  },
  {
    name: "CLOUD9",
    rank: "#15",
    winrate: "33%",
    avgKills: "21",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/CLOUD9.png",
    players: [
      { role: "Core", name: "WATSON", rank: "4" },
      { role: "Mid", name: "NO[0]ONE", rank: "65" },
      { role: "Offlane", name: "DM", rank: "32" },
      { role: "Support", name: "KATAOMI", rank: "124" },
      { role: "Full Support", name: "FISHMAN", rank: "85" },
    ],
  },
  {
    name: "BEASTCOAST",
    rank: "#18",
    winrate: "47%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/BEASTCOAST.png",
    players: [
      { role: "Core", name: "PAYK", rank: "162" },
      { role: "Mid", name: "ALONE", rank: "-" },
      { role: "Offlane", name: "VITALY", rank: "304" },
      { role: "Support", name: "ELMISHO", rank: "63" },
      { role: "Full Support", name: "MOOZ", rank: "415" },
    ],
  },
  {
    name: "TALON ESPORTS",
    rank: "#21",
    winrate: "56%",
    avgKills: "32",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/TALON-ESPORTS.png",
    players: [
      { role: "Core", name: "AKASHI", rank: "187" },
      { role: "Mid", name: "MIKOTO", rank: "23" },
      { role: "Offlane", name: "WS", rank: "3" },
      { role: "Support", name: "JHOCAM", rank: "242" },
      { role: "Full Support", name: "PONYO", rank: "368" },
    ],
  },
  {
    name: "TEAM ZERO",
    rank: "#26",
    winrate: "45%",
    avgKills: "32",
    logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM-ZERO.png",
    players: [
      { role: "Core", name: "ERIKA", rank: "34" },
      { role: "Mid", name: "7E", rank: "-" },
      { role: "Offlane", name: "BEYOND", rank: "87" },
      { role: "Support", name: "PONLO", rank: "97" },
      { role: "Full Support", name: "ZZQ", rank: "368" },
    ],
  },
  {
    name: "AVALUS",
    rank: "#22",
    winrate: "66%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/avalus_dota2.webp",
    players: [
      { role: "Core", name: "Smiling Knight", rank: "87" },
      { role: "Mid", name: "Stormstormer", rank: "132" },
      { role: "Offlane", name: "Xibbe", rank: "335" },
      { role: "Support", name: "Ekki", rank: "54" },
      { role: "Full Support", name: "SoNNeikO", rank: "145" },
    ],
  },
  {
    name: "Invictus Gaming",
    rank: "#22",
    winrate: "62%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/Invictus-Gaming-dota-2-image.webp",
    players: [
      { role: "Core", name: "flyfly", rank: "78" },
      { role: "Mid", name: "Emo", rank: "6" },
      { role: "Offlane", name: "zeal", rank: "-" },
      { role: "Support", name: "BoBoKa", rank: "160" },
      { role: "Full Support", name: "Oli", rank: "208" },
    ],
  },
  {
    name: "Azure Ray",
    rank: "#22",
    winrate: "62%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/Azure-Ray-image-dota2-.webp",
    players: [
      { role: "Core", name: "LOU", rank: "39" },
      { role: "Mid", name: "HAN", rank: "115" },
      { role: "Offlane", name: "niu", rank: "11" },
      { role: "Support", name: "PLANET", rank: "62" },
      { role: "Full Support", name: "Siamese.C", rank: "129" },
    ],
  },
  {
    name: "Alliance",
    rank: "#22",
    winrate: "62%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/alliance-dota-2-logo-image.webp",
    players: [
      { role: "Core", name: "NANDE", rank: "401" },
      { role: "Mid", name: "  MO13E1", rank: "153" },
      { role: "Offlane", name: "SKYLARK", rank: "225" },
      { role: "Support", name: "DESIRE", rank: "136" },
      { role: "Full Support", name: "KIDARO", rank: "355" },
    ],
  },
  {
    name: "Natus Vincere",
    rank: "#18",
    winrate: "62%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/NAVI-DOTA2-IMAGE.webp",
    players: [
      { role: "Core", name: "YURAGI", rank: "34" },
      { role: "Mid", name: "  BZM", rank: "7" },
      { role: "Offlane", name: "BOOM", rank: "61" },
      { role: "Support", name: "ZAYAC", rank: "249" },
      { role: "Full Support", name: "PUPPEY", rank: "819" },
    ],
  },
  {
    name: "Infinity",
    rank: "#36",
    winrate: "54%",
    avgKills: "28",
    logo: "https://dotalive.org/wp-content/uploads/2024/10/Infinity-dota2-image.webp",
    players: [
      { role: "Core", name: "Costabile", rank: "221" },
      { role: "Mid", name: "  Pipi", rank: "546" },
      { role: "Offlane", name: "ILICH", rank: "228" },
      { role: "Support", name: "N1GHT", rank: "833" },
      { role: "Full Support", name: "PANDA", rank: "586" },
    ],
  },
  {
    name: "MOUZ",
    rank: "#20",
    winrate: "57%",
    avgKills: "21.9",
    logo: "https://dltv.org/uploads/teams/small/XvST6sGtx30WVvvQJJaAMqpYzwzlLdvN.png.webp",
    players: [
      { role: "Core", name: "Ulnit", rank: "127" },
      { role: "Mid", name: "Supream^", rank: "27" },
      { role: "Offlane", name: "Force", rank: "144" },
      { role: "Support", name: "NARMAN", rank: "90" },
      { role: "Full Support", name: "Bengan", rank: "292" },
    ],
  },
  {
    name: "DMS",
    rank: "#51",
    winrate: "61%",
    avgKills: "24.3",
    logo: "https://dltv.org/uploads/teams/medium/XClxAfoXHo3pQ4sgS369UIOnnmtaDa91.png.webp",
    players: [
      { role: "Core", name: "SHIGETSU", rank: "51" },
      { role: "Mid", name: "MELLOJUL^", rank: "38" },
      { role: "Offlane", name: "DAXAK", rank: "283" },
      { role: "Support", name: "PRBLMS", rank: "359" },
      { role: "Full Support", name: "AIK", rank: "117" },
    ],
  },

  // Add more teams here
];

interface TeamE {
  team_id:number,
  rating:number,
  wins:number,
  losses:number,
  name:string,
  tag:string,
  logo_url:string | null,
  last_match_time:number,

}

export const all_teams: TeamE[] = [
  {
    team_id: 9247354,
    rating: 1633.91,
    wins: 290,
    losses: 106,
    last_match_time: 1731265022,
    name: "Team Falcons",
    tag: "FLCN",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2314350571781870059/2B5C9FE9BA0A2DC303A13261444532AA08352843/",
  },
  {
    team_id: 8255888,
    rating: 1554.88,
    wins: 374,
    losses: 318,
    last_match_time: 1731265022,
    name: "BetBoom Team",
    tag: "BetBoom",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2501271299873667086/51E13136D4CCC8C7D8062861541A1D13B8ED87E0/",
  },
  {
    team_id: 8291895,
    rating: 1520.41,
    wins: 490,
    losses: 310,
    last_match_time: 1731019861,
    name: "Tundra Esports",
    tag: "Tundra",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2031716132171967904/07B168B8063D9B22CDAD53AB421ECAF3D4B2E07E/",
  },
  {
    team_id: 7119388,
    rating: 1487.49,
    wins: 630,
    losses: 442,
    last_match_time: 1731244320,
    name: "Team Spirit",
    tag: "TSpirit",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/1839179120711951766/CD7E0885CB527334205CC7885E9C101B7BC17702/",
  },
  {
    team_id: 2163,
    rating: 1465.39,
    wins: 1480,
    losses: 1002,
    last_match_time: 1730989504,
    name: "Team Liquid",
    tag: "Liquid",
    logo_url:
      "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2163.png",
  },
  {
    team_id: 8599101,
    rating: 1415.14,
    wins: 428,
    losses: 283,
    last_match_time: 1730309797,
    name: "Gaimin Gladiators",
    tag: "GG",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/1850419664501191993/5DAAB68FB5604D29E1792A0F35E74B3FE3F3A026/",
  },
  {
    team_id: 2586976,
    rating: 1305.05,
    wins: 1089,
    losses: 730,
    last_match_time: 1729848931,
    name: "OG",
    tag: "OG",
    logo_url:
      "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2586976.png",
  },
  {
    team_id: 8261500,
    rating: 1411.98,
    wins: 347,
    losses: 308,
    last_match_time: 1731412996,
    name: "Xtreme Gaming",
    tag: "XG",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2402194226059610590/E3CF4B6C4B2CFB974A9B415141E4A37317AD4D80/",
  },
  {
    team_id: 9572001,
    rating: 1375.04,
    wins: 28,
    losses: 21,
    last_match_time: 1731164828,
    name: "PARIVISION",
    tag: "PARIVISION",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2476508692281774105/EDE870E88FFD1C4E01DE40BF1B5E548D3459D046/",
  },
  {
    team_id: 9303484,
    rating: 1366.6,
    wins: 128,
    losses: 109,
    last_match_time: 1730316540,
    name: "HEROIC",
    tag: "Heroic",
    logo_url:
      "https://steamusercontent-a.akamaihd.net/ugc/2471984170520125054/B066431AF4D322D300DD5180CEC8F6BA0E85A7F5/",
  },
  // {
  //   team_id: 9255706,
  //   rating: 1455.86,
  //   wins: 86,
  //   losses: 67,
  //   last_match_time: 1719130091,
  //   name: "Aurora.1xBet",
  //   tag: "Aurora",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2362769341411270166/E13C592A0E744E1C386E09DE650BE36B85AE8137/",
  // },
  // {
  //   team_id: 7412785,
  //   rating: 1520.12,
  //   wins: 267,
  //   losses: 1,
  //   last_match_time: 1639928575,
  //   name: "CyberBonch-1",
  //   tag: "CB",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1842537871043985153/774A1838BEB3E73BFDEFEC0EDFD97B4F5C62B838/",
  // },
  // {
  //   team_id: 8605863,
  //   rating: 1497.49,
  //   wins: 326,
  //   losses: 243,
  //   last_match_time: 1726305637,
  //   name: "Cloud9",
  //   tag: "C9",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2399941883261718982/81DE19B3FD9737B5F16C725D3FB7E72251BE2A81/",
  // },
  // {
  //   team_id: 5228654,
  //   rating: 1450.04,
  //   wins: 79,
  //   losses: 36,
  //   last_match_time: 1535061995,
  //   name: "VGJ Storm",
  //   tag: "VGJ.Storm",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5228654.png",
  // },
  // {
  //   team_id: 5026801,
  //   rating: 1429.8,
  //   wins: 176,
  //   losses: 116,
  //   last_match_time: 1535053843,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5026801.png",
  // },
  // {
  //   team_id: 8169775,
  //   rating: 1407.57,
  //   wins: 448,
  //   losses: 368,
  //   last_match_time: 1707305498,
  //   name: "Geek Fam",
  //   tag: "Geek",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2047502867535132722/C72214A86E6C450DA8CF1A18AE9539B5CAEE7D45/",
  // },
  // {
  //   team_id: 8121295,
  //   rating: 1403.61,
  //   wins: 37,
  //   losses: 9,
  //   last_match_time: 1603906271,
  //   name: "mudgolems",
  //   tag: ".",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1635325934676609507/8E073AB63209CE73BD08E056B1C6CCE1014B3890/",
  // },
  // {
  //   team_id: 8260983,
  //   rating: 1402.96,
  //   wins: 291,
  //   losses: 206,
  //   last_match_time: 1700362218,
  //   name: "TSM",
  //   tag: "TSM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1996813186806561034/BC39F0DC131EDC7D7D8A9DCE4933B4A8B0966004/",
  // },
  // {
  //   team_id: 3214108,
  //   rating: 1401.12,
  //   wins: 136,
  //   losses: 90,
  //   last_match_time: 1498631388,
  //   name: "Team NP",
  //   tag: "NP",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3214108.png",
  // },
  // {
  //   team_id: 2519319,
  //   rating: 1389,
  //   wins: 45,
  //   losses: 16,
  //   last_match_time: 1445985009,
  //   name: "(monkey) Business",
  //   tag: "(monkey)",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/383162071968605685/B670804CDB13F184D122ACBB8F75E2DB2C959CAF/",
  // },
  // {
  //   team_id: 7819701,
  //   rating: 1381.67,
  //   wins: 76,
  //   losses: 40,
  //   last_match_time: 1601139882,
  //   name: "VP.Prodigy",
  //   tag: "VP.P",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1009310639742423917/9175453DE6C700E0CC6D547F437B4819F1144A1A/",
  // },
  // {
  //   team_id: 9142866,
  //   rating: 1380.6,
  //   wins: 133,
  //   losses: 25,
  //   last_match_time: 1693586046,
  //   name: "Business Club",
  //   tag: "bs",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2031737253379162247/A9D128309B9FC2C328AE048CB69E4D07C67BD26C/",
  // },
  // {
  //   team_id: 8571960,
  //   rating: 1377.16,
  //   wins: 111,
  //   losses: 58,
  //   last_match_time: 1658142446,
  //   name: "Team Orca",
  //   tag: "Orca",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1822272203102401273/0DDED0FB4C6EA41DCE3C3DDB25B3D42A40C3C6BB/",
  // },
  // {
  //   team_id: 5,
  //   rating: 1371.82,
  //   wins: 1478,
  //   losses: 1116,
  //   last_match_time: 1725974053,
  //   name: "Invictus Gaming",
  //   tag: "iG",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5.png",
  // },
  // {
  //   team_id: 8894818,
  //   rating: 1366.22,
  //   wins: 249,
  //   losses: 168,
  //   last_match_time: 1724162180,
  //   name: "PSG.Quest",
  //   tag: "Quest",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2481004682513190539/324F8847AD21944686DED20FB3E2C0DEA4154AE7/",
  // },
  // {
  //   team_id: 2101260,
  //   rating: 1360.39,
  //   wins: 35,
  //   losses: 17,
  //   last_match_time: 1423390272,
  //   name: "BigGooooood",
  //   tag: "BG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/53249782181410639/A754634D7F2CA2B33322789ED2EBB953DADEEDA5/",
  // },
  // {
  //   team_id: 8721219,
  //   rating: 1349.97,
  //   wins: 83,
  //   losses: 54,
  //   last_match_time: 1666086511,
  //   name: "Darkside",
  //   tag: "Darkside",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1845922937363804366/B718AFD0F76379ACDDE28F7512FC91B76C478985/",
  // },
  // {
  //   team_id: 6953913,
  //   rating: 1342.56,
  //   wins: 176,
  //   losses: 140,
  //   last_match_time: 1600707049,
  //   name: "FlyToMoon",
  //   tag: "FTM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/916953413384701477/58A15D27D05A5051190589BBED3CDD362AFC3C86/",
  // },
  // {
  //   team_id: 36,
  //   rating: 1341.66,
  //   wins: 1227,
  //   losses: 1035,
  //   last_match_time: 1728759847,
  //   name: "Natus Vincere",
  //   tag: "NAVI",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/36.png",
  // },
  // {
  //   team_id: 8244493,
  //   rating: 1341.27,
  //   wins: 267,
  //   losses: 214,
  //   last_match_time: 1697247521,
  //   name: "Team SMG",
  //   tag: "SMG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1856049226625971775/C8540DF2478E5EE8890CD4128DE07176F9FE5FA2/",
  // },
  // {
  //   team_id: 8597391,
  //   rating: 1339.16,
  //   wins: 185,
  //   losses: 151,
  //   last_match_time: 1698419209,
  //   name: "Luna Galaxy",
  //   tag: "LXG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2038491084577608095/CA67ADB750E3B98E8544FB0CC1D2FF1C9EC27A77/",
  // },
  // {
  //   team_id: 1848158,
  //   rating: 1338.35,
  //   wins: 46,
  //   losses: 29,
  //   last_match_time: 1420608609,
  //   name: "LV-Gaming",
  //   tag: "Lv",
  //   logo_url: null,
  // },
  // {
  //   team_id: 999689,
  //   rating: 1336.72,
  //   wins: 182,
  //   losses: 87,
  //   last_match_time: 1411994693,
  //   name: "Titan",
  //   tag: "Titan",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/612798094497099775/B31FD08745284986F02B3FE04021574F964D9FA1/",
  // },
  // {
  //   team_id: 9467224,
  //   rating: 1335.98,
  //   wins: 35,
  //   losses: 42,
  //   last_match_time: 1729848556,
  //   name: "Aurora Gaming",
  //   tag: "Aurora",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2529416016828320142/E277C75D4E5B793D5A0AE7E901FB21A2239CC73A/",
  // },
  // {
  //   team_id: 8574561,
  //   rating: 1333.88,
  //   wins: 300,
  //   losses: 220,
  //   last_match_time: 1730318344,
  //   name: "Azure Ray",
  //   tag: "AR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2298587339939886506/26FF6DD1070476EAF1C69DD30B259FBB197F0256/",
  // },
  // {
  //   team_id: 9255039,
  //   rating: 1326.85,
  //   wins: 171,
  //   losses: 122,
  //   last_match_time: 1727618359,
  //   name: "1w Team",
  //   tag: "1w",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2385306361427679719/DCD7C12478E276346140846AB8EB0B5759351744/",
  // },
  // {
  //   team_id: 8255756,
  //   rating: 1323.54,
  //   wins: 270,
  //   losses: 264,
  //   last_match_time: 1697923175,
  //   name: "Evil Geniuses",
  //   tag: "EG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1983302387907692940/BAA861E234E1BA39D75DF4CB814A5B76D020BED7/",
  // },
  // {
  //   team_id: 6214538,
  //   rating: 1323.25,
  //   wins: 115,
  //   losses: 95,
  //   last_match_time: 1566447672,
  //   name: "Newbee",
  //   tag: "Newbee",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/6214538.png",
  // },
  // {
  //   team_id: 8254400,
  //   rating: 1323.1,
  //   wins: 351,
  //   losses: 320,
  //   last_match_time: 1728724694,
  //   name: "beastcoast",
  //   tag: "bc",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2008072595679478968/A080A63C70A5DEA039FBC1AE798EE2570E194606/",
  // },
  // {
  //   team_id: 9303383,
  //   rating: 1321.91,
  //   wins: 141,
  //   losses: 98,
  //   last_match_time: 1729539429,
  //   name: "L1GA TEAM",
  //   tag: "L1",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/40063200209690390/20B527165E8E637C83F27A62FFE1AE957CB43018/",
  // },
  // {
  //   team_id: 5229127,
  //   rating: 1317.83,
  //   wins: 55,
  //   losses: 40,
  //   last_match_time: 1534963179,
  //   name: "Winstrike",
  //   tag: "Winstrike",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5229127.png",
  // },
  // {
  //   team_id: 9498970,
  //   rating: 1315.14,
  //   wins: 83,
  //   losses: 44,
  //   last_match_time: 1730308261,
  //   name: "AVULUS",
  //   tag: "AVULUS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2484388089175887648/AD2555E0F8E1783B66E6A3F88D0D3481E11BDE2A/",
  // },
  // {
  //   team_id: 4251435,
  //   rating: 1314.81,
  //   wins: 52,
  //   losses: 41,
  //   last_match_time: 1506108006,
  //   name: "w33ha earthspirit",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/4251435.png",
  // },
  // {
  //   team_id: 543897,
  //   rating: 1311.7,
  //   wins: 587,
  //   losses: 503,
  //   last_match_time: 1566461924,
  //   name: "Mineski",
  //   tag: "Mineski",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/543897.png",
  // },
  // {
  //   team_id: 9080405,
  //   rating: 1310.81,
  //   wins: 117,
  //   losses: 79,
  //   last_match_time: 1727697076,
  //   name: "Team Zero",
  //   tag: "Tz",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2384179827551149860/D284923AE9D25AB431DCBF11D0E2CC88C495E00E/",
  // },
  // {
  //   team_id: 6382242,
  //   rating: 1310.12,
  //   wins: 275,
  //   losses: 163,
  //   last_match_time: 1608522486,
  //   name: "Thunder Predator",
  //   tag: "ThunderP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/771721592096213701/FB6F45207F9BF03F6D94C1C515DACD45A9C33411/",
  // },
  // {
  //   team_id: 1910985,
  //   rating: 1306.77,
  //   wins: 90,
  //   losses: 39,
  //   last_match_time: 1432358766,
  //   name: "TeamMalaysia",
  //   tag: "MY",
  //   logo_url: null,
  // },
  // {
  //   team_id: 9017006,
  //   rating: 1302.59,
  //   wins: 180,
  //   losses: 150,
  //   last_match_time: 1731424024,
  //   name: "NAVI Junior",
  //   tag: "NAVIj",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2494508506331433699/C258C4752510A57BD36244696ECF51E2BF97969E/",
  // },
  // {
  //   team_id: 9187066,
  //   rating: 1299.72,
  //   wins: 82,
  //   losses: 57,
  //   last_match_time: 1707576749,
  //   name: "Team Tickles",
  //   tag: "TT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2056510796283092608/8B9AE4B1B129EBBCE213FE917D4B22478B087870/",
  // },
  // {
  //   team_id: 3922738,
  //   rating: 1298.86,
  //   wins: 49,
  //   losses: 18,
  //   last_match_time: 1501539783,
  //   name: " Thunder Awaken ///",
  //   tag: "Thunder",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/170412655661567888/FEF408443C2CA8AB1953CFA3E530AB9D5EE7ADB7/",
  // },
  // {
  //   team_id: 8597976,
  //   rating: 1296.92,
  //   wins: 347,
  //   losses: 252,
  //   last_match_time: 1730319179,
  //   name: "Talon",
  //   tag: "TALON",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2028347991408203552/8DC9872DA88071D728A914CE17279959423FA340/",
  // },
  // {
  //   team_id: 8204512,
  //   rating: 1296.55,
  //   wins: 216,
  //   losses: 125,
  //   last_match_time: 1641110732,
  //   name: "Neptune Gaming",
  //   tag: "NG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1856041839331632619/2BEAA9EAB2E127CFE87C149A88C4F07548E65685/",
  // },
  // {
  //   team_id: 9545759,
  //   rating: 1294.55,
  //   wins: 36,
  //   losses: 37,
  //   last_match_time: 1731019861,
  //   name: "Waska",
  //   tag: "Waska",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2492268755024477919/F2376446D05157B080B5D506473EE2F1D43097BF/",
  // },
  // {
  //   team_id: 15,
  //   rating: 1293.71,
  //   wins: 1748,
  //   losses: 1089,
  //   last_match_time: 1722666066,
  //   name: "LGD Gaming",
  //   tag: "LGD",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/15.png",
  // },
  // {
  //   team_id: 8261818,
  //   rating: 1290.79,
  //   wins: 108,
  //   losses: 69,
  //   last_match_time: 1628462253,
  //   name: "Hellbear Smashers",
  //   tag: "SMASH",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1745679796967643438/92B0E956ED01D24C679FECED3D23D01EAB1C3048/",
  // },
  // {
  //   team_id: 9018710,
  //   rating: 1290.56,
  //   wins: 59,
  //   losses: 40,
  //   last_match_time: 1699684549,
  //   name: "Holy Grail",
  //   tag: "HG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2036233631759545038/183C2584681DBC309834BCE5561EA04CDDFFC736/",
  // },
  // {
  //   team_id: 1820360,
  //   rating: 1289.92,
  //   wins: 127,
  //   losses: 108,
  //   last_match_time: 1503241925,
  //   name: "TEAMERINO TINKERINO",
  //   tag: "TT",
  //   logo_url: null,
  // },
  // {
  //   team_id: 7553952,
  //   rating: 1284.91,
  //   wins: 75,
  //   losses: 41,
  //   last_match_time: 1587488785,
  //   name: "Chicken Fighters !",
  //   tag: "ChFight!",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/769486732001897391/E5A91F8FB5ACFE3BC4DF89158B318C4D34960F80/",
  // },
  // {
  //   team_id: 293390,
  //   rating: 1284.02,
  //   wins: 194,
  //   losses: 188,
  //   last_match_time: 1401640167,
  //   name: "Radical Online X-tremists",
  //   tag: "RoX.KIS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/921253447668725714/13C7456AA325AD7AC03DC7D117483E8DBDAD5486/",
  // },
  // {
  //   team_id: 7203342,
  //   rating: 1283.86,
  //   wins: 43,
  //   losses: 34,
  //   last_match_time: 1581551146,
  //   name: "Chaos EC",
  //   tag: "CHAOS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/785254538964288557/60C71B6F83A686A41F3AC5455A70D930B92C41F0/",
  // },
  // {
  //   team_id: 1047281,
  //   rating: 1282.72,
  //   wins: 51,
  //   losses: 29,
  //   last_match_time: 1389816358,
  //   name: "Speed Gaming International",
  //   tag: "SPG.int",
  //   logo_url: null,
  // },
  // {
  //   team_id: 9248240,
  //   rating: 1281.24,
  //   wins: 32,
  //   losses: 17,
  //   last_match_time: 1724954098,
  //   name: "TeamWaska",
  //   tag: "WaSka",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2376297984889887726/F2376446D05157B080B5D506473EE2F1D43097BF/",
  // },
  // {
  //   team_id: 8168562,
  //   rating: 1280.05,
  //   wins: 29,
  //   losses: 8,
  //   last_match_time: 1608462872,
  //   name: "INK ICE",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1673610911185136184/D03909DFDAC45F9A06E33F42EDE3989BBAD065F7/",
  // },
  // {
  //   team_id: 7748744,
  //   rating: 1278.99,
  //   wins: 120,
  //   losses: 95,
  //   last_match_time: 1600829726,
  //   name: "CR4ZY",
  //   tag: "CR4ZY",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/777372824325330501/0203F5435560EFC32DDBB0B7D170ECF50CCD3BE6/",
  // },
  // {
  //   team_id: 8722443,
  //   rating: 1276.33,
  //   wins: 89,
  //   losses: 64,
  //   last_match_time: 1667930850,
  //   name: "Ooredoo Thunders",
  //   tag: "OT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1883094124816874766/6F395B569068034041A162578E78E83902E46E83/",
  // },
  // {
  //   team_id: 2526472,
  //   rating: 1273.19,
  //   wins: 23,
  //   losses: 7,
  //   last_match_time: 1444158470,
  //   name: "$5JuNGz$",
  //   tag: "$5JuNGz$",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/620723145147160586/09F1EF05604FC45B22E2B5986715441FB8FCA786/",
  // },
  // {
  //   team_id: 7554697,
  //   rating: 1272.95,
  //   wins: 397,
  //   losses: 365,
  //   last_match_time: 1730308260,
  //   name: "Nigma Galaxy",
  //   tag: "NGX",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1827894588975105240/421C0D8318D71D5DD31FD08A7933AB622AE26590/",
  // },
  // {
  //   team_id: 5027210,
  //   rating: 1271.22,
  //   wins: 137,
  //   losses: 109,
  //   last_match_time: 1534821698,
  //   name: "VGJ Thunder",
  //   tag: "VGJ.T",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5027210.png",
  // },
  // {
  //   team_id: 3326875,
  //   rating: 1269.74,
  //   wins: 131,
  //   losses: 73,
  //   last_match_time: 1498579717,
  //   name: "Faceless",
  //   tag: "fL",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3326875.png",
  // },
  // {
  //   team_id: 8668460,
  //   rating: 1269.62,
  //   wins: 176,
  //   losses: 148,
  //   last_match_time: 1718874889,
  //   name: "Blacklist Rivalry ",
  //   tag: "BLR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2050860821377863997/BAE16F709F0AE46642FAD3366C78FD92FD2FD741/",
  // },
  // {
  //   team_id: 8962585,
  //   rating: 1269.09,
  //   wins: 90,
  //   losses: 27,
  //   last_match_time: 1677082142,
  //   name: "Star Foxes ",
  //   tag: "SF",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2046362392217398188/E6B179AA7B1FAAD9674EE563C044BFDA13502383/",
  // },
  // {
  //   team_id: 7732977,
  //   rating: 1268.26,
  //   wins: 570,
  //   losses: 397,
  //   last_match_time: 1731405849,
  //   name: "BOOM Esports",
  //   tag: "BOOM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2908503275318752/52C6F6228C73CDB6855C04B64FF21D061D2C15A9/",
  // },
  // {
  //   team_id: 8724984,
  //   rating: 1267.96,
  //   wins: 230,
  //   losses: 190,
  //   last_match_time: 1729270938,
  //   name: "Virtus.pro",
  //   tag: "VP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1796396122320355023/2E833B3744ECB93AD9FF3797C0309B4ADB54AD2E/",
  // },
  // {
  //   team_id: 39,
  //   rating: 1264.77,
  //   wins: 1485,
  //   losses: 944,
  //   last_match_time: 1727737347,
  //   name: "Shopify Rebellion",
  //   tag: "SR",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/39.png",
  // },
  // {
  //   team_id: 7555613,
  //   rating: 1263.38,
  //   wins: 89,
  //   losses: 74,
  //   last_match_time: 1592502969,
  //   name: "OG.Seed",
  //   tag: "OG.S",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1004809121655041802/D9C893AC4F6CF2DA59CB22CF7FE1885133389F9A/",
  // },
  // {
  //   team_id: 1883502,
  //   rating: 1262.99,
  //   wins: 1369,
  //   losses: 1016,
  //   last_match_time: 1650719046,
  //   name: "Virtus.pro",
  //   tag: "VP",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1883502.png",
  // },
  // {
  //   team_id: 726228,
  //   rating: 1262.89,
  //   wins: 1421,
  //   losses: 1035,
  //   last_match_time: 1680427578,
  //   name: "Vici Gaming",
  //   tag: "VG",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/726228.png",
  // },
  // {
  //   team_id: 8980714,
  //   rating: 1262.73,
  //   wins: 100,
  //   losses: 84,
  //   last_match_time: 1723447033,
  //   name: "Team Tough",
  //   tag: "TT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2298586614153129772/34892DA3BE31B7E7E9E4BED828EB54B3A055388A/",
  // },
  // {
  //   team_id: 9351740,
  //   rating: 1261.94,
  //   wins: 32,
  //   losses: 55,
  //   last_match_time: 1729858741,
  //   name: "Yakult Brothers",
  //   tag: "YB",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/55825798897929424/76CAF13C890C3FEBA92E152739F02C769865B286/",
  // },
  // {
  //   team_id: 8119006,
  //   rating: 1259.63,
  //   wins: 19,
  //   losses: 6,
  //   last_match_time: 1601186472,
  //   name: "Among Us",
  //   tag: "AUS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1617311354350013884/6D9A00FEEBF5C70482B1F743EF44EC0764BA4F9F/",
  // },
  // {
  //   team_id: 7405754,
  //   rating: 1259.51,
  //   wins: 26,
  //   losses: 9,
  //   last_match_time: 1572362697,
  //   name: "Positive Guys",
  //   tag: "PG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/766098228974731217/3D71569580889E44075A700A836DCC0BA86F1160/",
  // },
  // {
  //   team_id: 8944440,
  //   rating: 1259.06,
  //   wins: 82,
  //   losses: 86,
  //   last_match_time: 1696092774,
  //   name: "StoRm",
  //   tag: "StoRm",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2028354481551397489/BA39B32EA6335F75295CDB5E36DCB31D3FC93F77/",
  // },
  // {
  //   team_id: 7424172,
  //   rating: 1257.53,
  //   wins: 99,
  //   losses: 81,
  //   last_match_time: 1599118256,
  //   name: "T1",
  //   tag: "T1",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/773981969136635863/BE28F059BD864F4820323DE5DDD864D4C353CA87/",
  // },
  // {
  //   team_id: 2576071,
  //   rating: 1256.46,
  //   wins: 138,
  //   losses: 104,
  //   last_match_time: 1723977184,
  //   name: "Yellow Submarine",
  //   tag: "YeS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2506900380361558769/01D7A1FC1156B0550B4EF9EA2A3A2AF84D9BF884/",
  // },
  // {
  //   team_id: 3326126,
  //   rating: 1254.42,
  //   wins: 66,
  //   losses: 45,
  //   last_match_time: 1483257669,
  //   name: "Team VG.J",
  //   tag: "VG.J",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3326126.png",
  // },
  // {
  //   team_id: 6209166,
  //   rating: 1252.34,
  //   wins: 643,
  //   losses: 538,
  //   last_match_time: 1706523006,
  //   name: "Team Aster",
  //   tag: "Aster",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1629730253163462144/FEF15B2B4528E81103DD693E37FD7B6C9BE6B471/",
  // },
  // {
  //   team_id: 2108395,
  //   rating: 1251.66,
  //   wins: 749,
  //   losses: 550,
  //   last_match_time: 1719399915,
  //   name: "TNC Predator",
  //   tag: "TNC",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2108395.png",
  // },
  // {
  //   team_id: 3722973,
  //   rating: 1251.35,
  //   wins: 28,
  //   losses: 24,
  //   last_match_time: 1489787368,
  //   name: "Team Onyx",
  //   tag: "Onyx",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3722973.png",
  // },
  // {
  //   team_id: 9283988,
  //   rating: 1248.88,
  //   wins: 43,
  //   losses: 20,
  //   last_match_time: 1710210863,
  //   name: "Invaders",
  //   tag: "INV",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2297462073171121106/5EF06B2A21F8BDFA6E3A99CE45C896E47B05A926/",
  // },
  // {
  //   team_id: 8143502,
  //   rating: 1248.78,
  //   wins: 15,
  //   losses: 15,
  //   last_match_time: 1605531390,
  //   name: "4am.小象",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1658972492052757763/A6D7CC20DF82E1C48C4B307BF1FD67CA46493AE5/",
  // },
  // {
  //   team_id: 416900,
  //   rating: 1247.49,
  //   wins: 67,
  //   losses: 47,
  //   last_match_time: 1378648464,
  //   name: "Orange.Neolution Esports",
  //   tag: "Orange",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/612760050488516720/DF0EE7F44746239DBA83B8F4537758ECBB51655E/",
  // },
  // {
  //   team_id: 6382740,
  //   rating: 1247.38,
  //   wins: 61,
  //   losses: 40,
  //   last_match_time: 1565416228,
  //   name: "Royal",
  //   tag: "Royal",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/937206137839516292/51F476E62579AA097D2035B9BECC8ADB827660CF/",
  // },
  // {
  //   team_id: 9448501,
  //   rating: 1244.48,
  //   wins: 18,
  //   losses: 5,
  //   last_match_time: 1731404953,
  //   name: "TEAM TURTLE",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2465238196943871680/E2D76C1FAB3A8F86D56B811D15971CD91969B40A/",
  // },
  // {
  //   team_id: 9389381,
  //   rating: 1243.52,
  //   wins: 36,
  //   losses: 32,
  //   last_match_time: 1718176940,
  //   name: "Team  KEV",
  //   tag: "KEV",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2527155798055904204/2AEEDAB9C8203A2C066B01850585C6E37589FF9F/",
  // },
  // {
  //   team_id: 7806386,
  //   rating: 1243.51,
  //   wins: 27,
  //   losses: 14,
  //   last_match_time: 1588294053,
  //   name: "Quincy Crew",
  //   tag: "",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2635099,
  //   rating: 1242.81,
  //   wins: 124,
  //   losses: 86,
  //   last_match_time: 1469212980,
  //   name: "CDEC.Y",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/266100190168033440/3164343438ECC6AB8E76D0B59349F00CC4034296/",
  // },
  // {
  //   team_id: 8062847,
  //   rating: 1242.74,
  //   wins: 205,
  //   losses: 158,
  //   last_match_time: 1632490268,
  //   name: "Galaxy Racer Esports",
  //   tag: "GXR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1768194395980957182/F98E05970A8BB8574785B80B004EF148D6F589B7/",
  // },
  // {
  //   team_id: 2777247,
  //   rating: 1242.13,
  //   wins: 124,
  //   losses: 84,
  //   last_match_time: 1470701768,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2777247.png",
  // },
  // {
  //   team_id: 3586078,
  //   rating: 1241.98,
  //   wins: 291,
  //   losses: 237,
  //   last_match_time: 1598956612,
  //   name: "Geek Fam",
  //   tag: "GeekFam",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2011450519782421256/C72214A86E6C450DA8CF1A18AE9539B5CAEE7D45/",
  // },
  // {
  //   team_id: 2790766,
  //   rating: 1241.4,
  //   wins: 140,
  //   losses: 144,
  //   last_match_time: 1515791892,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2790766.png",
  // },
  // {
  //   team_id: 2621843,
  //   rating: 1240.18,
  //   wins: 303,
  //   losses: 235,
  //   last_match_time: 1546459686,
  //   name: "Team. Spirit",
  //   tag: "TSpirit",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2621843.png",
  // },
  // {
  //   team_id: 9466943,
  //   rating: 1237.58,
  //   wins: 68,
  //   losses: 26,
  //   last_match_time: 1723292504,
  //   name: "Team Tea",
  //   tag: "Tea",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2547430415563820852/14C528D0CE511F1534CABBB97EBAECCF0CC22DD0/",
  // },
  // {
  //   team_id: 8261234,
  //   rating: 1237.52,
  //   wins: 105,
  //   losses: 60,
  //   last_match_time: 1637687948,
  //   name: "CREEPWAVE",
  //   tag: "CREEP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1681517831574098379/EDF37F1B1A2A8684DC959A8D0880B70FA6F8C5D2/",
  // },
  // {
  //   team_id: 7118032,
  //   rating: 1236.69,
  //   wins: 232,
  //   losses: 250,
  //   last_match_time: 1648746472,
  //   name: "Winstrike Team",
  //   tag: "Winstrike",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/789748832053588401/447AE24D04DB1E105B26197B8716D702A740F310/",
  // },
  // {
  //   team_id: 41,
  //   rating: 1235.91,
  //   wins: 116,
  //   losses: 106,
  //   last_match_time: 1427741342,
  //   name: "4 Friends + Chrillee",
  //   tag: "4FC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/706274505311787193/24FA17D5019799AF118AEB4469DDB76D69A66F63/",
  // },
  // {
  //   team_id: 9255238,
  //   rating: 1234.51,
  //   wins: 34,
  //   losses: 18,
  //   last_match_time: 1710728809,
  //   name: "ESTAR_BACKS",
  //   tag: "StarBackus",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2247920665251197227/5D236EEB62190F9B0FC6F25BDA98DDB3EB6A5F0A/",
  // },
  // {
  //   team_id: 8728920,
  //   rating: 1233.71,
  //   wins: 293,
  //   losses: 232,
  //   last_match_time: 1730308260,
  //   name: "nouns",
  //   tag: "nouns",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1861686187975269057/6E221E0B04CCB4AEF93A3FDA4DC7873A1BBAFAE2/",
  // },
  // {
  //   team_id: 6685591,
  //   rating: 1232.86,
  //   wins: 366,
  //   losses: 244,
  //   last_match_time: 1625738885,
  //   name: "ViKin.gg",
  //   tag: "Vikin.gg",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1628571207900914834/9A093A05B2DDED488984470F0FADEFDE1F5EDE9F/",
  // },
  // {
  //   team_id: 8975678,
  //   rating: 1232.35,
  //   wins: 97,
  //   losses: 53,
  //   last_match_time: 1692795857,
  //   name: "Team Sexy",
  //   tag: "Sexy",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2032861231216761354/13C0C97E4F1B758EDAF89B19C45226E68DFEEA52/",
  // },
  // {
  //   team_id: 4288603,
  //   rating: 1230.49,
  //   wins: 71,
  //   losses: 44,
  //   last_match_time: 1522130537,
  //   name: "Rock.Young",
  //   tag: "Rock.Y",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/924796104665763830/965117DF684B6E3801028E33DF02B8528A18E752/",
  // },
  // {
  //   team_id: 9263388,
  //   rating: 1230.32,
  //   wins: 14,
  //   losses: 5,
  //   last_match_time: 1701885535,
  //   name: "The dudley boys",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2240039780382774383/E60E1732900AE3CE26C1BEA0BBE7B85CCB872B8E/",
  // },
  // {
  //   team_id: 9238717,
  //   rating: 1229.06,
  //   wins: 20,
  //   losses: 0,
  //   last_match_time: 1701526117,
  //   name: "Team 13",
  //   tag: "T13",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2006913,
  //   rating: 1228.39,
  //   wins: 565,
  //   losses: 478,
  //   last_match_time: 1565806832,
  //   name: "Vega Squadron",
  //   tag: "Vega",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2006913.png",
  // },
  // {
  //   team_id: 4,
  //   rating: 1227.72,
  //   wins: 904,
  //   losses: 766,
  //   last_match_time: 1677426601,
  //   name: "EHOME",
  //   tag: "EHOME",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/4.png",
  // },
  // {
  //   team_id: 7681441,
  //   rating: 1226.73,
  //   wins: 83,
  //   losses: 82,
  //   last_match_time: 1599269045,
  //   name: "business associates",
  //   tag: "business",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/789755010916561479/354F9DF6125FE48FC70FF8588985EF77E8CDDAAE/",
  // },
  // {
  //   team_id: 1025734,
  //   rating: 1226.01,
  //   wins: 40,
  //   losses: 12,
  //   last_match_time: 1448418242,
  //   name: "LUCCINI GAM1NG",
  //   tag: "Luccini",
  //   logo_url: null,
  // },
  // {
  //   team_id: 1803996,
  //   rating: 1223.67,
  //   wins: 22,
  //   losses: 15,
  //   last_match_time: 1431260006,
  //   name: "NAM VEZET-MI IGRAEM",
  //   tag: "NVMI",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/575652270942579148/9B38BEC3BEDEE0E300138929E3C802FA9F5ACDCB/",
  // },
  // {
  //   team_id: 1556884,
  //   rating: 1223.17,
  //   wins: 52,
  //   losses: 19,
  //   last_match_time: 1418332963,
  //   name: "AftershockGaming Int.",
  //   tag: "aShock",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/776056112971508921/DF725FB62C1B052CE52381747AE7C296A65A4051/",
  // },
  // {
  //   team_id: 9201870,
  //   rating: 1222.52,
  //   wins: 19,
  //   losses: 12,
  //   last_match_time: 1697745036,
  //   name: "ROR",
  //   tag: "ROR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2076779599437195979/F472FC7B699006A25B505F6AAC6B12CBF1B73DCF/",
  // },
  // {
  //   team_id: 2006291,
  //   rating: 1221.1,
  //   wins: 20,
  //   losses: 8,
  //   last_match_time: 1419620393,
  //   name: "Lajons",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/541884168947857532/A265E0462753D3981E33961066F897E7C1E6AFDC/",
  // },
  // {
  //   team_id: 1453020,
  //   rating: 1220.94,
  //   wins: 142,
  //   losses: 82,
  //   last_match_time: 1442178578,
  //   name: "Sneaky Nyx Assassins",
  //   tag: "Nyx",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/3281180234046031347/2975FFC5B5EB653F5165D7D4E8698C30CEE8AF07/",
  // },
  // {
  //   team_id: 8482814,
  //   rating: 1220.6,
  //   wins: 56,
  //   losses: 35,
  //   last_match_time: 1635157561,
  //   name: "Ragdoll",
  //   tag: "Ragdoll",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1697280262050066254/F18A176DAA613BB8B016AE90F4E8D17BE02AAA63/",
  // },
  // {
  //   team_id: 8750317,
  //   rating: 1219.46,
  //   wins: 261,
  //   losses: 128,
  //   last_match_time: 1673351999,
  //   name: "Team Sphinx",
  //   tag: "TSX",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2048607934858898157/B904083AF403DC27F8DC18EA96E2406AEB64BFDA/",
  // },
  // {
  //   team_id: 8145157,
  //   rating: 1218.51,
  //   wins: 42,
  //   losses: 25,
  //   last_match_time: 1607457606,
  //   name: "Yellow Submarine",
  //   tag: "YeS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1648839325588212512/01D7A1FC1156B0550B4EF9EA2A3A2AF84D9BF884/",
  // },
  // {
  //   team_id: 2780729,
  //   rating: 1218.12,
  //   wins: 35,
  //   losses: 6,
  //   last_match_time: 1468142705,
  //   name: "G.star",
  //   tag: "GS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/455236803146988180/37D5285DC80FFBEDF84FD0347ADE2E89A4E29731/",
  // },
  // {
  //   team_id: 1838315,
  //   rating: 1217.49,
  //   wins: 1152,
  //   losses: 657,
  //   last_match_time: 1723900688,
  //   name: "Team Secret",
  //   tag: "Secret",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1838315.png",
  // },
  // {
  //   team_id: 9338413,
  //   rating: 1216.73,
  //   wins: 141,
  //   losses: 140,
  //   last_match_time: 1729512069,
  //   name: "MOUZ",
  //   tag: "MOUZ",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2453977469313883353/584A9278F4834E081204E826BBF8A00FFAAD3F99/",
  // },
  // {
  //   team_id: 3332295,
  //   rating: 1215.38,
  //   wins: 90,
  //   losses: 68,
  //   last_match_time: 1492114658,
  //   name: "Friends",
  //   tag: "Friends",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/172662099982618634/E9F11AE27B2DA56D749DF996FB687ED583806241/",
  // },
  // {
  //   team_id: 5167450,
  //   rating: 1215.31,
  //   wins: 59,
  //   losses: 14,
  //   last_match_time: 1620562566,
  //   name: "boths",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2176988246615554366/E4222AFD4CF656D721315F1442A4E8E8E9E069FA/",
  // },
  // {
  //   team_id: 5466118,
  //   rating: 1215.1,
  //   wins: 67,
  //   losses: 52,
  //   last_match_time: 1554636650,
  //   name: "YOSHIMOTO.DETONATOR",
  //   tag: "DTN",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/921424381009057600/F846ED54123BCE31A1DF03A33879A51CA83E3FF6/",
  // },
  // {
  //   team_id: 8261648,
  //   rating: 1214.88,
  //   wins: 188,
  //   losses: 177,
  //   last_match_time: 1687708216,
  //   name: "Old G",
  //   tag: "Old.G",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2057626457212027024/ADB880F0121D19FD7353B6DCD539D276D88F770C/",
  // },
  // {
  //   team_id: 4425527,
  //   rating: 1213.12,
  //   wins: 23,
  //   losses: 15,
  //   last_match_time: 1670416509,
  //   name: "Geek Fam",
  //   tag: "GeekFam",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/770525551623882955/0C4DB2D2430056E2E887CFCB3DD77567F5DE8EE5/",
  // },
  // {
  //   team_id: 8021940,
  //   rating: 1211.54,
  //   wins: 47,
  //   losses: 48,
  //   last_match_time: 1601881884,
  //   name: "New Esports",
  //   tag: "NEW",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1048723493300485230/77C98B21711C5D6981D6964A17F024EEC8674743/",
  // },
  // {
  //   team_id: 8282268,
  //   rating: 1211.49,
  //   wins: 43,
  //   losses: 17,
  //   last_match_time: 1616332843,
  //   name: "Chadou Fint",
  //   tag: "SF",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1764816394006016274/C062CDA27CABC48CFD5DF52459994DCCA43887FE/",
  // },
  // {
  //   team_id: 8514342,
  //   rating: 1211.42,
  //   wins: 49,
  //   losses: 21,
  //   last_match_time: 1636415947,
  //   name: "Infamous Uesports",
  //   tag: "Inf.U",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1691652603942290191/76D5156E3CF7847989185216EEBF36157381CAF9/",
  // },
  // {
  //   team_id: 1283482,
  //   rating: 1210.66,
  //   wins: 80,
  //   losses: 59,
  //   last_match_time: 1408812537,
  //   name: "Scythe.SG-",
  //   tag: "SCYTHE",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/469812062884140883/C9EDFEDE46BCAC0F1C16A5A3196D6947654FE664/",
  // },
  // {
  //   team_id: 38,
  //   rating: 1210.3,
  //   wins: 63,
  //   losses: 44,
  //   last_match_time: 1360786208,
  //   name: "FnaticRaidCall",
  //   tag: "Fntc",
  //   logo_url: null,
  // },
  // {
  //   team_id: 8951348,
  //   rating: 1209.18,
  //   wins: 40,
  //   losses: 7,
  //   last_match_time: 1730645170,
  //   name: "fire beavers",
  //   tag: "FBeavers",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1979926046523390782/D449E850DC2A83DDA3984B3788DA054AE404E28E/",
  // },
  // {
  //   team_id: 9403748,
  //   rating: 1209.08,
  //   wins: 76,
  //   losses: 109,
  //   last_match_time: 1731421736,
  //   name: "Dragon Esports Club",
  //   tag: "DEC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2467493249717162257/D34CF87168F0C0C6D8BC4580AE02C84E732AEE3A/",
  // },
  // {
  //   team_id: 1836806,
  //   rating: 1208.81,
  //   wins: 229,
  //   losses: 171,
  //   last_match_time: 1492085926,
  //   name: "the wings gaming",
  //   tag: "Wings",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1836806.png",
  // },
  // {
  //   team_id: 6904594,
  //   rating: 1208.64,
  //   wins: 29,
  //   losses: 19,
  //   last_match_time: 1555683523,
  //   name: "TEAM TEAM",
  //   tag: "tt",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/941705094013048629/255CE8E5D541EE17F1851A13802EA56B7E8E0124/",
  // },
  // {
  //   team_id: 1277104,
  //   rating: 1207.8,
  //   wins: 132,
  //   losses: 86,
  //   last_match_time: 1413641260,
  //   name: "Nvidia.ArrowGaming.AVF",
  //   tag: "Arrow",
  //   logo_url: null,
  // },
  // {
  //   team_id: 6298307,
  //   rating: 1207.57,
  //   wins: 70,
  //   losses: 58,
  //   last_match_time: 1555747688,
  //   name: "The Pango",
  //   tag: "ThePango",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/940591295026034251/E12A61717FA706FD38EB08A90F5C3C4D475613EF/",
  // },
  // {
  //   team_id: 6951036,
  //   rating: 1207,
  //   wins: 37,
  //   losses: 15,
  //   last_match_time: 1604726003,
  //   name: "Cignal Ultra Warriors",
  //   tag: "CUW",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1649964989855561528/65E8E2799787201DB9BB4DE498EFC7888E31F2C8/",
  // },
  // {
  //   team_id: 2714312,
  //   rating: 1205.68,
  //   wins: 49,
  //   losses: 17,
  //   last_match_time: 1509287344,
  //   name: "Aggressive 5",
  //   tag: "A5",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/395581850125148530/0A70A5AE02043F47BBF2DF70FDD698F3602D39B8/",
  // },
  // {
  //   team_id: 8086218,
  //   rating: 1205.55,
  //   wins: 70,
  //   losses: 72,
  //   last_match_time: 1608329601,
  //   name: "TEAM BRASIL",
  //   tag: "BR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1494586360945844832/05E4DDCA25CA313396FFDF01C0D8E65141911E91/",
  // },
  // {
  //   team_id: 1087145,
  //   rating: 1204.99,
  //   wins: 78,
  //   losses: 49,
  //   last_match_time: 1435977931,
  //   name: "Osliki Gaming",
  //   tag: "OsG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/594766568879356896/536D5835599DC8A99ABF3D224D6D2B894905E9EB/",
  // },
  // {
  //   team_id: 8214850,
  //   rating: 1204.98,
  //   wins: 263,
  //   losses: 237,
  //   last_match_time: 1719306430,
  //   name: "BLEED",
  //   tag: "BLEED ",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2295213538402514054/0B50AC73180D7322D099C37682AFF28BE4C4875E/",
  // },
  // {
  //   team_id: 9467430,
  //   rating: 1203.45,
  //   wins: 19,
  //   losses: 19,
  //   last_match_time: 1723899329,
  //   name: "Blacklist International",
  //   tag: "BLCK",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2541800915846514860/CF1883DC6BFE37EA765A0920324ACE06A410DF63/",
  // },
  // {
  //   team_id: 6209804,
  //   rating: 1203.4,
  //   wins: 574,
  //   losses: 419,
  //   last_match_time: 1677433151,
  //   name: "Antarctic Penguins",
  //   tag: "AP",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/6209804.png",
  // },
  // {
  //   team_id: 3580606,
  //   rating: 1203.37,
  //   wins: 48,
  //   losses: 44,
  //   last_match_time: 1494724644,
  //   name: "SG e-sports",
  //   tag: "SG",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3580606.png",
  // },
  // {
  //   team_id: 3326680,
  //   rating: 1202.21,
  //   wins: 51,
  //   losses: 46,
  //   last_match_time: 1488729655,
  //   name: "Horde",
  //   tag: "Horde",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/253716559970590297/28D42322E370E42F0A371025990F4633C4909FDD/",
  // },
  // {
  //   team_id: 7653600,
  //   rating: 1202.08,
  //   wins: 65,
  //   losses: 68,
  //   last_match_time: 1590536584,
  //   name: "Aggressive Mode",
  //   tag: "A-Mode",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/793133917198591097/2372880E304EFDBEFE73D101E54C0A34D9AA8612/",
  // },
  // {
  //   team_id: 7959677,
  //   rating: 1201.53,
  //   wins: 15,
  //   losses: 0,
  //   last_match_time: 1593009943,
  //   name: "Cow boys",
  //   tag: "Coy boys",
  //   logo_url: null,
  // },
  // {
  //   team_id: 166,
  //   rating: 1201.01,
  //   wins: 94,
  //   losses: 28,
  //   last_match_time: 1652279132,
  //   name: "Scenario",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1832407370390600391/4B8EDF6460D5D3210412F6CFC2CBE3764DE863C1/",
  // },
  // {
  //   team_id: 8936210,
  //   rating: 1200.98,
  //   wins: 38,
  //   losses: 26,
  //   last_match_time: 1683172946,
  //   name: "Outsiders From CN",
  //   tag: "OFC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2028342277680788691/B504CC3B01CFEB0201FF7B7CE96F50F61196CE29/",
  // },
  // {
  //   team_id: 8390848,
  //   rating: 1200.93,
  //   wins: 170,
  //   losses: 143,
  //   last_match_time: 1693220545,
  //   name: "ITB.Shuffle",
  //   tag: "ITB",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2033971688891717612/74B24272DD4C683A2069F5B75254A61AC429E8A9/",
  // },
  // {
  //   team_id: 1333179,
  //   rating: 1200.19,
  //   wins: 325,
  //   losses: 252,
  //   last_match_time: 1502155223,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1333179.png",
  // },
  // {
  //   team_id: 8160726,
  //   rating: 1199.2,
  //   wins: 37,
  //   losses: 24,
  //   last_match_time: 1606961904,
  //   name: "4Fun",
  //   tag: "4Fun",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1683743254297527294/E931D830AC1CDEB7D249E39F652F9C3ED8175625/",
  // },
  // {
  //   team_id: 8203841,
  //   rating: 1199.04,
  //   wins: 20,
  //   losses: 4,
  //   last_match_time: 1682874850,
  //   name: "DestroyItems",
  //   tag: "DI",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1680367691784130566/110EDF7E5BDD7D305792871B1FC584B52A5794A8/",
  // },
  // {
  //   team_id: 1773539,
  //   rating: 1198.98,
  //   wins: 25,
  //   losses: 9,
  //   last_match_time: 1439867040,
  //   name: "Battle Zone-",
  //   tag: "Bz",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/528385406419881229/5C6A83090C1C1EFD3818475F1A834DB28D1669FD/",
  // },
  // {
  //   team_id: 8962689,
  //   rating: 1198.62,
  //   wins: 23,
  //   losses: 4,
  //   last_match_time: 1677079160,
  //   name: "Star Foxes",
  //   tag: "Star Fox",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2036224757701238372/A6FA8DDA68296D4ED65754301F2C0AEC812321F7/",
  // },
  // {
  //   team_id: 8310936,
  //   rating: 1198.59,
  //   wins: 90,
  //   losses: 88,
  //   last_match_time: 1666988086,
  //   name: "Wayfarers",
  //   tag: "Way",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1844811606349277848/9115EF5523F852C1EDA27BF1194658188AA197FA/",
  // },
  // {
  //   team_id: 6666989,
  //   rating: 1197.71,
  //   wins: 30,
  //   losses: 27,
  //   last_match_time: 1557239913,
  //   name: "Chaos Esports Club",
  //   tag: "CHAOS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/967615741400119713/CE2F84E2109A30E7726191C7A574756407478ECE/",
  // },
  // {
  //   team_id: 1079149,
  //   rating: 1197.66,
  //   wins: 68,
  //   losses: 60,
  //   last_match_time: 1397485929,
  //   name: "SIGMA.int",
  //   tag: "SIGMA",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1047377791103893273/2F86EB8DE06754907CD316B5DD5D4BE9FC8B1F8A/",
  // },
  // {
  //   team_id: 4372042,
  //   rating: 1197.19,
  //   wins: 24,
  //   losses: 13,
  //   last_match_time: 1682274708,
  //   name: "Team Freedom",
  //   tag: "Freedom",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/788539568429905171/E19817F8B3BFA2F8CDB6F6694F9E024391E82CA0/",
  // },
  // {
  //   team_id: 5994455,
  //   rating: 1196.94,
  //   wins: 27,
  //   losses: 10,
  //   last_match_time: 1569164446,
  //   name: "Team Orca",
  //   tag: "Orca",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/947336408508413358/0FB718DDF99BC7ACA9704C76792C76A31FD5A1E8/",
  // },
  // {
  //   team_id: 111474,
  //   rating: 1196.73,
  //   wins: 1253,
  //   losses: 1018,
  //   last_match_time: 1729861628,
  //   name: "Alliance",
  //   tag: "Alliance",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/111474.png",
  // },
  // {
  //   team_id: 4593831,
  //   rating: 1194.7,
  //   wins: 21,
  //   losses: 12,
  //   last_match_time: 1515615451,
  //   name: "Planet Dog",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/836958712904758112/869204355A15C4FF851DE8FBB51CB5456F0FFA20/",
  // },
  // {
  //   team_id: 1375614,
  //   rating: 1194.2,
  //   wins: 933,
  //   losses: 670,
  //   last_match_time: 1589290053,
  //   name: "Newbee",
  //   tag: "Newbee",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1375614.png",
  // },
  // {
  //   team_id: 1531850,
  //   rating: 1194.1,
  //   wins: 38,
  //   losses: 17,
  //   last_match_time: 1401644024,
  //   name: "North American Rejects",
  //   tag: "NAR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/598160756144927455/E850C4122F42BB762134853C301E4085A1580B19/",
  // },
  // {
  //   team_id: 1059824,
  //   rating: 1193.07,
  //   wins: 39,
  //   losses: 46,
  //   last_match_time: 1403194810,
  //   name: "Dream time.",
  //   tag: "DT",
  //   logo_url: null,
  // },
  // {
  //   team_id: 8083854,
  //   rating: 1192.55,
  //   wins: 36,
  //   losses: 14,
  //   last_match_time: 1603535701,
  //   name: "cuteanimegirls",
  //   tag: "cute",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1486703883910276246/1CFB9D3E73FEF9E4820E6B2B17735FFDA9E3D00C/",
  // },
  // {
  //   team_id: 2640099,
  //   rating: 1192.2,
  //   wins: 57,
  //   losses: 51,
  //   last_match_time: 1464749731,
  //   name: "Shazam~",
  //   tag: "Shazam",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/352770708586900243/F4F65117024751E46AA8696E8B41B5799F984EF7/",
  // },
  // {
  //   team_id: 8597594,
  //   rating: 1192.12,
  //   wins: 92,
  //   losses: 53,
  //   last_match_time: 1668260580,
  //   name: "GrindSky Esports",
  //   tag: "GS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1820012456995413028/340E1875F6D42B587AE030D8084A07785785A3E1/",
  // },
  // {
  //   team_id: 2626685,
  //   rating: 1192,
  //   wins: 397,
  //   losses: 396,
  //   last_match_time: 1590137373,
  //   name: "KEEN GAMING",
  //   tag: "KG",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2626685.png",
  // },
  // {
  //   team_id: 1866993,
  //   rating: 1191.6,
  //   wins: 38,
  //   losses: 35,
  //   last_match_time: 1422443104,
  //   name: "T.O.T",
  //   tag: "SPG.NT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/44230686471780820/581805D8E5EE6AE00B9E0928F2C77CA3352A5309/",
  // },
  // {
  //   team_id: 134900,
  //   rating: 1190.93,
  //   wins: 36,
  //   losses: 34,
  //   last_match_time: 1375638430,
  //   name: "iCCup Team",
  //   tag: "iCCup",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/576743332782064613/4D8326823462288234562F8D1B8B1C0C4265BA6C/",
  // },
  // {
  //   team_id: 8747056,
  //   rating: 1190.91,
  //   wins: 12,
  //   losses: 6,
  //   last_match_time: 1657556724,
  //   name: "Virtus.pro2",
  //   tag: "VP2",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1872947708319301759/087EF97925087F92561983C34475ACBB68A60AE4/",
  // },
  // {
  //   team_id: 20,
  //   rating: 1190.37,
  //   wins: 90,
  //   losses: 131,
  //   last_match_time: 1552055494,
  //   name: "Newbee.mgb",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/20.png",
  // },
  // {
  //   team_id: 8750069,
  //   rating: 1190.35,
  //   wins: 73,
  //   losses: 64,
  //   last_match_time: 1669540224,
  //   name: "Gorilla",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1869570008605866150/1470830050EE0578A875D814A678A5B2FEC01162/",
  // },
  // {
  //   team_id: 1370482,
  //   rating: 1189.71,
  //   wins: 33,
  //   losses: 25,
  //   last_match_time: 1442451852,
  //   name: "Doggy Team",
  //   tag: "DOG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/3280049265377219538/69DDD415F33497854C4EA35A2A96E817E88C0F74/",
  // },
  // {
  //   team_id: 1271771,
  //   rating: 1189.57,
  //   wins: 52,
  //   losses: 57,
  //   last_match_time: 1486128827,
  //   name: "Zero Latitude",
  //   tag: "ZL",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/430448599226110019/41D8B7F02B76DD15E16D40E9C1CC8736D4867E06/",
  // },
  // {
  //   team_id: 8172168,
  //   rating: 1188.68,
  //   wins: 35,
  //   losses: 42,
  //   last_match_time: 1611770460,
  //   name: "mudgolems",
  //   tag: "mg",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1691625337365419101/8E073AB63209CE73BD08E056B1C6CCE1014B3890/",
  // },
  // {
  //   team_id: 8075993,
  //   rating: 1188.37,
  //   wins: 31,
  //   losses: 22,
  //   last_match_time: 1606652860,
  //   name: "For The Dream",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1479947759687058876/E9D7C758D1A2B39D972B0A429660334474F1840E/",
  // },
  // {
  //   team_id: 5725202,
  //   rating: 1187.79,
  //   wins: 21,
  //   losses: 13,
  //   last_match_time: 1531254227,
  //   name: "Wind and Rain",
  //   tag: "WaR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/920302854103492567/67C768AB9721910BE9F29ACF0C8DC6B648B07BC3/",
  // },
  // {
  //   team_id: 8680612,
  //   rating: 1187.31,
  //   wins: 181,
  //   losses: 193,
  //   last_match_time: 1689100340,
  //   name: "noMERCY",
  //   tag: "noMERCY",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2072263299220300385/701C7260941442D8CA1765012CBEE385EC2E23A7/",
  // },
  // {
  //   team_id: 1194815,
  //   rating: 1186.81,
  //   wins: 15,
  //   losses: 2,
  //   last_match_time: 1408827736,
  //   name: "Dwayne 'The John' Rockson",
  //   tag: "THE ROCK",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/685968273337811268/E090D4C9B497CA496D7E32D4F59BA36DD4C846D1/",
  // },
  // {
  //   team_id: 2642171,
  //   rating: 1186.71,
  //   wins: 85,
  //   losses: 92,
  //   last_match_time: 1575137512,
  //   name: "Team AD FINEM",
  //   tag: "AF",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2642171.png",
  // },
  // {
  //   team_id: 8761147,
  //   rating: 1186.04,
  //   wins: 22,
  //   losses: 5,
  //   last_match_time: 1731174845,
  //   name: "tam_sme",
  //   tag: "tam",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1830165414580693025/0490CB1A1C9A39BE4735653CDA9DF8270BE26CB8/",
  // },
  // {
  //   team_id: 6391499,
  //   rating: 1185.39,
  //   wins: 58,
  //   losses: 30,
  //   last_match_time: 1566150730,
  //   name: "ReckoninG eSports ",
  //   tag: "R|G",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/942812211562949712/1A92BF358F2687A4D74D79C4F9C02A4FAE9738EF/",
  // },
  // {
  //   team_id: 8998465,
  //   rating: 1184.16,
  //   wins: 20,
  //   losses: 3,
  //   last_match_time: 1681674200,
  //   name: "TpaBoMaH",
  //   tag: "TpaBa",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2317732077341504585/4CC459216744AE7B6C266B6DB34922976C86AF2C/",
  // },
  // {
  //   team_id: 8376696,
  //   rating: 1183.28,
  //   wins: 282,
  //   losses: 244,
  //   last_match_time: 1731421736,
  //   name: "One Move",
  //   tag: "Move",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2429222166861438379/2016A60B73B29A620CFA81A830603361CBB388AD/",
  // },
  // {
  //   team_id: 6019885,
  //   rating: 1182.92,
  //   wins: 28,
  //   losses: 10,
  //   last_match_time: 1573814224,
  //   name: "Godlike E-Sports",
  //   tag: "GODLIKE",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/947336408508392662/23A2C40943D44B5BE28E278CD55EE0D6D8CD7287/",
  // },
  // {
  //   team_id: 4010914,
  //   rating: 1182.59,
  //   wins: 13,
  //   losses: 1,
  //   last_match_time: 1499392201,
  //   name: "Pariente Gaming ",
  //   tag: "PG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/88223956350603792/FCC2C8AE4B3696CC949AD5E71C887C06B6411AAE/",
  // },
  // {
  //   team_id: 8936568,
  //   rating: 1182.03,
  //   wins: 24,
  //   losses: 4,
  //   last_match_time: 1730648806,
  //   name: "МГАФК",
  //   tag: "МГАФК",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2031736116034979471/1A61C034BCF52896F2AA0421D033716923CA6880/",
  // },
  // {
  //   team_id: 9303076,
  //   rating: 1181.78,
  //   wins: 29,
  //   losses: 23,
  //   last_match_time: 1710346861,
  //   name: "Ninja Penguins",
  //   tag: "NP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2321110410943510446/9E5FCD7F361FE09E5F98520D435ECF6642680AEC/",
  // },
  // {
  //   team_id: 2085365,
  //   rating: 1181.13,
  //   wins: 148,
  //   losses: 148,
  //   last_match_time: 1446833808,
  //   name: "-Ninjas-in-Pyjamas-",
  //   tag: "NiP",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2780911,
  //   rating: 1181.1,
  //   wins: 21,
  //   losses: 3,
  //   last_match_time: 1495722587,
  //   name: "Eagles GIGABYTE",
  //   tag: "Eagles",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/293105300895055647/333101B42C1C0139ED4B1ABB61B8EB46375F30E9/",
  // },
  // {
  //   team_id: 7653080,
  //   rating: 1180.1,
  //   wins: 518,
  //   losses: 479,
  //   last_match_time: 1727183611,
  //   name: "Yangon Galacticos",
  //   tag: "YG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2473119197018002013/9F54FB2ED4476EA67C55994F5FB7CDAC3B882809/",
  // },
  // {
  //   team_id: 3715574,
  //   rating: 1179.1,
  //   wins: 149,
  //   losses: 112,
  //   last_match_time: 1529795935,
  //   name: "SG e-sports team",
  //   tag: "SG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/936063882399484485/4DD8B4901982E1821341B595BCF90FCC721F0291/",
  // },
  // {
  //   team_id: 3331948,
  //   rating: 1178.79,
  //   wins: 258,
  //   losses: 203,
  //   last_match_time: 1529567163,
  //   name: "LGD.Forever Young",
  //   tag: "LFY",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3331948.png",
  // },
  // {
  //   team_id: 1951061,
  //   rating: 1178.07,
  //   wins: 318,
  //   losses: 277,
  //   last_match_time: 1555659408,
  //   name: "Newbee.Young",
  //   tag: "Newbee.Y",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/942838330319094177/5F9876C7DA2D8085310A8093BBBD37FF51093167/",
  // },
  // {
  //   team_id: 990533,
  //   rating: 1177.96,
  //   wins: 15,
  //   losses: 1,
  //   last_match_time: 1472400222,
  //   name: "MeeT YouR Mak3rS",
  //   tag: "mYm",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2555665,
  //   rating: 1177.54,
  //   wins: 15,
  //   losses: 7,
  //   last_match_time: 1450134920,
  //   name: "Archon Dota",
  //   tag: "Archon",
  //   logo_url: null,
  // },
  // {
  //   team_id: 3722485,
  //   rating: 1177.53,
  //   wins: 18,
  //   losses: 10,
  //   last_match_time: 1489425372,
  //   name: "B)ears",
  //   tag: "B)",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/101728066962847284/B07F6E7D1D4ECCDA3AEB4985A222F839C42E1CBE/",
  // },
  // {
  //   team_id: 8112124,
  //   rating: 1177,
  //   wins: 219,
  //   losses: 196,
  //   last_match_time: 1664204560,
  //   name: "Brame",
  //   tag: "BR",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1535122449444925400/4FF8F5867A8D86DF5700A8BDAD237A457B52E97B/",
  // },
  // {
  //   team_id: 7121264,
  //   rating: 1176.78,
  //   wins: 14,
  //   losses: 1,
  //   last_match_time: 1562491789,
  //   name: "PANDA GAMING",
  //   tag: "PG",
  //   logo_url: null,
  // },
  // {
  //   team_id: 626763,
  //   rating: 1176.25,
  //   wins: 13,
  //   losses: 3,
  //   last_match_time: 1370974522,
  //   name: "AllianceGG",
  //   tag: "[A]",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2552118,
  //   rating: 1176.13,
  //   wins: 18,
  //   losses: 7,
  //   last_match_time: 1479719778,
  //   name: "FTD club a",
  //   tag: "FTD.a",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2552118.png",
  // },
  // {
  //   team_id: 3317512,
  //   rating: 1175.81,
  //   wins: 22,
  //   losses: 6,
  //   last_match_time: 1484377661,
  //   name: "DUOBAO Young",
  //   tag: "DUOBAO.Y",
  //   logo_url: null,
  // },
  // {
  //   team_id: 7422789,
  //   rating: 1175.8,
  //   wins: 481,
  //   losses: 428,
  //   last_match_time: 1727186426,
  //   name: "9Pandas",
  //   tag: "9Pandas",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2485502666697365913/234C3E0EDA6A8E315DC78A5EC2C6C1FBB1DD6657/",
  // },
  // {
  //   team_id: 8132093,
  //   rating: 1174.05,
  //   wins: 19,
  //   losses: 2,
  //   last_match_time: 1604163707,
  //   name: "01 Esports",
  //   tag: "01",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1539625527765315928/563F5BCDBD36AC91DB60BB2A5C017CFB0020B042/",
  // },
  // {
  //   team_id: 9017079,
  //   rating: 1174.02,
  //   wins: 22,
  //   losses: 12,
  //   last_match_time: 1685854942,
  //   name: "Piggy Killer",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2008080431591762816/F33DCB7DA537A7F1BD28F972FCFF358913CF546A/",
  // },
  // {
  //   team_id: 2585896,
  //   rating: 1173.86,
  //   wins: 20,
  //   losses: 4,
  //   last_match_time: 1530377198,
  //   name: "eEriness!",
  //   tag: "eE",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/404556234640411761/4758656C1505E4B00FFACA5DF3C027F0D7D2AD5B/",
  // },
  // {
  //   team_id: 2346476,
  //   rating: 1173.76,
  //   wins: 15,
  //   losses: 1,
  //   last_match_time: 1434211078,
  //   name: "LEGIO VICTRIX !",
  //   tag: "lv",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/543026540283900928/5F8FF9047351094BF1B55A0BC52AB1C9A21B8CB0/",
  // },
  // {
  //   team_id: 7731375,
  //   rating: 1173.69,
  //   wins: 28,
  //   losses: 6,
  //   last_match_time: 1596451473,
  //   name: "Neets Uprising",
  //   tag: "Neets",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/793134540759885368/BA2CD08B658CE3F2B3571FAAF20E6242555FC1D5/",
  // },
  // {
  //   team_id: 995682,
  //   rating: 1173.54,
  //   wins: 27,
  //   losses: 21,
  //   last_match_time: 1436466709,
  //   name: "SuperStrongDinosaurs",
  //   tag: "SSD",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/884119748687027366/4E5F67CD1B80D2886939D737E906451741305ABD/",
  // },
  // {
  //   team_id: 9279103,
  //   rating: 1173.47,
  //   wins: 17,
  //   losses: 4,
  //   last_match_time: 1715104797,
  //   name: "Business club",
  //   tag: "BC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2315476471672988468/630288F96C4CB6BE1A045382D8638D0E93FC81BF/",
  // },
  // {
  //   team_id: 3325212,
  //   rating: 1173.22,
  //   wins: 74,
  //   losses: 21,
  //   last_match_time: 1560416158,
  //   name: "Natural 9",
  //   tag: "N9",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/437235714274666198/2EEED3186A9F34FE3798107001498618882EC97B/",
  // },
  // {
  //   team_id: 8374752,
  //   rating: 1172.89,
  //   wins: 23,
  //   losses: 13,
  //   last_match_time: 1635864174,
  //   name: "Tapan Gaming",
  //   tag: "TPN",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1801996808835002009/40ADAF50CF0B40D86D4AD5A1E8E18D98343570E8/",
  // },
  // {
  //   team_id: 6849739,
  //   rating: 1171.46,
  //   wins: 111,
  //   losses: 81,
  //   last_match_time: 1610055021,
  //   name: "Ωmega Gaming",
  //   tag: "Ωmega",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1458555594796362184/AB542C336682A319783E2EF6AB24F9ACE0779F0D/",
  // },
  // {
  //   team_id: 8058269,
  //   rating: 1171.13,
  //   wins: 15,
  //   losses: 1,
  //   last_match_time: 1595000860,
  //   name: "Clown City",
  //   tag: "CC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1338082799228400637/420D6223667D8F44081C7F794395215C08061B08/",
  // },
  // {
  //   team_id: 6189996,
  //   rating: 1171.06,
  //   wins: 39,
  //   losses: 31,
  //   last_match_time: 1549791122,
  //   name: "Lotac",
  //   tag: "LOTAC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/772866954991861623/B62AB6B6CC124B730DC30D1D8A551490E1EBF48A/",
  // },
  // {
  //   team_id: 2789717,
  //   rating: 1169.95,
  //   wins: 32,
  //   losses: 30,
  //   last_match_time: 1468431479,
  //   name: "Polarity Dota 2",
  //   tag: "Polarity",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/290853501095454898/DEF7A669784898DFDAC5B3C92816FEC8D1DA4785/",
  // },
  // {
  //   team_id: 1798457,
  //   rating: 1169.78,
  //   wins: 28,
  //   losses: 22,
  //   last_match_time: 1731449003,
  //   name: "Hokori",
  //   tag: "Hk",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2273817764862395533/4669EB60CBE75674BC738516919F5C53A1BD148C/",
  // },
  // {
  //   team_id: 1061269,
  //   rating: 1168.35,
  //   wins: 217,
  //   losses: 163,
  //   last_match_time: 1701202262,
  //   name: "Vivo Keyd Stars",
  //   tag: "VKS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/5095292505104094637/268CD3E0F09AF23BB67C536D74056E8D8C1488E9/",
  // },
  // {
  //   team_id: 689412,
  //   rating: 1168.08,
  //   wins: 15,
  //   losses: 3,
  //   last_match_time: 1382279007,
  //   name: 'Team "SEX"',
  //   tag: "sex",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/28466819170196410/708BB6A5590552457B6C8496BD7A28D775E9866E/",
  // },
  // {
  //   team_id: 8456656,
  //   rating: 1167.88,
  //   wins: 17,
  //   losses: 4,
  //   last_match_time: 1625233929,
  //   name: "lowkees",
  //   tag: "lwkk",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1711912925177936251/6973F4F7F63EEA64B3B7D2B765F989503434CF3B/",
  // },
  // {
  //   team_id: 6491135,
  //   rating: 1167.31,
  //   wins: 35,
  //   losses: 28,
  //   last_match_time: 1552224858,
  //   name: "Flying Penguins",
  //   tag: "FP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/995764507566907229/CB60599857CFB9D6710D508F7FC88A3E8D09FE95/",
  // },
  // {
  //   team_id: 9414800,
  //   rating: 1166.63,
  //   wins: 69,
  //   losses: 33,
  //   last_match_time: 1720689273,
  //   name: "Business Club",
  //   tag: "bc",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2518151211081909317/069C7E7A630A8FA333ED65FE72820D4091D24953/",
  // },
  // {
  //   team_id: 498033,
  //   rating: 1166.34,
  //   wins: 22,
  //   losses: 6,
  //   last_match_time: 1383045806,
  //   name: "FXOpen E-Sports",
  //   tag: "FXO",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/595873628477068295/F40490019313A0FC878D6555EAFCC068F975169F/",
  // },
  // {
  //   team_id: 9175127,
  //   rating: 1165.89,
  //   wins: 100,
  //   losses: 72,
  //   last_match_time: 1697837089,
  //   name: "Generation of Miracles",
  //   tag: "GeoM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2018230014077150128/2A5B42C481DCF8BC3A8AF59BB40F2587E10541C8/",
  // },
  // {
  //   team_id: 8858106,
  //   rating: 1165.88,
  //   wins: 27,
  //   losses: 17,
  //   last_match_time: 1669975851,
  //   name: "Unity Gaming",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1903359885429306314/1A31E4B8947848865DDF8656458180572F6DB941/",
  // },
  // {
  //   team_id: 8789578,
  //   rating: 1165.8,
  //   wins: 19,
  //   losses: 6,
  //   last_match_time: 1701522845,
  //   name: "vezzra",
  //   tag: "vezzra",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2042986495990339051/AD05C5372484A344F81EF3351636B3841D46B38E/",
  // },
  // {
  //   team_id: 2880140,
  //   rating: 1165.76,
  //   wins: 32,
  //   losses: 29,
  //   last_match_time: 1521259802,
  //   name: "Fire Dragoon",
  //   tag: "FDG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/918041897063794108/2CA6E623CF6693940E3456FB8D77764E35C62E95/",
  // },
  // {
  //   team_id: 8089675,
  //   rating: 1165.69,
  //   wins: 25,
  //   losses: 13,
  //   last_match_time: 1605503742,
  //   name: "Escape Velocity",
  //   tag: "EV",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1482200421119183052/E2A77696D5B5F77238212747B0940810A9751858/",
  // },
  // {
  //   team_id: 2659468,
  //   rating: 1165.6,
  //   wins: 176,
  //   losses: 126,
  //   last_match_time: 1512618696,
  //   name: "歪歪僾輻ЧЧ ƒ·控м",
  //   tag: "歪歪僾輻ЧЧ ƒ·控",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2659468.png",
  // },
  // {
  //   team_id: 6929895,
  //   rating: 1164.82,
  //   wins: 14,
  //   losses: 1,
  //   last_match_time: 1557053381,
  //   name: "Pejuang Dota Badung",
  //   tag: "PDB",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/990136273741176420/D0459811843F8E55E73732623C3564812AB5C226/",
  // },
  // {
  //   team_id: 9470838,
  //   rating: 1164.8,
  //   wins: 27,
  //   losses: 10,
  //   last_match_time: 1723586210,
  //   name: "Infinity",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2509149818685438891/5B2E6F77EF94D4EF6DDF90A003CA91BEA2C03F31/",
  // },
  // {
  //   team_id: 8261554,
  //   rating: 1164.39,
  //   wins: 384,
  //   losses: 359,
  //   last_match_time: 1705466787,
  //   name: "Army Geniuses Mansion ",
  //   tag: "AGM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2036229291620856332/487BA4B704ADCA08C99B4F67E0DF5EF3D2F4DFD1/",
  // },
  // {
  //   team_id: 5067988,
  //   rating: 1163.74,
  //   wins: 35,
  //   losses: 14,
  //   last_match_time: 1551533667,
  //   name: "ROG TiTans",
  //   tag: "ROG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/868494063065916993/C67CC3764B12B5007D87434BC78A0AFB8DEEDB6E/",
  // },
  // {
  //   team_id: 1286339,
  //   rating: 1163.53,
  //   wins: 13,
  //   losses: 0,
  //   last_match_time: 1395099934,
  //   name: "Arizona Iced Out Boys",
  //   tag: "sad",
  //   logo_url: null,
  // },
  // {
  //   team_id: 9366499,
  //   rating: 1163.47,
  //   wins: 19,
  //   losses: 13,
  //   last_match_time: 1711510325,
  //   name: "KEV",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2470858903185171081/B86EC502336507A8BC8CEDC1E571D9E273B7EAD6/",
  // },
  // {
  //   team_id: 9337731,
  //   rating: 1163.41,
  //   wins: 34,
  //   losses: 28,
  //   last_match_time: 1719347702,
  //   name: "LEVIATAN",
  //   tag: "LEV",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2476496009610060553/805B58DE6A151FD0946F26A8181F711AB38FDD9F/",
  // },
  // {
  //   team_id: 8634419,
  //   rating: 1162.67,
  //   wins: 16,
  //   losses: 5,
  //   last_match_time: 1728232099,
  //   name: "«444» Squad",
  //   tag: "«444»",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2483261555751237752/8444746CA8F12036A07FB6A2237C3F0A45A14D42/",
  // },
  // {
  //   team_id: 8022392,
  //   rating: 1162.41,
  //   wins: 18,
  //   losses: 8,
  //   last_match_time: 1592593762,
  //   name: "One More Game",
  //   tag: "OMG",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2783913,
  //   rating: 1160.23,
  //   wins: 83,
  //   losses: 100,
  //   last_match_time: 1480181442,
  //   name: "Escape Gaming",
  //   tag: "ESC",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2783913.png",
  // },
  // {
  //   team_id: 7542619,
  //   rating: 1160.02,
  //   wins: 13,
  //   losses: 1,
  //   last_match_time: 1712467473,
  //   name: "GALKYNYSH",
  //   tag: "GLKN",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1839180033860671079/ADC9EC54D3B6A50F54E372E97C36809E008BD11B/",
  // },
  // {
  //   team_id: 5895083,
  //   rating: 1159.59,
  //   wins: 75,
  //   losses: 30,
  //   last_match_time: 1580548879,
  //   name: "Athletico",
  //   tag: "/A/",
  //   logo_url: null,
  // },
  // {
  //   team_id: 2552670,
  //   rating: 1159.36,
  //   wins: 110,
  //   losses: 106,
  //   last_match_time: 1497370190,
  //   name: "Prodota Gaming",
  //   tag: "PD",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2552670.png",
  // },
  // {
  //   team_id: 7344577,
  //   rating: 1159.35,
  //   wins: 12,
  //   losses: 0,
  //   last_match_time: 1566737645,
  //   name: "Gang Bang",
  //   tag: "GB",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/795369537916539589/A550EF44B84CB15D04C1D9B6A5E3943F5F67894C/",
  // },
  // {
  //   team_id: 6736502,
  //   rating: 1159.19,
  //   wins: 23,
  //   losses: 7,
  //   last_match_time: 1567780225,
  //   name: "Fourzerozone",
  //   tag: "40z",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1004771069909482752/0C5151599DECC6AEFD28F8B114C107BC4B840B20/",
  // },
  // {
  //   team_id: 8944302,
  //   rating: 1158.76,
  //   wins: 101,
  //   losses: 74,
  //   last_match_time: 1693219165,
  //   name: "Myth Avenue Gaming",
  //   tag: "MAG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2077887587643978863/6C1ACCEC01EB0BE4C9C75D82421118973C757DCF/",
  // },
  // {
  //   team_id: 8360138,
  //   rating: 1158.72,
  //   wins: 604,
  //   losses: 524,
  //   last_match_time: 1719382924,
  //   name: "Neon Esports",
  //   tag: "neon",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1905612771137210708/67688ED3000B1B2140DCDD96275E114F3F9F3BC7/",
  // },
  // {
  //   team_id: 468458,
  //   rating: 1158.71,
  //   wins: 22,
  //   losses: 8,
  //   last_match_time: 1382465627,
  //   name: "E.C.",
  //   tag: "EC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/882977285055841873/5029916D0E6C0E527029054143275A02693375F3/",
  // },
  // {
  //   team_id: 680683,
  //   rating: 1158.43,
  //   wins: 35,
  //   losses: 28,
  //   last_match_time: 1380478840,
  //   name: "Quantic|Gaming",
  //   tag: "Quantic",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1117170899589104466/11989B210556A85FCA671890780B2D0C8BC467A4/",
  // },
  // {
  //   team_id: 9381131,
  //   rating: 1158.21,
  //   wins: 210,
  //   losses: 117,
  //   last_match_time: 1731099228,
  //   name: "Uzumaki",
  //   tag: "uzu",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2466356199630889718/46DF71E78D3B769867F51200FB60F6C692EFFF28/",
  // },
  // {
  //   team_id: 2244697,
  //   rating: 1158.18,
  //   wins: 53,
  //   losses: 40,
  //   last_match_time: 1458604298,
  //   name: "Team Archon",
  //   tag: "Archon",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2244697.png",
  // },
  // {
  //   team_id: 5000254,
  //   rating: 1158.12,
  //   wins: 42,
  //   losses: 28,
  //   last_match_time: 1511768191,
  //   name: "ForTheDream",
  //   tag: "FTD",
  //   logo_url:
  //     "http://cloud-3.steamusercontent.com/ugc/860606208065606394/85EFA7A0173DA2C1095BB473335F8BED3EB6E4EF/",
  // },
  // {
  //   team_id: 5138280,
  //   rating: 1157.71,
  //   wins: 79,
  //   losses: 40,
  //   last_match_time: 1561809918,
  //   name: "Signify",
  //   tag: "Signify",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/832512818113241705/529626C109FCA595D47A5F04C85A76106DA43E6A/",
  // },
  // {
  //   team_id: 8161113,
  //   rating: 1157.71,
  //   wins: 48,
  //   losses: 34,
  //   last_match_time: 1612726280,
  //   name: "Live to Win",
  //   tag: "LTW",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1684869309280763059/CE8EAFDDA8854D0B380EC63B819149778ACEEE0B/",
  // },
  // {
  //   team_id: 9500213,
  //   rating: 1157.39,
  //   wins: 18,
  //   losses: 15,
  //   last_match_time: 1727619542,
  //   name: "Bruv123",
  //   tag: "Bruv",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2464121890863035592/69CB2CDE8B7290C49DB92F16EE84811F3D6F7278/",
  // },
  // {
  //   team_id: 1410456,
  //   rating: 1157.23,
  //   wins: 26,
  //   losses: 12,
  //   last_match_time: 1400425324,
  //   name: "TeamCoast",
  //   tag: "mmc",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/777181466527196630/3206CD32FB9D14B0106F4742AAC23FA7B3C699B3/",
  // },
  // {
  //   team_id: 9545744,
  //   rating: 1157.08,
  //   wins: 11,
  //   losses: 6,
  //   last_match_time: 1727277577,
  //   name: "Team Kukuys",
  //   tag: "Kukuys",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2495646454751623231/64BE465662BBDCBF2128F93AC1BFCB0B8C6E15AF/",
  // },
  // {
  //   team_id: 7937681,
  //   rating: 1157.06,
  //   wins: 19,
  //   losses: 5,
  //   last_match_time: 1597770245,
  //   name: "DestroyItems",
  //   tag: "DI",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1026202486988728855/110EDF7E5BDD7D305792871B1FC584B52A5794A8/",
  // },
  // {
  //   team_id: 8230491,
  //   rating: 1156.94,
  //   wins: 13,
  //   losses: 11,
  //   last_match_time: 1608476893,
  //   name: "TEAM DOG",
  //   tag: "",
  //   logo_url: null,
  // },
  // {
  //   team_id: 3547682,
  //   rating: 1156.43,
  //   wins: 56,
  //   losses: 59,
  //   last_match_time: 1498656255,
  //   name: "Team VGJ",
  //   tag: "VGJ",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3547682.png",
  // },
  // {
  //   team_id: 8428953,
  //   rating: 1156.35,
  //   wins: 11,
  //   losses: 1,
  //   last_match_time: 1664707318,
  //   name: "Sinister 5",
  //   tag: "Sin5",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1758072603532456160/1F5DEE24522DB96F9CCA4F5D9BFCAFF5F47BB658/",
  // },
  // {
  //   team_id: 7404529,
  //   rating: 1156.34,
  //   wins: 105,
  //   losses: 128,
  //   last_match_time: 1597644701,
  //   name: "Reality Rift",
  //   tag: "RR ",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1022822776634092251/D6D106057358BC1992E78DEC8421AEC34C472C4E/",
  // },
  // {
  //   team_id: 7355202,
  //   rating: 1155.93,
  //   wins: 18,
  //   losses: 8,
  //   last_match_time: 1637798108,
  //   name: "0-900",
  //   tag: "Ceros&99",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1704034601417286784/36BCC7B8CD0F264624D0D51541B0A17EE75185C8/",
  // },
  // {
  //   team_id: 5407259,
  //   rating: 1155.9,
  //   wins: 26,
  //   losses: 18,
  //   last_match_time: 1533715886,
  //   name: "TNC Tigers",
  //   tag: "Tigers",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/923681543827846572/0F5B3F843B4BFFCE3540CEF8268FC47871AE22DA/",
  // },
  // {
  //   team_id: 6465690,
  //   rating: 1155.88,
  //   wins: 13,
  //   losses: 2,
  //   last_match_time: 1546153905,
  //   name: "Team Fantech",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/959725957385483074/80189B02D479436280E4C3C98A0327E30663721C/",
  // },
  // {
  //   team_id: 2512249,
  //   rating: 1155.77,
  //   wins: 286,
  //   losses: 197,
  //   last_match_time: 1510864553,
  //   name: "123",
  //   tag: "123",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/2512249.png",
  // },
  // {
  //   team_id: 7269341,
  //   rating: 1155.23,
  //   wins: 24,
  //   losses: 11,
  //   last_match_time: 1570203571,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/781854449293846193/3B7DF794677380551069930FBC517F74BE1EA685/",
  // },
  // {
  //   team_id: 6796511,
  //   rating: 1155.13,
  //   wins: 18,
  //   losses: 6,
  //   last_match_time: 1564317305,
  //   name: "Saints ID",
  //   tag: "Saints",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/936090012088817342/7FEB4D013374B88FB118D393988C9949A97FF412/",
  // },
  // {
  //   team_id: 8697328,
  //   rating: 1155.11,
  //   wins: 7,
  //   losses: 3,
  //   last_match_time: 1682706211,
  //   name: "Please dont gank my carry",
  //   tag: "gnk",
  //   logo_url: null,
  // },
  // {
  //   team_id: 1848465,
  //   rating: 1154.99,
  //   wins: 43,
  //   losses: 56,
  //   last_match_time: 1421324382,
  //   name: "LaiGaming",
  //   tag: "LaiG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/28466819225810269/A0495348313DA709ACE717D1D4BDFA10FF0F715B/",
  // },
  // {
  //   team_id: 1256674,
  //   rating: 1154.89,
  //   wins: 32,
  //   losses: 19,
  //   last_match_time: 1412885225,
  //   name: "XPC International",
  //   tag: "XPC.Int",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/50986085993332472/2733A94CBA92F8D2B87DA608536E2E86ECE91D30/",
  // },
  // {
  //   team_id: 7441136,
  //   rating: 1154.85,
  //   wins: 181,
  //   losses: 159,
  //   last_match_time: 1608415239,
  //   name: "beastcoast",
  //   tag: "bc",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/773981410527259604/8B88726CBCA1E1F5FC3368D49BCF84F609B138FF/",
  // },
  // {
  //   team_id: 7359917,
  //   rating: 1154.26,
  //   wins: 61,
  //   losses: 47,
  //   last_match_time: 1585215931,
  //   name: "Lowkey",
  //   tag: "LK",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/780750599790860449/55E39216A68ADCFC25930DE5D27AA8C542C0AB2F/",
  // },
  // {
  //   team_id: 4426165,
  //   rating: 1154.14,
  //   wins: 17,
  //   losses: 6,
  //   last_match_time: 1513417977,
  //   name: "xxX",
  //   tag: "Signify",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/842585341023837640/349CE23F40FBA351CE9A5BC4A4388CFFF6B0D30B/",
  // },
  // {
  //   team_id: 8375259,
  //   rating: 1153.84,
  //   wins: 356,
  //   losses: 300,
  //   last_match_time: 1729674633,
  //   name: "Infinity",
  //   tag: "Infinity",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2270441745021468359/0B29AFE6D9B224CB4EDB33A07AAC0D3FD19A00A6/",
  // },
  // {
  //   team_id: 9005165,
  //   rating: 1153.19,
  //   wins: 559,
  //   losses: 286,
  //   last_match_time: 1697814332,
  //   name: "Star Foxes",
  //   tag: "SF",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2027220095511607538/A6FA8DDA68296D4ED65754301F2C0AEC812321F7/",
  // },
  // {
  //   team_id: 9449748,
  //   rating: 1153.18,
  //   wins: 59,
  //   losses: 53,
  //   last_match_time: 1729278274,
  //   name: "DMS",
  //   tag: "DMS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2455104631629284478/B74AA7BD9C3BFAF7FABE05E2833719A5D9A3A386/",
  // },
  // {
  //   team_id: 4186376,
  //   rating: 1153.14,
  //   wins: 59,
  //   losses: 38,
  //   last_match_time: 1500658139,
  //   name: "Team Singularity",
  //   tag: "SNG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/809929916643509535/322F82A5A63214DE068DF2FCBC2A5ADC1FDDF6C7/",
  // },
  // {
  //   team_id: 7556672,
  //   rating: 1152.9,
  //   wins: 68,
  //   losses: 56,
  //   last_match_time: 1604942915,
  //   name: "TEMPO",
  //   tag: "TMP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1014941230375645445/E826D65D49CF0CE7A41F1F65BD39AAA5A88A9ADE/",
  // },
  // {
  //   team_id: 5051649,
  //   rating: 1152.87,
  //   wins: 69,
  //   losses: 72,
  //   last_match_time: 1529966930,
  //   name: "Immortals",
  //   tag: "IMT",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/5051649.png",
  // },
  // {
  //   team_id: 6306453,
  //   rating: 1152.58,
  //   wins: 38,
  //   losses: 39,
  //   last_match_time: 1554546750,
  //   name: "WarriorsGaming.Unity",
  //   tag: "WG.Unity",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/947338758893687723/9ED51D18B93C3E1CBA9C58706D45CF42D6F2551C/",
  // },
  // {
  //   team_id: 3785359,
  //   rating: 1152.17,
  //   wins: 159,
  //   losses: 134,
  //   last_match_time: 1581669735,
  //   name: "BOOM ID",
  //   tag: "BOOM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/763846429146901501/E85EF58254E8BA84ED8A085512ECA0A6499499FE/",
  // },
  // {
  //   team_id: 9142627,
  //   rating: 1151.58,
  //   wins: 16,
  //   losses: 6,
  //   last_match_time: 1705132749,
  //   name: "Business Club",
  //   tag: "BC",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2031737253375716324/F2394A2E273AFE7FF7A0CCA0E8151D693EA2558C/",
  // },
  // {
  //   team_id: 67,
  //   rating: 1151.32,
  //   wins: 307,
  //   losses: 279,
  //   last_match_time: 1582505594,
  //   name: "paiN Gaming",
  //   tag: "paiN",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/67.png",
  // },
  // {
  //   team_id: 7586931,
  //   rating: 1151.11,
  //   wins: 14,
  //   losses: 3,
  //   last_match_time: 1580630315,
  //   name: "Avengers",
  //   tag: "A",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/779619985829101396/7AD3F16DB80CC0EB3D429F0B66A85F05D68DF6A7/",
  // },
  // {
  //   team_id: 9148266,
  //   rating: 1150.87,
  //   wins: 19,
  //   losses: 9,
  //   last_match_time: 1691866517,
  //   name: "AcatSuki",
  //   tag: "CAT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2053131008994362284/5E9C97210030F1BE6E890A2B813DD18411C1A12A/",
  // },
  // {
  //   team_id: 8563718,
  //   rating: 1150.54,
  //   wins: 68,
  //   losses: 15,
  //   last_match_time: 1642524567,
  //   name: "WildDeer",
  //   tag: "WD",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1871808498262766640/599BC2F85FE5F33F28E3D5922EE7601999ED7340/",
  // },
  // {
  //   team_id: 9499095,
  //   rating: 1150.4,
  //   wins: 23,
  //   losses: 15,
  //   last_match_time: 1727647674,
  //   name: "Legacy",
  //   tag: "LGCY",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2483257751331641617/0F960E60CEB27A6732146E98A194B9E3F8E29BE4/",
  // },
  // {
  //   team_id: 7669103,
  //   rating: 1150.31,
  //   wins: 95,
  //   losses: 116,
  //   last_match_time: 1603026289,
  //   name: "Cyber Legacy",
  //   tag: "CL",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1634201755650370035/913F61C7C49EC6330168C986C5F1B0D5D2873361/",
  // },
  // {
  //   team_id: 9346249,
  //   rating: 1150.23,
  //   wins: 18,
  //   losses: 20,
  //   last_match_time: 1711546216,
  //   name: "Geek Fam",
  //   tag: "GeekFam",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2492249322244227443/C72214A86E6C450DA8CF1A18AE9539B5CAEE7D45/",
  // },
  // {
  //   team_id: 9079676,
  //   rating: 1150.15,
  //   wins: 16,
  //   losses: 10,
  //   last_match_time: 1687640893,
  //   name: "Los moticucos",
  //   tag: "PARK",
  //   logo_url: null,
  // },
  // {
  //   team_id: 6187657,
  //   rating: 1149.85,
  //   wins: 633,
  //   losses: 450,
  //   last_match_time: 1614858991,
  //   name: "Plae8 Neon",
  //   tag: "neon",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1840291882364164364/B33D494DF247FC383DB1E0847BB81BE0DA6DB9C3/",
  // },
  // {
  //   team_id: 2787008,
  //   rating: 1149.82,
  //   wins: 12,
  //   losses: 1,
  //   last_match_time: 1467550373,
  //   name: "Corgi in a Team",
  //   tag: "Ciat",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/263835861937179495/E6621DE29134BF92F72B44B595805C2CF6638514/",
  // },
  // {
  //   team_id: 3706823,
  //   rating: 1149.63,
  //   wins: 29,
  //   losses: 10,
  //   last_match_time: 1500983020,
  //   name: "G4",
  //   tag: "G4",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/824567564818787534/F69B1468E544413DF562F8923F9B7E5FFE1355A9/",
  // },
  // {
  //   team_id: 8831040,
  //   rating: 1149.38,
  //   wins: 989,
  //   losses: 874,
  //   last_match_time: 1731498780,
  //   name: "Ghost Sheep",
  //   tag: "GS",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1812159972016009901/2463CD2FDC05838CACEB66AAE27F597798C40B34/",
  // },
  // {
  //   team_id: 1556150,
  //   rating: 1149.34,
  //   wins: 10,
  //   losses: 0,
  //   last_match_time: 1404048629,
  //   name: "SPNV Inspire return",
  //   tag: "SPNV",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/3281181583533490678/16F77ED8634829BA95FC93A20EBABC8BF1D45E8E/",
  // },
  // {
  //   team_id: 7716206,
  //   rating: 1149.31,
  //   wins: 44,
  //   losses: 30,
  //   last_match_time: 1590033794,
  //   name: "Clcombat Team",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/771742768467331494/0472A95C77A0E26D08922566045C5039078C6D78/",
  // },
  // {
  //   team_id: 5040783,
  //   rating: 1148.99,
  //   wins: 15,
  //   losses: 11,
  //   last_match_time: 1506543545,
  //   name: "Immortals",
  //   tag: "IMT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/872993216721628296/3840921B905A5B28840C85C6449D1447073A16E5/",
  // },
  // {
  //   team_id: 5992560,
  //   rating: 1148.66,
  //   wins: 24,
  //   losses: 23,
  //   last_match_time: 1586623575,
  //   name: "Infamous Young",
  //   tag: "Infamous Y",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/787496790090045569/38FCE2E7A46F3C558C9F00DB0B9287E310DE38C7/",
  // },
  // {
  //   team_id: 1236083,
  //   rating: 1148.26,
  //   wins: 23,
  //   losses: 8,
  //   last_match_time: 1474906257,
  //   name: "FelMysT",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/847088009729950752/DF0CEFAD8DC30804594D5925A8DA9BC73F182CD3/",
  // },
  // {
  //   team_id: 8168302,
  //   rating: 1148.19,
  //   wins: 97,
  //   losses: 91,
  //   last_match_time: 1629358787,
  //   name: "Forest",
  //   tag: "Forest",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1678114018011000727/D4221B7FF3778B415752910C1B95147BD299A32D/",
  // },
  // {
  //   team_id: 8790102,
  //   rating: 1147.77,
  //   wins: 18,
  //   losses: 12,
  //   last_match_time: 1664911118,
  //   name: "Anime Enjoyers",
  //   tag: "AE",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1779504675340866269/9B84E5C6529F42243451665477F33B96C1F475F5/",
  // },
  // {
  //   team_id: 8598633,
  //   rating: 1147.71,
  //   wins: 174,
  //   losses: 168,
  //   last_match_time: 1700936463,
  //   name: "Moneymakers",
  //   tag: "MM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2021594397761808667/0D4392B2C152DE0424EBCB6B6DC3A4DBDDCCD12F/",
  // },
  // {
  //   team_id: 6876721,
  //   rating: 1147.55,
  //   wins: 19,
  //   losses: 9,
  //   last_match_time: 1573978199,
  //   name: "Hans Pro Gaming",
  //   tag: "HPG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/781853033311013173/34F2620CE5151465690DE7C106B6EF69F02A4757/",
  // },
  // {
  //   team_id: 8589016,
  //   rating: 1147.51,
  //   wins: 17,
  //   losses: 4,
  //   last_match_time: 1661565713,
  //   name: "Team WANKA",
  //   tag: "T.Wanka",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1926996054456055976/4EBBE607134216E7F16DC7E111D16095AD1B5548/",
  // },
  // {
  //   team_id: 1371515,
  //   rating: 1147.43,
  //   wins: 38,
  //   losses: 35,
  //   last_match_time: 1401912809,
  //   name: "Aware.Gaming",
  //   tag: "Aware",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/794063002581119172/C1F4467BD1C24C7E8B72343394F92394C88CFC2A/",
  // },
  // {
  //   team_id: 8151879,
  //   rating: 1145.66,
  //   wins: 16,
  //   losses: 14,
  //   last_match_time: 1604845391,
  //   name: "Among Us",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1660098812171826030/93B496CC8B327FEB2573D6DDD7C340CB9D09A9AB/",
  // },
  // {
  //   team_id: 7391166,
  //   rating: 1145.45,
  //   wins: 17,
  //   losses: 6,
  //   last_match_time: 1571751459,
  //   name: "Fighting Pepegas",
  //   tag: "FP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/784113713638523170/BCEB87E857A0D1EA01D4E84C731A5FC7258779B1/",
  // },
  // {
  //   team_id: 8261774,
  //   rating: 1145.38,
  //   wins: 163,
  //   losses: 107,
  //   last_match_time: 1644854977,
  //   name: "V-Gaming",
  //   tag: "V",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1699530468133422483/88FB30CF0880687F9BB0429E3C7B59CF673DC700/",
  // },
  // {
  //   team_id: 1513164,
  //   rating: 1145.18,
  //   wins: 68,
  //   losses: 62,
  //   last_match_time: 1441036430,
  //   name: "aSpera ESports",
  //   tag: "aSpera",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/35247586654199397/AA75FD3C7D0AD1399A0F03CAE53CD33AC7CE7C45/",
  // },
  // {
  //   team_id: 9476778,
  //   rating: 1144.92,
  //   wins: 36,
  //   losses: 15,
  //   last_match_time: 1730382370,
  //   name: "Eye Gaming",
  //   tag: "EYE",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2548556315446212479/F4D0AD6C0EFC58DCF8DED009E4D51F25BE2DA05F/",
  // },
  // {
  //   team_id: 2384932,
  //   rating: 1144.84,
  //   wins: 11,
  //   losses: 1,
  //   last_match_time: 1449360917,
  //   name: "Team OvP.",
  //   tag: "TOvP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/439450841570226042/44C52CC76E72908CE95D250488E4CDF934943EB5/",
  // },
  // {
  //   team_id: 3333433,
  //   rating: 1144.45,
  //   wins: 48,
  //   losses: 37,
  //   last_match_time: 1486004907,
  //   name: "",
  //   tag: "",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/3333433.png",
  // },
  // {
  //   team_id: 6214973,
  //   rating: 1144.34,
  //   wins: 277,
  //   losses: 268,
  //   last_match_time: 1606751484,
  //   name: "Ninjas in Pyjamas",
  //   tag: "NiP",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/939457282117079692/28F558E0F2E7BD190435810894A08D2E331CE0EF/",
  // },
  // {
  //   team_id: 7101255,
  //   rating: 1143.47,
  //   wins: 65,
  //   losses: 59,
  //   last_match_time: 1607427315,
  //   name: "Revenge Gaming",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/787480872793617031/B207560C6D16E99774A180C44C5795E73DB99C0D/",
  // },
  // {
  //   team_id: 8970358,
  //   rating: 1143.39,
  //   wins: 547,
  //   losses: 591,
  //   last_match_time: 1701881777,
  //   name: "mind takers",
  //   tag: "MT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2001317618118649176/D5F933113354B4991C5D61FB3C6FCEB074507571/",
  // },
  // {
  //   team_id: 1642908,
  //   rating: 1143,
  //   wins: 73,
  //   losses: 56,
  //   last_match_time: 1441220704,
  //   name: "Natus Vincere US",
  //   tag: "Na`Vi US",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/487827175124862465/3ABE239EE4A96C9FEB2EFC2AB79E3E74C622049B/",
  // },
  // {
  //   team_id: 534136,
  //   rating: 1142.99,
  //   wins: 11,
  //   losses: 4,
  //   last_match_time: 1531254227,
  //   name: "New Guys",
  //   tag: "NG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/527291846450105057/CF0F2BAC9CC10F3F4E4F77990F36EF7877F74C88/",
  // },
  // {
  //   team_id: 1846548,
  //   rating: 1142.55,
  //   wins: 160,
  //   losses: 190,
  //   last_match_time: 1511815094,
  //   name: "HellRaisers",
  //   tag: "HR",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/1846548.png",
  // },
  // {
  //   team_id: 2101368,
  //   rating: 1142.27,
  //   wins: 22,
  //   losses: 15,
  //   last_match_time: 1425118842,
  //   name: "LaDottA's Stacks",
  //   tag: "LaD",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/546393011940036650/5069EEDFD9C2D36183875F9561FB3EBFF3419372/",
  // },
  // {
  //   team_id: 8721917,
  //   rating: 1142.25,
  //   wins: 27,
  //   losses: 8,
  //   last_match_time: 1688308218,
  //   name: "shelbywalk",
  //   tag: "shelbywalk",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1842545237657570279/51C55116DE2DC86A621867C9D55001C17DA60B5C/",
  // },
  // {
  //   team_id: 8131438,
  //   rating: 1142.23,
  //   wins: 15,
  //   losses: 4,
  //   last_match_time: 1605523262,
  //   name: "4AM",
  //   tag: "4AM",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1634200726535406834/8226A44B6C690601A9DEF8CBDA98EF857E9CF658/",
  // },
  // {
  //   team_id: 8193609,
  //   rating: 1142.22,
  //   wins: 14,
  //   losses: 9,
  //   last_match_time: 1647033563,
  //   name: "Come down",
  //   tag: "CD",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1836910666882174916/13C0F9ECE0232A65FDBAECDD2A44DAE63A17FE73/",
  // },
  // {
  //   team_id: 9256222,
  //   rating: 1142,
  //   wins: 10,
  //   losses: 14,
  //   last_match_time: 1702444144,
  //   name: "Wawitas sagazes",
  //   tag: "Wawitas",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2295210273364324956/528675A3C5A05E302E1BCD5FB2409479B0A2F632/",
  // },
  // {
  //   team_id: 66855,
  //   rating: 1141.69,
  //   wins: 11,
  //   losses: 1,
  //   last_match_time: 1403453582,
  //   name: "",
  //   tag: "",
  //   logo_url: null,
  // },
  // {
  //   team_id: 8193681,
  //   rating: 1141.44,
  //   wins: 14,
  //   losses: 4,
  //   last_match_time: 1637593765,
  //   name: "Team Thailand",
  //   tag: "THA",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1692752142641385446/54BF6230D47E44F1E370235FB63AC7EAF070F525/",
  // },
  // {
  //   team_id: 2224197,
  //   rating: 1141.25,
  //   wins: 17,
  //   losses: 12,
  //   last_match_time: 1432974286,
  //   name: "G Guard Esports",
  //   tag: "G Guard",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/708527825009376675/5069EEDFD9C2D36183875F9561FB3EBFF3419372/",
  // },
  // {
  //   team_id: 1366458,
  //   rating: 1141.17,
  //   wins: 129,
  //   losses: 92,
  //   last_match_time: 1471359842,
  //   name: "R a v e",
  //   tag: "Rave",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/357274177493063612/C6646260536702EC11BE924D3CB2D8C696280BC4/",
  // },
  // {
  //   team_id: 55,
  //   rating: 1140.79,
  //   wins: 443,
  //   losses: 469,
  //   last_match_time: 1478884383,
  //   name: "_PowerRangers",
  //   tag: "PR",
  //   logo_url:
  //     "https://steamcdn-a.akamaihd.net/apps/dota2/images/team_logos/55.png",
  // },
  // {
  //   team_id: 8375459,
  //   rating: 1140.5,
  //   wins: 60,
  //   losses: 49,
  //   last_match_time: 1642755620,
  //   name: "rush b!",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1767081074465828139/2F29E9D8E728757B87F96DD4E63056DD5F4A4593/",
  // },
  // {
  //   team_id: 9368468,
  //   rating: 1140.49,
  //   wins: 58,
  //   losses: 63,
  //   last_match_time: 1720026715,
  //   name: "Twisted Minds",
  //   tag: "Twis",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/2441593112495798517/2CF737A11985595217CF576C9DCB365CEB5A74DE/",
  // },
  // {
  //   team_id: 8856125,
  //   rating: 1140.45,
  //   wins: 11,
  //   losses: 4,
  //   last_match_time: 1668614552,
  //   name: "Ancient Tribe2",
  //   tag: "ATT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1990057992897734075/07A293B6565085107C7A440115D66892AA2006FC/",
  // },
  // {
  //   team_id: 9224799,
  //   rating: 1139.99,
  //   wins: 18,
  //   losses: 5,
  //   last_match_time: 1701612259,
  //   name: "NoMuerta",
  //   tag: "NM",
  //   logo_url: null,
  // },
  // {
  //   team_id: 8062738,
  //   rating: 1139.58,
  //   wins: 17,
  //   losses: 8,
  //   last_match_time: 1607554438,
  //   name: "egoboys",
  //   tag: "ego",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1326824069902136066/4049258DD58AFA5FB7608E7DC8AC6B08BA920F15/",
  // },
  // {
  //   team_id: 6615678,
  //   rating: 1139.05,
  //   wins: 36,
  //   losses: 21,
  //   last_match_time: 1552056602,
  //   name: "Room310",
  //   tag: "",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/948470338978740210/987A4860BE260F2A4707310760B6B46543BE17B1/",
  // },
  // {
  //   team_id: 5194336,
  //   rating: 1138.88,
  //   wins: 43,
  //   losses: 33,
  //   last_match_time: 1547793807,
  //   name: "DeathBringer Gaming",
  //   tag: "DBG",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/941706090396064098/6C00706941158190001629D0B456B8BEAB54B6D9/",
  // },
  // {
  //   team_id: 485526,
  //   rating: 1138.83,
  //   wins: 24,
  //   losses: 23,
  //   last_match_time: 1481920155,
  //   name: "Life.",
  //   tag: "Life",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/903254994325386629/98F3E4A7B2DECCF94FE06DE33EA12049295BBCC5/",
  // },
  // {
  //   team_id: 2390951,
  //   rating: 1138.54,
  //   wins: 10,
  //   losses: 1,
  //   last_match_time: 1453806711,
  //   name: "Kanaya",
  //   tag: "Kanaya",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/532895345481654582/6B0AF87CC0A6D793BD6C81080F14AC5801078549/",
  // },
  // {
  //   team_id: 7453020,
  //   rating: 1138.18,
  //   wins: 269,
  //   losses: 364,
  //   last_match_time: 1692430294,
  //   name: "Aster.Aries",
  //   tag: "Aries",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1021697620249691876/0C6C8F47D723EF0B110292609BFDB64EB7B9553C/",
  // },
  // {
  //   team_id: 8496030,
  //   rating: 1138.05,
  //   wins: 24,
  //   losses: 18,
  //   last_match_time: 1637707146,
  //   name: "Furia Jovem",
  //   tag: "FJ",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1695028324681823345/3E19FB91AF9863F91DABF16601A7E1EB262E5C9B/",
  // },
  // {
  //   team_id: 5155538,
  //   rating: 1138,
  //   wins: 10,
  //   losses: 0,
  //   last_match_time: 1724606342,
  //   name: "Рыцари Hiden pool'а",
  //   tag: "gena ls",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/884259198341578366/8C4CC9EDB4D83BD1BA75C279976E493D2923C6ED/",
  // },
  // {
  //   team_id: 2395241,
  //   rating: 1137.93,
  //   wins: 12,
  //   losses: 2,
  //   last_match_time: 1445209458,
  //   name: "Artyk Dota",
  //   tag: "Artyk",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/428193628077963983/E79C23C12B93B7A4E0755E88C75B58CC190B805D/",
  // },
  // {
  //   team_id: 7930695,
  //   rating: 1137.83,
  //   wins: 26,
  //   losses: 26,
  //   last_match_time: 1601992170,
  //   name: "FAMILY TEAM",
  //   tag: "FT",
  //   logo_url:
  //     "https://steamusercontent-a.akamaihd.net/ugc/1021698582220690828/25303B125189AD8D01A52AF6E5B1C7AA4411869E/",
  // },
];
