import React, { useEffect, useState } from "react";
import "./../../styles/UserHome.css";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import UserDraft from "./UserDraft.tsx";
import TollIcon from "@mui/icons-material/Toll";
import MatchData from "./MatchData.tsx"; // Import the Match component
import LogoutIcon from "@mui/icons-material/Logout";


import {
  Alert,
  Button,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import CountdownTimer from "./CountdownTimer.tsx";
import UserTournamentDetails from "./UserTournamentDetails.tsx";
import { Hero, HeroLit, TournamentLit , Match} from "../../types.tsx";
import { useNavigate } from "react-router-dom";

interface Heading {
  title: string;
  info: string;
  button_text: string;
  img: string;
}


interface Player {
  username: string;
  points: number;
  radiant_heroes: any[];
  dire_heroes: any[];
  banned_heroes: any[];
}

interface Tournament {
  name: string;
  no_players: number;
  uuid: string;
  players: Player[];
}

interface PlayerData {
  name: string;
  username: string;
  user_uuid: string;
  current_gold:number;
  points: number;
  radiant_heroes: HeroLit[];
  dire_heroes: HeroLit[];
  banned_heroes: HeroLit[];
  tournaments: TournamentLit[];
}


const headings: Heading[] = [
  {
    title: "1win Series Dota 2 Fall",
    info: "Discover all the amazing features we offer.",
    button_text: "View matches",
    img: "https://i.pinimg.com/736x/53/cc/d1/53ccd175bac45bbedfa8571e7e05d4d5.jpg",
  },
  {
    title: "Dota 2Blast Slam I",
    info: "Create and analyze drafts for your matches.",
    button_text: "Enter now",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4SwYU8Zf5lQQAtoMBCW_HrlMRUjbKsoEBbg&s",
  },
  {
    title: "Tournaments",
    info: "Stay updated with the latest tournaments.",
    button_text: "View Tournaments",
    img: "https://via.placeholder.com/800x400?text=Tournaments",
  },
  {
    title: "Settings",
    info: "Customize your experience to suit your needs.",
    button_text: "Open Settings",
    img: "https://via.placeholder.com/800x400?text=Settings",
  },
  {
    title: "About Us",
    info: "Learn more about our mission and team.",
    button_text: "About Us",
    img: "https://via.placeholder.com/800x400?text=About+Us",
  },
];

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface AdminWelcomeProps {
  userDetails: UserDetails | null;
}

const UserHome: React.FC<AdminWelcomeProps> = ({ userDetails }) => {
  const [currentView, setCurrentView] = useState("Home");
  const [playerData, setPlayerData] = useState<PlayerData>({
    banned_heroes:[],
    current_gold:0,
    dire_heroes:[],
    name:'',
    points:0,
    radiant_heroes:[],
    tournaments:[],
    user_uuid:'',
    username:''
  });
  const [matches, setMatches] = useState<Match[]>([]);

  const menuItems = [
    { name: "Home", icon: <HomeIcon /> },
    { name: "Draft", icon: <GroupsIcon /> },
    // { name: "Tournament", icon: <EmojiEventsIcon /> },
    // { name: "About", icon: <InfoIcon /> },
  ];

  const handleMenuClick = (view: string) => {
    setCurrentView(view);
  };
  

  const onChangeHero = async (
    hero_id: number,
    type: string,
    role: string,
    remaining_gold: number,
    free_transfers:number
  ) => {
    try {
      console.log(hero_id, "---", type, "====", role, "--------[", remaining_gold);
      let i =0
      if(type =="radiant") {
        playerData.radiant_heroes.map((hero, index)=>{
          if (hero.role == role){
            i = index;
          }
        })

        

        playerData.radiant_heroes[i].hero_id = hero_id
      } else {
        playerData.dire_heroes.map((hero, index)=>{
          if (hero.role == role){
            i = index;
          }
        })

        

        playerData.dire_heroes[i].hero_id = hero_id        
      }
  
      const response = await fetch(`https://server.dotalive.org/api/update-player-hero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_uuid: playerData.user_uuid, // Replace with the actual user's UUID
          remaining_gold,
          radiant:playerData.radiant_heroes,
          dire:playerData.dire_heroes,
          free_transfers:free_transfers,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error updating hero: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message);
  
      // Optionally refresh the player data after the update
      await fetchPlayerData();
    } catch (error) {
      console.error('Error updating hero:', error);
    }
  };

  const onChangeBannedHero = async (
    hero_id: number,index:number
  ) => {
    try {
     playerData.banned_heroes[index].hero_id = hero_id
  
      const response = await fetch(`https://server.dotalive.org/api/update-player-banned-hero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_uuid: playerData.user_uuid, // Replace with the actual user's UUID
          banned_heroes:playerData.banned_heroes
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error updating hero: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message);
  
      // Optionally refresh the player data after the update
      await fetchPlayerData();
    } catch (error) {
      console.error('Error updating hero:', error);
    }
  };
  const onChangeTripleCaptain = async (
    hero_id: number,index:number
  ) => {
    try {
      const response = await fetch(`https://server.dotalive.org/api/update-player-captain-hero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_uuid: playerData.user_uuid, // Replace with the actual user's UUID
          hero_id:hero_id
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error updating hero: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message);
  
      // Optionally refresh the player data after the update
      await fetchPlayerData();
    } catch (error) {
      console.error('Error updating hero:', error);
    }
  };
  
  const onSwitchHeroes = async (
    index: number,
  ) => {
    try {

        let rad= playerData.radiant_heroes[index]
        playerData.radiant_heroes[index] = playerData.dire_heroes[index]         
        playerData.dire_heroes[index] = rad
      
  
      const response = await fetch(`https://server.dotalive.org/api/switch-player-hero`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_uuid: playerData.user_uuid, // Replace with the actual user's UUID
          radiant:playerData.radiant_heroes,
          dire:playerData.dire_heroes
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error updating hero: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message);
  
      // Optionally refresh the player data after the update
      await fetchPlayerData();
    } catch (error) {
      console.error('Error updating hero:', error);
    }
  };

  
  
  // Tournaments data state
  const [tournaments, setTournaments] = useState([
    { name: "DreamLeague", players: 16, uuid: "11-11-11-11" },
    { name: "ESL One", players: 12, uuid: "22-22-22-22" },
    { name: "The International", players: 10, uuid: "33-33-33-33" },
    { name: "The International", players: 10, uuid: "44-33-33-33" },
  ]);
  const navigate = useNavigate();

  const [allHeroes, setAllHeroes] = useState<Hero[]>([])

  const fetchPlayerData = async () => {
    try {
      const response = await fetch(`https://server.dotalive.org/api/users/${userDetails?.uuid}`);
      const data = await response.json();
      setPlayerData(data);
    } catch (error) {
      console.error("Error fetching player data:", error);
    }
  };

  const fetchPlayerMatches = async (userId: string) => {
    try {
      const response = await fetch(`https://server.dotalive.org/api/users/${userDetails?.uuid}/matches`); // Endpoint for fetching player matches
      const data = await response.json();
  
      // Transform the data to include user-specific match data
      const matches = data.map((match: any) => {
        const userMatch = match.user_match || {}; // Fallback if user match data is missing
        return {
          match_id: match.match_id,
          team1_name: match.team1_name,
          team1_logo: match.team1_logo,
          team2_name: match.team2_name,
          team2_logo: match.team2_logo,
          radiant_heroes: match.radiant_heroes || [], // Match radiant heroes
          dire_heroes: match.dire_heroes || [], // Match dire heroes
          banned_heroes: match.banned_heroes || [], // Match banned heroes
          played: match.played, // Match date
          user_match: {
            radiant_heroes: userMatch.radiant_heroes || [], // User-specific radiant heroes
            dire_heroes: userMatch.dire_heroes || [], // User-specific dire heroes
            banned_heroes: userMatch.banned_heroes || [], // User-specific banned heroes
            points: userMatch.points || 0, // Points earned by the user for this match
          },
        };
      });
  
      setMatches(matches)
    } catch (error) {
      console.error("Error fetching player matches:", error);
      return [];
    }
  };
  
  const fetchHeroes = async () => {

    
    try {
      const response = await fetch("https://server.dotalive.org/api/heroes");
      const data = await response.json();
      console.log(data)
      setAllHeroes(data); // Update the services state with fetched data

      // setRefreshingLoading(false)
    } catch (error) {
      // setRefreshingLoading(false)
      console.error("Error fetching services:", error);
    }
  };
  useEffect(() => {
    if (userDetails && userDetails.uuid) {
      fetchPlayerData();
      fetchHeroes();
      fetchPlayerMatches(userDetails.uuid);
    }
  }, [userDetails]); // Add userDetails as a dependency
  


  // Define state with the correct type
  const [currentTournament, setCurrentTournament] = useState<Tournament | null>(
    null
  );

  const handleTournamentClick = async (uuid: string) => {
    if (currentTournament?.uuid === uuid) {
      setCurrentTournament(null); // Collapse if the same tournament is clicked again
      return;
    }
  
    try {
      const response = await fetch(`https://server.dotalive.org/api/tournaments-data/${uuid}`);
      if (!response.ok) {
        throw new Error("Failed to fetch tournament details");
      }
      const data: Tournament = await response.json();
      setCurrentTournament(data);
    } catch (error) {
      console.error("Error fetching tournament details:", error);
    }
  };
  
  const renderView = () => {
    switch (currentView) {
      case "Home":
        return (
          <div className="home-page">
            <div className="carousel-container">
              <div className="carousel">
                {headings.map((heading, index) => (
                  <div
                    className="carousel-item"
                    key={index} 
                  >
                    <div className="carousel-content">
                      <div className="left-side">
                        <h2 style={{ marginTop: "0rem" }}>{heading.title}</h2>
                        <p style={{ marginTop: "0rem" }}>{heading.info}</p>
                        <Button
                          style={{
                            background: "#dd183b",
                            color: "white",
                            border: "1px solid white",
                          }}
                        >
                          {heading.button_text}
                        </Button>
                      </div>
                      <div className="right-side">
                        <img
                          width="140px"
                          height="140px"
                          style={{ borderRadius: "20px" }}
                          src={heading.img}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* My Tournaments Section */}
            {tournaments[0].name != null &&
            <div className="my-tournaments">
              <h2 className="heading">Leaderboards</h2>
              <div className="tournament-carousel-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1.2rem",
                    overflowY: "scroll", // For Firefox
                    msOverflowStyle: "none", // For Internet Explorer and Edge
                  }}
                >
                  {playerData?.tournaments.map((tournament, index) => (
                    <div
                      className={
                        currentTournament != null &&
                        currentTournament.uuid == tournament.uuid
                          ? "tournament-item"
                          : "tournament-item"
                      }
                      key={index}
                      onClick={() => handleTournamentClick(tournament.uuid)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="tournament-info">
                        <div className="players-badge">
                          {tournament.players_number}
                        </div>
                        <div className="tournament-icon">
                          <EmojiEventsIcon
                            style={{
                              padding:
                                currentTournament != null &&
                                currentTournament.uuid == tournament.uuid
                                  ? "0.5rem"
                                  : "",
                            }}
                            className={
                              currentTournament != null &&
                              currentTournament.uuid == tournament.uuid
                                ? "active_tournament"
                                : ""
                            }
                          />
                        </div>
                        <div className="tournament-details">
                          <span className="tournament-name">
                            {tournament.name != null ?tournament.name.length > 8
                              ? tournament.name.substring(0, 8) + "..."
                              : tournament.name != null ? tournament.name :"":""}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Current Tournament Stats */}
              {currentTournament && (
                <UserTournamentDetails currentTournament={currentTournament} />
              )}
            </div>
              }
            <h2 className="heading">Latest Matches</h2>

            <div className="latest-matches">
              {/* <h2>Latest Matches</h2> */}
              <div className="matches-list">
                {matches.map((match, index) => (
                  <MatchData heroes={allHeroes} key={index} match={match}  />
                ))}
              </div>
            </div>
          </div>
        );
      case "Draft":
        return <UserDraft onChangeTripleCaptain={onChangeTripleCaptain} onChangeHero={onChangeHero} onChangeBannedHero={onChangeBannedHero} onSwitchHeroes={onSwitchHeroes} currentGold={playerData.current_gold} heroes={allHeroes} playerData={playerData} />;
      case "Tournament":
        return <div>Tournament Page Content</div>;
      case "About":
        return <div>About Page Content</div>;
      default:
        return <div>Page Not Found</div>;
    }
  };

  return (
    <div className="user-home">
    {/* Header */}
    <header className="user-home-header">
      <div className="logo_user">MyApp</div>
      <div
        style={{
          fontWeight: "bold",
          borderRadius: "10px",
          textAlign: "center",
          display: "flex",
          fontSize: "1.2rem",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.4rem",
        }}
      >
        <span style={{ lineHeight: "1", verticalAlign: "middle" }}>{"DOTA 2 FANTASY"}</span>
      </div>
 
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PersonIcon />
        <NotificationsIcon />
        <div
              className="logout"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");

                navigate("/login");
              }}
            >
              {" "}
              <LogoutIcon />{" "}
              <span style={{ marginLeft: "0.5rem" }}>Log out </span>
            </div>
      </div>
    </header>
 
    {/* Main Content */}
    <main className="user-home-content">
      {renderView()}
    </main>
 
    {/* Footer Menu */}
    <footer className="footer-menu">
      <ul>
        {menuItems.map((item) => (
          <li
            key={item.name}
            onClick={() => handleMenuClick(item.name)}
            className={currentView === item.name ? "active" : ""}
          >
            {item.icon}
            <span>{item.name}</span>
          </li>
        ))}
      </ul>
    </footer>
  </div>
 
  );
};

export default UserHome;
