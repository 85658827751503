import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import LoginPage from './views/LoginPage';
import * as jwtDecode from 'jwt-decode';
import './App.css';
import { SuperAdminBlog, UserLite } from './types';
import Dotalive from './views/Dotalive';
import DraftPicker from './views/DraftPciker';
// import AdminPage from './views/fantasy/AdminPage';
import UserHome from './views/fantasy/UserHome';

import RealPage from './views/real/RealPage'
import AdminWelcome from './views/admin/AdminWelcome';
import InvitationPage from './views/InvitationPage';

// ... other imports ...

type Segment = 'booking' | 'services' | 'about' | 'prices' | 'news' | 'home';
type Language = 'mk' | 'en';

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface CompanyData {
  company_name: string;
  company_uuid: string;
  company_projects: string[];
  current_project_blogs: SuperAdminBlog[];
}

function App(): JSX.Element {
  const [token, setToken] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();

  const [all_users, setAllUsers] = useState<UserLite[]>([]);
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedDetails = localStorage.getItem('userDetails');
    if (storedToken && storedDetails) {
        setToken(storedToken);
        setUserDetails(JSON.parse(storedDetails));
    }
  }, []);

  const RequireAuth = ({ children, requiredRole }: { children: JSX.Element, requiredRole: string }) => {
    
    const token = localStorage.getItem('token');
    let storageUserDetailsString = localStorage.getItem('userDetails');
    let details
    if ( storageUserDetailsString) {
      details = (JSON.parse(storageUserDetailsString));
    }

    if (!token) {
      return <Navigate to="/login" state={{ from: location }} />;
    }


    if (!token || !details) {
      return <Navigate to="/login" state={{ from: location }} />;
    }

    if (!details.roles.split(',').includes(requiredRole)) {
      // Redirect to a different page or show an error message
      return <Navigate to="/unauthorized" />;
    }


    try {
      const decodedToken = jwtDecode.jwtDecode(token);
      const currentDate = new Date();

      console.log( Number(decodedToken.exp) * 1000 < currentDate.getTime())
      console.log( currentDate.getTime()- Number(decodedToken.exp) * 1000 )
      if ( Number(decodedToken.exp) * 1000 < currentDate.getTime()) {
        localStorage.removeItem('token');
        return <Navigate to="/login" state={{ from: location }} />;
      }
    } catch (error) {

      localStorage.removeItem('token');
      return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
  }

  const handleLogin = async (details: any)  => {
    navigate('/admin/dashboard');
    localStorage.setItem('token', details.token);
    localStorage.setItem('userDetails', JSON.stringify(details));

    setUserDetails(details);
    setToken(details.token);

    if ( details.roles.split(',').includes("Admin")) {
      // Redirect to a different page or show an error message
    } else if (details.roles === "company") {
      // setCurrentCompanyData({
      //   company_name:details.name,
      //   company_projects:details.company_projects.length > 0 ? details.company_projects.split(",") : [] ,
      //   company_uuid:details.uuid,
      //   current_project_blogs:[],
      // })

        navigate('/company-view');

    } else {
      navigate('/user-view');
    }
  };

  return (
    <>
      <Routes>
        <Route path="/draft" element={<DraftPicker/>}/>
        <Route path="/dotalive" element={<Dotalive/>}/>
        <Route path="/invitation/:invitationId" element={<InvitationPage />} />

        {/* <Route path="/fantasy/user" element={<UserHome/>}/> */}
        <Route path="/real-estate" element={<RealPage/>}/>

        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route
          path="/user-view"
          element={
            <RequireAuth requiredRole="Player">
            <UserHome userDetails={userDetails}  />
            </RequireAuth>
          }
        />
        {/* ... other routes ... */}
        <Route path="*" element={<Navigate to="/login" />} />
        <Route
          path="/admin/dashboard"
          element={
            <RequireAuth requiredRole="Admin">
            <AdminWelcome userDetails={userDetails} />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default App;
