import React, { useState } from "react";
import "./../../styles/Players.css";
// import { mdiOpenInNew } from '@mdi/js';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { HeroLit, Player, PlayerDetails, TournamentLit } from "../../types.tsx";


interface Hero {
  hero_id: number;
  role: "carry" | "mid" | "offlane" | "support" | "hard_support" | "banned";
}


interface PlayersProps {
  players: Player[];
  heroes: any[],
  onAddPlayer: (name: string, surname: string, username: string) => void;
  onEditPlayer: (
    user_uuid: number,
    name: string,
    surname: string,
    username: string
  ) => void;
  onDeletePlayer: (user_uuid: number) => void;
  onAddHero: (
    userId: number,
    hero: Hero,
    type: "radiant" | "dire" | "banned"
  ) => void;
  onRemoveHero: (
    userId: number,
    heroId: number,
    type: "radiant" | "dire" | "banned"
  ) => void;
}

const Players: React.FC<PlayersProps> = ({
  players,
  heroes,
  onAddPlayer,
  onEditPlayer,
  onDeletePlayer,
  onAddHero,
  onRemoveHero,
}) => {
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    surname: "",
    username: "",
  });
  const [editPlayerUuid, setEditPlayerUuid] = useState<string | null>(null);
  const [newHero, setNewHero] = useState<Hero>({ hero_id: 0, role: "carry" });
  const [selectedType, setSelectedType] = useState<
    "radiant" | "dire" | "banned"
  >("radiant");
  const [expandedPlayer, setExpandedPlayer] = useState<string>("");


  const handleAddOrUpdatePlayer = () => {
    if (!newPlayer.name || !newPlayer.surname || !newPlayer.username) return;

    if (editPlayerUuid === null) {
      onAddPlayer(newPlayer.name, newPlayer.surname, newPlayer.username);
    } else {
  
    }

    setNewPlayer({ name: "", surname: "", username: "" });
    setEditPlayerUuid(null);
  };

  const [playerDetails, setPlayerDetails] = useState<PlayerDetails>({
    banned_heroes:[],
    dire_heroes:[],
    radiant_heroes:[],
    tournaments:[],
    double_ban:0,
    free_transfers:0,
    triple_captain:0,
    wild_card:false

  });


  const toggleHeroesVisibility = async (userUuid: string) => {
    if (expandedPlayer === userUuid) {
      setExpandedPlayer(""); // Collapse if already expanded
      return;
    }
  
    try {
      const response = await fetch(`https://server.dotalive.org/api/users/${userUuid}`);
      if (!response.ok) {
        throw new Error("Failed to fetch player details");
      }
  
      const playerDetails: PlayerDetails = await response.json();
      // Update player data in `players` with the fetched details
      setPlayerDetails(playerDetails)
      setExpandedPlayer(userUuid)

    } catch (error) {
      console.error("Error fetching player details:", error);
    }
  };
  

  const handleEditClick = (player: Player) => {
    setEditPlayerUuid(player.user_uuid);
    setNewPlayer({
      name: player.name,
      surname: player.surname,
      username: player.username,
    });
  };

  function renderPlayerHeroes(
    player_heroes: HeroLit[],
    is_banned: boolean
  ): React.ReactNode {
    return player_heroes.map((hero, index) => {
      let filtered = heroes.find((hero_data:any) => hero_data.id === hero.hero_id);
      if (filtered) {
        console.log(
          `/assets/img/heroes` +
            String(filtered.localized_name).toLowerCase().replaceAll(" ", "_") +
            `.png ` +
            "---------------------" +
            String(filtered.localized_name).toLowerCase().replaceAll(" ", "_") +
            `Hero found: ${filtered.localized_name}`
        );
      } else {
        console.log("Hero not found " + hero.hero_id);
      }

      return (
        <div key={index} style={{ padding: "0.2rem", textAlign: "center", display:'flex', flexDirection:'column' }}>
          <span><img
            key={String(filtered.localized_name)
              .toLowerCase()
              .replaceAll(" ", "_")}
              src={
                `/assets/img/heroes/` +
                String(filtered.localized_name)
                .toLowerCase()
                .replaceAll(" ", "_") +
                `.png`
              }
              alt={String(filtered.localized_name)
                .toLowerCase()
                .replaceAll(" ", "_")}
                style={{
                  width: "3rem",
                  height: "1.5rem",
                  // borderRadius: "5px",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  border: "1px solid black",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  zIndex: 1,
                }}
                /></span>
                <span style={{fontSize:'0.6rem', fontWeight:'bold'}}>{renderRole(hero.role)}</span>
          {/* {is_banned ? null : <span> | Role: {hero.role}</span>} */}
        </div>
      );
    });
  }

  function renderRole(role: string): string {
    switch (role) {
      case "carry":
        return "Carry";
      case "mid":
        return "Mid";
      case "offlane":
        return "Offlane";
      case "support":
        return "Support";
      case "hard_support":
        return "Hard support";
      case "banned":
        return "Banned";
    }
    return "";
  }
  return (
    <div
    style={{
      border: "1px solid #ccc",
      padding: "1rem",
      // marginBottom: "1rem",
      background:'#dd183b59'
    }}
  >
 
    {/* Section Header */}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem 0",
        borderBottom: "2px solid #ccc",
        fontWeight: "bold",
      }}
    >
      <div style={{ flex: 1, textAlign: "center" }}>UUID</div>
      <div style={{ flex: 2, textAlign: "center" }}>Name</div>
      <div style={{ flex: 2, textAlign: "center" }}>Username</div>
      <div style={{ flex: 3, textAlign: "center" }}>Actions</div>
    </div>

    {players.map((player) => (
      <div
        key={player.user_uuid}
        style={{
          borderBottom: "1px solid #ccc",
          marginBottom: "1rem",
        }}
      >
        {/* Player Row */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 0",
            cursor: "pointer",
          }}
          className= {expandedPlayer === player.user_uuid ? "active_player player_info" : "player_info"}
          onClick={() => toggleHeroesVisibility(player.user_uuid)}
        >
          <div style={{ flex: 1, textAlign: "center" }}>{player.user_uuid.toString().substring(0,7)}</div>
          <div style={{ flex: 2, textAlign: "center" }}>
            {player.name} {player.surname}
          </div>
          <div style={{ flex: 2, textAlign: "center" }}>{player.username}</div>
          <div style={{ flex: 3, textAlign: "center" }}>
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(player);
              }}
              style={{
                marginRight: "0.5rem",
                padding: "0.3rem 0.5rem",
                background: "orange",
                color: "white",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Edit
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
               
              }}
              style={{
                padding: "0.3rem 0.5rem",
                background: "red",
                color: "white",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Delete
            </button> */}
          </div>
        </div>

        {/* Heroes Section (Collapsible) */}
        {expandedPlayer === player.user_uuid && (
         <div style={{padding: "1rem", display:'flex', flexDirection:'column', gap:'1rem', paddingTop:'2rem'}} className= {expandedPlayer === player.user_uuid ? "active_player" : ""}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              borderRadius: "5px",
            }}
            

          >
            <div>
              <strong>Radiant:</strong>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "0.1rem", background:'lightgreen', borderRadius:'10px' }}>
                {renderPlayerHeroes(playerDetails?.radiant_heroes, false)}
              </div>
            </div>
            <div>
              <strong>Dire:</strong>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "0.1rem", background:'#fc9090', borderRadius:'10px' }}>
                {renderPlayerHeroes(playerDetails?.dire_heroes, false)}
              </div>
            </div>
            <div>
              <strong>Banned:</strong>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "0.1rem", background:'lightgray', borderRadius:'10px' }}>
                {renderPlayerHeroes(playerDetails?.banned_heroes, true)}
              </div>
            </div>
            <div>
              <strong>Points:</strong>
              <div >
                <h3>Total: {player.points}</h3>
              </div>
            </div>
          </div>
          <div style={{display:'flex', flexDirection:'column', gap:'1rem'}}>
           <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}><b>Tournaments:</b><div>
           {/* <button
                    style={{
                      padding: "0.3rem 0.5rem",
                      background: "green",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center", // Correct property to align items vertically
                    }}
                  >
                    <span>Add To Tournament</span>
                    <AddIcon
                      style={{
                        maxWidth: "1rem",
                        minWidth: "1rem",
                        width: "1rem",
                        maxHeight: "1.5rem",
                      }}
                    />
                  </button> */}
            </div></div>
           <div style={{borderTop:'1px solid gray',paddingTop:'1rem'}}> <b>Active</b>
           {playerDetails.tournaments.map((tournament:TournamentLit) => {
            if (!tournament.active) {
              return
            } 
            return (
              <div
              key={tournament.uuid}
              style={{
                borderBottom: "1px solid #ccc",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0.5rem 0",
                  cursor: "pointer",
                }}
              >
                <div style={{ flex: 5, textAlign: "left", }}>{tournament.name}</div>
                <div style={{ flex: 2, textAlign: "center" }}><b style={{marginRight:'0.2rem'}}>Active Players:</b>{tournament.players_number}</div>
                <div style={{ flex: 5, textAlign: "right", display:'flex', flexDirection:'row', justifyContent:'right', gap:'1rem' }}>
                {/* <button
                  style={{
                    padding: "0.3rem 0.5rem",
                    background: "gray",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center", // Correct property to align items vertically
                  }}
                >
                  <span>View tournament</span>
                  <OpenInNewIcon
                    style={{
                      maxWidth: "1rem",
                      minWidth: "1rem",
                      width: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  />
                </button>
                <button
                  style={{
                    padding: "0.3rem 0.5rem",
                    background: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center", // Correct property to align items vertically
                  }}
                >
                  <span>Remove</span>
                  <DeleteIcon
                    style={{
                      maxWidth: "1rem",
                      minWidth: "1rem",
                      width: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  />
                </button> */}

                </div>
              </div>

             
            </div>
            )
           })}
          </div>
          <div style={{borderTop:'1px solid gray',paddingTop:'1rem'}}>  <b>Finished</b>
          {playerDetails.tournaments.map((tournament:TournamentLit) => {
            if (tournament.active) {
              return
            } 
            return (
              <div
              key={tournament.uuid}
              style={{
                borderBottom: "1px solid #ccc",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0.5rem 0",
                  cursor: "pointer",
                }}
              >
                 <div style={{ flex: 5, textAlign: "left", }}>{tournament.name}</div>
                <div style={{ flex: 2, textAlign: "center" }}><b style={{marginRight:'0.2rem'}}>Active Players:</b>{tournament.players_number}</div>
                <div style={{ flex: 5, textAlign: "right", display:'flex', flexDirection:'row', justifyContent:'right', gap:'1rem' }}>
                {/* <button
                  style={{
                    padding: "0.3rem 0.5rem",
                    background: "gray",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center", // Correct property to align items vertically
                  }}
                >
                  <span>View tournament</span>
                  <OpenInNewIcon
                    style={{
                      maxWidth: "1rem",
                      minWidth: "1rem",
                      width: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  />
                </button>
                <button
                  style={{
                    padding: "0.3rem 0.5rem",
                    background: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center", // Correct property to align items vertically
                  }}
                >
                  <span>Remove</span>
                  <DeleteIcon
                    style={{
                      maxWidth: "1rem",
                      minWidth: "1rem",
                      width: "1rem",
                      maxHeight: "1.5rem",
                    }}
                  />
                </button> */}

                </div>
              </div>

             
            </div>
            )
           })}
          </div>
          </div>
            
          </div>
        )}
      </div>
    ))}
    </div>
  );
};

export default Players;
