import React, { useState } from "react";
import { Match } from "../../types";
import { debug } from "console";

interface MatchProps {
  match: Match,
  heroes:any[]
}

const MatchData: React.FC<MatchProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  function renderPlayerHeroes(player_heroes: any, is_banned: boolean, user_heroes:any): React.ReactNode {
    console.log("player_heroes:", player_heroes, "Type:", typeof player_heroes);
  
    // Parse JSON if player_heroes is a string
    if (typeof player_heroes === "string") {
      try {
        player_heroes = JSON.parse(player_heroes); // Convert JSON string to array
      } catch (error) {
        console.error("Error parsing player_heroes JSON:", error);
        return null; // Return nothing if parsing fails
      }
    }
  
    // Ensure player_heroes is an array
    if (!Array.isArray(player_heroes)) {
      console.error("Invalid player_heroes:", player_heroes);
      return null; // Return nothing if player_heroes is not an array
    }
  
    return player_heroes.map((hero_id: number, index: number) => {
      // Find the hero in the heroes array
      const filtered = props.heroes.find((hero_data) => hero_data.id === hero_id);
      if (!filtered) {
        console.warn("Hero not found for ID:", hero_id);
        return null;
      }
      if (typeof user_heroes === "string") {
        try {
          user_heroes = JSON.parse(user_heroes); // Convert JSON string to array
        } catch (error) {
          console.error("Error parsing player_heroes JSON:", error);
          return null; // Return nothing if parsing fails
        }
      }
      
      let isPicked = false;

      for ( let i =0; i < user_heroes.length;i++) {
        if (user_heroes[i]==hero_id) {
          isPicked = true
        }
      }

    
      return (
        <div
          key={index}
          style={{
            padding: "0.1rem",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            <img
              src={
                `/assets/portraits/` +filtered?.name +
                `.png`
              }
              alt={filtered.localized_name.toLowerCase().replaceAll(" ", "_")}
              style={{
                opacity:is_banned ? '0.5':'1',
                width: is_banned? '1.15rem':"1.6rem" ,
                height: is_banned? '2rem':"2.6rem",
                borderRadius: "5px",
                transition: "transform 0.2s ease, z-index 0.2s",
                zIndex: 1,
                border:isPicked? "5px solid gold":""
              }}
            />
          </span>
        </div>
      );
    });
  }
  
  
  return (
    <div className="match-item">
      <div className="match-info" onClick={toggleExpand}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer", // Indicate clickable behavior
          }}
        >
          <span className="match-date">{props.match.played.split("T")[0]}</span>
          <div className="teams" style={{ display: "flex", alignItems: "center" }}>
            <div className="team-first" style={{ display: "flex", alignItems: "center", color:'black', fontWeight:'bold' }}>
              <img
                src={props.match.team1_logo}
                alt={`${props.match.team1_name} logo`}
                className="team-logo"
                style={{ width: "30px", height: "30px", marginRight: "0.5rem" }}
              />
              <span className="team-name">{props.match.team1_name}</span>
            </div>
            <div className="vs" style={{ margin: "0 1rem", color:'black' }}>
              vs
            </div>
            <div className="team" style={{ display: "flex", alignItems: "center", color:'black', fontWeight:'bold' }}>
              <img
                src={props.match.team2_logo}
                alt={`${props.match.team2_name} logo`}
                className="team-logo"
                style={{ width: "30px", height: "30px", marginRight: "0.5rem" }}
              />
              <span className="team-name">{props.match.team2_name}</span>
            </div>
          </div>
          <div className="match-points">
            <span>{props.match.user_match.points}</span>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div
          className="match-details"
          style={{
            // marginTop: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#f7f7f7",
          }}
        >
          <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className={"active_player"}
            >
     
              <div style={{ display: "flex", flexDirection: "row", gap:'1rem' }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.2rem",
                    // background: "lightgreen",
                  }}
                >
                  {renderPlayerHeroes(props.match.radiant_heroes, false, props.match.user_match.radiant_heroes)}
                </div>
      
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.2rem",
                    // background: "#fc9090",
                  }}
                >
                  {renderPlayerHeroes(props.match.dire_heroes, false,props.match.user_match.dire_heroes)}
                </div>
      
                
              </div>
              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0.2rem",
                    background: "lightgray",
                  }}
                >
                  {renderPlayerHeroes(props.match.banned_heroes, true,props.match.user_match.banned_heroes)}
                </div>
            </div>
           
        </div>
      )}
    </div>
  );
};

export default MatchData ;
