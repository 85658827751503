import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import OpenWithIcon from "@mui/icons-material/OpenWith";

interface Room {
  id: number;
  name: string;
  width: number;
  height: number;
  color: string;
  x: number;
  y: number;
}

interface RoomCanvasProps {
  rooms: Room[];
  setSelectedRoom: (room: Room | null) => void;
  selectedRoom: Room | null;
  updateRoomDetails: (
    roomId: number,
    updatedDetails: { x?: number; y?: number }
  ) => void;
}

const GRID_SIZE = 10; // Define grid size (10px by 10px squares)

const snapToGrid = (value: number) => {
  return Math.round(value / GRID_SIZE) * GRID_SIZE;
};

const RoomCanvas: React.FC<RoomCanvasProps> = ({
  rooms,
  setSelectedRoom,
  selectedRoom,
  updateRoomDetails,
}) => {
  const canvasRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(() => ({
    accept: "room",
    drop: (item: { id: number; offsetX: number; offsetY: number }, monitor: any) => {
      const offset = monitor.getClientOffset();
      if (!offset || !canvasRef.current) return;

      const canvasRect = canvasRef.current.getBoundingClientRect();

      const rawX = offset.x - canvasRect.left - item.offsetX;
      const rawY = offset.y - canvasRect.top - item.offsetY;

      const newX = snapToGrid(rawX);
      const newY = snapToGrid(rawY);

      updateRoomDetails(item.id, { x: newX, y: newY });
    },
  }));

  return (
    <div
      ref={(node) => {
        drop(node);
        // @ts-ignore
        canvasRef.current = node;
      }}
      style={{
        minHeight: "60vh",
        flex: "2",
        position: "relative",
        width: "600px",
        height: "400px",
        overflow: "hidden",
        backgroundImage: `
          linear-gradient(to right, gray 1px, transparent 1px),
          linear-gradient(to bottom, gray 1px, transparent 1px)
        `,
        backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
      }}
    >
      {rooms.map((room) => (
        <RoomComponent
          key={room.id}
          room={room}
          isSelected={selectedRoom?.id === room.id}
          setSelectedRoom={setSelectedRoom}
        />
      ))}
    </div>
  );
};

interface RoomComponentProps {
  room: Room;
  isSelected: boolean;
  setSelectedRoom: (room: Room | null) => void;
}

const RoomComponent: React.FC<RoomComponentProps> = ({
  room,
  isSelected,
  setSelectedRoom,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "room",
    item: { id: room.id, offsetX: room.width / 2, offsetY: room.height / 2 },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        position: "absolute",
        left: snapToGrid(room.x),
        top: snapToGrid(room.y),
        width: room.width,
        height: room.height,
        backgroundColor: room.color,
        cursor: "move",
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => setSelectedRoom(room)}
    >
      <span
        style={{
          position: "absolute",
          top: "2px",
          left: "2px",
          fontSize: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          color: "black",
          padding: "2px 4px",
          borderRadius: "4px",
          pointerEvents: "none",
        }}
      >
        {room.name}
      </span>
      <OpenWithIcon
        style={{
          width: "10px",
          height: "10px",
          color: "white",
          borderRadius: "50%",
        }}
      />
    </div>
  );
};

export default RoomCanvas;
