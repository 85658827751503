import React, { useState } from "react";
import { Hero, PlayerDetails } from "../../types.tsx";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import "../../styles/UserHome.css";
import { Button } from "@mui/material";

const radiant_heroes = [
  { hero_id: 5, role: "carry" },
  { hero_id: 6, role: "mid" },
  { hero_id: 7, role: "offlane" },
  { hero_id: 8, role: "support" },
  { hero_id: 9, role: "hard_support" },
];

const dire_heroes = [
  { hero_id: 10, role: "carry" },
  { hero_id: 11, role: "mid" },
  { hero_id: 13, role: "offlane" },
  { hero_id: 14, role: "support" },
  { hero_id: 15, role: "hard_support" },
];

interface UserDraftProps {
  playerData: any;
  heroes: Hero[];
  currentGold: number;
  onChangeHero: (
    hero_id: number,
    type: string,
    role: string,
    gold_cost: number,
    free_transfers:number,
  ) => void;
  onSwitchHeroes :(index:number) =>void;
  onChangeBannedHero: (hero_id:number, index:number) => void;
  onChangeTripleCaptain: (hero_id:number, index:number) => void;

}

const UserDraft: React.FC<UserDraftProps> = ({
  playerData,
  heroes,
  currentGold,
  onChangeHero,
  onSwitchHeroes,
  onChangeBannedHero,
  onChangeTripleCaptain
}) => {
  const [radiantHeroes, setRadiantHeroes] = useState(radiant_heroes);
  const [direHeroes, setDireHeroes] = useState(dire_heroes);
  const [isSelectingHero, setIsSelectingHero] = useState(false);
  const [currentHeroIndex, setCurrentHeroIndex] = useState<number | null>(null);
  const [currentHeroType, setCurrentHeroType] = useState<string | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string | null>("all");
  const [selectedHeroData, setSelectedHeroData] = useState(null);
  const [selectedHeroID, setSelectedHeroID] = useState<number>(0);
  const [currentHeroGold, setCurrentHeroGold] = useState<number>(0);
  const [pickingCaptain, setPickingCaptain] = useState<boolean>(false);

  const [pickingBanned, setPickingBanned] = useState<boolean>(false);
  const [currentHeroRole, setCurrentHeroRole] = useState<string>("carry");

  const handleHeroClick = (type: string, role: string, index: number) => {
  
    if(type =="banned") {
      setPickingBanned(true)
      setCurrentHeroIndex(index);
      setIsSelectingHero(true);
      setSelectedHeroID(0)
    } else {
      setCurrentHeroType(type);
      setCurrentHeroIndex(index);
      setIsSelectingHero(true);
      setCurrentHeroRole(role);
    }
    
  };

  const handleHeroReplace = (newHeroId: number) => {
    
    setSelectedHeroID(0);
    setIsSelectingHero(false);
    setCurrentHeroIndex(null);
    setCurrentHeroType(null);
    
    onChangeHero(
      newHeroId,
      currentHeroType ? currentHeroType : "radiant",
      currentHeroRole,
      currentGold - currentHeroGold,
      playerData.free_transfers-1,
    );
  };

  const handleBack = () => {
    setIsSelectingHero(false);
    setCurrentHeroIndex(null);
    setCurrentHeroType(null);
  };

  function renderRole(role: string): string {
    switch (role) {
      case "carry":
        return "Carry";
      case "mid":
        return "Mid";
      case "offlane":
        return "Offlane";
      case "support":
        return "Support";
      case "hard_support":
        return "Hard support";
      case "banned":
        return "Banned"
    }
    return "";
  }

  const renderHeroList = (heroList: any[], type: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack items vertically
          gap: "10px",
          justifyContent: type === "radiant" ? "flex-start" : "flex-end",
        }}
      >
        {heroList.map((hero, index) => {
          const heroDetails = heroes.find((h) => h.id === hero.hero_id);
          return (
            heroDetails && (
              <div
                key={hero.hero_id}
                style={{
                  position: "relative",
                  width: "4rem",
                  height: "7rem",
                  cursor: "pointer",
                  borderRadius: "3px",
                  overflow: "hidden",
                  textAlign: "center",
                }}
                onClick={() => {
                  handleHeroClick(type, hero.role, index);
                }}
              >
                <img
                  src={`/assets/portraits/${heroDetails.name}.png`}
                  alt={heroDetails.name}
                  style={{ width: "100%", height: "80%" }}
                />
                <div
                  style={{
                    background: "#333",
                    color: "white",
                    fontSize: "0.5rem",
                    padding: "0.2rem",
                  }}
                >
                  {renderRole(hero.role)}
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  const renderBannedHeroes = (playerData:PlayerDetails) => {
    const left_hero = heroes.find((h) => h.id === playerData.banned_heroes[0].hero_id);
    const right_hero = heroes.find((h) => h.id === playerData.banned_heroes[1].hero_id);

   

    return (
      <div
      style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
    >
      <div style={{display:"flex", flexDirection:'column', textAlign:'center', gap:'0.2rem'}}>
      <img
        src={`/assets/portraits/${left_hero?.name}.png`}
        alt={left_hero?.localized_name}
        className="hero-image"
        onClick={() => {
          handleHeroClick("banned", "", 0);
        }}
      />
       <div
        style={{
          background: "#333",
          color: "white",
          fontSize: "0.5rem",
          padding: "0.2rem",
        }}
      >
        {renderRole("banned")}
      </div>
      </div>
      <div style={{display:"flex", flexDirection:'column', textAlign:'center', gap:'0.2rem'}}>
      <img
        src={`/assets/portraits/${right_hero?.name}.png`}
        alt={right_hero?.localized_name}
        className="hero-image"
        onClick={() => {
          handleHeroClick("banned", "", 1);
        }}
      />
       <div
        style={{
          background: "#333",
          color: "white",
          fontSize: "0.5rem",
          padding: "0.2rem",
        }}
      >
        {renderRole("banned")}
      </div>
      </div>
    </div>
    );
  };
  const renderRoleHeroes = (radiantHero:any, direHero:any, type: string, index :number) => {
    const left_hero = heroes.find((h) => h.id === radiantHero.hero_id);
    const right_hero = heroes.find((h) => h.id === direHero.hero_id);

    const getDynamicFontSize = (
      text: string | undefined,
      maxFontSize: number
    ) => {
      if (!text) return `${maxFontSize}px`;
      const length = text.length;
      const fontSize = Math.max(maxFontSize - (length - 10) * 0.5, 10); // Adjust font size dynamically
      return `${fontSize}px`;
    };

    return (
      <div className="hero-comparison-container">
        <div className="hero-side left">
        
            <img
              src={left_hero? `/assets/portraits/${left_hero.name}.png` :`/assets/portraits/pick.png` }
              alt={left_hero?.name}
              className="hero-image"
              style={{border: playerData.triple_captain ==left_hero?.id ? "5px solid gold":""}}
              onClick={() => {
                if(playerData.free_transfers === 0 ) {
                window.alert("not enough transfers")
                return  
              } 
                handleHeroClick("radiant", radiantHero.role, index);
              }}
            />
          <div className="hero-details">
            <span
              className="hero-name"
              style={{
                fontSize: getDynamicFontSize(left_hero?.localized_name, 14),
              }}
            >
              {left_hero?.localized_name}
            </span>
            <span
              className="hero-role"
              style={{
                fontSize: getDynamicFontSize(renderRole(radiantHero.role), 12),
              }}
            >
              {renderRole(radiantHero.role)}
            </span>
            <span
              className="hero-stat"
              style={{
                fontSize: getDynamicFontSize(
                  `GOLD COST: ${left_hero?.gold_cost}`,
                  12
                ),
              }}
            >
              GOLD COST: {left_hero?.gold_cost}
            </span>
            {pickingCaptain && <>
            <span style={{background:'yellow', color:'black', fontSize:'0.6rem', padding:'0.5rem 1rem', borderRadius:'10px', fontWeight:'bold', textAlign:'center'}}
            
            onClick={()=>{
              onChangeTripleCaptain(radiantHero.hero_id,0)

              setPickingCaptain(false)
            }}
            >CHOOSE</span>
            </>}
            {/* <span
              className="hero-stat"
              style={{
                fontSize: getDynamicFontSize(
                  `POINTS: ${radiantHero.points}`,
                  12
                ),
              }}
            >
              POINTS: {"23"}
            </span> */}
          </div>
        </div>

        <div className="icon-container">
          <SwapHorizIcon onClick={()=>{
            onSwitchHeroes(index)
          }} style={{ fontSize: "xxx-large" }} />
        </div>

        <div className="hero-side right">
          <div className="hero-details">
            <span
              className="hero-name"
              style={{
                fontSize: getDynamicFontSize(right_hero?.localized_name, 14),
              }}
            >
              {right_hero?.localized_name}
            </span>
            <span
              className="hero-role"
              style={{
                fontSize: getDynamicFontSize(renderRole(direHero.role), 12),
              }}
            >
              {renderRole(direHero.role)}
            </span>
            <span
              className="hero-stat"
              style={{
                fontSize: getDynamicFontSize(
                  `GOLD COST: ${right_hero?.gold_cost}`,
                  12
                ),
              }}
            >
              GOLD COST:{right_hero?.gold_cost}
            </span>
            {pickingCaptain && <>
            <span style={{background:'yellow', color:'black', fontSize:'0.6rem', padding:'0.5rem 1rem', borderRadius:'10px', fontWeight:'bold', textAlign:'center'}}
              onClick={()=>{
                onChangeTripleCaptain(direHero.hero_id,0)
                setPickingCaptain(false)
              }}
             >CHOOSE</span>
            </>}
            {/* <span
              className="hero-stat"
              style={{
                fontSize: getDynamicFontSize(
                  `POINTS: ${direHero.points}`,
                  12
                ),
              }}
            >
              POINTS: {"23"}
            </span> */}
          </div>
        
            <img
            src={right_hero ? `/assets/portraits/${right_hero?.name}.png` :`/assets/portraits/pick.png` }
            style={{border: playerData.triple_captain ==right_hero?.id ? "5px solid gold":""}}
              alt={right_hero?.name}
              className="hero-image"
              onClick={() => {
                if(playerData.free_transfers === 0 ) {
                  window.alert("not enough transfers")
                  return  
                } 
                handleHeroClick("dire", direHero.role, index);
              }}
            />
        </div>
      </div>
    );
  };

  const renderHeroSelection = () => {
    const handleAdditionalHeroData = async (
      heroId: number,
      gold_cost: number
    ) => {
      setCurrentHeroGold(gold_cost);
      setSelectedHeroID(heroId);
      // // Fetch hero data based on ID (Replace the URL with your API endpoint)
      // try {
      //   const response = await fetch(`/api/hero-stats/${heroId}`);
      //   const data = await response.json();
      //   setSelectedHeroData(data);
      // } catch (error) {
      //   console.error("Error fetching hero data:", error);
      //   setSelectedHeroData(null); // Reset data in case of error
      // }
    };

    return (
      <div style={{padding:'0.3rem'}}>
        <button
          onClick={handleBack}
          style={{
            padding: "0.5rem 1rem",
            background: "#555",
            color: "white",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
        >
          {"<"}
        </button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            margin: "1rem 0rem",
          }}
        >
          {[
            { label: "All", value: "all" },
            { label: "Strength", value: "str" },
            { label: "Agility", value: "agi" },
            { label: "Intelligence", value: "int" },
          ].map((filter) => (
            <div
              key={filter.value}
              style={{
                cursor: "pointer",
                padding: "0.5rem 1rem",
                background: selectedFilter === filter.value ? "#555" : "#333",
                color: "white",
                borderRadius: "5px",
                fontSize: "0.6rem",
                textAlign: "center",
              }}
              onClick={() => {
                setSelectedFilter((prev) =>
                  prev === filter.value ? null : filter.value
                );
              }}
            >
              {filter.label.toUpperCase()}
            </div>
          ))}
        </div>

        {/* Filtered Heroes */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "3px",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          {heroes
            .filter((hero) =>
              selectedFilter != "all"
                ? hero.primary_attr === selectedFilter
                : true
            )
            .map((hero) => (
              <div
                key={hero.id}
                style={{
                  position: "relative",
                  width: selectedHeroID == hero.id ? "100%" : "4.3rem",
                  height: selectedHeroID == hero.id ? "max-content" : "9rem", // Adjusted height for gold display
                  cursor: "pointer",
                  borderRadius: "10px",
                  overflow: "hidden",
                  textAlign: "center",
                }}
                onClick={() => {
                  if(pickingBanned) {
                    onChangeBannedHero(
                      hero.id,
                      currentHeroIndex? currentHeroIndex: 0
                    );
                    setSelectedHeroID(0);
                    setIsSelectingHero(false);
                    setCurrentHeroIndex(null);
                    setCurrentHeroType(null);
                    setPickingBanned(false)

                    return
                  } else {
                    handleAdditionalHeroData(hero.id, hero.gold_cost)
                  }
                }
                }
              >
                {selectedHeroID == hero.id ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src={`/assets/portraits/${hero.name}.png`}
                      alt={hero.name}
                      style={{
                        width: selectedHeroID == hero.id ? "" : "100%",
                        height: "70%",
                      }}
                    />
                    <div
                      style={{
                        padding: "1rem",
                        minWidth: "max-content",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        textAlign: "left",
                      }}
                    >
                      <span>Stats: </span>
                      <span>
                        <b>Average kills</b> 12
                      </span>
                      <span>
                        <b>Average points</b> 4
                      </span>
                      <span>
                        <b>Average bans</b> 7
                      </span>
                    </div>
                  </div>
                ) : (
                  <img
                    src={`/assets/portraits/${hero.name}.png`}
                    alt={hero.name}
                    style={{
                      width: selectedHeroID == hero.id ? "" : "100%",
                      height: "70%",
                    }}
                  />
                )}
                <div
                  style={{
                    background: "#333",
                    color: "white",
                    fontSize: selectedHeroID == hero.id ? "0.8rem" : "0.4rem",
                    padding: "0.2rem",
                  }}
                >
                  {hero.localized_name}
                </div>
                <div
                  style={{
                    background: "#ebbd34",
                    color: "#222",
                    fontSize: selectedHeroID == hero.id ? "0.8rem" : "0.4rem",
                    padding: "0.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Gold: {hero.gold_cost}
                </div>
                {selectedHeroID == hero.id ? (
                  <div
                    style={{
                      background: "green",
                      color: "white",
                      fontSize: selectedHeroID == hero.id ? "0.8rem" : "0.4rem",
                      padding: "0.5rem",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      if (currentGold - currentHeroGold > 0) {
                        handleHeroReplace(hero.id);
                      } else {
                        window.alert("not enough gold");
                      }
                    }}
                  >
                    SELECT
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          minHeight: "1rem",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
          padding: "0.5rem",
          justifyContent: "space-between",
          background: "#333",
          color: "white",
        }}
      >
        <div
          style={{
            minWidth: "max-content",
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{"Remaining Gold"}</span>
          <span>{currentGold}</span>
        </div>
        <div
          style={{
            minWidth: "max-content",
            gap: "0.2rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{"Total Points"}</span>
          <span>{playerData.points}</span>
        </div>
        <div
          style={{
            minWidth: "max-content",
            gap: "0.2rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{"Free Transfers"}</span>
          <span>{playerData.free_transfers}</span>
        </div>
      </div>

      {!isSelectingHero ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "0.2rem",
              padding: "0.35rem",
            }}
          >
            {/* {renderHeroList(playerData.radiant_heroes, "radiant")} */}
            {renderRoleHeroes(playerData.radiant_heroes[0],playerData.dire_heroes[0], "carry",0)}
            {renderRoleHeroes(playerData.radiant_heroes[1],playerData.dire_heroes[1], "mid",1)}
            {renderRoleHeroes(playerData.radiant_heroes[2],playerData.dire_heroes[2], "offlane",2)}
            {renderRoleHeroes(playerData.radiant_heroes[3],playerData.dire_heroes[3], "soft_support",3)}
            {renderRoleHeroes(playerData.radiant_heroes[4],playerData.dire_heroes[4], "hard_support",4)}




            <div style={{ display: "flex", flexDirection: "row", gap: "1rem", paddingTop:'1.3rem',                  justifyContent:'space-between', }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.65rem",
                    background: "#333",
                    fontSize:'0.5rem',
                    borderRadius: "10px",
                  }}
                >
                  WILD CARD
                </Button>
                <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.65rem",
                    background: "#333",
                    fontSize:'0.5rem',
                    borderRadius: "10px",
                  }}
                >
                DOUBLE BAN
                </Button>
              </div>
              {renderBannedHeroes(playerData)}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Button
                  style={{
                    color: pickingCaptain ? "black":"white",
                    fontWeight: "bold",
                    padding: "0.65rem",
                    background: pickingCaptain ? "yellow":"#333",
                    fontSize:'0.5rem',
                    borderRadius: "10px",
                    border: playerData.triple_captain !=0 ? "5px solid gold":""
                  }}
                  onClick={()=>{
                    setPickingCaptain(true)
                  }}
                >
                  
                  {pickingCaptain ? "PICK NEW CAPTAIN":"TRIPLE CAPTAIN"}
                </Button>
                <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    padding: "0.65rem",
                    background: "#333",
                    fontSize:'0.5rem',
                    borderRadius: "10px",
                  }}
                >
                  DOUBLE ROLE
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        renderHeroSelection()
      )}
    </div>
  );
};

export default UserDraft;
