export const  heroCounterpicks = [
    {
        hero: "Abaddon",

        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast negates Abaddon's healing, making his Aphotic Shield and Borrowed Time ineffective."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call prevents Abaddon from supporting his allies, forcing him to fight."
          },
          {
            hero: "Brewmaster",
            reason: "Brewmaster's Cyclone removes Abaddon from the fight, rendering him unable to support his team."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Abaddon, preventing him from casting Borrowed Time or using his shield."
          },
          {
            hero: "Lina",
            reason: "Lina's high burst damage can quickly eliminate Abaddon before his healing can take effect."
          },
          {
            hero: "Outworld Destroyer",
            reason: "Outworld Destroyer's Astral Imprisonment disrupts Abaddon's positioning and removes him temporarily from fights."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Demonic Purge prevents Abaddon from using his shield effectively."
          },
          {
            hero: "Viper",
            reason: "Viper's Nethertoxin disables Abaddon's passive abilities, severely reducing his survivability."
          }
        ]
      },
      {
        hero: "Alchemist",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Alchemist from benefiting from his regeneration and healing."
          },
          {
            hero: "Enchantress",
            reason: "Enchantress's Impetus punishes Alchemist's low armor, and her untargetable nature makes her difficult for him to handle."
          },
          {
            hero: "Invoker",
            reason: "Invoker's EMP burns Alchemist's mana, limiting his ability to use Chemical Rage effectively."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's Ghostship mitigates Alchemist's burst damage, and X Marks the Spot prevents him from escaping."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's high pure damage counters Alchemist's tankiness and reduces his effectiveness in sustained fights."
          }
        ]
      },
      {
        hero: "obsidian_destroyer",
        counterpicks: [
         
        ]
      },
      {
        hero: "Ancient Apparition",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Mana Void punish Ancient Apparition's reliance on spells and low health pool."
          },
          {
            hero: "Brewmaster",
            reason: "Brewmaster's Primal Split disrupts Ancient Apparition's ability to position safely during fights."
          },
          {
            hero: "Riki",
            reason: "Riki's invisibility and silence make it difficult for Ancient Apparition to cast spells effectively."
          },
          {
            hero: "Lycan",
            reason: "Lycan's summons quickly overwhelm Ancient Apparition, who lacks the mobility or defenses to escape."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions make it hard for Ancient Apparition to land his Ice Blast effectively."
          }
        ]
      },
      {
        hero: "phantom_lancer",
        counterpicks: [
        
        ]
      },
      {
        hero: "Terrorblade",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call pierces Terrorblade's illusions and locks him in place, allowing him to be burst down."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam deals massive damage to Terrorblade and his illusions, making him an effective counter."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel isolates Terrorblade, nullifying his illusions and preventing Sunder usage."
          },
          {
            hero: "Medusa",
            reason: "Medusa's Split Shot can clear Terrorblade's illusions quickly while Stone Gaze interrupts his ability to fight effectively."
          },
          {
            hero: "Winter Wyvern",
            reason: "Winter Wyvern's Winter's Curse can turn Terrorblade's own illusions and allies against him, dealing significant damage."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer can overwhelm Terrorblade with his own illusions, out-sustaining him in extended fights."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's AoE damage and tankiness make it difficult for Terrorblade to burst him down, even with Metamorphosis."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify prevents Terrorblade from dealing physical damage, and Life Drain can counteract Sunder."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's AoE abilities and pure damage quickly eliminate Terrorblade's illusions and chip away at his health."
          }
        ]
      }
      ,
      {
        hero: "antimage",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down Anti-Mage, preventing him from blinking away during fights."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip and Enfeeble counter Anti-Mage's mobility and attack-based effectiveness."
          },
          {
            hero: "Terrorblade",
            reason: "Terrorblade's high physical damage and Sunder ability punish Anti-Mage's low durability."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Glimpse and Static Storm prevent Anti-Mage from blinking and using his abilities effectively."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Anti-Mage's mobility, forcing him to stay in place."
          }
        ]
      },
      {
        hero: "Arc Warden",
        counterpicks: [
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings overwhelm Arc Warden's ability to split focus and punish his lack of mobility."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and Mana Void counter Arc Warden's reliance on abilities."
          },
          {
            hero: "Axe",
            reason: "Axe's initiation punishes Arc Warden's squishy nature, and his illusions are weak to Axe's AoE abilities."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's durability allows him to withstand Arc Warden's attacks while dealing consistent damage."
          },
          {
            hero: "Spectre",
            reason: "Spectre's global presence punishes Arc Warden's split-push strategy and focuses him in fights."
          }
        ]
      },
      {
        hero: "Axe",
        counterpicks: [
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate prevents Axe from casting Berserker's Call and Counter Helix during fights."
          },
          {
            hero: "Jakiro",
            reason: "Jakiro's slows and area control make it difficult for Axe to initiate effectively."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage makes him immune to Axe's call, and his lifesteal sustains him in fights."
          },
          {
            hero: "Necrophos",
            reason: "Necrophos's Heartstopper Aura and Reaper's Scythe counter Axe's high HP pool and punish him for initiating."
          },
          {
            hero: "Terrorblade",
            reason: "Terrorblade's illusions overwhelm Axe, forcing him to choose between poor targets for his call."
          }
        ]
      },
      {
        hero: "necrolyte",
        counterpicks: [
         
        ]
      },
      {
        hero: "furion",
        counterpicks: [
         
        ]
      },
      {
        hero: "Bane",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Bane's disables and counters his grip on allies."
          },
          {
            hero: "Pudge",
            reason: "Pudge's Hook interrupts Bane's channeling abilities, rendering him ineffective in fights."
          },
          {
            hero: "Luna",
            reason: "Luna's high burst damage punishes Bane's low mobility and squishy nature."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Bane's powerful disables, turning them against him and his team."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and stuns prevent Bane from channeling his abilities effectively."
          }
        ]
      },
      {
        hero: "Pudge",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Mana Void punish Pudge's low mana pool, making him vulnerable in fights."
          },
          {
            hero: "Lifestealer",
            reason: "Lifestealer's Rage makes him immune to Pudge's Rot and Dismember, negating Pudge's kill potential."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Pudge from using his abilities, especially Dismember, during crucial moments."
          },
          {
            hero: "Slark",
            reason: "Slark's Dark Pact removes Pudge's Dismember and slows, while his mobility allows him to evade Pudge's Meat Hook."
          },
          {
            hero: "Viper",
            reason: "Viper's Poison Attack and Nethertoxin reduce Pudge's tankiness by breaking his passive Flesh Heap."
          },
          {
            hero: "Tinker",
            reason: "Tinker's Laser and Missile damage from range, and his mobility makes it difficult for Pudge to land his abilities."
          },
          {
            hero: "Venomancer",
            reason: "Venomancer's damage-over-time abilities outlast Pudge's tankiness, and the slows make it harder for Pudge to chase or escape."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Dismember or Meat Hook, turning Pudge's own abilities against him."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions confuse Pudge, making it hard to land Meat Hook or focus the real hero."
          },
          {
            hero: "Snapfire",
            reason: "Snapfire's Cookie and ultimate provide long-range damage and crowd control, keeping her allies safe from Pudge's initiation."
          }
        ]
      },      
      {
        hero: "Batrider",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield dispels Batrider's Flaming Lasso, saving his allies."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's X Marks the Spot prevents Batrider from escaping after initiating."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Press the Attack dispels Batrider's disables, making her a strong counter."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Spiked Carapace punishes Batrider's AoE damage and prevents his mobility."
          },
          {
            hero: "Oracle",
            reason: "Oracle's False Promise nullifies Batrider's burst damage and disables."
          }
        ]
      },
      {
        hero: "Oracle",
        counterpicks: [
         
        ]
      },
      {
        hero: "Beastmaster",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Beastmaster's Primal Roar and slows from his summons."
          },
          {
            hero: "Crystal Maiden",
            reason: "Crystal Maiden's Frostbite immobilizes Beastmaster's summons, reducing their effectiveness."
          },
          {
            hero: "Enchantress",
            reason: "Enchantress can take control of Beastmaster's summons, turning them against him."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam punishes Beastmaster's summons and grouped positioning."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Supernova forces Beastmaster to disengage, countering his push-heavy playstyle."
          }
        ]
      },
      {
        hero: "Bloodseeker",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Rupture, negating Bloodseeker's primary threat."
          },
          {
            hero: "Dazzle",
            reason: "Dazzle's Shallow Grave prevents Bloodseeker from securing kills and healing through Thirst."
          },
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's mobility allows him to avoid Bloodseeker's Rupture and control him in fights."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere isolates Bloodseeker, removing him from fights."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify prevents Bloodseeker from dealing physical damage effectively."
          }
        ]
      },
      {
        hero: "Bounty Hunter",
        counterpicks: [
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Thirst reveals Bounty Hunter when he is low on health or trying to escape."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's silence and sustained damage make it hard for Bounty Hunter to pick off targets."
          },
          {
            hero: "Luna",
            reason: "Luna's high burst damage and vision advantage counter Bounty Hunter's stealthy playstyle."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Bounty Hunter's Track and use it against him and his team."
          },
          {
            hero: "Slardar",
            reason: "Slardar's Corrosive Haze provides vision, nullifying Bounty Hunter's invisibility."
          }
        ]
      },
      {
        hero: "Brewmaster",
        counterpicks: [
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip locks down Brewmaster, preventing him from activating Primal Split."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's silence prevents Brewmaster from casting his abilities effectively."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain quickly disable and deplete Brewmaster's mana pool."
          },
          {
            hero: "Outworld Destroyer",
            reason: "Outworld Destroyer's Astral Imprisonment removes Brewmaster from fights temporarily."
          },
          {
            hero: "Invoker",
            reason: "Invoker's crowd control abilities make it difficult for Brewmaster to engage and survive in fights."
          }
        ]
      },
      {
        hero: "Bristleback",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break prevents Bristleback from spamming his abilities."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Bristleback, negating his defensive and offensive capabilities."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere isolates Bristleback, allowing his team to focus him down."
          },
          {
            hero: "Hoodwink",
            reason: "Hoodwink's Bushwhack and Sharpshooter punish Bristleback's reliance on positioning."
          },
          {
            hero: "Shadow Fiend",
            reason: "Shadow Fiend's high physical burst and Requiem of Souls punish Bristleback's low magic resistance."
          }
        ]
      },
      {
        hero: "Broodmother",
        counterpicks: [
          {
            hero: "Alchemist",
            reason: "Alchemist's AoE abilities clear Broodmother's spiderlings and prevent her from snowballing."
          },
          {
            hero: "Axe",
            reason: "Axe's Counter Helix punishes Broodmother's spiderlings and forces her to reposition."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast counters Broodmother's lifesteal and sustain from webs."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's durability and AoE abilities make it hard for Broodmother to engage effectively."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam punishes Broodmother's spiderlings and grouped positioning."
          }
        ]
      },
      {
        hero: "Centaur",
        counterpicks: [
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs disrupt Centaur's initiation and trap him, limiting his mobility."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Glimpse and Static Storm punish Centaur's Stampede and control his team."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's X Marks the Spot can bring Centaur back after a Stampede escape."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage and high physical damage make it hard for Centaur to trade effectively."
          },
          {
            hero: "Tinker",
            reason: "Tinker's high magic burst and mobility counter Centaur's tanky but predictable playstyle."
          }
        ]
      },
      {
        hero: "Chaos Knight",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Chaos Knight's Chaos Bolt stun and protects allies."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void and mobility make him effective against Chaos Knight's illusions."
          },
          {
            hero: "Axe",
            reason: "Axe's Counter Helix and Berserker's Call clear Chaos Knight's illusions quickly."
          },
          {
            hero: "Brewmaster",
            reason: "Brewmaster's Primal Split dispels Chaos Knight's illusions and controls him in fights."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's AoE damage and mobility deal with Chaos Knight's illusions effectively."
          }
        ]
      },
      {
        hero: "Chen",
        counterpicks: [
          {
            hero: "Crystal Maiden",
            reason: "Crystal Maiden's Frostbite immobilizes Chen's creeps, reducing their impact in fights."
          },
          {
            hero: "Enchantress",
            reason: "Enchantress can steal Chen's creeps, turning them against him."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam punishes Chen's army of summoned creeps and grouped units."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel allows her to isolate Chen while ignoring his creeps."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Supernova and AoE abilities counter Chen's reliance on grouped units."
          }
        ]
      },
      {
        hero: "Phoenix",
        counterpicks: [
        
        ]
      },
      {
        hero: "Clinkz",
        counterpicks: [
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Thirst reveals Clinkz when he is low on health or trying to escape."
          },
          {
            hero: "Bounty Hunter",
            reason: "Bounty Hunter's Track nullifies Clinkz's invisibility advantage."
          },
          {
            hero: "doom_bringer",
            reason: "Doom silences Clinkz, preventing him from using his high burst damage abilities."
          },
          {
            hero: "Enchantress",
            reason: "Enchantress's high durability and Untouchable counter Clinkz's physical attacks."
          },
          {
            hero: "Zeus",
            reason: "Zeus's static vision and global abilities punish Clinkz's reliance on stealth."
          }
        ]
      },
      {
        hero: "rattletrap",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Blink allows him to escape Clockwerk's Power Cogs easily."
          },
          {
            hero: "Chen",
            reason: "Chen's army of creeps can block Clockwerk's Hookshot, disrupting his initiation."
          },
          {
            hero: "Clinkz",
            reason: "Clinkz's high mobility and range make it hard for Clockwerk to trap him effectively."
          },
          {
            hero: "Dazzle",
            reason: "Dazzle's Shadow Wave and Shallow Grave make it hard for Clockwerk to finish off targets."
          },
          {
            hero: "Invoker",
            reason: "Invoker's crowd control and summons counter Clockwerk's aggressive playstyle."
          }
        ]
      },
      {
        hero: "Crystal Maiden",
        counterpicks: [
          {
            hero: "Bounty Hunter",
            reason: "Bounty Hunter's high mobility and burst damage make it easy for him to pick off Crystal Maiden."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness allows him to ignore Crystal Maiden's low-damage abilities."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs and Hookshot prevent Crystal Maiden from channeling her ultimate."
          },
          {
            hero: "doom_bringer",
            reason: "Doom silences Crystal Maiden, preventing her from casting her impactful abilities."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Spiked Carapace counter Crystal Maiden's low mobility and high mana reliance."
          }
        ]
      },
      {
        hero: "Dark Seer",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Spell Shield counter Dark Seer's mana-reliant abilities."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Dark Seer's high mobility and Surge ability."
          },
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's high mobility and AoE damage counter Dark Seer's Ion Shell."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's X Marks the Spot disrupts Dark Seer's mobility and punishes his aggressive positioning."
          },
          {
            hero: "Gyrocopter",
            reason: "Gyrocopter's AoE abilities clear Dark Seer's illusions and minions effectively."
          }
        ]
      },
      {
        hero: "Dark Willow",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's high mobility and ability to avoid Dark Willow's disables counter her effectively."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call interrupts Dark Willow's abilities and punishes her low health pool."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and AoE damage make it hard for Dark Willow to burst him down."
          },
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's silence and high physical damage counter Dark Willow's abilities and low durability."
          },
          {
            hero: "Luna",
            reason: "Luna's Eclipse and AoE damage punish Dark Willow's fragile nature."
          }
        ]
      },
      {
        hero: "Dawnbreaker",
        counterpicks: [
          {
            hero: "Viper",
            reason: "Viper's Nether Toxin prevents Dawnbreaker's healing and nullifies her sustain during fights."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Spiked Carapace punish Dawnbreaker's low mana pool and predictable abilities."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Static Storm and Glimpse disrupt Dawnbreaker's initiation and mobility."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere isolates Dawnbreaker, preventing her from saving allies or healing effectively."
          },
          {
            hero: "Enigma",
            reason: "Enigma's Black Hole neutralizes Dawnbreaker's ability to counter-initiate or heal in team fights."
          }
        ]
      },
      {
        hero: "Naga Siren",
        counterpicks: [
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam is devastating against Naga Siren's illusions, dealing massive damage in team fights."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down Naga Siren and her illusions, punishing her reliance on illusion-based gameplay."
          },
          {
            hero: "Leshrac",
            reason: "Leshrac's AoE spells, like Pulse Nova and Diabolic Edict, quickly destroy Naga Siren's illusions and pressure her in fights."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain eliminate Naga's illusions and control her during fights."
          },
          {
            hero: "Invoker",
            reason: "Invoker's AoE spells like EMP and Tornado disrupt Naga Siren's positioning and drain her mana, reducing her effectiveness."
          },
          {
            hero: "Tidehunter",
            reason: "Tidehunter's Ravage can stun Naga Siren and her illusions, providing control during team fights."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon can create stronger illusions of Naga Siren with Disruption, turning her strength against her."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions can overwhelm Naga Siren in fights, and his mobility makes him hard to control."
          },
          {
            hero: "Tinker",
            reason: "Tinker's March of the Machines and high burst damage remove Naga's illusions effectively and zone her out of fights."
          },
          {
            hero: "Dark Seer",
            reason: "Dark Seer's Vacuum and Wall of Replica can use Naga Siren's illusions against her while providing excellent area control."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's Quill Spray punishes Naga's illusions and deals high damage over time in extended fights."
          },
          {
            hero: "Medusa",
            reason: "Medusa's Split Shot and tankiness allow her to clear Naga's illusions and survive through Song of the Siren."
          }
        ]
      },
      {
        hero: "Dazzle",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Dazzle's Shallow Grave and healing, rendering him ineffective."
          },
          {
            hero: "Axe",
            reason: "Axe's Culling Blade executes heroes under Shallow Grave, bypassing Dazzle's key defensive tool."
          },
          {
            hero: "Naga Siren",
            reason: "Naga Siren's Song of the Siren allows her team to reset fights and target Dazzle after his spells are used."
          },
          {
            hero: "Outworld Destroyer",
            reason: "Outworld Destroyer's Astral Imprisonment disrupts Dazzle's positioning and interrupts his channeling."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Dazzle from using Shallow Grave or healing spells during fights."
          }
        ]
      },
      {
        hero: "Death Prophet",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ice Blast counters Death Prophet's Spirit Siphon healing and ultimate sustain."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void punishes Death Prophet's high mana pool while his mobility counters her ultimate."
          },
          {
            hero: "doom_bringer",
            reason: "Doom silences Death Prophet, preventing her from casting Exorcism and Spirit Siphon."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel isolates Death Prophet, negating her teamfight potential."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Disruption removes Death Prophet's Exorcism spirits temporarily, reducing her damage."
          }
        ]
      },
      {
        hero: "Disruptor",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Disruptor's Glimpse and Static Storm debuffs, protecting his team."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's X Marks the Spot and Ghost Ship counter Disruptor's positioning-based abilities."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury makes him immune to Disruptor's magical damage and disables."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage counters Disruptor's Static Storm and allows him to engage safely."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin punishes Disruptor's mana reliance with Mana Burn and counters his static field presence."
          }
        ]
      },
      {
        hero: "doom_bringer",
        counterpicks: [
          {
            hero: "Alchemist",
            reason: "Alchemist's high farm potential outpaces Doom's single-target disable and sustain."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur Warrunner's Stampede allows his team to disengage from Doom's ultimate and reposition."
          },
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's Frost Arrows and high physical damage counter Doom's tanky nature."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage makes him immune to Doom's ultimate, allowing him to fight effectively."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Omnislash and Blade Fury counter Doom's disables and pressure in fights."
          }
        ]
      },
      {
        hero: "Dragon Knight",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast nullifies Dragon Knight's healing and sustain during his Dragon Blood state."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and Quill Spray damage make it hard for Dragon Knight to trade effectively."
          },
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's Marksmanship and Frost Arrows bypass Dragon Knight's armor and slow him down."
          },
          {
            hero: "Lich",
            reason: "Lich's Frost Shield and Chain Frost counter Dragon Knight's melee-centric and tanky playstyle."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's Pure damage and mobility counter Dragon Knight's armor and lack of mobility."
          }
        ]
      },
      {
        hero: "Drow Ranger",
        counterpicks: [
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere isolates Drow Ranger, negating her positioning advantage."
          },
          {
            hero: "Tinker",
            reason: "Tinker's high magical burst and range punish Drow Ranger's low mobility."
          },
          {
            hero: "Arc Warden",
            reason: "Arc Warden's Tempest Double and Spark Wraiths pressure Drow Ranger from a safe distance."
          },
          {
            hero: "Tusk",
            reason: "Tusk's Snowball and high burst damage disrupt Drow Ranger's attacks and positioning."
          },
          {
            hero: "Spectre",
            reason: "Spectre's Haunt punishes Drow Ranger's reliance on positioning and forces her into unfavorable fights."
          }
        ]
      },
      {
        hero: "Earth Spirit",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Earth Spirit's stuns and slows, reducing his impact in fights."
          },
          {
            hero: "Techies",
            reason: "Techies' mines punish Earth Spirit's mobility and aggressive positioning."
          },
          {
            hero: "Slark",
            reason: "Slark's Dark Pact removes Earth Spirit's disables, and his mobility outmaneuvers Earth Spirit."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin counters Earth Spirit's mana dependence with Mana Burn and punishes his initiation."
          }
        ]
      },
      {
        hero: "Earthshaker",
        counterpicks: [
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs disrupt Earthshaker's positioning and prevent him from landing Echo Slam."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's Spirit Siphon and Exorcism punish Earthshaker's tankiness and slow movement."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Glimpse and Static Storm counter Earthshaker's need to position for Echo Slam."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and mobility make it difficult for Earthshaker to set up his abilities."
          },
          {
            hero: "Weaver",
            reason: "Weaver's mobility and escape tools make it hard for Earthshaker to land his stuns."
          }
        ]
      },
      {
        hero: "Elder Titan",
        counterpicks: [
          {
            hero: "Brewmaster",
            reason: "Brewmaster's Primal Split disrupts Elder Titan's teamfight setup and isolates him."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs and Battery Assault interrupt Elder Titan's Astral Spirit and Echo Stomp."
          },
          {
            hero: "Lich",
            reason: "Lich's Frost Shield and Chain Frost negate Elder Titan's high physical damage."
          },
          {
            hero: "Lion",
            reason: "Lion's disables and burst damage counter Elder Titan's long cast times and low mobility."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Elder Titan's spells, turning his Astral Spirit or Earth Splitter against him."
          }
        ]
      },
      {
        hero: "Ember Spirit",
        counterpicks: [
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain disable Ember Spirit and prevent him from escaping with Fire Remnant."
          },
          {
            hero: "Shadow Fiend",
            reason: "Shadow Fiend's high burst damage punishes Ember Spirit's fragile early game."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Ember Spirit from using his mobility spells."
          },
          {
            hero: "Storm Spirit",
            reason: "Storm Spirit's electric burst and mobility allow him to outmaneuver and outdamage Ember Spirit."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander isolates Ember Spirit with Duel, negating his mobility and spellcasting."
          }
        ]
      },
      {
        hero: "Enchantress",
        counterpicks: [
          {
            hero: "Razor",
            reason: "Razor's Static Link steals Enchantress's damage, neutralizing her impact in fights."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiders overwhelm Enchantress and force her to spend mana inefficiently."
          },
          {
            hero: "Chen",
            reason: "Chen's ability to steal Enchantress's creeps counters her primary strength in fights."
          },
          {
            hero: "Crystal Maiden",
            reason: "Crystal Maiden's Frostbite and high magic damage punish Enchantress's low health pool."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call forces Enchantress into melee range, negating her kiting potential."
          }
        ]
      },
      {
        hero: "Enigma",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes the stun from Black Hole, saving his allies."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void punishes Enigma's high mana pool and dependence on abilities."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Enigma for repositioning during teamfights."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and AoE damage counter Enigma's Eidolons and sustain through Black Hole."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence interrupts Black Hole and prevents Enigma from initiating effectively."
          }
        ]
      },
      {
        hero: "Faceless Void",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes disables and protects allies caught in Chronosphere."
          },
          {
            hero: "Brewmaster",
            reason: "Brewmaster's Primal Split creates chaos in fights, disrupting Faceless Void's follow-up during Chronosphere."
          },
          {
            hero: "Monkey King",
            reason: "Monkey King's Wukong's Command pressures Faceless Void inside his own Chronosphere."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and disables make it difficult for Faceless Void to position for Chronosphere."
          },
          {
            hero: "Puck",
            reason: "Puck's mobility and Dream Coil disrupt Faceless Void's positioning and initiation."
          }
        ]
      },
      {
        hero: "Grimstroke",
        counterpicks: [
          {
            hero: "Puck",
            reason: "Puck's mobility and silences disrupt Grimstroke's abilities and prevent him from casting Soulbind effectively."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Disruption counters Grimstroke's Inkswell and isolates his targets."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur Warrunner's Stampede allows his team to disengage from Grimstroke's Soulbind."
          },
          {
            hero: "Sniper",
            reason: "Sniper's long range and high damage pressure Grimstroke before he can get in range for his spells."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin punishes Grimstroke's mana dependence and interrupts his spell combos with Mana Burn."
          }
        ]
      },
      {
        hero: "Gyrocopter",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's high mobility and Mana Break punish Gyrocopter's mana-dependent abilities."
          },
          {
            hero: "Arc Warden",
            reason: "Arc Warden's Tempest Double overwhelms Gyrocopter, splitting his focus and neutralizing his AoE damage."
          },
          {
            hero: "Huskar",
            reason: "Huskar's magic resistance negates Gyrocopter's magical damage from Rocket Barrage and Call Down."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere locks Gyrocopter in place, preventing him from dealing damage."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence stops Gyrocopter's abilities during key moments."
          }
        ]
      },
      {
        hero: "Hoodwink",
        counterpicks: [
          {
            hero: "Storm Spirit",
            reason: "Storm Spirit's mobility counters Hoodwink's need to land skillshots and isolates her in fights."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's high durability and ability to cut trees disrupt Hoodwink's Bushwhack combo."
          },
          {
            hero: "treant",
            reason: "Treant Protector's tankiness and tree-dependent abilities counter Hoodwink's playstyle."
          },
          {
            hero: "vengefulspirit",
            reason: "Vengeful Spirit's Nether Swap interrupts Hoodwink's long-range channeling spells."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Vendetta and Mana Burn punish Hoodwink's squishiness and mana dependence."
          }
        ]
      },
      {
        hero: "Huskar",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Huskar's Burning Spear stacks and counters his sustain."
          },
          {
            hero: "Alchemist",
            reason: "Alchemist's high farm rate and AoE damage overpower Huskar's single-target focus."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast negates Huskar's healing and sustainability."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call forces Huskar into melee range, making him vulnerable to counterattacks."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's durability and constant damage pressure Huskar in extended fights."
          }
        ]
      },
      {
        hero: "Invoker",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Mana Void punish Invoker's reliance on spells."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings swarm Invoker, disrupting his ability to cast effectively."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate disables Invoker's spells, nullifying his presence in fights."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere locks Invoker in place, preventing him from casting spells."
          },
          {
            hero: "Puck",
            reason: "Puck's mobility and silences outplay Invoker's slow spell setup."
          }
        ]
      },
      {
        hero: "wisp",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast negates Io's healing from Tether and Overcharge."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Io's tethered movement and prevents escapes."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's high damage output overpowers Io and his tethered ally."
          },
          {
            hero: "Clinkz",
            reason: "Clinkz's burst damage and mobility outplay Io's sustain and support capabilities."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's AoE stuns punish Io's reliance on staying close to his ally."
          }
        ]
      },
      {
        hero: "Jakiro",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and mobility punish Jakiro's mana-reliant spells."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs and Battery Assault disrupt Jakiro's spellcasting."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's magic immunity negates Jakiro's AoE magic damage."
          },
          {
            hero: "Oracle",
            reason: "Oracle's False Promise saves allies from Jakiro's damaging abilities and combos."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Jakiro from casting his key spells in teamfights."
          }
        ]
      },
      {
        hero: "Juggernaut",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call punishes Juggernaut during Blade Fury or Omnislash."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip locks Juggernaut down even during Blade Fury."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions overwhelm Juggernaut and his single-target focus."
          },
          {
            hero: "Enigma",
            reason: "Enigma's Black Hole interrupts Juggernaut's Omnislash and disables him in teamfights."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain counter Juggernaut's mobility and sustain."
          }
        ]
      },
      {
        hero: "Keeper of the Light",
        counterpicks: [
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel locks Keeper of the Light in place, neutralizing his utility."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's magic immunity prevents Keeper of the Light from affecting him with Illuminate."
          },
          {
            hero: "Night Stalker",
            reason: "Night Stalker's Crippling Fear silences Keeper of the Light and prevents his spellcasting."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and stuns punish Keeper of the Light's squishiness and mana dependence."
          },
          {
            hero: "Puck",
            reason: "Puck's mobility and silences outplay Keeper of the Light's positioning and spell setup."
          }
        ]
      },
      {
        hero: "Puck",
        counterpicks: [
         
        ]
      },
      {
        hero: "Night Stalker",
        counterpicks: [
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and Quill Spray allow him to survive and deal consistent damage during Night Stalker's attacks, even in darkness."
          },
          {
            hero: "Tinker",
            reason: "Tinker's long-range nukes and ability to Blink away make it difficult for Night Stalker to lock him down."
          },
          {
            hero: "Venomancer",
            reason: "Venomancer's slows from Poison Sting and Venomous Gale reduce Night Stalker's mobility, making him vulnerable even during night."
          },
          {
            hero: "Keeper of the Light",
            reason: "Keeper of the Light's Illuminate can control Night Stalker from a distance, and his Chakra Magic reduces downtime for teammates' disables."
          },
          {
            hero: "Viper",
            reason: "Viper's Nethertoxin nullifies Night Stalker's passive and reduces his effectiveness in fights."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify and Life Drain allow him to kite and sustain through Night Stalker's aggression."
          },
          {
            hero: "Slark",
            reason: "Slark's Dark Pact removes Night Stalker's silence, and his ability to regen health during nighttime gives him an edge in prolonged fights."
          },
          {
            hero: "Winter Wyvern",
            reason: "Winter Wyvern's Arctic Burn allows her to stay out of Night Stalker's reach, and Winter's Curse can punish his initiation on her team."
          }
        ]
      },
      {
        hero: "Kunkka",
        counterpicks: [
          {
            hero: "Clinkz",
            reason: "Clinkz's burst damage and mobility outplay Kunkka's slow abilities and reliance on Tidebringer."
          },
          {
            hero: "Ursa",
            reason: "Ursa's Fury Swipes overpower Kunkka in melee fights and force him to retreat."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Kunkka from comboing with X Marks the Spot and Torrent."
          },
          {
            hero: "Mirana",
            reason: "Mirana's leap and mobility make it difficult for Kunkka to land his skillshot abilities."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Kunkka's movement during teamfights."
          }
        ]
      },
      {
        hero: "Legion Commander",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield dispels Legion Commander's Duel and negates her lockdown."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip and Nightmare disable Legion Commander during critical fights."
          },
          {
            hero: "Arc Warden",
            reason: "Arc Warden's Tempest Double and Spark Wraith zone Legion Commander and make dueling difficult."
          },
          {
            hero: "Dazzle",
            reason: "Dazzle's Shallow Grave prolongs allies' survival, negating Duel's effectiveness."
          }
        ]
      },
      {
        hero: "Leshrac",
        counterpicks: [
          {
            hero: "Pugna",
            reason: "Pugna's Nether Ward punishes Leshrac's high mana usage and magical damage reliance."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Spell Shield counter Leshrac's mana-dependent spells."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Power Cogs trap Leshrac and disrupt his AoE spells."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage provides magic immunity, nullifying Leshrac's damage output."
          }
        ]
      },
      {
        hero: "Lich",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and mobility punish Lich's reliance on spells."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Thirst capitalizes on Lich's low mobility and squishiness."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings swarm Lich and make Frost Shield less effective."
          },
          {
            hero: "Huskar",
            reason: "Huskar's magic resistance counters Lich's Chain Frost and Frost Blast."
          }
        ]
      },
      {
        hero: "life_stealer",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Lifestealer from healing through Feast or Infest."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip pierces magic immunity, disabling Lifestealer during Rage."
          },
          {
            hero: "Beastmaster",
            reason: "Beastmaster's Primal Roar locks down Lifestealer and allows his team to burst him."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions overwhelm Lifestealer's single-target focus."
          }
        ]
      },
      {
        hero: "Lina",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and mobility make it difficult for Lina to land her spells."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere disables Lina, preventing her from casting spells."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage negates Lina's magical damage."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Carapace punish Lina's high mana usage and squishiness."
          }
        ]
      },
      {
        hero: "Lion",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield dispels Lion's stuns and Hex, countering his lockdown."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage provides magic immunity, nullifying Lion's disables."
          },
          {
            hero: "Puck",
            reason: "Puck's mobility outplays Lion's slow skillshots and prevents him from landing spells."
          },
          {
            hero: "Rubick",
            reason: "Rubick's Spell Steal turns Lion's abilities against his team."
          }
        ]
      },
      {
        hero: "Lone Druid",
        counterpicks: [
          {
            hero: "Phantom Assassin",
            reason: "Phantom Assassin's burst damage quickly eliminates Lone Druid's Spirit Bear."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and AoE pressure counter Lone Druid's reliance on sustained damage."
          },
          {
            hero: "Necrophos",
            reason: "Necrophos's Reaper's Scythe punishes Lone Druid's low mobility and tankiness."
          },
          {
            hero: "Grimstroke",
            reason: "Grimstroke's Soulbind and AoE abilities counter Lone Druid's single-target focus."
          }
        ]
      },
      {
        hero: "Luna",
        counterpicks: [
          {
            hero: "Bristleback",
            reason: "Bristleback's durability and Quill Spray mitigate Luna's AoE damage."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings swarm Luna, making her Eclipse less effective."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury negates Luna's magic damage and outplays her in duels."
          },
          {
            hero: "Lina",
            reason: "Lina's burst damage and range punish Luna's squishiness."
          }
        ]
      },
      {
        hero: "Lycan",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call counters Lycan's summons and locks him down during Shapeshift."
          },
          {
            hero: "Beastmaster",
            reason: "Beastmaster's Primal Roar stops Lycan's mobility and disables his summons."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Lycan's reliance on movement during Shapeshift."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's durability withstands Lycan's burst damage and counters his summons."
          }
        ]
      },
      {
        hero: "magnataur",
        counterpicks: [
          {
            hero: "Alchemist",
            reason: "Alchemist's high farm rate and AoE damage punish Magnus's slow pace."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Magnus's mobility during Skewer."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Static Storm and Kinetic Field trap Magnus, nullifying Reverse Polarity."
          },
          {
            hero: "Huskar",
            reason: "Huskar's magic resistance counters Magnus's magical abilities."
          }
        ]
      },
      {
        hero: "Mars",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call forces Mars into unfavorable engagements during Bulwark."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage negates Mars's magic damage from God's Rebuke and Spear of Mars."
          },
          {
            hero: "Riki",
            reason: "Riki's mobility and Smoke Screen prevent Mars from landing his abilities."
          },
          {
            hero: "Batrider",
            reason: "Batrider's Firefly bypasses Mars's Bulwark and burns him in engagements."
          }
        ]
      },
      {
        hero: "Medusa",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break drains Medusa's mana, nullifying her tankiness and damage."
          },
          {
            hero: "Arc Warden",
            reason: "Arc Warden's Tempest Double applies constant pressure on Medusa, forcing her to defend."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip and Nightmare disable Medusa during key fights."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's map control and spiderlings swarm Medusa, limiting her farm potential."
          }
        ]
      },
      {
        hero: "Meepo",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down multiple Meepo clones, punishing their close positioning."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Echo Slam devastates Meepo's grouped clones with massive AoE damage."
          },
          {
            hero: "Elder Titan",
            reason: "Elder Titan's Natural Order and AoE abilities shred Meepo's clones' resistances."
          },
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's AoE magical damage punishes Meepo's low health pools."
          }
        ]
      },
      {
        hero: "Mirana",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Mirana's Arrow stun and other debuffs, neutralizing her effectiveness."
          },
          {
            hero: "Arc Warden",
            reason: "Arc Warden's Tempest Double and Spark Wraith make it difficult for Mirana to roam and land arrows."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Mirana's mobility and reliance on Leap."
          },
          {
            hero: "Lina",
            reason: "Lina's long-range abilities and burst damage outmatch Mirana in fights."
          }
        ]
      },
      {
        hero: "skeleton_king",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break prevents Wraith King from using his Reincarnation, rendering him vulnerable."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings swarm Wraith King, overwhelming him in fights and cutting off his farm."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions burst down Wraith King's health pool quickly, even with Reincarnation."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury and Omnislash ignore Wraith King's Reincarnation cooldown."
          }
        ]
      },
      {
        hero: "Razor",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and Spell Shield mitigate Razor's Static Link and Eye of the Storm."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions overwhelm Razor's single-target damage from Static Link."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur's tankiness and Stampede allow his team to disengage from Razor's Static Link."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's mobility and silences disrupt Razor's ability to maintain Static Link."
          }
        ]
      },
      {
        hero: "Riki",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Counter Helix punishes Riki's close-range attacks, and Berserker's Call reveals him in Smokescreen."
          },
          {
            hero: "Bounty Hunter",
            reason: "Bounty Hunter's Track reveals Riki's position, nullifying his invisibility advantage."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's durability and Quill Spray counter Riki's hit-and-run playstyle."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel forces Riki into direct engagements where he is vulnerable."
          }
        ]
      },
      {
        hero: "Rubick",
        counterpicks: [
          {
            hero: "Bristleback",
            reason: "Bristleback's passive tankiness makes it hard for Rubick to deal significant damage with stolen spells."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings overwhelm Rubick, who struggles to handle the swarm."
          },
          {
            hero: "Clinkz",
            reason: "Clinkz's mobility and invisibility allow him to easily evade Rubick and burst him down."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel prevents Rubick from casting spells, making him ineffective in fights."
          }
        ]
      },
      {
        hero: "Sand King",
        counterpicks: [
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and disables disrupt Sand King's Blink Dagger initiation and channeling of Epicenter."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Supernova forces Sand King to disengage or focus on the egg, interrupting his combo."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Sand King's Burrowstrike or Epicenter, turning his own spells against him."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence cancels Sand King's Epicenter and prevents follow-up after initiation."
          }
        ]
      },
      {
        hero: "Shadow Demon",
        counterpicks: [
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's mobility and Flame Guard make him difficult for Shadow Demon to disable effectively."
          },
          {
            hero: "Leshrac",
            reason: "Leshrac's high AoE magic damage punishes Shadow Demon's squishiness."
          },
          {
            hero: "Lina",
            reason: "Lina's burst damage and range allow her to quickly kill Shadow Demon in fights."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Finger of Death counter Shadow Demon's spellcasting and low HP pool."
          }
        ]
      },
      {
        hero: "Shadow Fiend",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down Shadow Fiend and disrupts his Requiem of Souls."
          },
          {
            hero: "Razor",
            reason: "Razor's Static Link steals Shadow Fiend's damage, nullifying his effectiveness in fights."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip disables Shadow Fiend and prevents him from casting his abilities."
          },
          {
            hero: "Bounty Hunter",
            reason: "Bounty Hunter's Track and burst damage punish Shadow Fiend's low mobility and squishiness."
          }
        ]
      },
      {
        hero: "Shadow Shaman",
        counterpicks: [
          {
            hero: "Dragon Knight",
            reason: "Dragon Knight's natural tankiness and high magic resistance mitigate Shadow Shaman's abilities."
          },
          {
            hero: "Mirana",
            reason: "Mirana's Leap and Arrow allow her to avoid Shadow Shaman's Shackles and Serpent Wards."
          },
          {
            hero: "Morphling",
            reason: "Morphling's high mobility and ability to replicate Shadow Shaman's abilities make him a difficult target."
          },
          {
            hero: "Meepo",
            reason: "Meepo's clones can quickly overwhelm Shadow Shaman, making his single-target disables less effective."
          }
        ]
      },
      {
        hero: "Morphling",
        counterpicks: [
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain are devastating against Morphling, as they prevent him from shifting attributes and quickly drain his mana."
          },
          {
            hero: "Shadow Shaman",
            reason: "Shadow Shaman's Hex and Shackles lock Morphling in place, leaving him vulnerable before he can shift strength."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Morphling from using his abilities, making him an easy target in fights."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ice Blast stops Morphling from regenerating health when he shifts to strength, significantly reducing his survivability."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Morphling entirely, preventing him from attribute shifting or using other abilities."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Impale disrupt Morphling's ability to shift attributes and deal with his low starting mana pool."
          },
          {
            hero: "Pugna",
            reason: "Decrepify prevents Morphling from dealing physical damage, and Life Drain punishes him when he shifts to strength."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks Morphling in place, and Counter Helix punishes him for summoning illusions with Morph."
          },
          {
            hero: "Phantom Assassin",
            reason: "Phantom Assassin's high burst damage can eliminate Morphling quickly before he has a chance to shift to strength."
          },
          {
            hero: "Tinker",
            reason: "Tinker’s burst damage with Laser and Rockets can burst Morphling down during his agility-shifting phase."
          }
        ]
      },      
      {
        hero: "Silencer",
        counterpicks: [
          {
            hero: "Dragon Knight",
            reason: "Dragon Knight's tankiness and minimal reliance on spells reduce Silencer's effectiveness in fights."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's spiderlings can swarm Silencer, bypassing his defensive abilities."
          },
          {
            hero: "Lycan",
            reason: "Lycan's summons and high mobility make it difficult for Silencer to control fights."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Spike Carapace counter Silencer's abilities and squishiness."
          }
        ]
      },
      {
        hero: "Skywrath Mage",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Spell Shield reduces Skywrath Mage's magic burst, and Mana Break punishes his mana reliance."
          },
          {
            hero: "Huskar",
            reason: "Huskar's high magic resistance and aggression counter Skywrath Mage's fragile nature."
          },
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield and Borrowed Time negate Skywrath Mage's burst damage."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Nether Ward punishes Skywrath Mage's high mana-cost abilities."
          }
        ]
      },
      {
        hero: "Slardar",
        counterpicks: [
          {
            hero: "Oracle",
            reason: "Oracle's False Promise and Fortune's End remove Slardar's Corrosive Haze, saving allies."
          },
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield cleanses Corrosive Haze and mitigates Slardar's initiation."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions overwhelm Slardar, making it difficult to focus the real target."
          },
          {
            hero: "Meepo",
            reason: "Meepo's clones and burst damage can quickly take down Slardar before he gets off his full combo."
          }
        ]
      },
      {
        hero: "Slark",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Slark from healing through Shadow Dance."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down Slark, preventing him from using his mobility to escape."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Slark's movement during Shadow Dance or Pounce."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur's tankiness and Stampede help his team disengage from Slark's aggressive plays."
          }
        ]
      },
      {
        hero: "Snapfire",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and Spell Shield reduce Snapfire's magic damage output."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Snapfire from casting Mortimer Kisses and her other spells."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Carapace stuns Snapfire during her Mortimer Kisses channel, interrupting her damage."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast nullifies Snapfire's healing from her Cookie and Scatterblast sustain."
          }
        ]
      },
      {
        hero: "Sniper",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Blink Call allows him to gap close and lock down Sniper, neutralizing his range advantage."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Sniper's mobility and forces him into unfavorable positions."
          },
          {
            hero: "Bounty Hunter",
            reason: "Bounty Hunter's invisibility and burst damage make it difficult for Sniper to stay safe."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur's tankiness and Stampede allow him and his team to dive Sniper easily."
          }
        ]
      },
      {
        hero: "Ogre Magi",
        counterpicks: [
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Ogre Magi from casting his spells, neutralizing his high impact in fights."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ice Blast nullifies Ogre Magi's high health regeneration and tankiness, making him vulnerable in prolonged fights."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Demonic Purge removes Ogre Magi's Bloodlust and slows him down, reducing his effectiveness."
          },
          {
            hero: "Grimstroke",
            reason: "Grimstroke's Soulbind can tether Ogre Magi to another hero, doubling the damage and disables from his spells."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain can quickly remove Ogre Magi from the fight, as he is mana-reliant but has a limited pool."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate prevents Ogre Magi from using his spells and negates his Multicast potential entirely."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Ogre Magi's powerful Multicast-enhanced spells and use them more effectively."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Fire Spirits reduce Ogre Magi's attack speed significantly, countering the effectiveness of Bloodlust."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify negates Ogre Magi's physical damage, and Nether Ward punishes him for his frequent spell usage."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip can lock Ogre Magi down for a long duration, preventing him from using his tankiness and utility."
          }
        ]
      },
      {
        hero: "Spectre",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mana burn significantly reduces Spectre's ability to survive and cast her abilities."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate ability silences Spectre, preventing her from using Haunt or dispersion effectively."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury and healing ward sustain help his team outlast Spectre in fights."
          },
          {
            hero: "Lion",
            reason: "Lion's burst damage and disable lock down Spectre before she can react."
          }
        ]
      },
      {
        hero: "pugna",
        counterpicks: [
        
        ]
      },
      {
        hero: "Omniknight",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast negates Omniknight's healing from Purification and Guardian Angel, severely reducing his effectiveness."
          },
          {
            hero: "Invoker",
            reason: "Invoker's EMP drains Omniknight's mana pool, limiting his ability to use his spells effectively in fights."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Omniknight from casting his abilities during critical moments, making him unable to save allies."
          },
          {
            hero: "Grimstroke",
            reason: "Grimstroke's Soulbind can chain Omniknight to another ally, limiting his mobility and forcing him into unfavorable positions."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Demonic Purge dispels Omniknight's buffs, including Repel and Guardian Angel, negating his defensive capabilities."
          },
          {
            hero: "Doom",
            reason: "Doom's ultimate ability disables Omniknight completely, preventing him from casting any of his powerful defensive spells."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Nether Ward punishes Omniknight for casting spells, while Decrepify can prevent Omniknight's Purification from dealing damage."
          },
          {
            hero: "Batrider",
            reason: "Batrider's Flaming Lasso pulls Omniknight out of position and away from his teammates, reducing his ability to protect them."
          }
        ]
      },
      {
        hero: "Spirit Breaker",
        counterpicks: [
          {
            hero: "Omniknight",
            reason: "Omniknight's Repel and Guardian Angel protect allies from Spirit Breaker's high physical damage and charge."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Cogs interrupt Spirit Breaker's Charge of Darkness and isolate him from his team."
          },
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's high mobility and Flame Guard mitigate Spirit Breaker's damage and make him hard to lock down."
          },
          {
            hero: "Outworld Destroyer",
            reason: "Outworld Destroyer's Astral Imprisonment disrupts Spirit Breaker's charge and removes him from the fight momentarily."
          }
        ]
      },
      {
        hero: "pigna",
        counterpicks: [
      
        ]
      },
      {
        hero: "Storm Spirit",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Spell Shield counter Storm Spirit's reliance on mana and magic damage."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Storm Spirit, preventing him from escaping or using his abilities."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Storm Spirit's mobility and forces him to fight on Bloodseeker's terms."
          },
          {
            hero: "Night Stalker",
            reason: "Night Stalker's Silence and vision control during nighttime make it difficult for Storm Spirit to escape or initiate."
          }
        ]
      },
      {
        hero: "Sven",
        counterpicks: [
          {
            hero: "rattletrap",
            reason: "Clockwerk's Cogs trap Sven and prevent him from reaching his targets or retreating."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage makes him immune to Sven's stuns and reduces his effectiveness in fights."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify nullifies Sven's physical damage, while Nether Ward punishes his mana usage."
          },
          {
            hero: "Razor",
            reason: "Razor's Static Link drains Sven's damage, significantly weakening him in engagements."
          }
        ]
      },
      {
        hero: "Techies",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield and Borrowed Time help mitigate Techies' mine damage."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility allows him to avoid mines and kill Techies quickly in engagements."
          },
          {
            hero: "Batrider",
            reason: "Batrider's flying vision reveals hidden mines, and his mobility makes him hard to trap."
          },
          {
            hero: "Beastmaster",
            reason: "Beastmaster's summons can trigger mines safely, and his Hawk provides vision to locate traps."
          }
        ]
      },
      {
        hero: "queenofpain",
        counterpicks: [
          
        ]
      },
      {
        hero: "Templar Assassin",
        counterpicks: [
          {
            hero: "Venomancer",
            reason: "Venomancer's damage-over-time abilities remove Templar Assassin's Refraction charges quickly."
          },
          {
            hero: "windrunner",
            reason: "Windranger's Focus Fire ignores Templar Assassin's Refraction, dealing consistent damage."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Fire Spirits and Sun Ray deal damage over time, countering Templar Assassin's defensive capabilities."
          },
          {
            hero: "Batrider",
            reason: "Batrider's sticky napalm and magic damage counter Templar Assassin's mobility and defensive Refraction."
          }
        ]
      },
      {
        hero: "Terrorblade",
        counterpicks: [
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's high physical damage and Frost Arrows kite Terrorblade effectively."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's disables and mobility allow him to disrupt Terrorblade's positioning and illusions."
          },
          {
            hero: "Axe",
            reason: "Axe's Call forces Terrorblade to attack, mitigating his illusion-based damage advantage."
          },
          {
            hero: "Elder Titan",
            reason: "Elder Titan's Astral Spirit and natural order remove Terrorblade's base armor, making him vulnerable."
          }
        ]
      },
      {
        hero: "Tidehunter",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break reduces Tidehunter's mana, preventing him from using Ravage effectively."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Carapace disrupt Tidehunter's initiation and sustainability."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's Exorcism deals consistent damage, which Tidehunter struggles to tank against."
          },
          {
            hero: "Razor",
            reason: "Razor's Static Link steals Tidehunter's damage, weakening his impact in team fights."
          }
        ]
      },
      {
        hero: "shredder",
        counterpicks: [
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip disables Timbersaw for a long duration, countering his mobility."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate prevents Timbersaw from casting his abilities, negating his survivability."
          },
          {
            hero: "Ember Spirit",
            reason: "Ember Spirit's magic damage can quickly deplete Timbersaw's reactive armor stacks."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere can lock Timbersaw in place, allowing his team to focus fire him."
          },
          {
            hero: "Ursa",
            reason: "Ursa's Fury Swipes deal high damage over time, making it hard for Timbersaw to tank."
          }
        ]
      },
      {
        hero: "Tinker",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void deals massive damage to Tinker due to his high mana pool."
          },
          {
            hero: "rattletrap",
            reason: "Clockwerk's Hookshot and Cogs trap Tinker and disrupt his mobility."
          },
          {
            hero: "Dazzle",
            reason: "Dazzle's Shadow Wave can heal allies while damaging Tinker during his burst attempts."
          },
          {
            hero: "doom_bringer",
            reason: "Doom silences Tinker, preventing him from casting his spells and blinking to safety."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Spiked Carapace punish Tinker's reliance on casting spells."
          }
        ]
      },
      {
        hero: "nyx_assassin",
        counterpicks: [
        
        ]
      },
      {
        hero: "Tiny",
        counterpicks: [
          {
            hero: "Bristleback",
            reason: "Bristleback's high durability and Quill Spray counter Tiny's burst damage."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury makes him immune to Tiny's combo, and Omnislash punishes Tiny's low armor."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage grants spell immunity, negating Tiny's initiation."
          },
          {
            hero: "Lion",
            reason: "Lion's crowd control abilities and high burst damage can effectively counter Tiny in fights."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer overwhelms Tiny with illusions, making it hard to focus on the real target."
          }
        ]
      },
      {
        hero: "treant",
        counterpicks: [
          {
            hero: "Monkey King",
            reason: "Monkey King's high mobility and damage output punish Treant's immobility in fights."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions ignore Treant's tankiness and overwhelm him."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Overgrowth, turning Treant's ultimate against his team."
          },
          {
            hero: "Slardar",
            reason: "Slardar's Corrosive Haze reduces Treant's armor, making him vulnerable to physical damage."
          },
          {
            hero: "Sniper",
            reason: "Sniper's long attack range makes it easy to harass Treant without retaliation."
          }
        ]
      },
      {
        hero: "monkey_king",
        counterpicks: [
         
        ]
      },
      {
        hero: "Phantom Lancer",
        counterpicks: [
          {
            hero: "doom_bringer",
            reason: "If Doom uses his ability PL becomes really varnible."
          },
         
        ]
      },
      {
        hero: "Abyssal Underlord",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ice Blast prevents Underlord's high health regeneration from items and his tanky sustain in team fights."
          },
          {
            hero: "Rubick",
            reason: "Rubick can steal Pit of Malice or Firestorm, turning Underlord's powerful area control against his team."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn punishes Underlord for his large mana pool and his reliance on repeated spellcasting."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Duel can isolate Underlord, preventing him from using his utility to aid teammates."
          },
          {
            hero: "Shadow Demon",
            reason: "Shadow Demon's Demonic Purge removes Atrophy Aura's attack reduction, allowing his team to focus fire on Underlord."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage makes him immune to Underlord's magical damage and disables, allowing him to fight uninterrupted."
          },
          {
            hero: "Pugna",
            reason: "Nether Ward punishes Underlord's mana-heavy spells, and Decrepify mitigates his damage output in fights."
          },
          {
            hero: "Templar Assassin",
            reason: "Templar Assassin can burst down Underlord with high physical damage before his tankiness becomes impactful."
          },
          {
            hero: "Invoker",
            reason: "Invoker's Tornado and EMP disrupt Underlord's initiation, depleting his mana and leaving him vulnerable."
          },
          {
            hero: "Outworld Destroyer",
            reason: "Outworld Destroyer's Arcane Orb and Sanity's Eclipse deal significant damage to Underlord due to his high mana pool."
          },
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's high physical damage and ranged attacks allow her to kite Underlord and avoid his area control."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions overwhelm Underlord's limited area damage and his inability to target single enemies effectively."
          }
        ]
      },      
      {
        hero: "Troll Warlord",
        counterpicks: [
          {
            hero: "Shadow Shaman",
            reason: "Shadow Shaman's disables (Hex and Shackles) can lock down Troll Warlord, preventing him from using his ultimate."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions can overwhelm Troll Warlord, making it difficult for him to focus on the real target."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call pierces Troll's BKB and forces him to attack Axe, countering his high attack speed."
          },
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip disables Troll Warlord, making him an easy target during team fights."
          },
          {
            hero: "shredder",
            reason: "Timbersaw's high mobility and pure damage output counter Troll's reliance on physical attacks."
          }
        ]
      },
      {
        hero: "Tusk",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and Mana Void punish Tusk's reliance on his mana-based abilities."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Glimpse can prevent Tusk from saving allies with Snowball or escaping after an engagement."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and stuns can control Tusk and stop his initiation."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Fissure blocks Tusk's Snowball and disrupts his positioning."
          },
          {
            hero: "Lion",
            reason: "Lion's disables and burst damage make him an effective counter to Tusk."
          }
        ]
      },
      {
        hero: "Underlord",
        counterpicks: [
          {
            hero: "Necrophos",
            reason: "Necrophos's Heartstopper Aura and Reaper's Scythe punish Underlord's tanky playstyle."
          },
          {
            hero: "Razor",
            reason: "Razor's Static Link steals Underlord's damage, making him ineffective in fights."
          },
          {
            hero: "Luna",
            reason: "Luna's high damage output and mobility make her difficult for Underlord to control."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Static Storm silences Underlord and prevents him from casting his spells."
          },
          {
            hero: "Invoker",
            reason: "Invoker's Tornado and EMP disrupt Underlord's positioning and deplete his mana."
          }
        ]
      },
      {
        hero: "Undying",
        counterpicks: [
          {
            hero: "Alchemist",
            reason: "Alchemist's high burst damage can quickly deal with Undying's Tombstone and punish his low mobility."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Fissure blocks Undying's movement and prevents him from effectively placing Tombstone."
          },
          {
            hero: "Gyrocopter",
            reason: "Gyrocopter's AoE damage can easily destroy Tombstone and clear zombies."
          },
          {
            hero: "Luna",
            reason: "Luna's Glaives can hit multiple zombies and the Tombstone simultaneously, making her effective against Undying."
          },
          {
            hero: "Phantom Assassin",
            reason: "Phantom Assassin's burst damage and mobility can quickly take down Undying."
          }
        ]
      },
      {
        hero: "phantom_assassin",
        counterpicks: [
          {
            hero: "Alchemist",
            reason: "Alchemist's high burst damage can quickly deal with Undying's Tombstone and punish his low mobility."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Fissure blocks Undying's movement and prevents him from effectively placing Tombstone."
          },
          {
            hero: "Gyrocopter",
            reason: "Gyrocopter's AoE damage can easily destroy Tombstone and clear zombies."
          },
          {
            hero: "Luna",
            reason: "Luna's Glaives can hit multiple zombies and the Tombstone simultaneously, making her effective against Undying."
          },
          {
            hero: "Phantom Assassin",
            reason: "Phantom Assassin's burst damage and mobility can quickly take down Undying."
          }
        ]
      },
      {
        hero: "Ursa",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void and mobility make him difficult for Ursa to lock down."
          },
          {
            hero: "Bane",
            reason: "Bane's disables can hold Ursa in place, preventing him from dealing damage during fights."
          },
          {
            hero: "doom_bringer",
            reason: "Doom silences Ursa, preventing him from using Enrage to survive burst damage."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Mana Drain make Ursa vulnerable during fights."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Decrepify prevents Ursa from attacking, effectively negating his damage output."
          }
        ]
      },
      {
        hero: "vengefulspirit",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and damage output punish Vengeful Spirit's lack of escape mechanisms."
          },
          {
            hero: "Lina",
            reason: "Lina's high burst damage can quickly take down Vengeful Spirit before she can use her abilities."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Mana Burn and Carapace can disrupt Vengeful Spirit's positioning and mana pool."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions overwhelm Vengeful Spirit's ability to single out targets."
          },
          {
            hero: "Sniper",
            reason: "Sniper's range allows him to avoid Vengeful Spirit's initiation and chip her down from afar."
          }
        ]
      },
      {
        hero: "Venomancer",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's mobility and spell resistance make him effective against Venomancer's poison."
          },
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Venomancer's debuffs and protects his team."
          },
          {
            hero: "Huskar",
            reason: "Huskar's magic resistance reduces the effectiveness of Venomancer's damage-over-time spells."
          },
          {
            hero: "Legion Commander",
            reason: "Legion Commander's Press the Attack purges Venomancer's debuffs and counters his slow."
          },
          {
            hero: "Slark",
            reason: "Slark's high mobility and purge mechanic make him effective against Venomancer's slows."
          }
        ]
      },
      {
        hero: "Viper",
        counterpicks: [
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions overwhelm Viper's single-target capabilities, forcing him to divide his attention."
          },
          {
            hero: "Invoker",
            reason: "Invoker's Tornado and EMP deplete Viper's mana and disrupt his positioning."
          },
          {
            hero: "Broodmother",
            reason: "Broodmother's Spiderlings outmaneuver Viper and punish his lack of mobility."
          },
          {
            hero: "Morphling",
            reason: "Morphling's ability to shift stats allows him to survive Viper's damage-over-time abilities."
          },
          {
            hero: "Sniper",
            reason: "Sniper's superior attack range allows him to kite Viper and avoid his corrosive abilities."
          }
        ]
      },
      {
        hero: "Visage",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks down Visage and his Familiars, preventing their high damage output."
          },
          {
            hero: "Phoenix",
            reason: "Phoenix's Fire Spirits and Supernova disrupt Visage's attacks and punish clustered units like Familiars."
          },
          {
            hero: "Luna",
            reason: "Luna's bouncing Glaives clear Visage's Familiars and her Eclipse can quickly burst him down."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Visage and prevents him from using his abilities effectively in fights."
          },
          {
            hero: "Tinker",
            reason: "Tinker’s mobility and burst damage from Heat-Seeking Missiles counter Visage’s slow playstyle."
          }
        ]
      },
      {
        hero: "Void Spirit",
        counterpicks: [
          {
            hero: "Bane",
            reason: "Bane's Fiend's Grip locks down Void Spirit, preventing him from escaping with Dissimilate or Astral Step."
          },
          {
            hero: "doom_bringer",
            reason: "Doom's ultimate silences Void Spirit, making him vulnerable and unable to escape."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Void Spirit's high mobility by forcing him to stay still or take massive damage."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's silences and consistent damage from Exorcism counter Void Spirit's evasive playstyle."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence shuts down Void Spirit’s abilities, making him ineffective in fights."
          }
        ]
      },
      {
        hero: "Warlock",
        counterpicks: [
          {
            hero: "Weaver",
            reason: "Weaver's high mobility allows him to avoid Warlock's Fatal Bonds and chaotic damage during fights."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Void punishes Warlock's reliance on his mana pool and long-cooldown spells."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Thirst counters Warlock's sustain, and Rupture forces him to stay in position."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Warlock from casting Chaotic Offering, rendering him ineffective."
          },
          {
            hero: "Dark Seer",
            reason: "Dark Seer's Vacuum can clump Warlock's team together, making it easier to kill them through Fatal Bonds."
          },
          {
            hero: "Pangolier",
            reason: "Pangolier's mobility and crowd control can disrupt Warlock's setup and prevent effective teamfight execution."
          }
        ]
      },
      {
        hero: "Weaver",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call locks Weaver in place, preventing him from using Shukuchi to escape."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Weaver's mobility and forces him to stay still during fights."
          },
          {
            hero: "Drow Ranger",
            reason: "Drow Ranger's Frost Arrows and high physical damage can kite Weaver effectively."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere can catch Weaver and burst him down before Time Lapse."
          },
          {
            hero: "Lion",
            reason: "Lion's Hex and Finger of Death can eliminate Weaver before he can react with Time Lapse."
          },
          {
            hero: "Sniper",
            reason: "Sniper's range allows him to stay safe while chipping away at Weaver, making him difficult to reach."
          }
        ]
      },
      {
        hero: "windrunner",
        counterpicks: [
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call pierces Windrun, forcing Windranger to attack Axe during Blade Mail."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Windranger's reliance on high mobility during Windrun."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur Warrunner's tankiness and Retaliate punish Windranger's focus on single-target damage."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Carapace stuns Windranger when Focus Fire is active, and Mana Burn disrupts her mana pool."
          },
          {
            hero: "Phantom Assassin",
            reason: "Phantom Assassin's Blur counters Windranger's single-target Focus Fire, making her less effective."
          },
          {
            hero: "Bristleback",
            reason: "Bristleback's tankiness and Quill Spray negate Windranger's ability to kill him quickly with Focus Fire."
          }
        ]
      },
      {
        hero: "Winter Wyvern",
        counterpicks: [
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Winter Wyvern's healing from Cold Embrace, nullifying her sustain."
          },
          {
            hero: "Invoker",
            reason: "Invoker's EMP drains Winter Wyvern's mana, leaving her unable to use her spells effectively."
          },
          {
            hero: "Luna",
            reason: "Luna's high damage output and mobility make her difficult for Winter Wyvern to control in fights."
          },
          {
            hero: "Naga Siren",
            reason: "Naga Siren's Song of the Siren can reset fights and counter Winter's Curse, saving her team."
          },
          {
            hero: "Puck",
            reason: "Puck's high mobility and silences make it hard for Winter Wyvern to position and cast spells effectively."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Winter Wyvern from casting Winter's Curse, neutralizing her impact."
          }
        ]
      },
      {
        hero: "Witch Doctor",
        counterpicks: [
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere isolates Witch Doctor, preventing him from channeling Death Ward effectively."
          },
          {
            hero: "Kunkka",
            reason: "Kunkka's Torrent and Ghostship disrupt Witch Doctor's positioning and make it hard for him to sustain in fights."
          },
          {
            hero: "Lina",
            reason: "Lina's burst damage can eliminate Witch Doctor before he can contribute much to teamfights."
          },
          {
            hero: "Luna",
            reason: "Luna's Eclipse punishes Witch Doctor's low mobility, making him an easy target during fights."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Witch Doctor from casting his channeling spells, rendering him ineffective."
          },
          {
            hero: "Earthshaker",
            reason: "Earthshaker's Fissure and Echo Slam punish Witch Doctor's clustered allies during Death Ward."
          }
        ]
      },
      {
        hero: "zuus",
        counterpicks: [
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break and Mana Void punish Zeus' reliance on his mana pool for consistent damage."
          },
          {
            hero: "Faceless Void",
            reason: "Faceless Void's Chronosphere allows his team to eliminate Zeus before he can deal damage from a safe range."
          },
          {
            hero: "Chaos Knight",
            reason: "Chaos Knight's illusions overwhelm Zeus, who lacks consistent AoE damage to handle them quickly."
          },
          {
            hero: "Juggernaut",
            reason: "Juggernaut's Blade Fury makes him immune to Zeus' magic damage, and Omnislash punishes Zeus' low mobility."
          },
          {
            hero: "life_stealer",
            reason: "Lifestealer's Rage grants magic immunity, nullifying Zeus' primary source of damage."
          },
          {
            hero: "Meepo",
            reason: "Meepo's multiple clones spread out the damage from Zeus' spells, making it hard for him to burst Meepo down."
          },
          {
            hero: "Pudge",
            reason: "Pudge's Hook and Dismember counter Zeus' lack of escape, locking him down for easy kills."
          },
          {
            hero: "Pugna",
            reason: "Pugna's Nether Ward punishes Zeus' frequent spell casting, dealing significant damage over time."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence shuts down Zeus' spells during crucial moments, reducing his impact."
          },
          {
            hero: "Templar Assassin",
            reason: "Templar Assassin's Refraction absorbs Zeus' damage while her mobility allows her to close the gap quickly."
          },
          {
            hero: "Ursa",
            reason: "Ursa's high burst damage and ability to gap close make Zeus an easy target during fights."
          },
          {
            hero: "Viper",
            reason: "Viper's damage-over-time abilities punish Zeus' immobility and prevent him from escaping effectively."
          }
        ]
      },
      {
        hero: "Marci",
        counterpicks: [
          {
            hero: "Dark Willow",
            reason: "Dark Willow's Bramble Maze and Terrorize disrupt Marci's aggressive positioning during fights."
          },
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Marci's mobility, forcing her to stay still and reducing her effectiveness."
          },
          {
            hero: "Centaur Warrunner",
            reason: "Centaur's high durability and Stampede counter Marci's attempts to isolate and burst down targets."
          },
          {
            hero: "Axe",
            reason: "Axe's Berserker's Call punishes Marci's melee aggression, locking her down during fights."
          },
          {
            hero: "Pangolier",
            reason: "Pangolier's Rolling Thunder interrupts Marci's combos and makes her miss key abilities."
          },
          {
            hero: "Silencer",
            reason: "Silencer's Global Silence prevents Marci from using her abilities, reducing her ability to engage or escape."
          }
        ]
      },
      {
        hero: "Pangolier",
        counterpicks: [
        
        ]
      },
      {
        hero: "Primal Beast",
        counterpicks: [
          {
            hero: "Bloodseeker",
            reason: "Bloodseeker's Rupture punishes Primal Beast's charge-based mobility, forcing him to stay in place."
          },
          {
            hero: "Death Prophet",
            reason: "Death Prophet's silences and Spirit Siphon punish Primal Beast's reliance on abilities and high HP pool."
          },
          {
            hero: "Disruptor",
            reason: "Disruptor's Glimpse and Static Storm disrupt Primal Beast's initiation and teamfight control."
          },
          {
            hero: "Earth Spirit",
            reason: "Earth Spirit's silences and stuns counter Primal Beast's aggression and ability to stay in fights."
          },
          {
            hero: "Ursa",
            reason: "Ursa's high damage output and Enrage counter Primal Beast's high durability during close engagements."
          },
          {
            hero: "Invoker",
            reason: "Invoker's Tornado and EMP disrupt Primal Beast's mana-dependent abilities, reducing his impact in fights."
          }
        ]
      },
      {
        hero: "Muerta",
        counterpicks: [
          {
            hero: "Abaddon",
            reason: "Abaddon's Aphotic Shield removes Muerta's debuffs and his healing sustains his team during prolonged fights."
          },
          {
            hero: "Nyx Assassin",
            reason: "Nyx Assassin's Carapace stuns Muerta when her piercing shots hit, and his mana burn disrupts her abilities."
          },
          {
            hero: "Ancient Apparition",
            reason: "Ancient Apparition's Ice Blast prevents Muerta's Lifesteal from being effective during her ult."
          },
          {
            hero: "antimage",
            reason: "Anti-Mage's Mana Break depletes Muerta's mana pool, and his mobility makes him difficult to target."
          },
          {
            hero: "Phantom Lancer",
            reason: "Phantom Lancer's illusions overwhelm Muerta, forcing her to split her damage inefficiently."
          },
          {
            hero: "Clinkz",
            reason: "Clinkz's high burst damage from a distance punishes Muerta's lack of escape options."
          }
        ]
      }
  ];
  