import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ReactQuill from "react-quill";

// Define your interfaces here
interface Player {
  role: string;
  name: string;
  rank: string;
}

interface Team {
  name: string;
  rank: string;
  winrate: string;
  avgKills: string;
  logo: string;
  players: Player[];
}

interface Bookmaker {
  name: string;
  logo: string;
  oddsTeam1: string;
  oddsTeam2: string;
}

interface Dota2MatchPredictionProps {
  team1: Team;
  setTeam1: (team: Team) => void;
  team2: Team;
  setTeam2: (team: Team) => void;
  onGenerateHTML: (htmlString: string) => void; // New prop to pass generated HTML to parent
}

interface Odds {
  team1Name: string;
  team2Name: string;
  team1Logo: string;
  team2Logo: string;
  bookmakers: Bookmaker[];
}

interface TeamInfoProps {
  team: Team;
  onTeamChange: (team: Team) => void;
}

// Reusable BookmakerInfo component
interface BookmakerInfoProps {
  bookmaker: Bookmaker;
  onBookmakerChange: (updatedBookmaker: Bookmaker) => void;
}

const BookmakerInfo: React.FC<BookmakerInfoProps> = ({
  bookmaker,
  onBookmakerChange,
}) => {
  return (
    <div className="row">
      <input
        type="text"
        value={bookmaker.name}
        onChange={(e) =>
          onBookmakerChange({ ...bookmaker, name: e.target.value })
        }
        placeholder="Bookmaker Name"
        className="website"
      />
      <input
        type="text"
        value={bookmaker.logo}
        onChange={(e) =>
          onBookmakerChange({ ...bookmaker, logo: e.target.value })
        }
        placeholder="Logo URL"
        className="website"
      />
      <input
        type="text"
        value={bookmaker.oddsTeam1}
        onChange={(e) =>
          onBookmakerChange({ ...bookmaker, oddsTeam1: e.target.value })
        }
        placeholder="Odds Team 1"
        className="odds"
      />
      <input
        type="text"
        value={bookmaker.oddsTeam2}
        onChange={(e) =>
          onBookmakerChange({ ...bookmaker, oddsTeam2: e.target.value })
        }
        placeholder="Odds Team 2"
        className="odds"
      />
    </div>
  );
};

// TeamInfo component
const TeamInfo: React.FC<TeamInfoProps> = ({ team, onTeamChange }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        gap: "20px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          type="text"
          value={team.name}
          onChange={(e) => onTeamChange({ ...team, name: e.target.value })}
          placeholder="Team Name"
          className="team_name"
        />
        <input
          type="text"
          value={team.rank}
          onChange={(e) => onTeamChange({ ...team, rank: e.target.value })}
          placeholder="World Rank"
          className="team_rank"
        />
        <input
          type="text"
          value={team.logo}
          onChange={(e) => onTeamChange({ ...team, logo: e.target.value })}
          placeholder="Team Logo URL"
          className="team_logo"
        />
      </div>
      <img
        style={{ maxWidth: "5rem", maxHeight: "5rem" }}
        decoding="async"
        src={team.logo}
        alt="Team Logo"
        className="team_logo"
      />
    </div>
  );
};

// ComparisonMetrics component
interface ComparisonMetricsProps {
  team1: Team;
  team2: Team;
  onTeam1Change: (team: Team) => void;
  onTeam2Change: (team: Team) => void;
}

const ComparisonMetrics: React.FC<ComparisonMetricsProps> = ({
  team1,
  team2,
  onTeam1Change,
  onTeam2Change,
}) => {
  return (
    <div className="comparison_metrics">
      <div className="metric_item">
        <div className="metric_name">Winrate</div>
        <input
          type="text"
          value={team1.winrate}
          onChange={(e) => onTeam1Change({ ...team1, winrate: e.target.value })}
          className="metric_value"
        />
        <input
          type="text"
          value={team2.winrate}
          onChange={(e) => onTeam2Change({ ...team2, winrate: e.target.value })}
          className="metric_value"
        />
      </div>
      <div className="metric_item">
        <div className="metric_name">Average Kills</div>
        <input
          type="text"
          value={team1.avgKills}
          onChange={(e) =>
            onTeam1Change({ ...team1, avgKills: e.target.value })
          }
          className="metric_value"
        />
        <input
          type="text"
          value={team2.avgKills}
          onChange={(e) =>
            onTeam2Change({ ...team2, avgKills: e.target.value })
          }
          className="metric_value"
        />
      </div>
    </div>
  );
};

// PlayerList component
interface PlayerListProps {
  team: Team;
  onTeamChange: (team: Team) => void;
}

const PlayerList: React.FC<PlayerListProps> = ({ team, onTeamChange }) => {
  const updatePlayer = (index: number, newPlayer: Player) => {
    const updatedPlayers = team.players.map((player, i) =>
      i === index ? newPlayer : player
    );
    onTeamChange({ ...team, players: updatedPlayers });
  };

  return (
    <div className="players_list">
      <h3>{team.name} Players</h3>
      {team.players.map((player, index) => (
        <div key={index} className="player">
          <input
            type="text"
            value={player.role}
            onChange={(e) =>
              updatePlayer(index, { ...player, role: e.target.value })
            }
            className={`player__role-item role__bg-${index + 1}`}
            placeholder="Role"
          />
          <input
            type="text"
            value={player.name}
            onChange={(e) =>
              updatePlayer(index, { ...player, name: e.target.value })
            }
            className="player__name-name"
            placeholder="Player Name"
          />
          <input
            type="text"
            value={player.rank}
            onChange={(e) =>
              updatePlayer(index, { ...player, rank: e.target.value })
            }
            className="player__rank"
            placeholder="Rank"
          />
        </div>
      ))}
    </div>
  );
};

// OddsTable component
interface OddsTableProps {
  odds: Odds;
  onOddsChange: (updatedOdds: Odds) => void;
  team2: Team;
  team1: Team;
}

const OddsTable: React.FC<OddsTableProps> = ({
  team1,
  team2,
  odds,
  onOddsChange,
}) => {
  const updateBookmaker = (index: number, updatedBookmaker: Bookmaker) => {
    const updatedBookmakers = odds.bookmakers.map((bookmaker, i) =>
      i === index ? updatedBookmaker : bookmaker
    );
    onOddsChange({ ...odds, bookmakers: updatedBookmakers });
  };

  return (
    <div style={{ maxWidth: "70%", marginTop: "2rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "70%",
        }}
      >
        <div>Bookmakers</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              style={{ maxWidth: "1.5rem", maxHeight: "1.5rem" }}
              decoding="async"
              src={team1.logo}
              alt="Team 1 Logo"
            />
            <span>{team1.name}</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              style={{ maxWidth: "1.5rem", maxHeight: "1.5rem" }}
              decoding="async"
              src={team2.logo}
              alt="Team 2 Logo"
            />
            <span>{team2.name}</span>
          </div>
        </div>
      </div>
      {odds.bookmakers.map((bookmaker, index) => (
        <BookmakerInfo
          key={index}
          bookmaker={bookmaker}
          onBookmakerChange={(updatedBookmaker) =>
            updateBookmaker(index, updatedBookmaker)
          }
        />
      ))}
    </div>
  );
};

// Prediction component
interface PredictionProps {
  prediction: string;
  onPredictionChange: (value: string) => void;
  bet: string;
  onBetChange: (value: string) => void;
}

// Main Dota2MatchPrediction component
const Dota2MatchPrediction: React.FC<Dota2MatchPredictionProps> = ({
  team1,
  setTeam1,
  team2,
  setTeam2,
  onGenerateHTML, // Accepting the new prop
}) => {

  const quillRef = useRef<ReactQuill | null>(null);
  const quillGeneratedText = useRef<ReactQuill | null>(null);

  const [prediction, setPrediction] = useState<string>(
    "This match is expected to be a close contest, with both teams showcasing their strengths in different areas."
  );

  const [prediction_prompt, setPredictionPrompt] = useState<string>("");
  const [introduction, setIntroduction] = useState<string>(
    "In this match, we analyze the strengths and weaknesses of both teams to provide a well-rounded prediction."
  );
  const [introduction_prompt, setIntroductionPrompt] = useState<string>("");
  const [loadingPrediction, setLoadingPrediction] = useState<boolean>(false);
  const [loadingIntroduction, setLoadingIntroduction] =
    useState<boolean>(false);

  const [bet, setBet] = useState<string>("10U - MOUZ WINNER");
  const [htmlString, setWrodpressContent] = useState<string>("");


  const [odds, setOdds] = useState<Odds>({
    team1Name: team1.name,
    team2Name: team2.name,
    team1Logo: team1.logo,
    team2Logo: team2.logo,
    bookmakers: [
      {
        name: "10x10bet",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Bet365_Logo.svg/442px-Bet365_Logo.svg.png",
        oddsTeam1: "2.40",
        oddsTeam2: "2.89",
      },
      {
        name: "1xBet",
        logo: "https://upload.wikimedia.org/wikipedia/en/7/72/Pinnacle_Logo.jpeg",
        oddsTeam1: "2.32",
        oddsTeam2: "2.90",
      },
      {
        name: "Alphabet",
        logo: "https://referralcodes.com/storage/359/FarmSkins-380x127.png",
        oddsTeam1: "2.29",
        oddsTeam2: "2.73",
      },
    ],
  });

  const formatText = (text: string) => {
    let formattedText = "";
    let charCount = 0;

    // Split the text into sentences using a regex that captures periods, exclamation marks, and question marks
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text]; // Fallback to the entire text if no sentence is found

    sentences.forEach((sentence, index) => {
      // Insert line break if character count exceeds 200
      if (charCount + sentence.length > 200) {
        formattedText += "<br><br>";
        charCount = 0; // Reset the character count after a line break
      }

      // Apply bold to specific keywords or phrases
      let boldedSentence = sentence
        .replace(/(important|highlighted|key)/gi, "<b>$1</b>") // Example: bold specific words
        .replace(/(Team Falcons|Xtreme Gaming)/gi, "<b>$1</b>"); // Example: bold team names

      formattedText += boldedSentence.trim() + " ";
      charCount += sentence.length + 1; // Update the character count
    });

    return formattedText.trim();
  };

  const handleEditorChange = (html: string) => {
    setPrediction(html);
  };

  const handleEditorIntroduction= (html: string) => {
    setIntroduction(html);
  };

  const handleEditorPrediction = (html: string) => {
    setPrediction(html);
  };


  async function handleWriteIntroduction() {
    setLoadingIntroduction(true);

    const payload = {
      team1: {
        name: team1.name,
        rank: team1.rank,
        winrate: team1.winrate,
        avgKills: team1.avgKills,
        logo: team1.logo,
        players: team1.players, // array of players
      },
      team2: {
        name: team2.name,
        rank: team2.rank,
        winrate: team2.winrate,
        avgKills: team2.avgKills,
        logo: team2.logo,
        players: team2.players, // array of players
      },
      introductionPrompt: introduction_prompt,
    };

    try {
      const response = await fetch(
        "https://server.dotalive.org/generate-dota-introduction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingIntroduction(false);
      setIntroduction(responseData);
    } catch (error) {
      setLoadingIntroduction(false);
      console.log("ERROR Regenerating prompt:", error);
    }
  }
  async function handleWritePrediction() {
    setLoadingPrediction(true);

    const payload = {
      predictionPrompt:
        ".here is the data just for references what we had talked about so far." +
        introduction +
        ". Now that you know the previous content lets write short content around 800 characters that will predict the win. here is a inside of who we will and why "+  prediction_prompt +"but please keep it short and simple",
    };

    try {
      const response = await fetch(
        "https://server.dotalive.org/generate-dota-prediction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingPrediction(false);
      setPrediction(responseData);
    } catch (error) {
      setLoadingPrediction(false);
      console.log("ERROR Regenerating prompt:", error);
    }
  }

  const generateHTML = () => {
    const formattedIntroduction = formatText(introduction);
    const formattedPrediction = formatText(prediction);
    const htmlString = `
    
    <div class="prediction">
    <h2>Introduction</h2>
    <p>${formattedIntroduction}</p>
    </div>
    <div class="comparison_table">
        <div class="comparison_header">
            <div class="team_info">
                <img src="${team1.logo}" alt="Team 1 Logo" class="team_logo">
                <div class="team_name">${team1.name}</div>
                <div class="team_rank">World Rank: ${team1.rank}</div>
            </div>
            <div class="comparison_metrics">
                <div class="metric_title">Team Comparison</div>
                <div class="metric_item">
                    <div class="metric_name">Winrate</div>
                    <div class="metric_value">${team1.winrate}</div>
                    <div class="metric_value">${team2.winrate}</div>
                </div>
                <div class="metric_item">
                    <div class="metric_name">Average Kills</div>
                    <div class="metric_value">${team1.avgKills}</div>
                    <div class="metric_value">${team2.avgKills}</div>
                </div>
            </div>
            <div class="team_info">
                <img src="${team2.logo}" alt="Team 2 Logo" class="team_logo">
                <div class="team_name">${team2.name}</div>
                <div class="team_rank">World Rank: ${team2.rank}</div>
            </div>
        </div>
        <div class="players_comparison">
            <div class="players_list">
                <h3>${team1.name} Players</h3>
                ${team1.players
                  .map(
                    (player) => `
                <div class="player">
                    <div class="player__role-item role__bg-1">${player.role}</div>
                    <div class="player__name-name">${player.name}</div>
                    <div class="player__rank">Rank: ${player.rank}</div>
                </div>`
                  )
                  .join("")}
            </div>
            <div class="players_list">
                <h3>${team2.name} Players</h3>
                ${team2.players
                  .map(
                    (player) => `
                <div class="player">
                    <div class="player__role-item role__bg-2">${player.role}</div>
                    <div class="player__name-name">${player.name}</div>
                    <div class="player__rank">Rank: ${player.rank}</div>
                </div>`
                  )
                  .join("")}
            </div>
        </div>
    </div>

    <div class="odds_table">
        <div class="header">
            <div>Bookmakers</div>
            <div class="team_info">
                <img src="${team1.logo}" alt="Team 1 Logo">
                <span>${team1.name}</span>
            </div>
            <div class="team_info">
                <img src="${team2.logo}" alt="Team 2 Logo">
                <span>${team2.name}</span>
            </div>
        </div>
        ${odds.bookmakers
          .map(
            (bookmaker) => `
        <div class="row">
            <div class="website">
                <img src="${bookmaker.logo}" alt="${bookmaker.name} Logo">
                <span>${bookmaker.name}</span>
                <div class="bonus">BONUS</div>
            </div>
            <div class="odds">
                <span class="green">${bookmaker.oddsTeam1}</span>
            </div>
            <div class="odds">
                <span class="red">${bookmaker.oddsTeam2}</span>
            </div>
        </div>`
          )
          .join("")}
    </div>

    <div class="prediction">
        <h2>Prediction</h2>
        <p>${formattedPrediction}</p>
        <h3 class="bet_text">BET: ${bet}</h3>
    </div>
    `;
    console.log(htmlString);
    setWrodpressContent(htmlString)
    onGenerateHTML(htmlString);
    // navigator.clipboard
    //   .writeText(htmlString)
    //   .then(() => {
    //     alert("HTML has been copied to the clipboard!");
    //   })
    //   .catch((err) => {
    //     console.error("Could not copy text: ", err);
    //   });
  };



  return (
    <div>
      <div>
        <h2>
          {
            "Write introduction ( Add below usefull information so the text can be more unique )"
          }
        </h2>
        <TextField
          label="Additional unique information"
          value={introduction_prompt}
          style={{ minWidth: "100%", marginBottom: "20px" }}
          onChange={(e) => setIntroductionPrompt(e.target.value)}
          className="introduction_text"
        />
        <Button
          style={{
            minWidth: "7rem",
            marginBottom: "1rem",
          }}
          variant="contained"
          color="primary"
          onClick={handleWriteIntroduction}
        >
          {loadingIntroduction ? "Writing..." : "Write Introduction"}
        </Button>
        <ReactQuill
          ref={quillGeneratedText}
          value={introduction}
          onChange={handleEditorIntroduction}
          theme="snow"
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
        />
      </div>
      <div className="comparison_table">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "5rem",
          }}
        >
          <TeamInfo team={team1} onTeamChange={setTeam1} />
          <ComparisonMetrics
            team1={team1}
            team2={team2}
            onTeam1Change={setTeam1}
            onTeam2Change={setTeam2}
          />
          <TeamInfo team={team2} onTeamChange={setTeam2} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <PlayerList team={team1} onTeamChange={setTeam1} />
          <PlayerList team={team2} onTeamChange={setTeam2} />
        </div>
      </div>
      <OddsTable
        odds={odds}
        team1={team1}
        team2={team2}
        onOddsChange={setOdds}
      />
      <div className="prediction">
        <h2>Prediction</h2>
        {/* <textarea
        value={prediction}
        style={{minWidth:'100%'}}
        onChange={(e) => onPredictionChange(e.target.value)}
        className="prediction_text"
      /> */}
        {/* <ReactQuill
          ref={quillRef}
          value={prediction}
          onChange={handleEditorChange}
          theme="snow"
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
        /> */}

        <TextField
          label="Write you will win and how"
          value={prediction_prompt}
          style={{ minWidth: "100%", marginBottom: "20px" }}
          onChange={(e) => setPredictionPrompt(e.target.value)}
          className="introduction_text"
        />
        <Button
          style={{
            minWidth: "7rem",
            marginBottom: "1rem",
          }}
          variant="contained"
          color="primary"
          onClick={handleWritePrediction}
        >
          {loadingPrediction ? "Writing..." : "Write Prediction"}
        </Button>
        <ReactQuill
          ref={quillRef}
          value={prediction}
          theme="snow"
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={handleEditorPrediction}

        />
        <h3>BET:</h3>
        <input
          type="text"
          value={bet}
          onChange={(e) => setBet(e.target.value)}
          className="bet_text"
        />
      </div>
      <button onClick={generateHTML}>Generate HTML</button>
    </div>
  );
};

export default Dota2MatchPrediction;
