import React, {
  useState,
  ChangeEvent,
  CSSProperties,
  useRef,
  useEffect,
  Component,
} from "react";
import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { HexColorPicker } from "react-colorful";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImageEditor, { ImageEditorHandle } from "./ImageEditor.tsx";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { ContentCopy as CopyIcon, Edit } from "@mui/icons-material";
import ReactQuill from "react-quill";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { StringLiteral, createNoSubstitutionTemplateLiteral } from "typescript";
import { all } from "axios";
import "./../styles/SoldMagazine.css";
import { DatePicker } from "material-ui";
import Dota2MatchPrediction from "./Dota2MatchPrediction.tsx";
import { teams } from "./Teamlist.tsx"; // Import the teams

interface TableRowData {
  featuredImage: string;
  articleUrl: string;
  images: any;
  liveLink: string;
  featured_image: string;
  instagram_img: string;
  instagram_text: string;
  title: string;
  text_color: string;
  story_img: string;
  scheduled_time: string;
  post_state: string;
  sneakerWidgetHTML: string;
  sneakerTableHTML: string;
  additonalHTML: string;
  articleContent: string;
}

interface PostData {
  id: number;
  title: string;
  link: string;
  date: string;
}

interface SneakerInfo {
  name: string;
  sku: string;
  price: string;
  date: string;
}

interface Product {
  id: string;
  featuredImage: string;
  productName: string;
  originalPrice: string;
  discountedPrice: string;
  sizes: string[];
  buyLink: string;
}

interface ImageData {
  url: string;
  source: string;
  altText: string;
}

interface ProductCardProps {
  uuid: string;
  buttonLabel: string;
  imageUrl: string;
  title: string;
  description: string;
  alertMessage: string;
  price: string;
  buttonText: string;
  affiliateUrl: string;
  onUpdate: (uuid: string, data: any) => void;
}

interface ArticleComponent {
  uuid: string; // Unique identifier
  type:
    | "ProductCard"
    | "SneakerWidget"
    | "ReactQuill"
    | "Image"
    | "Table"
    | "InstagramFeed";
  data: any; // You can define a more specific type based on your component's props
}

interface TableProduct {
  uuid: string;
  url: string;
  name: string;
  description: string;
  button_text: string;
  button_url: string;
}

const initialProducts: TableProduct[] = [
  {
    uuid: uuidv4(),
    url: "https://m.media-amazon.com/images/I/61SUj2aKoEL._AC_SX522_.jpg",
    name: "Apple AirPods (2nd Generation) Wireless Ear Buds",
    description:
      "HIGH-QUALITY SOUND — Powered by the Apple H1 headphone chip, AirPods (2nd generation) deliver rich, vivid sound.",
    button_text: "View at Amazon",
    button_url:
      "https://www.amazon.com/dp/B07PXGQC1Q?ref=ods_ucc_kindle_B07PXGQC1Q_nrc_ucc",
  },
];
interface Player {
  role: string;
  name: string;
  rank: string;
}
interface Team {
  name: string;
  rank: string;
  winrate: string;
  avgKills: string;
  logo: string;
  players: Player[];
}

const Dotalive: React.FC = () => {
  const [team1, setTeam1] = useState<Team>(teams[0]);
  const [team2, setTeam2] = useState<Team>(teams[1]);
  const handleTeamChange = (index: number, teamName: string) => {
    const selectedTeam = teams.find((team) => team.name === teamName);
    if (selectedTeam) {
      if (index === 1) setTeam1(selectedTeam);
      else setTeam2(selectedTeam);
    }
  };
  const [createInstagramStory, setCreateInstagramStory] = useState(false);

  const [createInstagramPost, setCreateInstagramPost] = useState(false);
  const [createWordpressPage, setCreateWordpressPage] = useState(false);
  const [writeNormalPost, setWriteNormalPost] = useState(false);

  
  const [open, setOpen] = useState(false);
  const [instagramPostPublish, setInstagramPostPublish] = useState(false);
  const [instagramStoryPublish, setInstagramStoryPublish] = useState(false);
  const [promotionImage, setPromotionImage] = useState(true);

  
  const [imgLink, setImgLink] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [altText, setAltText] = useState<string>("");
  const [imageInstagramStoryDate, setImageInstagramStoryDate] = useState<string>("");
  const [imageInstagramPostDate, setImageInstagramPostDate] = useState<string>("");
  const [postContent, setPostContent] = useState<string>("");

  
   
  // Article Content
  const [fullArticleContent, setFullArticleContent] = useState<
    ArticleComponent[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [insertIndex, setInsertIndex] = useState<number | null>(null); // Track where to insert

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setInsertIndex(null); // Reset the insert index
  };

  const handleUpdateComponent = (uuid: string, data: any) => {
    setFullArticleContent((prevContent) =>
      prevContent.map((component) =>
        component.uuid === uuid ? { ...component, data } : component
      )
    );
  };
  const [editingComponentUuid, setEditingComponentUuid] = useState<string>("");

  const handleEditorChange = (html: string, uuid: string) => {
    setFullArticleContent((prevContent) =>
      prevContent.map((component) =>
        component.uuid === uuid ? { ...component, data: html } : component
      )
    );
  };

  const handleImageFieldChange = (
    field: string,
    value: string,
    uuid: string
  ) => {
    setFullArticleContent((prevComponents) => {
      return prevComponents.map((component) => {
        if (component.uuid === uuid) {
          return {
            ...component,
            data: {
              ...component.data,
              [field]: value, // Updates the specified field within the data object
            },
          };
        }
        return component;
      });
    });
  };

  const [addedImgs, setAddedImgs] = useState<ImageData[]>([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editProduct, setEditProduct] = useState<Product | null>(null);

  const handleClose = () => {
    setOpen(false);
    setImgLink("");
    setSource("");
    setAltText("");
  };

  const handleSaveImage = () => {
    if (imgLink.trim() && source.trim() && altText.trim()) {
      setAddedImgs([
        ...addedImgs,
        { url: imgLink.trim(), source: source.trim(), altText: altText.trim() },
      ]);
      handleClose();
    }
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setEditProduct(null);
  };

  const handleEditSave = () => {
    if (editProduct) {
      let additional = false;
      let updatedWidgets = sneakerWidgets.map((widget) => {
        if (widget.id === editProduct.id) {
          additional = true;
          return editProduct;
        } else {
          return widget;
        }
      });
      if (!additional) {
        updatedWidgets = additionalWidget.map((widget) =>
          widget.id === editProduct.id ? editProduct : widget
        );
      } else {
        setSneakerWidgets(updatedWidgets);
        handleEditClose();
        return;
      }
      setAddintionalWidgets(updatedWidgets);
      handleEditClose();
    }
  };

  const [rows, setRows] = useState<TableRowData[]>([
    {
      featuredImage: "",
      articleUrl: "",
      images: "",
      title: "",
      liveLink: "",
      featured_image: "",
      instagram_img: "",
      story_img: "",
      text_color: "",
      instagram_text: "",
      scheduled_time: "",
      post_state: "true",
      sneakerWidgetHTML: "",
      sneakerTableHTML: "",
      additonalHTML: "",
      articleContent: "",
    },
  ]);

  const [postState, setPostState] = useState(true);
  const [posts, setPosts] = useState<PostData[]>([]);

  useEffect(() => {
    console.log(fullArticleContent);
  }, [fullArticleContent]);
  const [value, setValue] = useState(dayjs());

  const generateHTML = () => {
    const widgetHtml = sneakerWidgets
      .map((product) => {
        const sizes = product.sizes
          .map(
            (size) => `
            <span style="
              color: #999;
              display: inline-block;
              padding: 5px 10px;
              margin: 2px;
              border: 1px solid #ccc;
              border-radius: 5px;
            ">${size}</span>`
          )
          .join("");

        return `
        
          <div style="
            border: 1px solid #ccc;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            display: flex;
            margin-top:30px;
            flex-direction: row;
            width: 100%;
          ">
            <div style="flex: 1; max-height: 314px; max-width: 314px; min-height: 314px; display: flex; padding-left:30px; flex-direction: column; justify-content: center; align-items: center;">
              <img src="${product.featuredImage}" alt="${product.productName}" style="width: 100%; height: 100%; object-fit: contain;" />
            </div>
            <div style="flex: 1; padding: 15px;">
              <p style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">
                ${product.productName}
              </p>
              <p style="font-size: 16px; color: #000000; margin-bottom: 10px;">
                <span style="
                  color: #999;
                  margin-right: 10px;
                  text-decoration: line-through;
                ">${product.originalPrice}</span>
                ${product.discountedPrice}
              </p>
              <p style="font-size: 14px; margin-bottom: 10px;">
                ${sizes}
              </p>
              <div style="text-align: center;">
                <a
                  style="
                    background-color: #000000;
                    color: white;
                    text-decoration: none;
                    padding: 10px;
                    border-radius: 5px;
                    display: inline-block;
                    text-align: left;
                    transition: background-color 0.3s ease;
                    cursor: pointer;
                  "
                  href="${product.buyLink}"
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>`;
      })
      .join("");

    const finalHtml = ` <hr/> 

      <div class="generated_html">
        <div id="sneaker_widget" style="display: grid; gap: 30px; flex-direction: none; width: 100%;">
          ${widgetHtml}
        </div>
        <style>
          #sneaker_widget {
            grid-template-columns: repeat(2, 1fr);
          }
          .product_container {
            display: flex;
            width: 100%;
            align-items: center; 
            padding: 10px; 
            position: relative;
          }
          @media (max-width: 768px) {
            #sneaker_widget {
              grid-template-columns: none;
            }
            .generated_html > div {
              flex-direction: column !important;
              width: 100% !important;
            }
            .generated_html h3 {
              font-size: 16px !important;
            }
            .responsive-text {
              font-size: 20px; /* Default font size for desktop */
            }
            
            @media (max-width: 768px) {
              .responsive-text {
                font-size: 16px; /* Smaller font size for mobile */
              }
            }
            .product_container {
              display: flex;
              width: 100%;
              align-items: center; 
              padding: 10px; 
              position: relative;
              flex-direction:column;
            }
          }
        </style>
      </div>
    `;

    console.log(finalHtml);

    const htmlContainer = document.querySelector(".generated_html");
    if (htmlContainer) {
      htmlContainer.innerHTML = finalHtml;
    }
  };

  const [sneakerInfo, setSneakerInfo] = useState<SneakerInfo>({
    name: "Best Apple Deals Right Now",
    sku: "HM8965-001",
    price: "$170",
    date: "Jul 27, 2024",
  });

  const [editFieldSneakerInfo, setEditFieldSneakerInfo] = useState<
    keyof SneakerInfo | null
  >(null);
  const [editValueSneakerInfo, setEditValueSneakerInfo] = useState<string>("");

  const [loading, SetLoading] = useState(false);
  const [loadingStories, SetLoadingStories] = useState(false);

  const [loadingUploading, SetLoadingUploading] = useState(false);

  const [loadingUploadingPost, setLoadingUploadingPost] = useState(false);
  const [loadingUploadingStory, setLoadingUploadingStory] = useState(false);
  const [loadingUploadingPage, setLoadingUploadingPage] = useState(false);


  interface RowData {
    name: string;
    url: string;
    text_url: string;
  }

  async function generateSneakerTableHTML(rows: RowData[]) {
    const tableStyle = `
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    `;

    const thStyle = `
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #000000;
      color: black;
      border: 1px solid #000000;
    `;

    const tdStyle = `
      padding: 10px;
      border: 1px solid #000000;
    `;

    return `
    <div style=" marginTop: '30px' ">
      <div >
        <p style="margin-top:30px; font-size:20px; font-weight:'bold';">
          <strong>${sneakerInfo.name} </strong>
        </p>
      </div>
      <table style="${tableStyle}">
        <thead>
          <tr>
            <th style="${thStyle}">Product</th>
            <th style="${thStyle}"></th>
          </tr>
        </thead>
        <tbody>
          ${rows
            .map(
              (row) => `
            <tr>
              <td style="${tdStyle}"><a href="${row.url}" target="_blank">${row.name}</a></td>
              <td style="${tdStyle}"><a href="${row.url}" target="_blank">${row.text_url}</a></td>
            </tr>
          `
            )
            .join("")}
        </tbody>
      </table>
    `;
  }

  const [sneakerWidgets, setSneakerWidgets] = useState([
    {
      id: "afc62d0f-2a15-4544-8af2-acbed68d18ee",
      featuredImage:
        "https://m.media-amazon.com/images/I/71kVngS30JL._AC_SX575_.jpg",
      productName: "Jordan Youth Air 4 Retro GS University Blue",
      originalPrice: "$397.00",
      discountedPrice: "$347.00",
      sizes: ["Available"],
      buyLink:
        "https://www.amazon.com/Jordan-Youth-Retro-408452-University/dp/B091BCCR4W/ref=sr_1_47?crid=1ZJ7XYWI19N3&dib=eyJ2IjoiMSJ9.8U_-IgqHE6YKAOaWHSIRIjcCLV1QqNwKrpD8Mw0sVIkDAl0Fs4KWXL6KMyWT0WN8R0Lsvvjc3kF7CpeCUtjUSn7KKHBAZXDZCaav7j2Qe2ljy5iO7wE6xal4y2ViDoLCoPI2OyKwTIlssvP1HvnuHKY0SonoYKBw_kRrXIx1tmEqyguCSb4MChDLESN3R2dZ9MIVQD--Ro8eGGvwPp1ChjvV0xMnQMkJzSd1ogO3LMS88SSNp27VX4E-Px6MVqGZl9bb-y6aEk5opNCd1LQnkk4nWwG1-hHhF8SR5dwlipE.wwIG6KgBuJEqkLP3BxnHFdsQaP4PLHEhN4OpiQhqteY&dib_tag=se&keywords=jordan%2B4&qid=1722288945&sprefix=jordan%2B4%2Caps%2C309&sr=8-47&th=1",
    },
    {
      id: "1fc62d0f-2a15-4544-8af2-acbed68d18ee",
      featuredImage:
        "https://m.media-amazon.com/images/I/71+tR11-VbL._AC_SX575_.jpg",
      productName: "Nike Air Jordan 1 High OG Travis Scott Dark Mocha",
      originalPrice: "$3,245.00",
      discountedPrice: "$2,845.00",
      sizes: ["Available"],
      buyLink:
        "https://www.amazon.com/Jordan-Mens-CD4487-Travis-Scott/dp/B07RN27FFN/ref=sr_1_19?crid=2YBJR3WIG6MX5&dib=eyJ2IjoiMSJ9.iASAzAu14OIaSw2DkdugorpXL0GmfPd9UDLXR5h0CYbxmc12cfC_BfzaNiUm0VxgemhJzMmqIO2MeU0zpxai55oLAUfSNydMgQKsvnkvkgUjBLCrDOy5s3_JYWGjHRVZisqZEF1Pww0KhSJppBOquR55ZOkSd5ytGg72ToxRwib2Mt3_i9xBB-viGWZKo-Mt0x2FxJx6hM_EO_tmK3237Le1qElCAKrGhumKhrjwBkdGUHvm-6LXloL7hNeW4uuRwV8u-guR-91xUus1WdvKaX6FXdTyui9wFTRzeWcrjxo.FnPiXIq0OxKDLTHtWP5IOANQGPjMGdBaoQjU6E14srA&dib_tag=se&keywords=jordan+1+mocha&qid=1722289238&sprefix=jordan+1+moch%2Caps%2C186&sr=8-19",
    },
    {
      id: "2fc62d0f-2a15-4544-8af2-acbed68d18ee",
      featuredImage:
        "https://m.media-amazon.com/images/I/51AEeRJmplL._AC_SX575_.jpg",
      productName: "Air Jordan 1 Retro High Chicago Lost and Found",
      originalPrice: "$720.00",
      discountedPrice: "$548.32",
      sizes: ["Available"],
      buyLink:
        "https://www.amazon.com/Jordan-Retro-DZ5485-Chicago-Found/dp/B0BMDZYN9D/ref=sr_1_14?crid=2OQNOCTC9TGRR&dib=eyJ2IjoiMSJ9._JKiSygzfN9JAYa2f7wTwM8vQ9OIkNjFhavgE0aQtZkTP6kBf6zgYb43Z2dNLmqGDf8QwQ9UVug3yu2d0_MZxYVULRQN8ZTEJTyWS8HQhqTPD-2fPyghdTKTpLRnUcmUsfy9N_051lbYzlBinKB9fmH7MMalp5R7tWmhdTBAbBSsl1fLAbEOeAQZS6e693FAO8dqkGcx5JhjPf6fJ06XyGZ_e-MqLmYE24RNqoTCMtzWdssQ06ay6Z8qACPYgL2IzEimSroybBSW9nQGQrbf7S_lNfQ9ce2ed4sOHh3fxfA.gzFunD980U_kcLxIRrKXckOo9IEbo2-4c9ZGt8nm-Ko&dib_tag=se&keywords=retro+ones+jordans&qid=1722289391&sr=8-14",
    },
    {
      id: "3fc62d0f-2a15-4544-8af2-acbed68d18ee",
      featuredImage:
        "https://m.media-amazon.com/images/I/71VFNqWqXmL._AC_SX575_.jpg",
      productName: "SB Dunk Low x Ben & Jerry's - Nike",
      originalPrice: "$2,765.00",
      discountedPrice: "$2,465.00",
      sizes: ["Available"],
      buyLink:
        "https://www.amazon.com/Nike-Mens-Dunk-CU3244-Jerrys/dp/B08WF7J5GD/ref=sr_1_31?crid=1ND5D1QTF3QTE&dib=eyJ2IjoiMSJ9.ty7ukfCrxnMC4DxUukNqKL7wH-KVMOCpH3EbWbOZ4AVw9PvRtSzZTAYcWfaI_iuT73xvciar3tWcVQlmDm897amMPQh5BGXP9m_-Pk0WibBDq2J96B3TuBlEeJZwdgrVzx8wnCYn6w_DLkbitw_zw42jP1JGePxIOp8Wqv2RRiUFmdwHTRwMVS4ePqzcR3nDpSzurvWTPp8C-4DSA0EkW_MHNqhxAjGuQvDv6_VBiAfUa_ohUjS3ybQRCeCrE-UqyYoOXuCRG1PTJ3DerIFsMUBcY12RDgQyI03hz4z1JEc.JBaxW74fypWh0paSrAxV1hbEoj8DsQxs8kM1iujUS74&dib_tag=se&keywords=nike+dunks+limited&qid=1722289536&sprefix=nike+dunks+limited%2Caps%2C189&sr=8-31",
    },
  ]);

  const [additionalWidget, setAddintionalWidgets] = useState<Product[]>([]);

  const addSneakerWidget = () => {
    setSneakerWidgets([
      ...sneakerWidgets,
      {
        id: uuidv4(),
        featuredImage:
          "https://m.media-amazon.com/images/I/81uHhPfJhhL._AC_SX679_.jpg",
        productName: "iPhone 15 Pro",
        originalPrice: "$85.00",
        discountedPrice: "$72.97",
        sizes: ["All"],
        buyLink:
          "https://www.amazon.com/Apple-iPhone-256GB-Blue-Titanium/dp/B0CK55SJXR/ref=sr_1_1?crid=CBU0X0UNVVMK&dib=eyJ2IjoiMSJ9.0xA3TnhAfpSpcTSz2vdQK0D2Hfj8336h3Pl9ZPX9HKaTF4eOL9WWGnJYFlew7i-ccGQBmqxiL9iXbvJPPMf_7Tqx_v8OBulnGtQIAkiy7Ag_P3DxOYUhpIUb6tTYodXOS1Kon2bDekoZlcE3vqZk0v-UgJFY5TfwNCHpz6SIaM6l2BBWPeNDNFQW_1FSVxek0NFT1eV38IYj21B7Z1Sg8PI9j82MaiEAW1f43dYW7u0.mfZHiVsncMJxlBKEU1c7XAAEpKDyUY68ppjzP3oEMy8&dib_tag=se&keywords=iphone+15&qid=1721563402&sprefix=iphone+%2Caps%2C189&sr=8-1",
      },
    ]);
  };
  const addAdditionalWidget = () => {
    setAddintionalWidgets([
      ...additionalWidget,
      {
        id: uuidv4(),
        featuredImage:
          "https://m.media-amazon.com/images/I/81uHhPfJhhL._AC_SX679_.jpg",
        productName: "iPhone 15 Pro",
        originalPrice: "$85.00",
        discountedPrice: "$72.97",
        sizes: ["All"],
        buyLink:
          "https://www.amazon.com/Apple-iPhone-256GB-Blue-Titanium/dp/B0CK55SJXR/ref=sr_1_1?crid=CBU0X0UNVVMK&dib=eyJ2IjoiMSJ9.0xA3TnhAfpSpcTSz2vdQK0D2Hfj8336h3Pl9ZPX9HKaTF4eOL9WWGnJYFlew7i-ccGQBmqxiL9iXbvJPPMf_7Tqx_v8OBulnGtQIAkiy7Ag_P3DxOYUhpIUb6tTYodXOS1Kon2bDekoZlcE3vqZk0v-UgJFY5TfwNCHpz6SIaM6l2BBWPeNDNFQW_1FSVxek0NFT1eV38IYj21B7Z1Sg8PI9j82MaiEAW1f43dYW7u0.mfZHiVsncMJxlBKEU1c7XAAEpKDyUY68ppjzP3oEMy8&dib_tag=se&keywords=iphone+15&qid=1721563402&sprefix=iphone+%2Caps%2C189&sr=8-1",
      },
    ]);
  };

  const deleteSneakerWidget = (id: string) => {
    setSneakerWidgets(sneakerWidgets.filter((widget) => widget.id !== id));
  };
  const deleteAdditionalWidget = (id: string) => {
    setAddintionalWidgets(
      additionalWidget.filter((widget) => widget.id !== id)
    );
  };

  const generateStories = async () => {
    console.log(rows);
    SetLoadingStories(true);
    try {
      const response = await fetch(
        "https://server.dotalive.org/generate-stories-soldmagazine",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rows.filter((row) => row.liveLink.length > 0)),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save articles");
      }

      const responseData = await response.json();

      const updatedRows = rows.map((row) => {
        const responseEntry = responseData.find(
          (entry: any) => entry.articleUrl === row.articleUrl
        );
        if (responseEntry) {
          return {
            ...row,
            instagram_img: responseEntry.instagram_img,
            story_img: responseEntry.story_img,
          };
        }
        return row;
      });

      setRows(updatedRows);
      console.log("Articles saved successfully");
      SetLoadingStories(false);
    } catch (error) {
      SetLoadingStories(false);
      console.error("Error saving articles:", error);
    }
  };

  const uploadOnSocial = async () => {
    try {
      SetLoadingUploading(true);
      const response = await fetch(
        "https://featured-image.wecon-digital.agency/upload-social",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rows.filter((row) => row.liveLink.length > 0)),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save articles");
      }

      const responseData = await response.json();
      console.log("Articles saved successfully");
      SetLoadingUploading(false);
    } catch (error) {
      SetLoadingUploading(false);
      console.error("Error saving articles:", error);
    }
  };

  const getLatestPosts = async () => {
    try {
      const response = await fetch(
        "https://www.soldmagazine.mk/wp-json/wp/v2/posts"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch latest posts");
      }
      const data = await response.json();
      const postsData = data.map((post: any) => ({
        id: post.id,
        title: post.title.rendered,
        link: post.link,
        date: post.date,
      }));
      setPosts(postsData);
    } catch (error) {
      console.error("Error fetching latest posts:", error);
    }
  };

  const [tableRows, setTableRows] = useState<RowData[]>([
    { name: "Air Pods", url: "https://bit.ly/3hYsZCC", text_url: "Best Deals" },
    {
      name: "Apple Vision Pro",
      url: "https://bit.ly/46VZCJj",
      text_url: "Best Deals",
    },
    { name: "Apple Tv", url: "https://bit.ly/3s6feX7", text_url: "Best Deals" },
  ]);

  const containerStyle =
    sneakerWidgets.length === 1
      ? { display: "flex", flexDirection: "column", width: "100%" }
      : {
          display: "flex",
          flexDirection: "row",
          gap: "30px",
          flexWrap: "wrap",
        };

  const widgetStyle =
    sneakerWidgets.length === 1
      ? { width: "100%" }
      : { width: "calc(50% - 10px)" };

  const imageEditorRef = useRef<ImageEditorHandle>(null);
  const imageInstagramPost = useRef<ImageEditorHandle>(null);
  const imageInstagramStory = useRef<ImageEditorHandle>(null);


  async function handleCreateImageOnServer() {
    if (imageEditorRef.current) {
      imageEditorRef.current.createImageOnServer();
    }
  }
  const getImageEditorPayload = () => {
    if (imageEditorRef.current) {
      const payload = imageEditorRef.current.generatePayload(); // Call the method from the child component
      console.log("Image Editor Payload:", payload);
      // You can now use this payload as needed
    }
  };
  async function handleGenerateAllContent() {
    let content = ``;
    for (let i = 0; i < fullArticleContent.length; i++) {
      console.log(">>>>>>>>>>>>", fullArticleContent[i]);
      //
      switch (fullArticleContent[i].type) {
        case "ReactQuill": {
          content +=
            `<div style="max-width: 100%; word-wrap: break-word; overflow-wrap: break-word;">` +
            fullArticleContent[i].data +
            `</div>`;
          break;
        }
        case "ProductCard": {
          let product = `<div style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); border-radius: 10px; background-color: white; display: flex; justify-content: center; margin-top: 50px; margin-bottom: 50px;">
                <div class="product_container">
                    <button style="position: absolute; background-color: #000000; color: white; border: none; padding: 5px 10px; cursor: pointer; border-radius: 10px; top: 20px; left: 10px;">${fullArticleContent[i].data.buttonLabel}</button>
                    <div style="flex: 1; display: flex; align-items: center; justify-content: center;">
                        <img src="${fullArticleContent[i].data.imageUrl}" alt="Cupcake" style="width: 41%; height: auto; object-fit: cover;">
                    </div>
                    <div style="flex: 1; padding: 0px 20px; display: flex; flex-direction: column; justify-content: center;">
                        <h1 style="margin: 0; font-size: 24px; color: #333;">${fullArticleContent[i].data.title}</h1>
                        <p style="font-size: 14px; color: #777; margin: 10px 0;">${fullArticleContent[i].data.description}</p>
                        <div style="background-color: #ffcccc; color: #d9534f; padding: 10px; border-radius: 5px; margin: 10px 0;">${fullArticleContent[i].data.alertMessage}</div>
                        <div style="display: flex; align-items: center; margin: 10px 0;">
                            <span style="font-size: 24px; color: #333; margin-right: 20px;">${fullArticleContent[i].data.price}</span>
                        </div>
                        <button onclick="window.open('${fullArticleContent[i].data.affiliateUrl}', '_blank')" style="background-color: #000000; color: white; min-width:100%; border: none; padding: 10px; cursor: pointer; border-radius: 5px; font-size: 16px;">${fullArticleContent[i].data.buttonText}</button>          </div>
                </div>
              </div>`;
          content = content + product;

          break;
        }
        case "Image": {
          let image = `<div style="display: flex; flex-direction: column; gap: 20px; width: 100%;"><div key="{{index}}" style="display: flex; align-items: center; flex-direction: column;"><div><img src="${
            fullArticleContent[i].data.url
          }" alt="${
            fullArticleContent[i].data.alt_text
          }" style="width: auto; max-height: 700px;"></div><div style="display: flex; flex-direction: row; justify-content: center; min-width: 700px;">Source: ${String(
            fullArticleContent[i].data.source
          ).toLowerCase()}</div></div></div>`;
          content = content + image;
          break;
        }
        case "Table": {
          let full_products = ``;
          for (
            let j = 0;
            j < fullArticleContent[i].data.tableProducts.length;
            j++
          ) {
            full_products += `
              <div class="product-table-row"><div class="product-table-cell">
              <a href="${fullArticleContent[i].data.tableProducts[j].button_url}" target="_blank">
              <img src="${fullArticleContent[i].data.tableProducts[j].url}" alt="${fullArticleContent[i].data.tableProducts[j].name}" style="max-width: 100px; height: auto; padding-left: 1rem;"></a>
              </div><div class="product-table-cell"><div class="product_details_milddle">
              <a href="${fullArticleContent[i].data.tableProducts[j].button_url}" target="_blank"> <span class="product-title">${fullArticleContent[i].data.tableProducts[j].name}</span></a> <a href="${fullArticleContent[i].data.tableProducts[j].button_url}" target="_blank"><span class="product-description">${fullArticleContent[i].data.tableProducts[j].description}</span></a>
              </div></div>
              <div class="product-table-cell_button">
              <a href="${fullArticleContent[i].data.tableProducts[j].button_url}" class="btn" target="_blank">${fullArticleContent[i].data.tableProducts[j].button_text}</a></div></div>
              `;
          }

          content = content + full_products;
          break;
        }
      }
    }
    return content;
  }


  const [htmlContent, setHtmlContent] = useState<string>("");

  // Function to handle generated HTML
  const handleGenerateHTML = (htmlString: string) => {
    setHtmlContent(htmlString); // Store the HTML content in the state
    console.log("Generated HTML:", htmlString); // You can now use this HTML content
  };


  const handleSave = async () => {
    
    generateHTML();
    // getImageEditorPayload()
    try {
      let payload
      if(imageEditorRef.current !=null){
        payload = imageEditorRef.current.generatePayload(); // This would return the payload from ImageEditor
      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("allContent", htmlContent); // Add WordPress content

      // 
      // SetLoading(true)
      // const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-dota-page", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ allContent }),
      // });
      try {
        setLoadingUploadingPage(true);  // Set loading state to true at the start
        const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-dota-page", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            allContent: htmlContent,  // Send allContent
            data: JSON.stringify(payload),  // Send payload as a string
            normalPost: writeNormalPost,
          }),
        });
      
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Success:", jsonResponse);
          setLoadingUploadingPost(false);  // Set loading to false after successful upload
        } else {
          throw new Error("Upload failed");
        }
      } catch (error) {
        window.alert("Error uploading image: " + error);
        setLoadingUploadingPost(false);  // Set loading to false in case of error
      } finally {
        setLoadingUploadingPage(false);  // Set loading page to false once everything is done
      }
      
    
      console.log("Articles saved successfully");
      // SetLoading(false)
    } catch (error) { 
      // SetLoading(false)
      setLoadingUploadingPage(!loadingUploadingPage)

      console.error("Error saving articles:", error);
    }
  };
  
  const handleCreateInstagramPost = async () => {
    
    try {
      let payload
      let postContentFrom
      if(imageInstagramPost.current !=null){
        payload = imageInstagramPost.current.generatePayload(); // This would return the payload from ImageEditor
        postContentFrom = imageInstagramPost.current.returnPostContent(); // This would return the payload from ImageEditor

      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("allContent", htmlContent); // Add WordPress content

      // 
      // SetLoading(true)
      // const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-dota-page", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ allContent }),
      // });
      try {
        SetLoading(true)
        const response = await fetch("https://featured-image.wecon-digital.agency/upload-dota-instagram-post", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promotion:promotionImage,
            publish:createInstagramPost,
            post_content:postContentFrom,  // Send allContent
            scheduled_date:imageInstagramPostDate,
            data: JSON.stringify(payload),  // Send payload as a string
          }),
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Success:", jsonResponse);
        } else {
          console.error("Failed to create image on server");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }

    
      console.log("Articles saved successfully");
      // SetLoading(false)
    } catch (error) { 
      // SetLoading(false)
      console.error("Error saving articles:", error);
    }
  };
  const handleCreateInstagramStory = async () => {
    
    try {
      let payload
      if(imageInstagramStory.current !=null){
        payload = imageInstagramStory.current.generatePayload(); // This would return the payload from ImageEditor
      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("allContent", htmlContent); // Add WordPress content

      // 
      // SetLoading(true)
      // const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-dota-page", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ allContent }),
      // });
      try {
        const response = await fetch("https://featured-image.wecon-digital.agency/upload-dota-instagram-story", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            publish:createInstagramStory,
            allContent:htmlContent,  // Send allContent
            scheduled_date:imageInstagramStoryDate,
            data: JSON.stringify(payload),  // Send payload as a string
          }),
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Success:", jsonResponse);
        } else {
          console.error("Failed to create image on server");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }

    
      console.log("Articles saved successfully");
      // SetLoading(false)
    } catch (error) { 
      // SetLoading(false)
      console.error("Error saving articles:", error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={{ margin: "2rem 5rem", minWidth: "70%" }}>
        <img
          style={{ maxWidth: "5rem" }}
          src="https://dotalive.org/wp-content/uploads/2024/06/cropped-dotalive.webp"
        ></img>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {rows.map((row, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  textAlign: "center",
                  alignItems: "center",
                  padding: "1rem 0rem",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Team 1</InputLabel>
                  <Select
                    value={team1.name}
                    onChange={(e) =>
                      handleTeamChange(1, e.target.value as string)
                    }
                    fullWidth
                    renderValue={(selected) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            teams.find((team) => team.name === selected)?.logo
                          }
                          alt=""
                          style={{
                            maxWidth: "1.5rem",
                            maxHeight: "1.5rem",
                            marginRight: "10px",
                          }}
                        />
                        {selected}
                      </div>
                    )}
                  >
                    {teams.map((team) => (
                      <MenuItem key={team.name} value={team.name}>
                        <img
                          src={team.logo}
                          alt={team.name}
                          style={{
                            maxWidth: "1.5rem",
                            maxHeight: "1.5rem",
                            marginRight: "10px",
                          }}
                        />
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h3>VS</h3>
                <FormControl fullWidth>
                  <InputLabel>Team 2</InputLabel>
                  <Select
                    value={team2.name}
                    onChange={(e) =>
                      handleTeamChange(2, e.target.value as string)
                    }
                    fullWidth
                    renderValue={(selected) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            teams.find((team) => team.name === selected)?.logo
                          }
                          alt=""
                          style={{
                            maxWidth: "1.5rem",
                            maxHeight: "1.5rem",
                            marginRight: "10px",
                          }}
                        />
                        {selected}
                      </div>
                    )}
                  >
                    {teams.map((team) => (
                      <MenuItem key={team.name} value={team.name}>
                        <img
                          src={team.logo}
                          alt={team.name}
                          style={{
                            maxWidth: "1.5rem",
                            maxHeight: "1.5rem",
                            marginRight: "10px",
                          }}
                        />
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          ))}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2 style={{ padding: "0px", margin: "0px" }}>
                Create Wordpress Page
              </h2>
              <Checkbox
                checked={createWordpressPage}
                onChange={() => {
                  setCreateWordpressPage(!createWordpressPage);
                }}
                color="primary"
              />
            
            <Button variant="contained" disabled={htmlContent.length > 0?false:true} onClick={handleSave}>{loadingUploadingPage ? <>Loading...</>:<>Create Post</>}</Button>      

            </div>
          {createWordpressPage ? <>  <div>
            <ImageEditor
            ref={imageEditorRef}
            teamLogos={{ team1Logo: team1.logo, team2Logo: team2.logo }}
            imgurl={
              "https://dotalive.org/wp-content/uploads/2024/08/background_wep.webp"
            }
            preview={false}
            provider="dotalive"
            aspect_ratio="16:9"
            main_keyword={team1.name + "_vs_" + team2.name}
          />
         
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >

              Write normal post ?
               <Checkbox
               checked={writeNormalPost}
               onChange={() => {
                 setWriteNormalPost(!writeNormalPost);
               }}
               color="primary"
             />
            </div>
          {writeNormalPost? <>
          <div style={{display:'flex', flexDirection:'column'}}>
          {/* <TextField
              label="Write the body of the text"
              multiline={true}
              rows={10}
              value={htmlContent}
              style={{ minWidth: "100%", marginBottom: "20px" }}
              onChange={(e) => setHtmlContent(e.target.value)}
              className="introduction_text"
            /> */}
                    <ReactQuill
                    value={htmlContent}
                    onChange={(e) => setHtmlContent(e)}
                    theme="snow"
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                  />
          </div>
          </>:<><Dota2MatchPrediction
            team1={team1}
            setTeam1={setTeam1}
            team2={team2}
            setTeam2={setTeam2}
            onGenerateHTML={handleGenerateHTML} // Pass the function to the child component
          />{" "}</>}
            </div></>:<></>}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom:'10px',
                gap:"20px"
              }}
            >
              <h2 style={{ padding: "0px", margin: "0px" }}>
                Create Instagram Post
              </h2>
              <Checkbox
                checked={createInstagramPost}
                onChange={() => {
                  setCreateInstagramPost(!createInstagramPost);
                }}
                color="primary"
              />
               <DateTimePicker
              label="Select date and time"
              value={imageInstagramPostDate.length >0 ? null:null}
              onAccept={(e)=>{
                const formattedDate = e ? dayjs(e).format('YYYY-MM-DDTHH:mm:ss') :''
                setImageInstagramPostDate(formattedDate)
              }}
            />  
              <Button variant="contained" onClick={handleCreateInstagramPost}>Create Post</Button>      
              
              <div style={{display:'flex', flexDirection:'row', textAlign:'center'}}>              <Checkbox
                checked={instagramPostPublish}
                onChange={() => {
                  setInstagramPostPublish (!instagramPostPublish);
                }}
                color="primary"
              />
              <p>Publish?</p></div>
              <div style={{display:'flex', flexDirection:'row', textAlign:'center'}}>              <Checkbox
                checked={promotionImage}
                onChange={() => {
                  setPromotionImage (!promotionImage);
                }}
                color="primary"
              />
              <p>Promotion image?</p></div>
            </div>
            {createInstagramPost ? (
              <>
                <div>
                  <ImageEditor
                    ref={imageInstagramPost}
                    teamLogos={{ team1Logo: team1.logo, team2Logo: team2.logo }}
                    imgurl={
                      "https://dotalive.org/wp-content/uploads/2024/10/DALL·E-2024-10-09-17.36.30-A-simple-16_9-background-image-with-the-same-color-tones-and-texture-as-the-uploaded-image.-It-should-feature-dark-teal-greenish-shades-and-slight-g.webp"
                    }
                    preview={false}
                    provider="dotalive_instagram"
                    aspect_ratio="1:1"
                    main_keyword={team1.name + "_vs_" + team2.name}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap:'20px'
              }}
            >
              <h2 style={{ padding: "0px", margin: "0px" }}>
                Create Instagram Story
              </h2>
              <Checkbox
                checked={createInstagramStory}
                onChange={() => {
                  setCreateInstagramStory(!createInstagramStory);
                }}
                color="primary"
              />
            <DateTimePicker
              label="Select date and time"
              value={imageInstagramStoryDate.length >0 ? null:null}
              onAccept={(e)=>{
                const formattedDate = e ? dayjs(e).format('YYYY-MM-DDTHH:mm:ss') :''
                setImageInstagramStoryDate(formattedDate)
              }}
            />  
            <Button variant="contained" onClick={handleCreateInstagramStory}>Create Story</Button>
            <div style={{display:'flex', flexDirection:'row', textAlign:'center'}}>              <Checkbox
                checked={instagramStoryPublish}
                onChange={() => {
                  setInstagramStoryPublish (!instagramStoryPublish);
                }}
                color="primary"
              />
              <p>Publish?</p></div>
            </div>
            {createInstagramStory ? (
              <>
                {" "}
                <div>
                  <ImageEditor
                    ref={imageInstagramStory}
                    teamLogos={{ team1Logo: team1.logo, team2Logo: team2.logo }}
                    imgurl={
                      "https://dotalive.org/wp-content/uploads/2024/10/9_16.png"
                    }
                    preview={false}
                    provider="dotalive_instagram_story"
                    aspect_ratio="9:16"
                    main_keyword={team1.name + "_vs_" + team2.name}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          
        </LocalizationProvider>
      </div>

      {/* Dialog for editing Sneaker Widget */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Sneaker Widget</DialogTitle>
        <DialogContent>
          {editProduct && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TextField
                label="Featured Image"
                value={editProduct.featuredImage}
                onChange={(e) =>
                  setEditProduct({
                    ...editProduct,
                    featuredImage: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Product Name"
                value={editProduct.productName}
                onChange={(e) =>
                  setEditProduct({
                    ...editProduct,
                    productName: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Original Price"
                value={editProduct.originalPrice}
                onChange={(e) =>
                  setEditProduct({
                    ...editProduct,
                    originalPrice: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Discounted Price"
                value={editProduct.discountedPrice}
                onChange={(e) =>
                  setEditProduct({
                    ...editProduct,
                    discountedPrice: e.target.value,
                  })
                }
                fullWidth
              />
              <TextField
                label="Sizes"
                value={editProduct.sizes.join(", ")}
                onChange={(e) =>
                  setEditProduct({
                    ...editProduct,
                    sizes: e.target.value.split(",").map((size) => size.trim()),
                  })
                }
                fullWidth
              />
              <TextField
                label="Buy Link"
                value={editProduct.buyLink}
                onChange={(e) =>
                  setEditProduct({ ...editProduct, buyLink: e.target.value })
                }
                fullWidth
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding images */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Image</DialogTitle>
        <DialogContent>
          <TextField
            label="Image URL"
            value={imgLink}
            onChange={(e) => setImgLink(e.target.value)}
            fullWidth
            style={{ margin: "20px 0px 0px 0px" }}
          />
          <TextField
            label="Source"
            value={source}
            onChange={(e) => setSource(e.target.value)}
            fullWidth
            style={{ margin: "20px 0px" }}
          />
          <TextField
            label="Alt Text"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveImage} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <div className="all_html">
        <div hidden={true} className="generated_html"></div>
      </div>
    </div>
  );
};

export default Dotalive;
