import React, { useState } from "react";
import RoomCreator from "./RoomCreator";
import RoomCanvas from "./RoomCanvas";
import RoomList from "./RoomList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Room {
  id: number;
  name: string;
  width: number;
  height: number;
  color: string;
  x: number; // X position on the canvas
  y: number; // Y position on the canvas
}

const RealPage: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

  const addRoom = (room: Room) => {
    setRooms((prevRooms) => [...prevRooms, room]);
  };

  const updateRoomDetails = (
    roomId: number,
    updatedDetails: { name?: string; width?: number; height?: number; x?: number; y?: number }
  ) => {
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === roomId ? { ...room, ...updatedDetails } : room
      )
    );
  };

  const updateRoomPosition = (roomId: number, direction: "up" | "down" | "left" | "right") => {
    const movementDistance = 10; // Pixels to move per button press
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === roomId
          ? {
              ...room,
              x:
                direction === "left"
                  ? room.x - movementDistance
                  : direction === "right"
                  ? room.x + movementDistance
                  : room.x,
              y:
                direction === "up"
                  ? room.y - movementDistance
                  : direction === "down"
                  ? room.y + movementDistance
                  : room.y,
            }
          : room
      )
    );
  };

  const deleteRoom = (roomId: number) => {
    setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId));
    setSelectedRoom(null);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-around", padding: "2rem" }}>
        <RoomCreator addRoom={addRoom} />
        <RoomCanvas
          rooms={rooms}
          setSelectedRoom={setSelectedRoom}
          selectedRoom={selectedRoom}
          updateRoomDetails={updateRoomDetails}
        />
        <RoomList
          rooms={rooms}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          updateRoomDetails={updateRoomDetails}
          updateRoomPosition={updateRoomPosition}
          deleteRoom={deleteRoom}
          setRooms={setRooms}
        />
      </div>
    </DndProvider>
  );
};

export default RealPage;
