const DraftAnalytics = ({ analysis, team1, team2 }: { analysis: any, team1:string,team2:string }) => {
    if (!analysis) {
      return <div>No analysis data available</div>;
    }
  
    const {
      draft_overview,
      lane_matchups,
      early_kill_potential,
      objective_control,
      map_control_and_vision,
      teamfight_presence,
      item_timings,
      tempo_and_pressure,
      key_exploits,
      prediction,
      roi_recommendation,
    } = analysis;

    
  
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1.5rem",
          padding: "2rem",
          background: "#162e1e",
          color: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          maxWidth: "1400px",
          margin: "2rem auto",
        }}
      >
        <h2
          style={{
            gridColumn: "1 / -1",
            textAlign: "center",
            fontSize: "2rem",
            marginBottom: "1.5rem",
            color: "#1abc9c",
            fontWeight: "bold",
          }}
        >
          Draft Analytics Results
        </h2>
  
        {[
          {
            title: "Draft Overview",
            items: [
              {
                subtitle: team1 + " Overview",
                description: draft_overview?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Overview",
                description: draft_overview?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Lane Matchups",
            items: [
              {
                subtitle: "Safe Lane",
                description: lane_matchups?.safe_lane || "No data available",
              },
              {
                subtitle: "Mid Lane",
                description: lane_matchups?.mid_lane || "No data available",
              },
              {
                subtitle: "Off Lane",
                description: lane_matchups?.off_lane || "No data available",
              },
            ],
          },
          {
            title: "Early Kill Potential",
            items: [
              {
                subtitle: team1 + " Kill Potential",
                description: early_kill_potential?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Kill Potential",
                description: early_kill_potential?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Objective Control",
            items: [
              {
                subtitle: team1 + " Objectives",
                description: objective_control?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Objectives",
                description: objective_control?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Map Control and Vision",
            items: [
              {
                subtitle: team1 + " Vision",
                description: map_control_and_vision?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Vision",
                description: map_control_and_vision?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Teamfight Presence",
            items: [
              {
                subtitle: team1 + " Teamfight",
                description: teamfight_presence?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Teamfight",
                description: teamfight_presence?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Item Timings",
            items: [
              {
                subtitle: team1 + " Items",
                description: item_timings?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Items",
                description: item_timings?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Tempo and Pressure",
            items: [
              {
                subtitle: team1 + " Tempo",
                description: tempo_and_pressure?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Tempo",
                description: tempo_and_pressure?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Key Exploits",
            items: [
              {
                subtitle: team1 + " Exploits",
                description: key_exploits?.team_1 || "No data available",
              },
              {
                subtitle: team2 + " Exploits",
                description: key_exploits?.team_2 || "No data available",
              },
            ],
          },
          {
            title: "Prediction",
            items: [
              {
                subtitle: "Prediction",
                description: prediction || "No data available",
              },
              {
                subtitle: "ROI Recommendation",
                description: `${roi_recommendation?.predicted_winner || "No winner"}: ${roi_recommendation?.justification || "No data available"}`,
              },
            ],
          },
        ].map((section, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #0f5132",
              padding: "1rem",
              borderRadius: "8px",
              background: "#204a36",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
            }}
          >
            <h3
              style={{
                margin: "0 0 0.5rem 0",
                color: "#1abc9c",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              {section.title}
            </h3>
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                margin: 0,
              }}
            >
              {section.items.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  style={{
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    borderRadius: "5px",
                    background: "#1c3a29",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h4
                    style={{
                      margin: "0 0 0.2rem 0",
                      color: "#ffffff",
                      fontWeight: "bold",
                    }}
                  >
                    {item.subtitle}
                  </h4>
                  <p
                    style={{
                      margin: 0,
                      color: "#b7d4c3",
                      fontSize: "0.9rem",
                    }}
                  >
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };
  
  export default DraftAnalytics;
  