// PlayerInterface.ts
export interface Player {
  team_id: number;
  account_id: number;
  name: string | null;
  games_played: number;
  wins: number;
  is_current_team_member: boolean | null;
}

export const players: Player[] = [
  {
    account_id: 124801257,
    name: "Nightfall",
    games_played: 58,
    wins: 37,
    is_current_team_member: true,
    team_id: 8291895,
  },
  {
    account_id: 210053851,
    name: "lorenof",
    games_played: 56,
    wins: 35,
    is_current_team_member: true,
    team_id: 8291895,
  },
  {
    account_id: 86698277,
    name: "33",
    games_played: 575,
    wins: 352,
    is_current_team_member: true,
    team_id: 8291895,
  },
  {
    account_id: 103735745,
    name: "Saksa",
    games_played: 374,
    wins: 239,
    is_current_team_member: true,
    team_id: 8291895,
  },
  {
    account_id: 136829091,
    name: "Whitemon",
    games_played: 300,
    wins: 187,
    is_current_team_member: true,
    team_id: 8291895,
  },
  {
    account_id: 331855530,
    name: "Pure",
    games_played: 263,
    wins: 169,
    is_current_team_member: true,
    team_id: 8255888,
  },
  {
    account_id: 858106446,
    name: "kiyotaka",
    games_played: 70,
    wins: 45,
    is_current_team_member: true,
    team_id: 8255888,
  },
  {
    account_id: 165564598,
    name: "MieRo ",
    games_played: 209,
    wins: 115,
    is_current_team_member: true,
    team_id: 8255888,
  },
  {
    account_id: 317880638,
    name: "Save-",
    games_played: 437,
    wins: 257,
    is_current_team_member: true,
    team_id: 8255888,
  },
  {
    account_id: 196878136,
    name: "Kataomi`",
    games_played: 56,
    wins: 36,
    is_current_team_member: true,
    team_id: 8255888,
  },
  {
    account_id: 100058342,
    name: "skiter",
    games_played: 366,
    wins: 260,
    is_current_team_member: true,
    team_id: 9247354,
  },
  {
    account_id: 898455820,
    name: "Malr1ne",
    games_played: 345,
    wins: 248,
    is_current_team_member: true,
    team_id: 9247354,
  },
  {
    account_id: 183719386,
    name: "AMMAR_THE_F",
    games_played: 368,
    wins: 262,
    is_current_team_member: true,
    team_id: 9247354,
  },
  {
    account_id: 25907144,
    name: "Cr1t-",
    games_played: 368,
    wins: 262,
    is_current_team_member: true,
    team_id: 9247354,
  },
  {
    account_id: 10366616,
    name: "Sneyking",
    games_played: 363,
    wins: 257,
    is_current_team_member: true,
    team_id: 9247354,
  },
  {
    account_id: 171262902,
    name: "医者watson`",
    games_played: 30,
    wins: 15,
    is_current_team_member: true,
    team_id: 8599101,
  },
  {
    account_id: 221666230,
    name: "Quinn",
    games_played: 540,
    wins: 331,
    is_current_team_member: true,
    team_id: 8599101,
  },
  {
    account_id: 97590558,
    name: "Ace ♠",
    games_played: 711,
    wins: 428,
    is_current_team_member: true,
    team_id: 8599101,
  },
  {
    account_id: 91730177,
    name: "Seleri",
    games_played: 711,
    wins: 428,
    is_current_team_member: true,
    team_id: 8599101,
  },
  {
    account_id: 16497807,
    name: "tOfu",
    games_played: 710,
    wins: 428,
    is_current_team_member: true,
    team_id: 8599101,
  },
  {account_id: 1171243748, name: "ɹǝʞɹɐd⚔️", games_played: 43, wins: 25, is_current_team_member: true, team_id: 9303484},
  {account_id: 131303632, name: "4nalog <01", games_played: 238, wins: 130, is_current_team_member: true, team_id: 9303484}, 
  {account_id: 138880576, name: "Davai Lama", games_played: 238, wins: 130, is_current_team_member: true, team_id: 9303484}, 
  {account_id: 157989498, name: "Scofield", games_played: 238, wins: 130, is_current_team_member: true, team_id: 9303484}, 
  {account_id: 81306398, name: "KingJungles", games_played: 238, wins: 130, is_current_team_member: true, team_id: 9303484},
  {account_id: 152962063, name: "m1CKe", games_played: 1158, wins: 664, is_current_team_member: true, team_id: 2163},
  {account_id: 201358612, name: "Nisha", games_played: 532, wins: 325, is_current_team_member: true, team_id: 2163},
  {account_id: 126212866, name: "El SaberLightO", games_played: 66, wins: 36, is_current_team_member: true, team_id: 2163},
  {account_id: 77490514, name: "Boxi", games_played: 1129, wins: 650, is_current_team_member: true, team_id: 2163},
  {account_id: 54580962, name: "Insania ", games_played: 1167, wins: 668, is_current_team_member: true, team_id: 2163},
  {account_id: 137129583, name: "Xm", games_played: 250, wins: 149, is_current_team_member: true, team_id: 8261500},
  {account_id: 138543123, name: "Pyw", games_played: 181, wins: 106, is_current_team_member: true, team_id:8261500 },
  {account_id: 185908355, name: "楼", games_played: 99, wins: 61, is_current_team_member: true, team_id: 8261500},
  {account_id: 76904792, name: "poloson", games_played: 2, wins: 2, is_current_team_member: true, team_id: 8261500},
  {account_id: 145957968, name: "niu", games_played: 2, wins: 2, is_current_team_member: true, team_id: 8261500},
  {account_id: 1044002267, name: "Satanic", games_played: 73, wins: 41, is_current_team_member: true, team_id: 7119388},
  {account_id: 106305042, name: "Larl", games_played: 550, wins: 333, is_current_team_member: true, team_id: 7119388},
  {account_id: 203351055, name: "Malik", games_played: 81, wins: 43, is_current_team_member: true, team_id: 7119388},
  {account_id: 847565596, name: "rue", games_played: 59, wins: 32, is_current_team_member: true, team_id: 7119388},
  {account_id: 113331514, name: "Miposhka", games_played: 902, wins: 560, is_current_team_member: true, team_id: 7119388},
  {account_id: 73401082, name: "Dukalis", games_played: 47, wins: 28, is_current_team_member: true, team_id: 9572001},
  {account_id: 56351509, name: "DM", games_played: 47, wins: 28, is_current_team_member: true, team_id: 9572001},
  {account_id: 164199202, name: "9Class", games_played: 47, wins: 28, is_current_team_member: true, team_id: 9572001},
  {account_id: 127617979, name: "Crystallis", games_played: 47, wins: 28, is_current_team_member: true, team_id: 9572001},
  {account_id: 106573901, name: "No[o]ne-", games_played: 47, wins: 28, is_current_team_member: true, team_id: 9572001},
  {account_id: 375507918, name: "23savage", games_played: 24, wins: 13, is_current_team_member: true, team_id: 2586976},
  {account_id: 94786276, name: "Nine", games_played: 19, wins: 8, is_current_team_member: true, team_id: 2586976},
  {account_id: 292921272, name: "Wisper", games_played: 256, wins: 142, is_current_team_member: true, team_id: 2586976},
  {account_id: 346412363, name: "Ari", games_played: 256, wins: 142, is_current_team_member: true, team_id: 2586976},
  {account_id: 88271237, name: "Ceb", games_played: 890, wins: 500, is_current_team_member: true, team_id: 2586976}
// {account_id: 302214028, name: "Collapse", games_played: 829, wins: 519, is_current_team_member: true, team_id: 7119388}
// {account_id: 321580662, name: "Raddan", games_played: 829, wins: 519, is_current_team_member: true, team_id: 7119388}
// {account_id: 256156323, name: "Mira", games_played: 800, wins: 503, is_current_team_member: true, team_id: 7119388}  
];

export interface PlayerGame {
  match_id: number;
  start_time: number;
  win: boolean;
  hero_id: number;
  account_id: number;
  leaguename: string;
}

export const skitter_games: PlayerGame[] = [
  {
    match_id: 7474086223,
    start_time: 1701794451,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "Pinnacle: 25 Year Anniversary Show",
  },
  {
    match_id: 7488983127,
    start_time: 1702644480,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "ESL One Kuala Lumpur powered by Intel",
  },
  {
    match_id: 7537910800,
    start_time: 1705259587,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha - Closed Qualifier - MENA",
  },
  {
    match_id: 7540602339,
    start_time: 1705424459,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 22 Qualifiers",
  },
  {
    match_id: 7561515168,
    start_time: 1706622808,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "ESL One Birmingham 2024 Qualifiers powered by Intel",
  },
  {
    match_id: 7561791199,
    start_time: 1706633352,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "ESL One Birmingham 2024 Qualifiers powered by Intel",
  },
  {
    match_id: 7573655445,
    start_time: 1707305882,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Dubai 2024",
  },
  {
    match_id: 7584695605,
    start_time: 1707830259,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Dubai 2024",
  },
  {
    match_id: 7620768845,
    start_time: 1709565064,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 22 powered by Intel",
  },
  {
    match_id: 7677044934,
    start_time: 1712605324,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "Elite League by FISSURE and ESB",
  },
  {
    match_id: 7686251961,
    start_time: 1713114961,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "Elite League by FISSURE and ESB",
  },
  {
    match_id: 7981664422,
    start_time: 1728570971,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7986228633,
    start_time: 1728825136,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7986751548,
    start_time: 1728842496,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7996294984,
    start_time: 1729426743,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 7997698304,
    start_time: 1729511427,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8001042470,
    start_time: 1729709101,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8001811405,
    start_time: 1729771439,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8003453293,
    start_time: 1729865700,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8003616297,
    start_time: 1729871632,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8005789257,
    start_time: 1729975863,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8007450111,
    start_time: 1730057824,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8010372999,
    start_time: 1730232241,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8011184646,
    start_time: 1730294707,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8011667160,
    start_time: 1730312826,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8018876444,
    start_time: 1730714107,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8018957268,
    start_time: 1730718364,
    win: false,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8027003149,
    start_time: 1731160504,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8028916514,
    start_time: 1731239701,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8029364010,
    start_time: 1731252305,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8029756021,
    start_time: 1731265022,
    win: true,
    hero_id: 73,
    account_id: 100058342,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 7838140144,
    start_time: 1720620105,
    win: false,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7840033220,
    start_time: 1720710902,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7841608029,
    start_time: 1720791727,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7842085047,
    start_time: 1720807200,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7843737858,
    start_time: 1720884080,
    win: false,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7848798413,
    start_time: 1721133127,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7857283180,
    start_time: 1721559618,
    win: false,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "Riyadh Masters 2024 at Esports World Cup",
  },
  {
    match_id: 7908558253,
    start_time: 1724328444,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "FISSURE Universe: Episode 3",
  },
  {
    match_id: 7911898211,
    start_time: 1724512753,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "FISSURE Universe: Episode 3",
  },
  {
    match_id: 7929019186,
    start_time: 1725531957,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "The International 2024",
  },
  {
    match_id: 7929316152,
    start_time: 1725546342,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "The International 2024",
  },
  {
    match_id: 7940501580,
    start_time: 1726216338,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "The International 2024",
  },
  {
    match_id: 7975935708,
    start_time: 1728223660,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7976071241,
    start_time: 1728227657,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7984472803,
    start_time: 1728737409,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "PGL Wallachia 2024 Season 2",
  },
  {
    match_id: 7997561814,
    start_time: 1729502229,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "BetBoom Dacha Belgrade 2024",
  },
  {
    match_id: 8008342762,
    start_time: 1730121907,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8009762120,
    start_time: 1730208306,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8014584231,
    start_time: 1730485500,
    win: false,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8016323810,
    start_time: 1730575140,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8028916514,
    start_time: 1731239701,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
  {
    match_id: 8029756021,
    start_time: 1731265022,
    win: true,
    hero_id: 16,
    account_id: 898455820,
    leaguename: "DreamLeague Season 24 powered by Intel",
  },
];
