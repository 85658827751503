import React, { useState } from "react";
import RoomDetails from "./RoomDetails";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface Room {
  id: number;
  name: string;
  width: number;
  height: number;
  color: string;
  x: number;
  y: number;
}

interface RoomListProps {
  rooms: Room[];
  selectedRoom: Room | null;
  setSelectedRoom: (room: Room | null) => void;
  updateRoomDetails: (
    roomId: number,
    updatedDetails: { name?: string; width?: number; height?: number; x?: number; y?: number }
  ) => void;
  updateRoomPosition: (roomId: number, direction: "up" | "down" | "left" | "right") => void;
  deleteRoom: (roomId: number) => void;
  setRooms: React.Dispatch<React.SetStateAction<Room[]>>;
}

const RoomList: React.FC<RoomListProps> = ({
  rooms,
  selectedRoom,
  setSelectedRoom,
  updateRoomDetails,
  updateRoomPosition,
  deleteRoom,
  setRooms,
}) => {
  const [expandedRoomId, setExpandedRoomId] = useState<number | null>(null);

  const toggleRoomDetails = (roomId: number) => {
    setExpandedRoomId((prev) => (prev === roomId ? null : roomId));
  };

  const moveRoomInList = (index: number, direction: "up" | "down") => {
    if (direction === "up" && index > 0) {
      // Swap with the room above
      const newRooms = [...rooms];
      [newRooms[index], newRooms[index - 1]] = [newRooms[index - 1], newRooms[index]];
      setRooms(newRooms);
    } else if (direction === "down" && index < rooms.length - 1) {
      // Swap with the room below
      const newRooms = [...rooms];
      [newRooms[index], newRooms[index + 1]] = [newRooms[index + 1], newRooms[index]];
      setRooms(newRooms);
    }
  };

  return (
    <div style={{ flex: "1", border: "1px solid #ccc", padding: "10px" }}>
      <h2>Room Layers</h2>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {rooms.map((room, index) => (
          <li
            key={room.id}
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginBottom: "10px",
              padding: "10px",
              backgroundColor: selectedRoom?.id === room.id ? "#f0f0f0" : "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedRoom(room);
                toggleRoomDetails(room.id);
              }}
            >
              <span>{room.name}</span>
              <div style={{ display: "flex", gap: "5px" }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent toggling details when moving
                    moveRoomInList(index, "up");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: index > 0 ? "pointer" : "not-allowed",
                  }}
                  disabled={index === 0} // Disable if it's the first item
                >
                  <ArrowUpwardIcon />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent toggling details when moving
                    moveRoomInList(index, "down");
                  }}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: index < rooms.length - 1 ? "pointer" : "not-allowed",
                  }}
                  disabled={index === rooms.length - 1} // Disable if it's the last item
                >
                  <ArrowDownwardIcon />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent toggling details when deleting
                    deleteRoom(room.id);
                  }}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
            {expandedRoomId === room.id && (
              <div
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  borderTop: "1px solid #ccc",
                }}
              >
                <RoomDetails
                  selectedRoom={room}
                  updateRoomDetails={updateRoomDetails}
                  updateRoomPosition={updateRoomPosition}
                  deleteRoom={deleteRoom}
                />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoomList;
