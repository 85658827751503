import React, { useState, useEffect } from "react";
import { heroes } from "../Heroes.tsx";
import { Button } from "@mui/material";

interface Player {
  username: string;
  points: number;
  banned_heroes: any[];
  dire_heroes: any[];
  radiant_heroes: any[];
}

interface Tournament {
  name: string;
  no_players: number;
  uuid: string;
  players: Player[];
}

interface TournamentDetailsProps {
  currentTournament: Tournament;
}

const UserTournamentDetails: React.FC<TournamentDetailsProps> = ({
  currentTournament,
}) => {
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState<number | null>(
    null
  );

  // Reset selectedPlayerIndex when currentTournament changes
  useEffect(() => {
    setSelectedPlayerIndex(null);
  }, [currentTournament]);

  const handlePlayerClick = (index: number) => {
    setSelectedPlayerIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderPlayerDraft = (player: any) => {
    function renderPlayerHeroes(
      player_heroes: any[],
      is_banned: boolean
    ): React.ReactNode {
      return player_heroes.map((hero, index) => {
        let filtered = heroes.find(
          (hero_data) => hero_data.id === hero.hero_id
        );
        if (!filtered) {
          console.log("Hero not found " + hero.hero_id);
          return null;
        }
        function renderRole(role: string): string {
          switch (role) {
            case "carry":
              return "Carry";
            case "mid":
              return "Mid";
            case "offlane":
              return "Offlane";
            case "support":
              return "Support";
            case "hard_support":
              return "Hard support";
            case "banned":
              return "Banned";
          }
          return "";
        }

        return (
          <div
            key={index}
            style={{
              padding: "0.1rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              <img
                key={String(filtered.localized_name)
                  .toLowerCase()
                  .replaceAll(" ", "_")}
                src={
                  `/assets/img/heroes/` +
                  String(filtered.localized_name)
                    .toLowerCase()
                    .replaceAll(" ", "_") +
                  `.png`
                }
                alt={String(filtered.localized_name)
                  .toLowerCase()
                  .replaceAll(" ", "_")}
                style={{
                  width: "1.6rem",
                  height: "1rem",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  zIndex: 1,
                }}
              />
            </span>
            <span style={{ fontSize: "0.4rem", fontWeight: "bold" }}>
              {renderRole(hero.role).length > 5
                ? renderRole(hero.role).substring(0, 7)
                : renderRole(hero.role)}
            </span>
          </div>
        );
      });
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={"active_player"}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "lightgreen",
            }}
          >
            {renderPlayerHeroes(player.radiant_heroes, false)}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "#fc9090",
            }}
          >
            {renderPlayerHeroes(player.dire_heroes, false)}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "lightgray",
            }}
          >
            {renderPlayerHeroes(player.banned_heroes, true)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: "#f4f4f4", padding: "0.5rem 0.2rem" }}>
      <div className="display-flex-row-justify-between-vertically-center" style={{padding:"0rem 0.6rem"}}>
        <span style={{ fontWeight: "bold" }}>
          {currentTournament.name} Stats
        </span>
        <p style={{ fontSize: "0.8rem" }}>
          No.Players: {currentTournament.no_players}
        </p>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            padding: "0.8rem",
            borderBottom: "2px solid #ccc",
          }}
        >
          <div style={{ flex: 1 }}>Player Name</div>
          <div style={{ flex: 1, textAlign:'end' }}>Points</div>
        </div>

        {currentTournament.players.sort((a, b) => b.points - a.points).map((player, index) => (
          <div key={index}>
            <div
              style={{
                display: "flex",
                padding: "0.8rem",
                borderBottom: "1px solid #eee",
                cursor: "pointer",
                fontWeight:'bold',
                backgroundColor:
                  selectedPlayerIndex === index ? "#d9cccc" : index == 0 ? "gold":index == 1 ? "silver":index == 2 ? "#CD7F32":"",
              }}
              onClick={() => handlePlayerClick(index)}
            >
              <div style={{ flex: 1  }}>{index+1 + " - " + player.username}</div>
              <div style={{ flex: 1 , textAlign:'end'}}>{player.points} points</div>
            </div>

            {selectedPlayerIndex === index && renderPlayerDraft(player)}
          </div>
        ))}
      </div>
      <Button
          style={{
            minWidth:'100%',
            background:'lightgray',
            color:'black',
            marginTop:'1rem',
            fontWeight:'bold'
          }}
          // onClick={handleWriteIntroduction}
        >
          {"view tournament data"}
        </Button>
     
    </div>
  );
};

export default UserTournamentDetails;
