import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface RoomDetailsProps {
  selectedRoom: {
    id: number;
    name: string;
    width: number;
    height: number;
    x: number;
    y: number;
  } | null;
  updateRoomDetails: (
    roomId: number,
    updatedDetails: { name?: string; width?: number; height?: number; x?: number; y?: number }
  ) => void;
  deleteRoom: (roomId: number) => void;
  updateRoomPosition: (roomId: number, direction: "up" | "down" | "left" | "right") => void;
}

const RoomDetails: React.FC<RoomDetailsProps> = ({
  selectedRoom,
  updateRoomDetails,
  deleteRoom,
  updateRoomPosition,
}) => {
  const [editableFields, setEditableFields] = useState({
    name: selectedRoom?.name || "",
    width: selectedRoom?.width || 0,
    height: selectedRoom?.height || 0,
    x: selectedRoom?.x || 0,
    y: selectedRoom?.y || 0,
  });

  useEffect(() => {
    if (selectedRoom) {
      setEditableFields({
        name: selectedRoom.name,
        width: selectedRoom.width,
        height: selectedRoom.height,
        x: selectedRoom.x,
        y: selectedRoom.y,
      });
    }
  }, [selectedRoom]);

  const handleFieldChange = (field: keyof typeof editableFields, value: string | number) => {
    const updatedFields = { ...editableFields, [field]: value };
    setEditableFields(updatedFields);

    // Update room details in real time for width and height changes
    if (selectedRoom) {
      updateRoomDetails(selectedRoom.id, {
        [field]: value,
      });
    }
  };

  const moveRoom = (direction: "up" | "down" | "left" | "right") => {
    if (selectedRoom) {
      updateRoomPosition(selectedRoom.id, direction);
    }
  };

  if (!selectedRoom) {
    return (
      <div style={{ flex: "1" }}>
        <p>No room selected</p>
      </div>
    );
  }

  return (
    <div style={{ flex: "1" }}>
      <label>
        Name:
        <input
          type="text"
          value={editableFields.name}
          onChange={(e) => handleFieldChange("name", e.target.value)}
        />
      </label>
      <br />
      <label>
        Width:
        <input
          type="number"
          value={editableFields.width}
          onChange={(e) => handleFieldChange("width", Number(e.target.value))}
        />
      </label>
      <br />
      <label>
        Height:
        <input
          type="number"
          value={editableFields.height}
          onChange={(e) => handleFieldChange("height", Number(e.target.value))}
        />
      </label>
      {/* <br />
      <label>
        X Position:
        <input
          type="number"
          value={editableFields.x}
          onChange={(e) => handleFieldChange("x", Number(e.target.value))}
        />
      </label>
      <br />
      <label>
        Y Position:
        <input
          type="number"
          value={editableFields.y}
          onChange={(e) => handleFieldChange("y", Number(e.target.value))}
        />
      </label>
      <br /> */}
      <div>
        <h3>Move Room</h3>
        <button onClick={() => updateRoomPosition(selectedRoom.id, "up")}>Up</button>
        <br />
        <button onClick={() => updateRoomPosition(selectedRoom.id, "left")}>Left</button>
        <button onClick={() => updateRoomPosition(selectedRoom.id, "down")}>Down</button>
        <button onClick={() => updateRoomPosition(selectedRoom.id, "right")}>Right</button>
      </div>
      <button
        onClick={() => deleteRoom(selectedRoom.id)}
        style={{
          marginTop: "20px",
          backgroundColor: "red",
          color: "white",
        }}
      >
        Delete Room
      </button>
    </div>
  );
};

export default RoomDetails;
