  import React, { useState } from "react";

  interface RoomCreatorProps {
    addRoom: (room: {
      id: number;
      name: string;
      width: number;
      height: number;
      color: string;
      x: number;
      y: number;
    }) => void;
  }

  const RoomCreator: React.FC<RoomCreatorProps> = ({ addRoom }) => {
    const [roomName, setRoomName] = useState<string>("");
    const [roomWidth, setRoomWidth] = useState<number>(100);
    const [roomHeight, setRoomHeight] = useState<number>(100);
    const [roomColor, setRoomColor] = useState<string>("#000"); // Default red color
    const getRandomColor = (): string => {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return `#${randomColor.padStart(6, '0')}`; // Ensures the color is always 6 digits
    };
    
    const handleCreateRoom = () => {
      
      if (roomColor === "#000") {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          
          setRoomColor(`#${randomColor.padStart(6, '0')}`); // Ensures the color is always 6 digits
        };

      const newRoom = {
        id: Date.now(),
        name: roomName || `Room`,
        width: roomWidth,
        height: roomHeight,
        color: roomColor,
        x: 50, // Default position
        y: 50,
      };
      addRoom(newRoom);
      setRoomName("");
      setRoomWidth(100);
      setRoomHeight(100);
      setRoomColor(getRandomColor()); // Reset color picker
    };

    return (
      <div style={{ flex: "1" }}>
        <h2>Create a Room</h2>
        <label>
          Room Name:
          <input
            type="text"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Width:
          <input
            type="number"
            value={roomWidth}
            onChange={(e) => setRoomWidth(Number(e.target.value))}
          />
        </label>
        <br />
        <label>
          Height:
          <input
            type="number"
            value={roomHeight}
            onChange={(e) => setRoomHeight(Number(e.target.value))}
          />
        </label>
        <br />
        <label>
          Color:
          <input
            type="color"
            value={roomColor}
            onChange={(e) => setRoomColor(e.target.value)}
          />
        </label>
        <br />
        <button onClick={handleCreateRoom}>Add Room</button>
      </div>
    );
  };

  export default RoomCreator;
