import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const InvitationPage = () => {
  const { invitationId } = useParams(); // Extract the invitationId from the URL
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    fetch(`https://server.dotalive.org/verify-invitation/${invitationId}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setVerified(true)
        // Handle successful verification
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error condition
      });
  }, [invitationId]);

  return (
    <div>
        {verified ? <><div onClick={()=>{
            navigate("/login")
        }}><a>Successfully verifed ! You can now login !</a></div></>:<>      Verifying your invitation...</>}

    </div>
  );
};

export default InvitationPage;
