import React, { useState } from "react";
import { DetailedTournament, Hero, TournamentLit } from "./../../types.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface TournamentProps {
  tournament: TournamentLit;
  allHeroes:Hero[],
  fetchDetailedTournament: (tournamentId: string) => Promise<DetailedTournament>;
  onDeleteTournament: (uuid: string) => void;
  onAddPlayer: (uuid: string) => void;
  onSaveTournament: (updatedTournament: DetailedTournament) => Promise<void>;
}



const Tournament: React.FC<TournamentProps> = ({
  tournament,
  allHeroes,
  fetchDetailedTournament,
  onDeleteTournament,
  onAddPlayer,
  onSaveTournament,
}) => {
  const [detailedTournament, setDetailedTournament] = useState<DetailedTournament | null>(null);
  const [isEditing, setIsEditing] = useState(true);
  const [editableTournament, setEditableTournament] = useState<DetailedTournament | null>(null);
  const renderPlayerDraft = (player: any) => {
    function renderPlayerHeroes(
      player_heroes: any[],
      is_banned: boolean
    ): React.ReactNode {
      if (player_heroes == null) {
        return <></>
      } 
      return player_heroes.map((hero, index) => {
        let filtered = allHeroes.find(
          (hero_data) => hero_data.id === hero.hero_id
        );
        if (!filtered) {
          console.log("Hero not found " + hero.hero_id);
          return null;
        }
        function renderRole(role: string): string {
          switch (role) {
            case "carry":
              return "Carry";
            case "mid":
              return "Mid";
            case "offlane":
              return "Offlane";
            case "support":
              return "Support";
            case "hard_support":
              return "Hard support";
            case "banned":
              return "Banned";
          }
          return "";
        }

        return (
          <div
            key={index}
            style={{
              padding: "0.1rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              <img
                key={String(filtered.localized_name)
                  .toLowerCase()
                  .replaceAll(" ", "_")}
                src={
                  `/assets/img/heroes/` +
                  String(filtered.localized_name)
                    .toLowerCase()
                    .replaceAll(" ", "_") +
                  `.png`
                }
                alt={String(filtered.localized_name)
                  .toLowerCase()
                  .replaceAll(" ", "_")}
                style={{
                  width: "1.6rem",
                  height: "1rem",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  zIndex: 1,
                }}
              />
            </span>
            <span style={{ fontSize: "0.4rem", fontWeight: "bold" }}>
              {renderRole(hero.role).length > 5
                ? renderRole(hero.role).substring(0, 7)
                : renderRole(hero.role)}
            </span>
          </div>
        );
      });
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className={"active_player"}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "lightgreen",
            }}
          >
            {renderPlayerHeroes(player.radiant_heroes, false)}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "#fc9090",
            }}
          >
            {renderPlayerHeroes(player.dire_heroes, false)}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0.2rem",
              background: "lightgray",
            }}
          >
            {renderPlayerHeroes(player.banned_heroes, true)}
          </div>
        </div>
      </div>
    );
  };
  const handleTournamentClick = async () => {
    if (!detailedTournament) {
      try {
        const details = await fetchDetailedTournament(tournament.uuid);
        setDetailedTournament(details);
        setEditableTournament(details); // Prepare for editing
      } catch (error) {
        console.error("Error fetching detailed tournament data:", error);
      }
    } else {
      setDetailedTournament(null); // Collapse details if already open
    }
  };

  const handleSaveChanges = async () => {
    if (editableTournament) {
      try {
        await onSaveTournament(editableTournament);
        setDetailedTournament(editableTournament);
        // setIsEditing(false);
        console.log("Tournament updated successfully.");
      } catch (error) {
        console.error("Error saving tournament changes:", error);
      }
    }
  };

  return (
    <div
      style={{
        marginBottom: "1rem",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <div
        key={tournament.uuid}
        style={{
          marginBottom: "1rem",
          padding: "0.5rem",
          border: "1px solid #ddd",
          borderRadius: "5px",
          background: tournament.active ? "#e8f5e9" : "#fbe9e7",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div onClick={handleTournamentClick} style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <span style={{ fontSize: "1rem", fontWeight: "bold" }}>{tournament.name}</span>
          <span style={{ fontSize: "0.9rem", color: "#555" }}>
            Players: {tournament.players_number}
          </span>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <DeleteIcon
            style={{
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => onDeleteTournament(tournament.uuid)}
          />
          <PersonAddIcon
            style={{
              color: "green",
              cursor: "pointer",
            }}
            onClick={() => onAddPlayer(tournament.uuid)}
          />
        </div>
      </div>

      {detailedTournament && (
        <div
          style={{
            paddingTop: "1rem",
            background: tournament.active ? "#e8f5e9" : "#fbe9e7",
            borderRadius: "5px",
          }}
        >
          {isEditing ? (
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <input
                type="text"
                value={editableTournament?.name || ""}
                onChange={(e) =>
                  setEditableTournament({
                    ...editableTournament!,
                    name: e.target.value,
                  })
                }
              />
              <input
                type="number"
                value={editableTournament?.prize || ""}
                onChange={(e) =>
                  setEditableTournament({
                    ...editableTournament!,
                    prize: Number(e.target.value),
                  })
                }
              />
             <input
              type="date"
              value={editableTournament?.start_date?.split("T")[0] || ""}
              onChange={(e) =>
                setEditableTournament({
                  ...editableTournament!,
                  start_date: e.target.value,
                })
              }
            />
            <input
              type="date"
              value={editableTournament?.end_date?.split("T")[0] || ""}
              onChange={(e) =>
                setEditableTournament({
                  ...editableTournament!,
                  end_date: e.target.value,
                })
              }
            />


              <button style={{marginBottom:'1rem'}} onClick={handleSaveChanges}>Save Changes</button>
              {/* <button onClick={() => setIsEditing(false)}>Cancel</button> */}
            </div>
          ) : (
            <div>
              <p>Prize: ${detailedTournament.prize}</p>
              <p>Status: {detailedTournament.active ? "Active" : "Inactive"}</p>
              <p>Start Date: {detailedTournament.start_date}</p>
              <p>End Date: {detailedTournament.end_date}</p>
              <button onClick={() => setIsEditing(true)}>Edit</button>
            </div>
          )}

          <div
            style={{
              padding: "0.5rem 1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              background: "lightblue",
              fontWeight: "bold",
            }}
          >
            <span style={{ flex: 1, textAlign: "center" }}>Player Name</span>
            <span style={{ flex: 1, textAlign: "center" }}>Points</span>
          </div>
          {detailedTournament.players.map((player) => (
            <>
            <div
              key={player.user_id}
              style={{
                padding: "0.5rem 1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid gray",
              }}
            >
              <span style={{ flex: 1, textAlign: "center" }}>{player.name}</span>
              <span style={{ flex: 1, textAlign: "center" }}>{player.points}</span>
            </div>
            <div>
              {renderPlayerDraft(player)}
            </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default Tournament;
