import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import { setDefaultLanguage, getTranslation } from "./../../translation";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import Alert from "@mui/material/Alert/Alert";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import { CompanyInfo, Guideline, SuperAdminBlog,Hero,HeroLit,TournamentType, TournamentLit, DetailedTournament, Player, PlayerLit } from "./../../types.tsx";
import SettingsIcon from "@mui/icons-material/Settings";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LogoutIcon from "@mui/icons-material/Logout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-calendar/dist/Calendar.css';
import "../../styles/Calendar.css";
import YouTube from "react-youtube";
import "../../styles/Reservation.css";
import "../../styles/AdminWelcome.css";
import LogoImg from "./../../img/wecon-mail.png"; // Adjust the path accordingly
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CompanyView from "./CompanyView";
import CompanyViewAll from "./CompanyViewAll.tsx"
import CompanyGuidelines from "./CompanyGuidelines";
import CompanySettings from "./CompanySettings";
import HeroStatsDisplay from "../fantasy/HeroStatsDisplay.tsx";
import Players from "../fantasy/Players.tsx";
import Tournament from "../fantasy/Tournament.tsx";
// import Checkbox from 'material-ui/Checkbox';

interface NewTournament {
  name: string;
  prize: number;
}


interface TournamentPlayer {
  user_id: number;
  name: string;
  points: number;
}

interface TournamentData {
  id: number;
  name: string;
  prize: number;
  players: TournamentPlayer[];
}



interface Company {
  company_name: string;
  company_uuid: string;
}

interface User {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
  password: string;
}


interface UserInfoShort {
  name: string;
  user_uuid: string;
  verified: boolean;
  roles: string[];
  email: string;
}

interface BlogDataResponse {
  user_info: UserInfoShort;
  blogs: SuperAdminBlog[];
  editor_blogs: SuperAdminBlog[];
}

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface AdminWelcomeProps {
  userDetails: UserDetails | null;
}

interface CompanyData {
  company_name: string;
  company_uuid: string;
  company_projects: string[];
  current_project_blogs: SuperAdminBlog[];
}



const AdminWelcome: React.FC<AdminWelcomeProps> = ({ userDetails }) => {
  const fetchHeroes = async () => {

    setRefreshingLoading(true)
    
    try {
      const response = await fetch("https://server.dotalive.org/api/heroes");
      const data = await response.json();
      console.log(data)
      setAllHeroes(data); // Update the services state with fetched data

      setRefreshingLoading(false)
    } catch (error) {
      setRefreshingLoading(false)
      console.error("Error fetching services:", error);
    }
  };

  const fetchTournamets = async () => {
    // ""
    try {
      const response = await fetch("https://server.dotalive.org/api/tournaments");
      const data = await response.json();
      // ""
      setTournamentsLit(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const updateHeroStats = async (heroId: number, updates: Partial<Hero>) => {
    try {

      setRefreshingLoading(true)
      const response = await axios.post('https://server.dotalive.org/api/update-hero', {
        id: heroId,
        updates,
      });
      if (response.status === 200) {
        // Update the local state after a successful backend update
        fetchHeroes()
      }
      
    } catch (error) {

      setRefreshingLoading(false)
      console.error('Failed to update hero:', error);
    }
  };

  const refreshHeroStatistics = async () => {
    try {
      setRefreshingLoading(true)
      const response = await fetch("https://server.dotalive.org/api/refresh-hero-stats");

      if (response.status === 200) {
        // Update the local state after a successful backend update
        fetchHeroes()
      }
    } catch (error) {
      setRefreshingLoading(false)
      console.error('Failed to update hero:', error);
    }
  }

 const fetchDetailedTournament = async (tournamentUuid: string): Promise<DetailedTournament> => {
  try {
    const response = await fetch(`https://server.dotalive.org/api/tournaments/${tournamentUuid}`);
    if (!response.ok) {
      throw new Error(`Error fetching tournament details: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching detailed tournament:", error);
    throw error;
  }
};

const fetchPlayerMatches = async (userId: string) => {
  try {
    const response = await fetch(`https://server.dotalive.org/api/users/193453a8-aedd-413e-98b8-56490241eea4/matches`); // Endpoint for fetching player matches
    const data = await response.json();

    // Transform the data to include user-specific match data
    const matches = data.map((match: any) => {
      const userMatch = match.user_match || {}; // Fallback if user match data is missing
      return {
        match_id: match.match_id,
        team1_name: match.team1_name,
        team1_logo: match.team1_logo,
        team2_name: match.team2_name,
        team2_logo: match.team2_logo,
        radiant_heroes: match.radiant_heroes || [], // Match radiant heroes
        dire_heroes: match.dire_heroes || [], // Match dire heroes
        banned_heroes: match.banned_heroes || [], // Match banned heroes
        played: match.played, // Match date
        user_match: {
          radiant_heroes: userMatch.radiant_heroes || [], // User-specific radiant heroes
          dire_heroes: userMatch.dire_heroes || [], // User-specific dire heroes
          banned_heroes: userMatch.banned_heroes || [], // User-specific banned heroes
          points: userMatch.points || 0, // Points earned by the user for this match
        },
      };
    });

    return matches;
  } catch (error) {
    console.error("Error fetching player matches:", error);
    return [];
  }
};


const fetchAllPlayers = async () => {
  try {
    const response = await fetch("https://server.dotalive.org/api/users"); // Endpoint for fetching all users
    const data = await response.json();

    // Map the fetched data to the Player interface
    const players: Player[] = data.map((user: any) => ({
      user_uuid: user.user_uuid,
      name: user.name,
      surname: user.surname || "", // Fallback if surname is not provided
      username: user.username || "", // Fallback if username is not provided
      points: user.points || "0", // Fallback if points are not provided
      radiant_heroes: JSON.parse(user.radiant_heroes || "[]"), // Parse radiant_heroes JSON
      dire_heroes: JSON.parse(user.dire_heroes || "[]"),       // Parse dire_heroes JSON
      banned_heroes: JSON.parse(user.banned_heroes || "[]"),   // Parse banned_heroes JSON
    }));

    setAllPlayers(players); // Update the allPlayers state
  } catch (error) {
    console.error("Error fetching players:", error);
  }
};




  const [isRefreshingLoading, setRefreshingLoading] = useState(false);
  const [matchIdPoints, setMatchIdPoints] = useState(0);
  const [loadingMatch, setLoadingMatch] = useState(false);

  // setLoadingMatch(true)
  
  const [showGuidelineEditor, setShowGuidelineEditor] = useState<string | null>(
    null
  );
  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([]);
  const [newTournamentState, setNewTournamentState] = useState<Omit<TournamentType, "id" | "players">>({
    uuid: crypto.randomUUID(),  // Generate unique UUID
    name: "",
    img: "",
    prize: 0,
    start_date: new Date().toISOString().split("T")[0], // Default to today's date
    end_date: new Date().toISOString().split("T")[0],   // Default to today's date
    active: true, // Default to active
  });
  

  const handleEyeIconClick = (guidelineId: string) => {
    setShowGuidelineEditor((prevId) =>
      prevId === guidelineId ? null : guidelineId
    );
  };

  const handleCheckboxChange = (guidelineUuid: string) => {
    setSelectedGuidelines((prevSelected) =>
      prevSelected.includes(guidelineUuid)
        ? prevSelected.filter((uuid) => uuid !== guidelineUuid)
        : [...prevSelected, guidelineUuid]
    );
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleChangeEditor = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`editor_name`]: event.target.value,
    }));
  };
  const handleChangeStatus = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`status`]: event.target.value,
    }));
  };
  const handleChange = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`writer`]: event.target.value,
    }));
  };

  const [editorContent, setEditorContent] = useState("");

  const handleContentChange = (content: any) => {
    setEditorContent(content);
  };

  const [tournaments, setTournaments] = useState<TournamentData[]>([
    {
      id: 1,
      name: "Summer Championship",
      prize: 1000,
      players: [
        { user_id: 1, name: "Nikola", points: 150 },
        { user_id: 2, name: "Mario", points: 120 },
      ],
    },
    {
      id: 3,
      name: "Autumn Cup",
      prize: 1500,
      players: [
        { user_id: 4, name: "Anna", points: 130 },
        { user_id: 5, name: "Linda", points: 140 },
      ],
    },
  ]);

  const [newTournament, setNewTournament] = useState({ name: "", prize: 0 });

  const handleAddTournament = async () => {
    const newTournament = {
      ...newTournamentState,
      players: [], // Default empty players array
    };
  
    try {
      const response = await fetch("https://server.dotalive.org/api/add-tournament", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newTournament),
      });
  
      if (!response.ok) {
        throw new Error("Failed to add tournament");
      }
  
      console.log("Tournament added successfully");
      fetchTournamets(); // Refresh the tournament list
      setNewTournamentState({
        uuid: crypto.randomUUID(),
        name: "",
        img: "",
        prize: 0,
        start_date: new Date().toISOString().split("T")[0],
        end_date: new Date().toISOString().split("T")[0],
        active: true,
      }); // Reset form
    } catch (error) {
      console.error("Error adding tournament:", error);
    }
  };
  
  
  

  const handleRemovePlayerFromTournament = (tournamentId: number, userId: number) => {
    setTournaments(
      tournaments.map(tournament =>
        tournament.id === tournamentId
          ? { ...tournament, players: tournament.players.filter(player => player.user_id !== userId) }
          : tournament
      )
    );
  };


  const handleSubmit = () => {
    // Here you would typically send the content to your server
    console.log(editorContent);
  };

  const navigate = useNavigate();
  const [getCompanyName, setGetCompanyName] = useState<String>("");
  const [newCompany, setNewCompany] = useState<CompanyInfo>({
    email: "",
    name: "",
    password: "",
  });
  const [currentUser, setUser] = useState<PlayerLit>({
   name:'',
   points:0,
   username:'',
   user_uuid:''
  });

  const [selectedOptions, setSelectedOptions] = useState([] as any);

  const MultipleSelectDropdown = () => {
    const options = ["Writer", "Editor"]; // Replace with your options

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(selectedValues);

      setUser((prevUser) => ({
        ...prevUser,
        roles: selectedValues,
      }));
    };

    return (
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="multiple-select-label">Roles</InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="Options" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const SelectTools = () => {
    const options = ["Grammarly", "Positional AI", "Copyscape", "Copyleaks"]; // Replace with your options

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(selectedValues);

      setUser((prevUser) => ({
        ...prevUser,
        roles: selectedValues,
      }));
    };

    return (
      <FormControl style={{ width: "100%", padding: "0rem 1rem" }}>
        <InputLabel id="multiple-select-label">Tools</InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="Tools" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const [newBlog, setNewBlog] = useState<Boolean>(false);
  const handleAddPlayer = (uuid: string) => {
    console.log(`Add player to tournament with UUID: ${uuid}`);
    // Implement add player functionality here
  };
  
  const onSaveTournament = async (updatedTournament: DetailedTournament) => {
    try {
      const response = await fetch(`https://server.dotalive.org/api/save-tournament/${updatedTournament.uuid}`, {
        method: "PUT", // Use PUT for updating
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: updatedTournament.name,
          prize: updatedTournament.prize,
          start_date: updatedTournament.start_date,
          end_date: updatedTournament.end_date,
          active: updatedTournament.active,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to save tournament changes");
      }
  
      console.log("Tournament updated successfully");
      // Optionally refresh the tournament list
      fetchTournamets();
    } catch (error) {
      console.error("Error saving tournament changes:", error);
    }
  };
  
  
  const handleDeleteTournament = async (uuid: string) => {
    try {
      const response = await fetch(`https://server.dotalive.org/api/tournaments/${uuid}`, {
        method: "DELETE",
      });
  
      if (!response.ok) {
        throw new Error("Failed to delete tournament");
      }
  
      console.log("Tournament deleted successfully");
      // Refresh the tournament list after deletion
      fetchTournamets();
    } catch (error) {
      console.error("Error deleting tournament:", error);
    }
  };

    
  const handleUpdatePoints = async (matchid:number) => {
    try {
      const response = await axios.post('https://server.dotalive.org/api/update-user-points', {
        matchid: matchid
      });
      setLoadingMatch(false)
      if (response.status === 200) {
        // Update the local state after a successful backend update
        fetchAllPlayers()
      }
      
    } catch (error) {

      setRefreshingLoading(false)
      console.error('Failed to update hero:', error);
    }
  };
  
  
  const handleEditPlayer = (user_id: number, name: string, surname: string, username: string) => {
    // setAllPlayers(
    //   allPlayers.map(player =>
    //     player. === user_id ? { ...player, name, surname, username } : player
    //   )
    // );
  };
  // const handleAddHero = (
  //   userId: number,
  //   hero: HeroLit,
  //   type: "radiant" | "dire" | "banned"
  // ) => {
  //   setAllPlayers(
  //     allPlayers.map(player => {
  //       if (player.user_uuid === userId) {
  //         if (type === "radiant" && player.radiant_heroes.length < 5) {
  //           return {
  //             ...player,
  //             radiant_heroes: [...player.radiant_heroes, hero],
  //           };
  //         } else if (type === "dire" && player.dire_heroes.length < 5) {
  //           return {
  //             ...player,
  //             dire_heroes: [...player.dire_heroes, hero],
  //           };
  //         } else if (type === "banned" && player.banned_heroes.length < 2) {
  //           return {
  //             ...player,
  //             banned_heroes: [...player.banned_heroes, hero],
  //           };
  //         }
  //       }
  //       return player;
  //     })
  //   );
  // };
  // const handleRemoveHero = (
  //   userId: number,
  //   heroId: number,
  //   type: "radiant" | "dire" | "banned"
  // ) => {
  //   setAllPlayers(
  //     allPlayers.map(player => {
  //       if (player.user_id === userId) {
  //         if (type === "radiant") {
  //           return {
  //             ...player,
  //             radiant_heroes: player.radiant_heroes.filter(hero => hero.hero_id !== heroId),
  //           };
  //         } else if (type === "dire") {
  //           return {
  //             ...player,
  //             dire_heroes: player.dire_heroes.filter(hero => hero.hero_id !== heroId),
  //           };
  //         } else if (type === "banned") {
  //           return {
  //             ...player,
  //             banned_heroes: player.banned_heroes.filter(hero => hero.hero_id !== heroId),
  //           };
  //         }
  //       }
  //       return player;
  //     })
  //   );
  // };
  // const handleDeletePlayer = (user_id: number) => {
  //   setAllPlayers(allPlayers.filter(player => player.user_id !== user_id));
  //   // setTournaments(
  //   //   tournaments.map(tournament => ({
  //   //     ...tournament,
  //   //     players: tournament.players.filter(player => player.user_id !== user_id),
  //   //   }))
  //   // );
  // };
  const createNewCompany = async () => {
    try {
      StartLoading();
      await fetch("https://server.dotalive.org/api/company/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCompany),
      });
      StopLoading();
      setCreateCompany(!createNewCompany);
      // tuka
      setNewCompany({
        email: "",
        name: "",
        password: "",
      });
      setGetCompanyName("");
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  const createNewWriter = async () => {
    try {
      StartLoading();
      console.log(currentUser);
      await axios.post(
        `https://server.dotalive.org/api/writer/create/`,
        currentUser, // Assuming `user` is the object that matches your User interface
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setCreateWriter(!createWriter);
      setGetCompanyName("");
      fetchUsers();
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };



  const renderMainView = () => {
  
    ("");
    switch (currentView) {
      case "home":
        return (
          <div style={{padding:'1rem'}}>
          <h3>Welcome to Homepage</h3>
          <div style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
          <TextField
          label="Additional unique information"
          value={matchIdPoints}
          onChange={(e) => {
              setMatchIdPoints(Number(e.target.value))
          }}
          className="introduction_text"
        />
         <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    background: "#333",
                    fontSize:'0.5rem',
                  }}
                  onClick={()=>{
                    setLoadingMatch(true)
                    handleUpdatePoints(matchIdPoints)
                  }}
                >
                  {!loadingMatch ? "UPDATE USER POINTS" :"Loading..."}
                </Button>
          </div>
          {/* <AdminPage/> */}
          </div>
        );
        case "all":
          return (
            <CompanyViewAll
            login={true}
            all_users={all_users}
            company={currentCompanyData}
          />
          );
      case "heroes":
        return (
          <HeroStatsDisplay isRefreshingLoading={isRefreshingLoading} heroes={allHeroes} updateHero={updateHeroStats} refreshHeroStatistics={refreshHeroStatistics} />
        );
      case "players":
        return (
          <Players
          heroes = {allHeroes}
          players={allPlayers}
          onAddPlayer={(name, surname, username) => {}}
          onEditPlayer={(user_id, name, surname, username) =>
            handleEditPlayer(user_id, name, surname, username)
          }
          onAddHero={(userId, hero, type) => {}}
          onRemoveHero={(userId, heroId, type) => {}}
          onDeletePlayer={()=>{}}
        />
        );
        case "tournaments":
        return (
          <div style={{ padding: "1rem", background: "#f9f9f9" }}>
          <h3 style={{ marginBottom: "1rem" }}>Tournaments</h3>
          <div style={{ marginBottom: "1rem", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <input
              type="text"
              placeholder="Tournament Name"
              value={newTournamentState.name}
              onChange={(e) =>
                setNewTournamentState({
                  ...newTournamentState,
                  name: e.target.value,
                })
              }
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <input
              type="text"
              placeholder="Image URL"
              value={newTournamentState.img}
              onChange={(e) =>
                setNewTournamentState({
                  ...newTournamentState,
                  img: e.target.value,
                })
              }
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <input
              type="number"
              placeholder="First Place Prize"
              value={newTournamentState.prize}
              onChange={(e) =>
                setNewTournamentState({
                  ...newTournamentState,
                  prize: Number(e.target.value),
                })
              }
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <input
              type="date"
              placeholder="Start Date"
              value={newTournamentState.start_date}
              onChange={(e) =>
                setNewTournamentState({
                  ...newTournamentState,
                  start_date: e.target.value,
                })
              }
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <input
              type="date"
              placeholder="End Date"
              value={newTournamentState.end_date}
              onChange={(e) =>
                setNewTournamentState({
                  ...newTournamentState,
                  end_date: e.target.value,
                })
              }
              style={{
                padding: "0.5rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
            <button
              onClick={handleAddTournament}
              style={{
                padding: "0.5rem",
                background: "green",
                color: "white",
                border: "none",
                borderRadius: "5px",
                fontSize: "0.9rem",
              }}
            >
              Add Tournament
            </button>
          </div>

    
          {/* Active Tournaments */}
          <div style={{ marginBottom: "2rem" }}>
            <h4 style={{ borderBottom: "1px solid #ddd", paddingBottom: "0.5rem" }}>Active Tournaments</h4>
            {activeTournaments.map(tournament => (
                 <Tournament
                 allHeroes={allHeroes}
                 key={tournament.name}
                 tournament={tournament}
                 fetchDetailedTournament={fetchDetailedTournament}
                 onDeleteTournament={handleDeleteTournament}
                 onAddPlayer={handleAddPlayer}
                 onSaveTournament={onSaveTournament}
               />
            ))}
          </div>
    
          {/* Inactive Tournaments */}
          <div>
            <h4 style={{ borderBottom: "1px solid #ddd", paddingBottom: "0.5rem" }}>Inactive Tournaments</h4>
            {inactiveTournaments.map(tournament => (
                 <Tournament
                 allHeroes={allHeroes}
                 key={tournament.name}
                 tournament={tournament}
                 fetchDetailedTournament={fetchDetailedTournament}
                 onDeleteTournament={handleDeleteTournament}
                 onAddPlayer={handleAddPlayer}
                 onSaveTournament={onSaveTournament}
               
               />
            ))}
          </div>
        </div>
        );

      default:
        return null; // Return null if no case matches
    }
  };

  const updateUser = async () => {
    try {
      StartLoading();
      console.log(currentUser);
      await axios.post(
        `https://server.dotalive.org/api/writer/update/`,
        currentUser, // Assuming `user` is the object that matches your User interface
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setCreateWriter(!createWriter);
      setEditUser(false);
      setGetCompanyName("");
      setSelectedWriterUuid(currentUser.user_uuid);
      getWriterBlogs(currentUser.user_uuid);
      fetchUsers();
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };
  const [showAdditionalGuidelines, setShowAdditionalGuidelines] = useState(
    false
  );
  const [guidelines, setGuidelines] = useState([]);

  const fetchGuidelines = async () => {
    // edit here
    if (true) {
      const requestBody = {
        // company_uuid: company.company_uuid,
      };

      try {
        const response = await fetch(
          "https://server.dotalive.org/api/company/guidelines",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);

        // setSelectedProject(data[selectedGuideline]);
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };


  const [selectedUserId, setSelectedUserId] = useState<Number>(9999);
  const [selectedCompany, setSelectedCompany] = useState<Number>(-1);
  const [currentCompanyUuid, setCurrentCompanyUuid] = useState<String>("");
  const [selectedWriterUuid, setSelectedWriterUuid] = useState<String>("");
  const [currentView, setCurrenteView] = useState<String>("home");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [all_users, setAllUsers] = useState<PlayerLit[]>([]);
  const [tournamentsLit, setTournamentsLit] = useState<TournamentLit[]>([]);
  const activeTournaments = tournamentsLit.filter(tournament => tournament.active);
  const inactiveTournaments = tournamentsLit.filter(tournament => !tournament.active);
  
  const [contact, setContact] = useState(false); // Loading state
  const [createCompany, setCreateCompany] = useState(false); // Loading state
  const [createWriter, setCreateWriter] = useState(false); // Loading state
  const [editUser, setEditUser] = useState(false); // Loading state
  const [showCompanyGuidelines, setShowCompanyGuidelines] = useState(false);
  const [generalGuidelines, setGeneralGuidelines] = useState(false);
  const [createEditor, setCreateEditor] = useState(false); // Loading state

  function getRender(status: string): React.ReactNode {
    let backgroundColor = "lightgreen";
    let color = "white";

    switch (status) {
      case "Pending": {
        backgroundColor = "lightgray";
        color = "black";
        break;
      }
      case "In Progress": {
        backgroundColor = "blue";
        break;
      }
      case "Written": {
        backgroundColor = "lightgreen";
        color = "black";
        break;
      }
      case "Editor": {
        backgroundColor = "orange";
        break;
      }
      case "Completed": {
        backgroundColor = "darkgreen";
        break;
      }
      case "Revision": {
        backgroundColor = "purple";
        break;
      }
      case "Paid": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Failed": {
        backgroundColor = "red";
        break;
      }
    }
    return (
      <>
        <div
          style={{
            padding: "0.2rem 0.5rem",
            // border: "1px solid gray",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "3vh",
            color: color,
            background: backgroundColor,
          }}
        >
          {status}
        </div>
      </>
    );
  }
  const [allPlayers, setAllPlayers] = useState<Player[]>([]);
  const [toast_message, setToastMessage] = useState("empty_fields");

  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);
  const handleCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteOpen(true);
  };
  const [isCreatePackageOpen, setIsCreatePackageOpen] = useState(false);
  const handleCloseCreatePackageDialog = () => {
    setIsCreatePackageOpen(false);
  };

  const handleOpenCreatePackageDialog = () => {
    setIsCreatePackageOpen(true);
  };

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  const [dateChanged, setDateChanged] = useState(true);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showAdminGuidelines, setShowAdminGuidelines] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showExampleArticle, setShowExampleArticle] = useState(false);
  const [createGoogleDoc, setCreateGoogleDoc] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);

  const [allSelected, setAllSelected] = useState(true);
  const [activeView, setActiveView] = useState("booking");

  // const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [allHeroes, setAllHeroes] = useState<Hero[]>([])
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();
  const [currentCompanieBlogs, setCurrentCompanieBlogs] = useState<
    SuperAdminBlog[]
  >([]);
  const [currentWriterBlogs, setCurrentWriterBlogs] = useState<
    BlogDataResponse
  >({
    blogs: [],
    editor_blogs: [],
    user_info: {
      email: "",
      user_uuid: "",
      name: "",
      roles: [],
      verified: false,
    },
  });

  // Function to fetch blogs
  const fetchBlogs = async () => {
    try {
      const response = await fetch("https://server.dotalive.org/api/blogs");
      const data = await response.json();
      setBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

 
  // Function to fetch companies
  const fetchCompanies = async () => {
    try {
      const response = await fetch("https://server.dotalive.org/api/companies");
      const data = await response.json();
      setCompanies(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  const fetchUsers = async () => {
    // ""
    try {
      const response = await fetch("https://server.dotalive.org/api/users");
      const data = await response.json();
      // ""
      setAllUsers(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  
  // Function to fetch services
  const saveChanges = async () => {
    try {
      StartLoading();
      await axios.post(
        `https://server.dotalive.org/api/save/${currentBlog.blog_uuid}`,
        currentBlog,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setContact(!setContact);
      if (SelectedView === "users") {
        getWriterBlogs(selectedWriterUuid.toString());
      } else {
        getCompanyBlogs(currentCompanyUuid.toString());
      }

      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };

  useEffect(() => {
    fetchHeroes()
    fetchTournamets()
    fetchAllPlayers()
    fetchPlayerMatches("193453a8-aedd-413e-98b8-56490241eea4")
    // fetchBlogs();
    // fetchCompanies();
    // fetchUsers();
  }, []);

  const [currentBlog, setCurrentBlog] = useState<SuperAdminBlog>({
    blog_uuid: "",
    amount: 0,
    blog_title: "",
    company_name: "",
    deadline: "",
    keywords: "",
    status: "Pending",
    editor_name: "",
    editor_uuid: "",
    url: "",
    words: 0,
    company_uuid: "",
    writer_uuid: "",
    writer: "",
    additional_info: "",
    blog_length: 0,
    tools: [],
    additional_guidelines: "",
    company_guidelines: [],
    example_article: [],
    general_guidelines: false,
    multiple: false,
    project_name: "",
    urgent: false,
    category:'',
    sub_category:''
  });

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const [SelectedView, setSelectedView] = useState("users");
  const handleAdminTimeslotClick = (index: any) => {
    // Toggle visibility: if clicked again on the same item, it will hide
    // "";
    setContact(!contact);
    // setSelectedBlogIndex(selectedBlogIndex === index ? null : index);
  };

  const getCompanyBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://server.dotalive.org/api/current-company/blogs/${company_uuid}`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs);
      setCurrentCompanyData(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getAllBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://server.dotalive.org/api/all/blogs/`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs);
      setCurrentCompanyData(
        {company_name:"all",
      company_projects:[],
    company_uuid:'all',
  current_project_blogs:data.current_project_blogs}
      ); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getWriterBlogs = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://server.dotalive.org/api/writer/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setCurrentWriterBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  // Custom styles
  const checkboxStyle = {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
    padding: "10px",
    bordeRadius: "10px",
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  };

  const handleChangeProject = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`project_name`]: event.target.value,
    }));
  };


  return (
    <>
      <div
        className="container minh-100"
        // , borderTop:'3vh solid #97112a',borderLeft:'3vh solid #97112a'
        style={{ display: "flex", width: "100%" }}
      >
        <div
          className="admin_header minh-100 left_side"
          style={{
            minHeight: "100%",
            width: "15%",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
            background:'#dd183b'
          }}
        >
          <div
            className="top_view"
            style={{
              userSelect: "none",
              minHeight: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid lightgray",
              background:'#dd183b'
            }}
          >
            <img
              src={"https://dotalive.org/wp-content/uploads/2024/06/cropped-dotalive.webp"}
              onClick={() => {
                  setCurrenteView("home");
                
              }}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
          </div>
          {/* NAV BAR LEFT */}
          <div style={{ minHeight: "100%" }}>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                color: currentView =="home" ? "white" : "white",
                background: currentView =="home" ? "#97112a" : "#dd183b",
                alignItems: "center",
                display: "flex",
                fontWeight:'bold',
                justifyContent: "space-between",
              }}
              onClick={() => {
                setCurrenteView("home")
              }}
            >
              Home
            </div>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                background: currentView =="heroes" ? "#97112a" : "#dd183b",
                color: currentView =="heroes" ? "white" : "white",
                alignItems: "center",
                fontWeight:'bold',
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setCurrenteView("heroes")
              }}
            >
              Heroes
            </div>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                background: currentView =="tournaments" ? "#97112a" : "#dd183b",
                color: currentView =="tournaments" ? "white" : "white",
                alignItems: "center",
                fontWeight:'bold',
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setCurrenteView("tournaments")
              }}
            >
              Tournaments
            </div>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                background: currentView =="players" ? "#97112a" : "#dd183b",
                color: currentView =="players" ? "white" : "white",
                alignItems: "center",
                fontWeight:'bold',
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setCurrenteView("players")
              }}
            >
              Players
            </div>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                // background: showAdminGuidelines ? "#97112a" : "#dd183b",
                color: showAdminGuidelines ? "white" : "white",
                alignItems: "center",
                fontWeight:'bold',
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                // setCurrentCompanyUuid("");
                setShowCompanies(false);
                setShowAdminGuidelines(!showAdminGuidelines);
                setCurrentCompanyUuid("");
                if (showAdminGuidelines) {
                  // setCurrenteView("")
                }
              }}
            >
              News
            </div>
          </div>
        </div>
        <div className="admin_header minh-100 right_side">
          {/* GORE KAJ STO E LOGOUT KOPCHETO */}
          <div className="top_view header_logout">
            <img
              className="mobile_img"
              src={LogoImg}
              onClick={() => {
                setCurrenteView("home");
              }}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
            <div
              className="logout"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");

                navigate("/login");
              }}
            >
              {" "}
              <LogoutIcon />{" "}
              <span style={{ marginLeft: "0.5rem" }}>Log out </span>
            </div>
          </div>
          {/* TUKAA */}
          {/* <AdminPage/> */}
          {renderMainView()}
        
        </div>
      </div>
      
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminWelcome;
