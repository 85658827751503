import React, { useState } from "react";
import "./../styles/SoldMagazine.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
const DraftDetails = ({
  analysis,
  team1,
  team2,
  conclusion,
  prediction,
  summary,
}: {
  analysis: any;
  team1: string;
  team2: string;
  conclusion: string;
  prediction: string;
  summary: { team1: string; team2: string };
}) => {


  const additionalFactors = analysis.additional_factors;

  const [selectedDropdown, setSelectedDropdown] = useState<string[]>([]);
  const handleToggle = (title: string) => {
    setSelectedDropdown((prev) => {
      if (prev.includes(title)) {
        // If the title is already in the array, remove it
        return prev.filter((item) => item !== title);
      } else {
        // If the title is not in the array, add it
        return [...prev, title];
      }
    });
    console.log(selectedDropdown)
  };


  if (!analysis) {
    return <div>No analysis data available</div>;
  }
  return (
   <div>
     <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "1.5rem",
        // padding: "0rem 1rem",
        color: "#ffffff",
        borderRadius: "10px",
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        maxWidth: "90%",
        margin: "auto",
      }}
    >
      <h2
        style={{
          gridColumn: "1 / -1",
          textAlign: "center",
          fontSize: "2rem",
          marginBottom: "1.5rem",
        //   color: "#1abc9c",
        color:'black',
          fontWeight: "bold",
        }}
      >
        DRAFT OVERVIEW
      </h2>
      <div
      style={{
        display: "flex",
        // gridTemplateColumns: "repeat(1, 1fr)",
        gap: "1.5rem",
        flexDirection:'column',
        // padding: "0rem 1rem",
        color: "#ffffff",
        borderRadius: "10px",
      }}
    >
              <h5
        style={{
          gridColumn: "1 / -1",
          textAlign: "left",
          fontSize: "1.2rem",
          margin:'0rem',
        //   color: "#1abc9c",
        color:'black',
          fontWeight: "bold",
        }}
      >
        {team1}
      </h5>
      {[
        {
          title: "Teamfight Potential",
          description: analysis.teamfight_potential?.description || "No data available",
          edge: analysis.teamfight_potential?.edge || "No data available",
        },
        {
          title: "Hero Synergy",
          description: analysis.hero_synergy?.description || "No data available",
          edge: analysis.hero_synergy?.edge || "No data available",
        },
        {
          title: "Hero Counters",
          description: analysis.hero_counters?.description || "No data available",
          edge: analysis.hero_counters?.edge || "No data available",
        },
        {
          title: "Map Control",
          description: analysis.map_control?.description || "No data available",
          edge: analysis.map_control?.edge || "No data available",
        },
        {
          title: "Cooldown Dependency",
          description: analysis.cooldown_dependency?.description || "No data available",
          edge: analysis.cooldown_dependency?.edge || "No data available",
        },
        {
          title: "Tempo Setting",
          description: analysis.tempo_setting?.description || "No data available",
          edge: analysis.tempo_setting?.edge || "No data available",
        },
        {
          title: "Objective Taking",
          description: analysis.objective_taking?.description || "No data available",
          edge: analysis.objective_taking?.edge || "No data available",
        },
        {
          title: "Stacking",
          description: analysis.stacking?.description || "No data available",
          edge: analysis.stacking?.edge || "No data available",
        },
        {
          title: "Lane Dominance",
          description: analysis.lane_dominance?.description || "No data available",
          edge: analysis.lane_dominance?.edge || "No data available",
        },
      ].map((section, index) => {

        if(section.edge ==="team2") {
            return
        }
        return  <div
        key={index}
        style={{
          // border: "1px solid #0f5132",
          padding: "1rem",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
          maxHeight:selectedDropdown.includes(section.title) ? "":"2.5rem"
        }}
        className={section.edge === "team2"? "background_dire":"background_radiant"}
      >
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}> 
      <h3
          style={{
            margin: "0 0 0rem 0",
          //   color: "#1abc9c",
        //   color:'black',
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
          className={section.edge === "team2"? "dire":"radiant"}
        >
          {section.title}
        </h3>
       
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}> 
      
                {/* {section.edge && (
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}

            className={section.edge === "team2"? "dire":"radiant"}
          >
            Edge: {section.edge === "team2" ? team2 : team1}
          </p>
        )} */}
      <div onClick={() => handleToggle(section.title)}  className={selectedDropdown.includes(section.title) ? section.edge ==="team2"? "details-button active_dire":"details-button active_radiant":"details-button"}> 
          <span>Details</span>
           {selectedDropdown.includes(section.title) ? <><ArrowDropUp/></>:<><ArrowDropDownIcon/></>}
      </div>
      </div>
      </div>
        {selectedDropdown.includes(section.title) ? <><p
          style={{
            margin: 0,
          //   color: "#b7d4c3",
             color:'black',
            fontSize: "1.1rem",
            lineHeight:'1.5rem'
          }}
        >
          {section.description}
        </p></>:<></>}
         
      </div>
      })}
    </div>
    <div
      style={{
        display: "flex",
        // gridTemplateColumns: "repeat(1, 1fr)",
        gap: "1.5rem",
        flexDirection:'column',
        // padding: "0rem 1rem",
        color: "#ffffff",
        borderRadius: "10px",
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  
      }}
    >
      <h5
        style={{
          gridColumn: "1 / -1",
          textAlign: "left",
          fontSize: "1.2rem",
          margin:'0rem',
        //   color: "#1abc9c",
        color:'black',
          fontWeight: "bold",
        }}
      >
        {team2}
      </h5>      {[
        {
          title: "Teamfight Potential",
          description: analysis.teamfight_potential?.description || "No data available",
          edge: analysis.teamfight_potential?.edge || "No data available",
        },
        {
          title: "Hero Synergy",
          description: analysis.hero_synergy?.description || "No data available",
          edge: analysis.hero_synergy?.edge || "No data available",
        },
        {
          title: "Hero Counters",
          description: analysis.hero_counters?.description || "No data available",
          edge: analysis.hero_counters?.edge || "No data available",
        },
        {
          title: "Map Control",
          description: analysis.map_control?.description || "No data available",
          edge: analysis.map_control?.edge || "No data available",
        },
        {
          title: "Cooldown Dependency",
          description: analysis.cooldown_dependency?.description || "No data available",
          edge: analysis.cooldown_dependency?.edge || "No data available",
        },
        {
          title: "Tempo Setting",
          description: analysis.tempo_setting?.description || "No data available",
          edge: analysis.tempo_setting?.edge || "No data available",
        },
        {
          title: "Objective Taking",
          description: analysis.objective_taking?.description || "No data available",
          edge: analysis.objective_taking?.edge || "No data available",
        },
        {
          title: "Stacking",
          description: analysis.stacking?.description || "No data available",
          edge: analysis.stacking?.edge || "No data available",
        },
        {
          title: "Lane Dominance",
          description: analysis.lane_dominance?.description || "No data available",
          edge: analysis.lane_dominance?.edge || "No data available",
        },
      ].map((section, index) => {

        if(section.edge !="team2") {
            return
        }
        return  <div
        key={index}
        style={{
          // border: "1px solid #0f5132",
          padding: "1rem",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
          maxHeight:selectedDropdown.includes(section.title) ? "":"2.5rem"
        }}
        className={section.edge === "team2"? "background_dire":"background_radiant"}
      >
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}> 
      <h3
          style={{
            margin: "0 0 0rem 0",
          //   color: "#1abc9c",
        //   color:'black',
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
          className={section.edge === "team2"? "dire":"radiant"}
        >
          {section.title}
        </h3>
       
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}> 
      
                {/* {section.edge && (
          <p
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}

            className={section.edge === "team2"? "dire":"radiant"}
          >
            Edge: {section.edge === "team2" ? team2 : team1}
          </p>
        )} */}
      <div onClick={() => handleToggle(section.title)}  className={selectedDropdown.includes(section.title) ? section.edge ==="team2"? "details-button active_dire":"details-button active_radiant":"details-button"}> 
          <span>Details</span>
           {selectedDropdown.includes(section.title) ? <><ArrowDropUp/></>:<><ArrowDropDownIcon/></>}
      </div>
      </div>
      </div>
        {selectedDropdown.includes(section.title) ? <><p
          style={{
            margin: 0,
          //   color: "#b7d4c3",
             color:'black',
            fontSize: "1.1rem",
            lineHeight:'1.5rem'
          }}
        >
          {section.description}
        </p></>:<></>}
         
      </div>
      })}

      </div>
      {/* Additional Factors */}
      {additionalFactors && (
        <div
          style={{
            gridColumn: "1 / -1",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
          }}
        >
          <h3
            style={{
              margin: "0 0 0.5rem 0",
              color: "black",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Additional Factors
          </h3>
          {Object.entries(additionalFactors).map(([key, factor]: any, idx) => (
            <div
              key={idx}
              style={{
                marginBottom: "0.5rem",
                padding: "0.5rem",
                borderRadius: "5px",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h4
                style={{
                  margin: "0 0 0.2rem 0",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                
                {key.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a:any) => a.toUpperCase())}
              </h4>
              <p
                style={{
                  margin: 0,
                  color: "black",
                  fontSize: "0.9rem",
                }}
              >
                {factor.description || "No data available"}
              </p>
              <p
                style={{
                  color: "#f39c12",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                Edge: {factor.edge === "team2" ? team2 : team1}
              </p>
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          gridColumn: "1 / -1",
          padding: "1rem",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
        }}
      >
        <h3
          style={{
            margin: "0 0 0.5rem 0",
            color: "black",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          Conclusion
        </h3>
        <p style={{ margin: 0, color: "black", fontSize: "1.2rem" }}>{conclusion}</p>
      </div>

      <div
        style={{
          gridColumn: "1 / -1",
          padding: "1rem",
          borderRadius: "8px",
        }}
      >
        <h3
          style={{
            margin: "0 0 0.5rem 0",
            color: "black",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          Prediction
        </h3>
        <p style={{ margin: 0, color: "black", fontSize: "0.9rem" , marginBottom:'12rem' }}>WINNER: {prediction === "team2" ? team2 : team1}</p>
      </div>
    </div>
    
   </div>
  );
};

export default DraftDetails;
