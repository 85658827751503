import React, { useState } from "react";
import "./../styles/SoldMagazine.css";
import {
  Alert,
  Button,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { teams, all_teams } from "./Teamlist.tsx"; // Import the teams
import { matches } from "./Matches.tsx";
import { players, skitter_games } from "./Players.tsx";
import {heroCounterpicks} from './CounterPicks.tsx'
import { heroes } from "./Heroes.tsx"
import DraftAnalytics from "./DraftAnalytics.tsx";
import DraftDetails from "./DraftDetails.tsx";
import CounterPickList from "./CounterPickList.tsx";

const HeroCategory = ({
  title,
  icon,
  heroes,
  onAddToRadiant,
  onAddToDire,
  teamRadiant,
  teamDire,
}: {
  title: string;
  icon: string;
  heroes: string[];
  onAddToRadiant: (hero: string, role: string) => void;
  onAddToDire: (hero: string, role: string) => void;
  teamRadiant: string[];
  teamDire: string[];
}) => {
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [showRadiantRoles, setShowRadiantRoles] = useState<boolean>(false);
  const [showDireRoles, setShowDireRoles] = useState<boolean>(false);

  return (
    <div id={title.toLowerCase()}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignContent: "center",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <img
          style={{ width: "2rem" }}
          src={`/assets/img/heroes/${icon}`}
          alt={title}
        />
        <span style={{ fontSize: "2rem", fontWeight: "bold", color: "gray" }}>
          {title}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px",
        }}
      >
        {heroes.map((hero) => {
          const isUsed =
            teamRadiant.includes(hero.replace(".png", "")) ||
            teamDire.includes(hero.replace(".png", ""));

          return (
            <div
              key={hero}
              style={{
                position: "relative",
                display: "inline-block",
                width: "4.2rem",
                height: "2.6rem",
                opacity: isUsed ? "0.3" : "1",
              }}
              className={isUsed ? "0.3" : "hero_img"}
            >
              <img
                src={`/assets/img/heroes/${hero}`}
                alt={hero}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onMouseEnter={() => {
                  if (!isUsed) {
                    setCurrentId(hero); // Update the current hero ID
                  }
                }}
                onMouseLeave={() => {
                  setCurrentId(null); // Clear the hero ID
                  setShowRadiantRoles(false); // Clear the hero ID
                  setShowDireRoles(false);
                }}
              />
              {/* Overlay message when hovering */}
              {currentId === hero && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    bottom:
                      !showRadiantRoles && !showDireRoles
                        ? "-1.5rem"
                        : "-5.5rem",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "rgba(0, 0, 0, 0.8)",
                    color: "red",
                    fontWeight: "bold",
                    padding: "0.2rem 0.2rem",
                    borderRadius: "5px",
                    zIndex: 2,
                    gap: "5px",
                  }}
                  onMouseEnter={() => {
                    setCurrentId(hero); // Update the current hero ID
                  }}
                  onMouseLeave={() => {
                    setCurrentId(null); // Clear the hero ID
                    setShowRadiantRoles(false); // Clear the hero ID
                    setShowDireRoles(false);
                  }}
                >
                  <div style={{ fontSize: "0.5rem", display: "flex" }}>
                    {showRadiantRoles ? (
                      <>
                        <div className="role_div">
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(hero.replace(".png", ""), "carry");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Carry
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(hero.replace(".png", ""), "mid");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Mid
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "offlane"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Offlane
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Support
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "hard_support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Hard Support
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setShowRadiantRoles(true);
                            setShowDireRoles(false);
                          }}
                          style={{
                            fontSize: "0.5rem",
                            padding: "0rem",
                            margin: "0rem",
                            background: "green",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: "bold",
                            alignContent: "center",
                            alignItems: "center",
                            maxHeight: "1.5rem",
                          }}
                        >
                          <AddIcon
                            style={{
                              maxWidth: "1rem",
                              minWidth: "1rem",
                              width: "1rem",
                              maxHeight: "1.5rem",
                            }}
                          />
                          Radiant
                        </Button>
                      </>
                    )}
                  </div>
                  <div style={{ fontSize: "0.5rem", display: "flex" }}>
                    {showDireRoles ? (
                      <>
                        <div className="role_div">
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "carry");
                            }}
                          >
                            Carry
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "mid");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Mid
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "offlane");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Offlane
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "support");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Support
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(
                                hero.replace(".png", ""),
                                "hard_support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Hard Support
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            // onAddToDire(hero.replace(".png", "")); // Add to Dire without .png
                            setShowRadiantRoles(false);
                            setShowDireRoles(true);
                          }}
                          style={{
                            fontSize: "0.5rem",
                            padding: "0rem",
                            margin: "0rem",
                            background: "red",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            maxHeight: "1.5rem",
                            fontWeight: "bold",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AddIcon
                            style={{
                              maxWidth: "1rem",
                              minWidth: "1rem",
                              width: "1rem",
                              maxHeight: "1.5rem",
                            }}
                          />
                          Dire
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface Hero {
  heroName: string;
  role: string;
}

interface Player {
  role: string;
  name: string;
  rank: string;
}
interface Team {
  name: string;
  rank: string;
  winrate: string;
  avgKills: string;
  logo: string;
  players: Player[];
}

interface TeamE {
  team_id: number;
  rating: number;
  wins: number;
  losses: number;
  name: string;
  tag: string;

  logo_url: string | null;
  last_match_time: number;
}

const DraftPicker: React.FC = () => {
  const [isAnalyseClicked, setIsAnalyseClicked] = useState<boolean>(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(false);
  const [analyticsData, setAnalyticsData] = useState<any>();
  const [draftDetails, setDraftDetails] = useState<any>();
  const [playerHeroPairs, setPlayerHeroPairs] = useState([
    { playerId: 100058342, heroId: 73 },
    { playerId: 898455820, heroId: 16 },
    { playerId: 183719386, heroId: 98 },
    { playerId: 25907144, heroId: 26 },
    { playerId: 10366616, heroId: 58 },
  ]);
  const [direPlayersWinrate, setDirePlayersWinrate] = useState<any[]>([]);
  const [radiantPlayersWinrate, setRadiantlayersWinrate] = useState<any[]>([]);

  const [showAnalysis, setShowAnalysis] = useState<boolean>(false);
//   const [teamRadiant, setTeamRadiant] = useState<string[]>(["ursa","primal_beast","beastmaster", "rubick","rattletrap"]);
//   const [teamFullRadiant, setTeamFullRadiant] = useState<Hero[]>([
//     {
//         heroName: "ursa",
//         role: "carry"
//     },
//     {
//         heroName: "primal_beast",
//         role: "mid"
//     },
//     {
//         heroName: "beastmaster",
//         role: "offlane"
//     },
//     {
//         heroName: "rubick",
//         role: "support"
//     },
//     {
//         heroName: "rattletrap",
//         role: "hard_support"
//     }
// ]);
  // const [teamDire, setTeamDire] = useState<string[]>(["shadow_fiend","storm_spirit","mars","hoodwink","naga_siren"]);
//   const [teamFullDire, setTeamFullDire] = useState<Hero[]>([
//     {
//         heroName: "shadow_fiend",
//         role: "carry"
//     },
//     {
//         heroName: "storm_spirit",
//         role: "mid"
//     },
//     {
//         heroName: "mars",
//         role: "offlane"
//     },
//     {
//         heroName: "hoodwink",
//         role: "support"
//     },
//     {
//         heroName: "naga_siren",
//         role: "hard_support"
//     }
// ]);
  const [teamDire, setTeamDire] = useState<string[]>([]);
  const [teamFullDire, setTeamFullDire] = useState<Hero[]>([]);
  const [teamRadiant, setTeamRadiant] = useState<string[]>([]);
  const [teamFullRadiant, setTeamFullRadiant] = useState<Hero[]>([]);
  
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [team1, setTeam1] = useState<TeamE>(all_teams[0]);
  const [team2, setTeam2] = useState<TeamE>(all_teams[1]);
  const handleTeamChange = (index: number, teamName: string) => {
    const selectedTeam = all_teams.find((team) => team.name === teamName);
    if (selectedTeam) {
      if (index === 1) setTeam1(selectedTeam);
      else setTeam2(selectedTeam);
    }
  };

  const handleAddToRadiant = (heroName: string, role: string) => {
    if (teamRadiant.length > 4) {
      console.log(">>>>>>>>>>>>>>>>>");
      setToastMessage("Already 5 Radiant Heroes");
      handleOpen();
      return;
    }
    if (!teamRadiant.includes(heroName)) {
      setTeamRadiant((prev) => [...prev, heroName]);
      setTeamFullRadiant((prev) => {
        if (prev.some((hero) => hero.heroName === heroName)) {
          return prev;
        }
        return [...prev, { heroName, role }]; // This works if heroName and role are variables in scope
      });
      setSearchQuery("");
    }
  };

  const handleAddToDire = (heroName: string, role: string) => {
    if (teamDire.length > 4) {
      console.log(">>>>>>>>>>>>>>>>>");
      setToastMessage("Already 5 Dire Heroes");
      handleOpen();
      return;
    }

    if (!teamDire.includes(heroName)) {
      setTeamDire((prev) => [...prev, heroName]);
      setTeamFullDire((prev) => {
        if (prev.some((hero) => hero.heroName === heroName)) {
          return prev;
        }
        return [...prev, { heroName, role }]; // This works if heroName and role are variables in scope
      });
      setSearchQuery("");
    }
  };

  const [open, setOpen] = useState(false);
  const [toast_message, setToastMessage] = useState("empty_fields");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveFromRadiant = (heroName: string) => {
    setTeamRadiant((prev) => prev.filter((hero) => hero !== heroName));
    setTeamFullRadiant((prev) =>
      prev.filter((hero) => hero.heroName !== heroName)
    );
    setIsAnalyseClicked(false);
  };

  const handleRemoveFromDire = (heroName: string) => {
    setTeamDire((prev) => prev.filter((hero) => hero !== heroName));
    setTeamFullDire((prev) =>
      prev.filter((hero) => hero.heroName !== heroName)
    );
    setIsAnalyseClicked(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase()); // Convert to lowercase for case-insensitive search
  };

  const filterHeroes = (heroes: string[]) => {
    return heroes.filter((hero) =>
      hero.replace(".png", "").toLowerCase().includes(searchQuery)
    );
  };

  const handleAnalysingDraft = async () => {
    try {
      setLoadingAnalysis(true);
      ;
      const response = await fetch(
        "https://server.dotalive.org/analyse-draft",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            radiant_draft: teamFullRadiant,
            dire_draft: teamFullDire,
            radiant_team: team1,
            dire_team: team2,
          }),
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json(); // Parse JSON response
        console.log("Success:", jsonResponse);
        setAnalyticsData(jsonResponse); // Store the parsed JSON object directly in the state
        setIsAnalyseClicked(true);
        setLoadingAnalysis(false);
      } else {
        console.error("Failed to create image on server");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }

    console.log("Articles saved successfully");
    // SetLoading(false)
  };

  const handleAnalysingDraftOverview = async () => {
      handleAnalysePlayerWinrate()
 
      console.log("console.log(teamFullRadiant)",teamFullRadiant)
      console.log("console.log(teamFullDire)",teamFullDire)

      try {
      setLoadingAnalysis(true);
      ;
      // https://server.dotalive.org/analyse-draft-overview
      const response = await fetch(
        "https://server.dotalive.org/analyse-draft-overview",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            radiant_draft: teamFullRadiant,
            dire_draft: teamFullDire,
            radiant_team: team1,
            dire_team: team2,
          }),
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json(); // Parse JSON response
        console.log("Success:", jsonResponse);
        // setAnalyticsData(jsonResponse); // Store the parsed JSON object directly in the state
        ;
        setDraftDetails(JSON.parse(jsonResponse));
        setIsAnalyseClicked(true);
        setLoadingAnalysis(false);
      } else {
        console.error("Failed to create image on server");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }

    console.log("Articles saved successfully");
    // SetLoading(false)
  };
  const handleAnalysePlayerWinrate = async () => {
    try {

      let playerHeroPairs = [] as any[];
      let activePlayers = [] as any[];

      for (let j = 0; j < players.length; j++) {

        if (String(players[j].team_id) === String(team2.team_id)) {
          console.log(`Player ${players[j].name} matches team2.team_id`);
          activePlayers.push(players[j]);
        }
      }

      for (let i = 0; i < teamDire.length; i++) {
        let heroId = heroes.filter((hero) => {

          if (hero.name === "npc_dota_hero_" + teamDire[i]) { return hero }
        })

        switch (i) {
          case 0:
            playerHeroPairs.push({
              playerId: activePlayers[0].account_id, heroId: heroId[0].id
            })
            break;
          case 1:
            playerHeroPairs.push({
              playerId: activePlayers[1].account_id, heroId: heroId[0].id
            })
            break;
          case 2:
            playerHeroPairs.push({
              playerId: activePlayers[2].account_id, heroId: heroId[0].id
            })
            break;
          case 3:
            playerHeroPairs.push({
              playerId: activePlayers[3].account_id, heroId: heroId[0].id
            })
            break;
          case 4:
            playerHeroPairs.push({
              playerId: activePlayers[4].account_id, heroId: heroId[0].id
            })
            break;
        }
        console.log(playerHeroPairs)
      }

      console.log("Starting analysis...");
      const response = await fetch("https://server.dotalive.org/analyse-draft-players", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playerHeroPairs, // Send the pairs from state
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setDirePlayersWinrate(data.data); // Update results state
        console.log("Analysis results:", data.data);
      } else {
        console.error("Failed to analyze player win rates.");
      }
    } catch (error) {
      console.error("Error analyzing win rates:", error);
    } finally {
    }
    try {

      let playerHeroPairs = [] as any[];
      let activePlayers = [] as any[];

      for (let j = 0; j < players.length; j++) {
        console.log(
          `Checking player ${players[j].name} with team_id ${players[j].team_id} against team2.team_id ${team1.team_id}`
        );
        if (String(players[j].team_id) === String(team1.team_id)) {
          console.log(`Player ${players[j].name} matches team2.team_id`);
          activePlayers.push(players[j]);
        }
      }

      for (let i = 0; i < teamRadiant.length; i++) {
        let heroId = heroes.filter((hero) => {

          if (hero.name === "npc_dota_hero_" + teamRadiant[i]) { return hero }
        })

        switch (i) {
          case 0:
            playerHeroPairs.push({
              playerId: activePlayers[0].account_id, heroId: heroId[0].id
            })
            break;
          case 1:
            playerHeroPairs.push({
              playerId: activePlayers[1].account_id, heroId: heroId[0].id
            })
            break;
          case 2:
            playerHeroPairs.push({
              playerId: activePlayers[2].account_id, heroId: heroId[0].id
            })
            break;
          case 3:
            playerHeroPairs.push({
              playerId: activePlayers[3].account_id, heroId: heroId[0].id
            })
            break;
          case 4:
            playerHeroPairs.push({
              playerId: activePlayers[4].account_id, heroId: heroId[0].id
            })
            break;
        }
        console.log(playerHeroPairs)
      }

      console.log("Starting analysis...");
      const response = await fetch("https://server.dotalive.org/analyse-draft-players", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playerHeroPairs, // Send the pairs from state
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setRadiantlayersWinrate(data.data); // Update results state
        console.log("Analysis results:", data.data);
      } else {
        console.error("Failed to analyze player win rates.");
      }
    } catch (error) {
      console.error("Error analyzing win rates:", error);
    } finally {
      
    }
  };


  const strength = [
    "alchemist.png",
    "axe.png",
    "bristleback.png",
    "centaur.png",
    "chaos_knight.png",
    "dawnbreaker.png",
    "doom_bringer.png",
    "dragon_knight.png",
    "earth_spirit.png",
    "earthshaker.png",
    "elder_titan.png",
    "huskar.png",
    "kunkka.png",
    "ogre_magi.png",
    "legion_commander.png",
    "life_stealer.png",
    "mars.png",
    "night_stalker.png",
    "omniknight.png",
    "primal_beast.png",
    "pudge.png",
    "slardar.png",
    "spirit_breaker.png",
    "sven.png",
    "tidehunter.png",
    "shredder.png",
    "tiny.png",
    "treant.png",
    "tusk.png",
    "abyssal_underlord.png",
    "undying.png",
    "skeleton_king.png",
  ];

  const intelligence = [
    "ancient_apparition.png",
    "crystal_maiden.png",
    "death_prophet.png",
    "disruptor.png",
    "enchantress.png",
    "grimstroke.png",
    "jakiro.png",
    "keeper_of_the_light.png",
    "leshrac.png",
    "lich.png",
    "lina.png",
    "lion.png",
    "muerta.png",
    "furion.png",
    "necrolyte.png",
    "oracle.png",
    "obsidian_destroyer.png",
    "puck.png",
    "pugna.png",
    "queenofpain.png",
    "rubick.png",
    "shadow_demon.png",
    "shadow_shaman.png",
    "silencer.png",
    "skywrath_mage.png",
    "storm_spirit.png",
    "tinker.png",
    "warlock.png",
    "witch_doctor.png",
    "zuus.png",
  ];

  const agility = [
    "antimage.png",
    "arc_warden.png",
    "bloodseeker.png",
    "bounty_hunter.png",
    "clinkz.png",
    "drow_ranger.png",
    "ember_spirit.png",
    "faceless_void.png",
    "gyrocopter.png",
    "hoodwink.png",
    "juggernaut.png",
    "luna.png",
    "medusa.png",
    "meepo.png",
    "monkey_king.png",
    "morphling.png",
    "naga_siren.png",
    "phantom_assassin.png",
    "phantom_lancer.png",
    "razor.png",
    "riki.png",
    "shadow_fiend.png",
    "slark.png",
    "sniper.png",
    "spectre.png",
    "templar_assassin.png",
    "terrorblade.png",
    "troll_warlord.png",
    "ursa.png",
    "viper.png",
    "weaver.png",
  ];

  const universal = [
    "abaddon.png",
    "bane.png",
    "batrider.png",
    "beastmaster.png",
    "brewmaster.png",
    "broodmother.png",
    "chen.png",
    "rattletrap.png",
    "dark_seer.png",
    "dark_willow.png",
    "dazzle.png",
    "enigma.png",
    "invoker.png",
    "wisp.png",
    "lone_druid.png",
    "lycan.png",
    "magnataur.png",
    "marci.png",
    "mirana.png",
    "nyx_assassin.png",
    "pangolier.png",
    "phoenix.png",
    "sand_king.png",
    "snapfire.png",
    "techies.png",
    "vengefulspirit.png",
    "venomancer.png",
    "visage.png",
    "void_spirit.png",
    "windrunner.png",
    "winter_wyvern.png",
  ];

  function renderPlayerWinrate(player_id: number,is_dire:boolean): any {
    let wirnate = `0`
    let total = is_dire ? direPlayersWinrate[player_id].totalMatches : radiantPlayersWinrate[player_id].totalMatches
    let win_total = is_dire ? direPlayersWinrate[player_id].wins : radiantPlayersWinrate[player_id].wins
    wirnate = String(((win_total / total) * 100).toFixed(0))
    return <span style={{ background: Number(wirnate) > 49 ? "green" : 'red', color: 'white', padding: '0.2rem 0.2rem', borderRadius: '10px' }}> {"Win:" + String(Number(wirnate) > 0 ? wirnate:"0")+ "%"} </span>
  }


  function renderPlayerTotalGames(player_id: number,is_dire:boolean): string {
    
    if (direPlayersWinrate != null && direPlayersWinrate.length > 0) {
      // let player_stats = direPlayersWinrate.filter(e=>e.playerId===player_id)
      return String(is_dire ? direPlayersWinrate[player_id].totalMatches : radiantPlayersWinrate[player_id].totalMatches) + " Games"
    }
    return ""
  }

  return (
    <div style={{ minHeight: "100vh" }}>
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <TextField
          label="Search Heroes"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ background: "white", width: "50%" }}
        />
      </div>
      {/* -----------------------------------------------HEROES-------------------------------------------------------- */}
      {isAnalyseClicked ? (
        <></>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem 8rem 0.5rem 8rem",
            }}
          >
            <HeroCategory
              title="Strength"
              icon="str.png"
              heroes={filterHeroes(strength)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
            <HeroCategory
              title="Intelligence"
              icon="int.png"
              heroes={filterHeroes(intelligence)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 8rem 0.5rem 8rem",
              marginBottom: "1rem",
            }}
          >
            <HeroCategory
              title="Agility"
              icon="agi.png"
              heroes={filterHeroes(agility)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
            <HeroCategory
              title="Universal"
              icon="all.png"
              heroes={filterHeroes(universal)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
          </div>
        </>
      )}

      {/* -----------------------------------------------DRAFT-------------------------------------------------------- */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          padding: "1rem 3rem 1rem 3rem",
          marginBottom: "1rem",
          justifyContent: "center",
          borderTop: "1px solid lightgray",
          borderBottom: "2px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            minWidth: "38rem",
            justifyContent: "center",
          }}
        >
          {teamFullRadiant.map((radiant_hero,index) => {
            function renderRole(role: string): string {
              switch (role) {
                case "carry":
                  return "Carry";
                case "mid":
                  return "Mid";
                case "offlane":
                  return "Offlane";
                case "support":
                  return "Support";
                case "hard_support":
                  return "Hard support";
              }
              return "";
            }

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    userSelect: "none",
                  }}
                >
                  <div
                    className="radient_hero_img"
                    onClick={() => {
                      handleRemoveFromRadiant(radiant_hero.heroName);
                    }}
                  >
                    X
                  </div>
                  <img
                    key={radiant_hero.heroName}
                    src={
                      `/assets/portraits/npc_dota_hero_` +
                      radiant_hero.heroName +
                      `.png`
                    }
                    alt={radiant_hero.heroName}
                    style={{
                      width: "7rem",
                      height: "10rem",
                      // borderRadius: "5px",
                      transition: "transform 0.2s ease, z-index 0.2s",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      padding: "0.2rem 0.5rem",
                      textAlign: "center",
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '3px'
                    }}
                  >
                    <span style={{
                      padding: "0.2rem 0.5rem",
                      background: "lightgray",
                    }}>{renderRole(radiant_hero.role)}</span>
                   {radiantPlayersWinrate.length > 0 ? <> <span>{renderPlayerTotalGames(index,false)}</span>
                   {renderPlayerWinrate(index,false)}</>:<></>}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div
          style={{
            maxHeight: "5rem",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "28rem",
          }}
        >
          <h2 style={{ color: "Gray", margin: "0px", textAlign: "center" }}>
            TEAMS
          </h2>
          <div
            style={{
              background: "white",
              maxHeight: "2rem",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              minWidth: "30rem",
            }}
          >
            <Select
              value={team1.name}
              onChange={(e) => handleTeamChange(1, e.target.value as string)}
              fullWidth
              renderValue={(selected) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={String(
                      all_teams.find((team) => team.name === selected)
                        ?.logo_url
                    )}
                    alt=""
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                      background: "black",
                    }}
                  />
                  {selected}
                </div>
              )}
            >
              {all_teams.map((team) => (
                <MenuItem key={team.name} value={team.name}>
                  <img
                    src={String(team.logo_url)}
                    alt={team.name}
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                      background: "black",
                    }}
                  />
                  {team.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={team2.name}
              onChange={(e) => handleTeamChange(2, e.target.value as string)}
              fullWidth
              renderValue={(selected) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={String(
                      all_teams.find((team) => team.name === selected)?.logo_url
                    )}
                    alt=""
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                      background: "black",
                    }}
                  />
                  {selected}
                </div>
              )}
            >
              {all_teams.map((team) => (
                <MenuItem key={team.name} value={team.name}>
                  <img
                    src={String(team.logo_url)}
                    alt={team.name}
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                      background: "black",
                    }}
                  />
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div  style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingTop:'0.5rem'}}>
          <div style={{display:'flex', flexDirection:'column', gap:'6px', textAlign:'center', justifyContent:'center'}}>
          {team1.team_id}    
  
               {players.map((player) => {
                 if (player.is_current_team_member && player.team_id == team1.team_id) {
                   return (
                     <span key={player.account_id}>
                           <b>{player.name ?? "Unknown"}</b>
                           </span>
                   );
                 } else {
                   return <></>;
                 }
               })}
            
           </div>
           
              <div style={{display:'flex', flexDirection:'column', gap:'6px', textAlign:'center', justifyContent:'center'}}>
              {team2.team_id}    
                  {players.map((player) => {
                    if (player.is_current_team_member&& player.team_id == team2.team_id) {
                      return (
                        <span  key={player.account_id}>
                           <b>{player.name ?? "Unknown"}</b>
                        </span>
                      );
                    } else {
                      return <></>;
                    }
                  })}
               
              </div>
          </div>
        </div>
        {/* --------------HEROES------------------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            minWidth: "38rem",
            justifyContent: "center",
          }}
        >
          {teamFullDire.map((dire_hero,index) => {
            function renderRole(role: string): string {
              switch (role) {
                case "carry":
                  return "Carry";
                case "mid":
                  return "Mid";
                case "offlane":
                  return "Offlane";
                case "support":
                  return "Support";
                case "hard_support":
                  return "Hard support";
              }
              return "";
            }

            

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    userSelect: "none",
                  }}
                >
                  <div
                    className="radient_hero_img"
                    onClick={() => {
                      handleRemoveFromDire(dire_hero.heroName);
                    }}
                  >
                    X
                  </div>
                  <img
                    key={dire_hero.heroName}
                    src={
                      `/assets/portraits/npc_dota_hero_` +
                      dire_hero.heroName +
                      `.png`
                    }
                    alt={dire_hero.heroName}
                    style={{
                      width: "7rem",
                      height: "10rem",
                      // borderRadius: "5px",
                      transition: "transform 0.2s ease, z-index 0.2s",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      padding: "0.2rem 0.5rem",
                      textAlign: "center",
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '3px'
                    }}
                  >
                    <span style={{
                      padding: "0.2rem 0.5rem",
                      background: "lightgray",
                    }}>{renderRole(dire_hero.role)}</span>
                   {direPlayersWinrate.length > 0 ? <> <span>{renderPlayerTotalGames(index,true)}</span>
                   {renderPlayerWinrate(index,true)}</>:<></>}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <CounterPickList direTeam={teamFullDire} radiantTeam={teamFullRadiant} />,
      
      {/* -----------------------------------------------ANALYTICS-------------------------------------------------------- */}

      {/* <div>
        <h1>Match List</h1>
        <ul>
          {matches.map((match) => (
            <li key={match.match_id}>
              <h2>{match.league_name}</h2>
              <p>Match ID: {match.match_id}</p>
              <p>Radiant Win: {match.radiant_win ? "Yes" : "No"}</p>
              <p>Radiant Score: {match.radiant_score}</p>
              <p>Dire Score: {match.dire_score}</p>
              <p>Opposing Team: {match.opposing_team_name}</p>
              <img
                src={match.opposing_team_logo}
                alt={`${match.opposing_team_name} logo`}
                width="100"
              />
            </li>
          ))}
        </ul>
      </div> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          padding: "1rem 3rem 0rem 3rem",
          marginBottom: "1rem",
          justifyContent: "end",
        }}
      >
        <Button variant="contained" onClick={handleAnalysePlayerWinrate}>
          {loadingAnalysis ? <>Loading...</> : <>Analyse Player Winrate</>}
        </Button>
      </div> */}
      {/* {direPlayersWinrate && (
        <div>
          <h2>Analysis Results</h2>
          
          <table >
            <thead>
              <tr>
                <th>Player ID</th>
                <th>Hero ID</th>
                <th>Total Matches</th>
                <th>Wins</th>
                <th>Win Rate (%)</th>
              </tr>
            </thead>
            <tbody>
              { direPlayersWinrate?.map((result:any, index:any) => (
                <tr key={index}>
                  <td>{result.playerId}</td>
                  <td>{result.heroId}</td>
                  <td>{result.totalMatches}</td>
                  <td>{result.wins}</td>
                  <td>{result.winRate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )} */}
      {teamDire.length == 5 && teamRadiant.length == 5 ? (
        <>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 3rem 0rem 3rem",
              marginBottom: "1rem",
              justifyContent: "end",
            }}
          >
            <Button variant="contained" onClick={handleAnalysingDraft}>
              {loadingAnalysis ? <>Loading...</> : <>Analyse this draft</>}
            </Button>
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 3rem 0rem 3rem",
              marginBottom: "1rem",
              justifyContent: "end",
            }}
          >
            <Button variant="contained" onClick={handleAnalysingDraftOverview}>
              {loadingAnalysis ? <>Loading...</> : <>Analyse Draft Overview</>}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
      {draftDetails != null ? (
        <>
          <DraftDetails
            analysis={draftDetails.analysis}
            team1={team1.name}
            team2={team2.name}
            conclusion={draftDetails.conclusion}
            prediction={draftDetails.prediction}
            summary={draftDetails.summary}
          />
        </>
      ) : (
        <></>
      )}
      {analyticsData != null ? (
        <>
          {" "}
          <DraftAnalytics
            team1={team1.name}
            team2={team2.name}
            analysis={
              typeof analyticsData === "string"
                ? JSON.parse(analyticsData)
                : analyticsData
            }
          />
        </>
      ) : (
        <></>
      )}

      {/* <div style={{ textAlign: "center", padding: "20px" }}>
        <h1>Team Information</h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {all_teams
            .filter((team: any) => {
              // Calculate the timestamp for 6 months ago
              const sixMonthsAgo = Date.now() - 6 * 30 * 24 * 60 * 60 * 1000; // Approximate 6 months in milliseconds
              return team.last_match_time * 1000 >= sixMonthsAgo; // Filter teams with last_match_time within 6 months
            })
            .sort((a: any, b: any) => {
              // Calculate "points" as rating + wins
              const aPoints = a.rating + a.wins;
              const bPoints = b.rating + b.wins;

              // First sort by points in descending order
              if (bPoints !== aPoints) {
                return bPoints - aPoints;
              }

              // If points are equal, sort by last_match_time in descending order
              return b.last_match_time - a.last_match_time;
            })
            .map((team: any) => (
              <div
                key={team.team_id}
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "250px",
                  textAlign: "left",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={team.logo_url || "https://via.placeholder.com/150"}
                  alt={team.name}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    background: "black",
                  }}
                />
                <h2 style={{ fontSize: "1.2em" }}>{team.name}</h2>
                <p>
                  <strong>Id:</strong> {team.team_id || "N/A"}
                </p>
                <p>
                  <strong>Tag:</strong> {team.tag || "N/A"}
                </p>
                <p>
                  <strong>Rating:</strong> {team.rating}
                </p>
                <p>
                  <strong>Record:</strong> {team.wins} Wins / {team.losses}{" "}
                  Losses
                </p>
                <p>
                  <strong>Last Match:</strong>{" "}
                  {new Date(team.last_match_time * 1000).toLocaleDateString()}
                </p>
              </div>
            ))}
        </div>
      </div> */}

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DraftPicker;
