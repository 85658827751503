import React from "react";
import { heroCounterpicks } from "./CounterPicks.tsx";

interface Hero {
  heroName: string;
  role: string;
}

interface CounterPick {
  hero: string;
  counterpicks: { hero: string; reason: string }[];
}

interface TeamProps {
  direTeam: Hero[];
  radiantTeam: Hero[];
}

const CounterPickList: React.FC<TeamProps> = ({ direTeam, radiantTeam }) => {
  function renderHeroCounters(hero_name: string, is_radiant: boolean): any {
    if (direTeam.length == 0 || radiantTeam.length == 0) {
      return;
    }
    // 
    let counters = [];
    let all_counters = heroCounterpicks.filter(
      (e) => e.hero.toLocaleLowerCase().replaceAll(" ","_") === hero_name
    )[0].counterpicks;

    console.log("All hero counters from the hero list:  ", all_counters)
    if (is_radiant){
        for (let i = 0; i < direTeam.length; i++) {
            //   ;
              let is_counter = all_counters.filter(
                (counter) =>
                  counter.hero.toLocaleLowerCase().replaceAll(" ", "_") ===
                  direTeam[i].heroName
              );
              if (is_counter.length > 0) {
                counters.push(is_counter[0]);
              }
            }
        
    } else {
        for (let i = 0; i < radiantTeam.length; i++) {
            //   ;
              let is_counter = all_counters.filter(
                (counter) =>
                  counter.hero.toLocaleLowerCase().replaceAll(" ", "_") ===
                  radiantTeam[i].heroName
              );
              if (is_counter.length > 0) {
                counters.push(is_counter[0]);
              }
            }
        
    }
   
    if (counters.length > 0) {
      return (
        <>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
              textAlign: "center",
              justifyItems: "center",
              alignContent: "center",
              alignItems: "center",
              borderBottom: "1px solid gray",
              marginBottom: "0.3rem",
            }}
          >
          
            <img
              key={hero_name}
              src={
                `/assets/img/heroes/` + hero_name.toLocaleLowerCase() + `.png`
              }
              alt={"counter" + hero_name.toLocaleLowerCase()}
              style={{ height: "3rem", borderRadius: "10px" }}
            />{" "}
            <h3>Countered by: </h3>{" "}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "7px" }}>
            {counters.map((counter) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    gap: "5px",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    key={counter.hero}
                    src={
                      `/assets/img/heroes/` +
                      counter.hero.toLocaleLowerCase().replaceAll(" ", "_") +
                      `.png`
                    }
                    alt={"counter" + counter.hero.toLocaleLowerCase()}
                    style={{ width: "5rem", borderRadius:'10px' }}
                  />{" "}
                  <b>{counter.reason}</b>
                </div>
              );
            })}
          </div>
        </>
      );
    }
    // return <h1 style={{color:'black'}}>asdasds</h1>
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        margin: "0rem 8rem",
        gap: "15rem",
      }}
    >
      <div style={{  flexDirection: "column", gap: "10px" }}>
        {radiantTeam.map((hero) => {
            console.log("Map the hero----",hero.heroName)
          return <div key={hero.heroName}>{renderHeroCounters(hero.heroName.toLocaleLowerCase().replaceAll(" ", "_"), true)}</div>;
        })}
       
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

      {direTeam.map((hero) => {
            console.log("Map the hero----",hero.heroName)
          return <div key={hero.heroName}>{renderHeroCounters(hero.heroName.toLocaleLowerCase().replaceAll(" ", "_"), false)}</div>;
        })}
        </div>
    </div>
  );
};

export default CounterPickList;
